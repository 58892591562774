<template>
  <div
    class="WheelOfLife"
    :class="{ showLegend: !hideLegend }">
    <canvas ref="chart" />
  </div>
</template>

<script>
import Chart from 'chart.js';
import categoryThemes from '@/category-themes';
import { categoryProgress } from '@/medals';
import Color from 'color';

export default {
    props: {
        hideLegend: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        datasets() {
            const categories = this.$store.state.moduleApp.categories;
            const progress = Object.keys(categories).map(cat => {
                return {
                    label: cat,
                    progress: categories[cat].lifeskills.reduce(categoryProgress, { total: 0, lifeskills: [] })
                };
            });
            let totalProgress = progress.reduce((total, p) => {
                return p.progress.total > total ? p.progress.total : total;
            }, 0);
            const max = Math.ceil(totalProgress / 5) * 5; // round two nearest 5
            return [
                {
                    label: 'Your progress',
                    borderColor: '#fff',
                    borderWidth: 1,
                    backgroundColor: progress.map(p => categoryThemes[p.label].color),
                    data: progress.map(p => p.progress.total)
                },
                {
                    borderColor: '#fff',
                    borderWidth: 0,
                    backgroundColor: progress.map(p => opacitate(categoryThemes[p.label].color)),
                    data: progress.map(() => max)
                }
            ];
        }
    },
    mounted() {
        const categories = Object.keys(this.$store.state.moduleApp.categories);
        const chart = new Chart(this.$refs.chart, {
            // eslint-disable-line no-unused-vars
            type: 'polarArea',
            data: {
                datasets: this.datasets,
                labels: this.hideLegend ? null : categories.map(c => this.$t(`dashboard.general.categoryLabels.${c}`))
            },
            options: {
                onClick: (e, elems) => {
                    // console.log('chart clicked', e, elems)
                },
                tooltips: {
                    enabled: false
                },
                responsive: true,
                aspectRatio: this.hideLegend ? 1 : undefined,
                legend: this.hideLegend
                    ? null
                    : {
                          position: 'left',
                          labels: {
                              fontColor: '#fff'
                          }
                      },
                scale: {
                    ticks: {
                        display: !this.hideLegend,
                        fontColor: '#fff',
                        backdropColor: 'rgba(0, 0, 0, 0)',
                        backdropPaddingX: 4,
                        backdropPaddingY: 4,
                        beginAtZero: false,
                        // min: 0,
                        // max: 100,
                        // maxTicksLimit: 10,
                        // precision: 10,
                        stepSize: 10,
                        // suggestedMax: 100,
                        // suggestedMin: 0,
                        showLabelBackdrop: true,
                        callback: (val, index, values) => {
                            return `${val}%`;
                        }
                    },
                    gridLines: {
                        display: false,
                        color: '#fff'
                    }
                }
            }
        });
    },

    methods: {}
};

const opacitate = hex => {
    return Color(hex).alpha(0.3).rgb().string();
};
</script>

<style lang="scss">
.WheelOfLife {
    &.showLegend {
        min-height: 200px;
        min-width: 335px;
    }
}
</style>
