import store from '@/store'

const AccountCheckoutView = () => import('./views/AccountCheckoutView')
const AccountExpiredView = () => import('./views/AccountExpiredView')
const AccountForgotView = () => import('./views/AccountForgotView')
const AccountLoginView = () => import('./views/AccountLoginView')
const AccountLogoutView = () => import('./views/AccountLogoutView')
const AccountPortalView = () => import('./views/AccountPortalView')
const AccountResetView = () => import('./views/AccountResetView')
const AccountSignupView = () => import('./views/AccountSignupView')
const AccountSignupPartnerView = () => import('./views/AccountSignupPartnerView')
const AccountUpdatedView = () => import('./views/AccountUpdatedView')
const AccountVoucherView = () => import('./views/AccountVoucherView')
const AccountMembershipView = () => import('./views/AccountMembershipView')

function redirectIfAuthenticated (to, from, next) {
  if (store.state.moduleAuth.isAuthenticated) {
    next({
      path: to.query.next || '/'
    })
  } else {
    next()
  }
}

function redirectIfSubscribed (to, from, next) {
  if (store.getters['moduleAuth/isSubscribed']) {
    next({
      path: to.query.next || '/'
    })
  } else {
    next()
  }
}

function standardPartnerRouteFactory ({ name, lang, hideFacebookLogin=false }) {
  return {
    path: `/partner/${name}`,
    component: AccountSignupPartnerView,
    meta: {
      requiresAuth: false
    },
    props: {
      partner: name,
      voucher: name,
      language: lang,
      memoransierForward: true,
      hideFacebookLogin,
    },
    async beforeEnter (to, from, next) {
      if (store.state.moduleAuth.isAuthenticated) {
        await store.dispatch('moduleAuth/updateMe', { roles: [name] })
        if (store.getters['moduleMemoransier/publishedGames'].length === 0) {
          await store.dispatch('moduleMemoransier/fetchAll')
        }
        const game = store.getters['moduleMemoransier/publishedGames'].find(g => g.role === name)
        if (game) {
          next({
            name: 'MemoransierLobby',
            params: { gameId: game.id }
          })
        } else {
          next({
            path: to.query.next || '/'
          })
        }
      } else {
        next()
      }
    }
  }
}

export default [
  // Routes without auth:

  {
    path: '/login',
    name: 'AccountLoginView',
    component: AccountLoginView,
    meta: { requiresAuth: false },
    beforeEnter: redirectIfAuthenticated
  },

  {
    path: '/logout',
    name: 'AccountLogoutView',
    component: AccountLogoutView,
    meta: { requiresAuth: false }
  },

  {
    path: '/forgot',
    name: 'AccountForgotView',
    component: AccountForgotView,
    meta: { requiresAuth: false },
    beforeEnter: redirectIfAuthenticated
  },

  {
    path: '/reset/:token',
    name: 'AccountResetView',
    props: true,
    component: AccountResetView,
    meta: { requiresAuth: false },
    beforeEnter: redirectIfAuthenticated
  },

  {
    path: '/signup',
    name: 'AccountSignupView-basic',
    component: AccountSignupView,
    meta: { requiresAuth: false },
    beforeEnter: redirectIfAuthenticated
  },

  {
    path: '/signup/:plan',
    component: AccountSignupView,
    meta: { requiresAuth: false },
    beforeEnter: redirectIfAuthenticated
  },

  {
    path: '/signup/:plan/:language/:currency',
    component: AccountSignupView,
    meta: { requiresAuth: false },
    beforeEnter: redirectIfAuthenticated
  },

  {
    path: '/signup/:plan/:language/:currency/:product',
    component: AccountSignupView,
    meta: { requiresAuth: false },
    beforeEnter: redirectIfAuthenticated
  },

  {
    path: '/eika',
    redirect: { path: 'partner' },
    meta: { requiresAuth: false },
    beforeEnter: redirectIfAuthenticated
  },

  {
    path: '/partner',
    name: 'signup-partner',
    component: AccountSignupView,
    meta: {
      requiresAuth: false
    },
    props: {
      voucherAfterSignup: true,
      hideLoginLink: true,
      hideFacebookLogin: true
    },
    beforeEnter: redirectIfAuthenticated
  },

  {
    path: '/superoffice',
    component: AccountSignupView,
    meta: {
      requiresAuth: false
    },
    props: {
      voucher: 'superoffice',
      hideLoginLink: true,
      hideFacebookLogin: true,
      language: 'en'
    },
    beforeEnter: redirectIfAuthenticated
  },

  {
    path: '/partner/superofficedagen',
    component: AccountSignupPartnerView,
    meta: {
      requiresAuth: false
    },
    props: {
      partner: 'superoffice',
      voucher: 'superofficedagen',
      hideFacebookLogin: true,
      language: 'no'
    },
    async beforeEnter (to, from, next) {
      if (store.state.moduleAuth.isAuthenticated) {
        await store.dispatch('moduleAuth/updateMe', { roles: ['superofficedagen'] })
        next({
          path: to.query.next || '/'
        })
      } else {
        next()
      }
    }
  },

  {
    path: '/partner/techdata',
    component: AccountSignupPartnerView,
    meta: {
      requiresAuth: false
    },
    props: {
      partner: 'techdata',
      voucher: 'techdata',
      // hideFacebookLogin: true,
      language: 'no'
    },
    async beforeEnter (to, from, next) {
      if (store.state.moduleAuth.isAuthenticated) {
        await store.dispatch('moduleAuth/updateMe', { roles: ['techdata'] })
        next({
          path: to.query.next || '/'
        })
      } else {
        next()
      }
    }
  },

  standardPartnerRouteFactory({ name: 'storebrand', lang: 'no', hideFacebookLogin: true }),

  standardPartnerRouteFactory({ name: 'aim2north', lang: 'en' }),

  standardPartnerRouteFactory({ name: 'technipfmc', lang: 'en' }),

  standardPartnerRouteFactory({ name: 'xledger', lang: 'no' }),

  standardPartnerRouteFactory({ name: 'sats', lang: 'no' }),

  standardPartnerRouteFactory({ name: 'tekna', lang: 'no' }),

  {
    path: '/partner/skjonnhaug',
    component: AccountSignupView,
    meta: {
      requiresAuth: false
    },
    props: {
      voucher: 'skjonnhaug',
      hideFacebookLogin: true,
      language: 'no'
    },
    async beforeEnter (to, from, next) {
      if (store.state.moduleAuth.isAuthenticated) {
        await store.dispatch('moduleAuth/updateMe', { roles: ['skjonnhaug'] })
        next({
          path: to.query.next || '/'
        })
      } else {
        next()
      }
    }
  },

  // {
  //   path: 'oauth/:token',
  //   moduleId: '../oauth'
  // },

  // Routes requiring auth:

  {
    path: '/voucher',
    name: 'AccountVoucherView',
    component: AccountVoucherView,
    beforeEnter: redirectIfSubscribed
  },
  {
    path: '/checkout/:plan',
    name: 'AccountCheckoutView',
    component: AccountCheckoutView
  },
  {
    path: '/portal',
    name: 'AccountPortalView',
    component: AccountPortalView
  },

  {
    path: '/expired',
    name: 'AccountExpiredView',
    component: AccountExpiredView
  },

  // NOTE: 'updated' route is used when exiting chargebe page.
  {
    path: '/updated',
    name: 'AccountUpdatedView',
    component: AccountUpdatedView
  },
  {
    path: '/membership',
    name: 'AccountMembershipView',
    component: AccountMembershipView
  }

]
