/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'heart-like': {
    width: 90,
    height: 78,
    viewBox: '0 0 90 78',
    data: '<path pid="0" d="M45.056 77.113a2.129 2.129 0 01-.113 0 2.15 2.15 0 01-1.249-.399c-1.199-.851-29.502-21.038-39.93-38.067l-.041-.069A24.828 24.828 0 01.166 25.755C.166 12.043 11.322.887 25.034.887a24.859 24.859 0 0119.965 10.037A24.86 24.86 0 0164.964.887c13.712 0 24.868 11.156 24.868 24.868 0 4.531-1.23 8.963-3.557 12.823-.013.023-.026.046-.041.069-10.427 17.029-38.73 37.216-39.93 38.067a2.14 2.14 0 01-1.248.399z" fill-rule="evenodd"/>'
  }
})
