/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'rocket': {
    width: 16,
    height: 16,
    viewBox: '0 0 30 30',
    data: '<path pid="0" d="M11 22l-3-3v-5l8 8z"/><path pid="1" d="M26.979 3.021S18 3 11 10c-2.99 2.99-3 8-1 10s7.01 1.99 10-1c7-7 6.979-15.979 6.979-15.979zM17 11a2 2 0 113.999-.001A2 2 0 0117 11z"/><path pid="2" d="M18.811 18.676s.757 3.784-.757 5.297C15.784 26.243 12 27 12 27l1-7 5.811-1.324zM11.324 11.189s-3.784-.757-5.297.757C3.757 14.216 3 18 3 18l7-1 1.324-5.811zM9.542 22.34c-.045.064-.072.138-.13.195-.975.975-2.438.488-2.438.488s-.488-1.463.488-2.438c.058-.058.131-.084.195-.13l-.457-.458c-.38.1-.741.29-1.038.587-1.366 1.366-1.149 4.4-1.149 4.4s3.016.235 4.4-1.149c.297-.297.487-.658.587-1.038l-.458-.457z"/>'
  }
})
