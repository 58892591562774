<template>
  <BlockContainer
    class="Step"
    type="Read"
    :progress="activity.progress">
    <ButtonWithCheckmark
      :done="activity.progress === 1"
      label="Done"
      @click="toggleRead()" />
  </BlockContainer>
</template>

<script>
import BlockContainer from '../BlockContainer';
import ButtonWithCheckmark from '../ButtonWithCheckmark';

export default {
    components: { ButtonWithCheckmark, BlockContainer },
    props: {
        stepId: {
            type: String,
            required: true
        },
        activityName: {
            type: String,
            required: true
        }
    },
    computed: {
        step() {
            return this.$store.state.moduleCourse.steps[this.stepId];
        },
        activity() {
            return this.step[this.activityName];
        }
    },
    methods: {
        toggleRead() {
            this.$store.dispatch('moduleCourse/dbgMarkActivity', {
                stepId: this.stepId,
                activityName: this.activityName,
                reset: this.activity.progress === 1
            });
        }
    }
};
</script>

<style lang="scss" scoped></style>
