import * as types from '@/store/mutation-types';

export const id = 'memolife-debug-settings';

export const version = 1;

export function build({ state }) {
    return {
        _id: id,
        version,
        data: state.moduleApp.debugSettings
    };
}

export function apply(doc, { commit, state }) {
    commit(`moduleApp/${types.SET_DEBUG_SETTINGS}`, { data: doc.data });
}
