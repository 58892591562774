<template>
  <BlockContainer
    class="ModuleFaces"
    type="Module"
    label="moduleFaces">
    <ButtonPlain
      label="Clear names userstate"
      @click="clearNamesUserstate()" />
    <!-- <button @click="clearGameBraindates()">Clear game braindates</button>
    <div class="games">
      <Game class="Game" :game-id="gameId" v-for="gameId of gameIds" :key="gameId"/>
    </div> -->
  </BlockContainer>
</template>

<script>
import BlockContainer from '../BlockContainer';
import ButtonPlain from '../ButtonPlain';

export default {
    components: { BlockContainer, ButtonPlain },
    computed: {
        gameIds() {
            return this.$store.state.moduleMemogenius.gameIds;
        }
    },
    methods: {
        clearNamesUserstate() {
            this.$store.commit('moduleSystemName/clearNamesUserstate');
        }
    }
};
</script>

<style lang="scss" scoped></style>
