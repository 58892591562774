/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'categories/career': {
    width: 40,
    height: 35,
    viewBox: '0 0 40 35',
    data: '<g fill-rule="nonzero"><path pid="0" d="M23.788 0h-6.576c-2.375 0-4.3 1.935-4.3 4.322v1.44H11V34h19V5.744h-1.911V4.303C28.078 1.923 26.156 0 23.788 0zm1.433 5.763H15.78V4.322c0-.796.642-1.44 1.433-1.44h6.576c.791 0 1.433.644 1.433 1.44v1.44zM7 35V6H5.687C2.547 6 0 8.869 0 12.407v16.186C0 32.13 2.546 35 5.688 35H7zM40 28.593V12.407C40 8.87 37.454 6 34.312 6H33v29h1.313C37.453 35 40 32.131 40 28.593z"/></g>'
  }
})
