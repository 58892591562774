/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sound-high': {
    width: 57,
    height: 47,
    viewBox: '0 0 57 47',
    data: '<path pid="0" d="M25.34.173c-.799-.368-1.72-.123-2.334.491l-10.81 10.872H2.8c-1.228 0-2.21.982-2.21 2.15v18.732c0 1.228.982 2.15 2.21 2.15h9.397L23.067 45.5c.43.43.983.676 1.536.676.307 0 .491-.062.737-.185a2.228 2.228 0 001.29-2.027V2.2c0-.921-.43-1.72-1.29-2.027zm9.888 32.245a2.193 2.193 0 01-1.658.737 2.32 2.32 0 01-1.474-.553c-.922-.798-.983-2.211-.184-3.132 5.466-6.142.552-12.1 0-12.775-.799-.922-.738-2.273.184-3.071.921-.799 2.272-.737 3.07.184 2.949 3.194 6.695 11.24.062 18.61zm6.695 5.835a2.193 2.193 0 01-1.659.737 2.32 2.32 0 01-1.474-.553c-.92-.799-.982-2.211-.184-3.133 10.871-12.099.43-24.014 0-24.505-.798-.922-.737-2.273.184-3.133.922-.798 2.273-.737 3.133.184.123.185 13.389 15.416 0 30.403zm6.94 4.422a2.193 2.193 0 01-1.658.737 2.32 2.32 0 01-1.474-.553c-.922-.799-.983-2.211-.185-3.132 6.204-6.88 8.108-14.557 5.59-22.787-1.843-6.264-5.59-10.44-5.59-10.502-.798-.921-.737-2.273.185-3.133.92-.798 2.272-.737 3.132.185.123.184 17.32 19.838 0 39.185z" _fill="#000" fill-rule="nonzero"/>'
  }
})
