/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'glass-learn': {
    width: 84,
    height: 30,
    viewBox: '0 0 84 30',
    data: '<path pid="0" d="M83.496 8.955H75.42C73.109 3.81 67.943.227 61.936.227c-5.584 0-10.443 3.1-12.955 7.669-4.508-3.801-9.155-3.785-13.94.043C32.536 3.344 27.665.227 22.062.227c-6.006 0-11.174 3.583-13.484 8.728H.503v4.499h6.866c-.054.507-.08 1.025-.08 1.548 0 8.157 6.614 14.772 14.772 14.772 8.158 0 14.771-6.615 14.771-14.772 0-1.209-.144-2.383-.417-3.506 3.369-2.35 7.472-2.587 11.162.012a14.834 14.834 0 00-.414 3.494c0 8.157 6.613 14.772 14.771 14.772 8.16 0 14.773-6.615 14.773-14.772 0-.522-.027-1.041-.08-1.548h6.867V8.955h.002zm-61.434 16.77c-5.922 0-10.724-4.801-10.724-10.723 0-5.924 4.802-10.725 10.724-10.725 5.923 0 10.724 4.801 10.724 10.725 0 5.922-4.801 10.723-10.724 10.723zm39.874 0c-5.92 0-10.725-4.801-10.725-10.723 0-5.924 4.805-10.725 10.725-10.725 5.922 0 10.725 4.801 10.725 10.725-.001 5.922-4.804 10.723-10.725 10.723z" fill-rule="evenodd"/>'
  }
})
