/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-up': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M27.414 12.586l-10-10a2 2 0 00-2.828 0l-10 10a2 2 0 102.828 2.828L14 8.828V28a2 2 0 104 0V8.828l6.586 6.586c.39.39.902.586 1.414.586s1.024-.195 1.414-.586a2 2 0 000-2.828z"/>'
  }
})
