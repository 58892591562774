import gql from 'graphql-tag';
import apolloClient from '@/apollo-client';
import { v3 as uuidv3 } from 'uuid';

import allGroupsListGql from './graphql/AllGroupsList.gql';
import createGroupGql from './graphql/createGroup.gql';
import createMemberGql from './graphql/createMember.gql';
import createChallengeGql from './graphql/createChallenge.gql';
import createGroupInvitationGql from './graphql/createGroupInvitation.gql';
import createActivityGql from './graphql/createActivity.gql';

const emailToUserMapping = {
    'user1@foobar.com': uuidv3('user1', uuidv3.DNS),
    'user2@foobar.com': uuidv3('user2', uuidv3.DNS)
};

export default {
    namespaced: true,
    state() {
        return {
            userName: null,
            userId: null,
            email: null,
            groups: [],
            groupInvitations: []
        };
    },
    actions: {
        async configure({ dispatch, commit }, { userId, email }) {
            commit('updateConfiguration', {
                userId,
                email
                // userName,
                // userId: uuidv3(userName, uuidv3.DNS),
                // email: `${userName}@foobar.com`
            });
        },
        async refresh({ dispatch }) {
            await dispatch('fetchAll');
        },
        async fetchAll({ state, commit }) {
            const response = await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: allGroupsListGql
            });
            commit('setGroups', response.data.allGroupsList);

            const r2 = await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: gql`
                    query($email: String!) {
                        allGroupInvitationsList(condition: { email: $email }) {
                            id
                            groupId
                            email
                        }
                    }
                `,
                variables: {
                    email: state.email
                }
            });

            commit('setGroupInvitations', r2.data.allGroupInvitationsList);
        },

        async fetchGroupInvitation({ commit }, { groupInvitationId }) {
            const r2 = await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: gql`
                    query($groupInvitationId: UUID!) {
                        allGroupInvitationsList(condition: { id: $groupInvitationId }) {
                            id
                            groupId
                            email
                        }
                    }
                `,
                variables: {
                    groupInvitationId
                }
            });

            commit('setGroupInvitations', r2.data.allGroupInvitationsList);
        },

        async createGroup({ dispatch, state }, { isPrivate = true, name = 'A group' }) {
            const r1 = await apolloClient.mutate({
                mutation: createGroupGql,
                variables: {
                    private: isPrivate,
                    name
                }
            });

            const groupId = r1.data.createGroup.group.id;

            await apolloClient.mutate({
                mutation: createMemberGql,
                variables: {
                    groupId,
                    userId: state.userId
                }
            });
            await dispatch('refresh');
        },

        async deleteGroup({ dispatch, state }, { groupId }) {
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: gql`
                    mutation($groupId: UUID!) {
                        deleteGroupById(input: { id: $groupId }) {
                            deletedGroupId
                        }
                    }
                `,
                variables: {
                    groupId
                }
            });
            await dispatch('refresh');
        },

        async renameGroup({ dispatch, state }, { groupId, name }) {
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',

                mutation: gql`
                    mutation($groupId: UUID!, $name: String!) {
                        updateGroupById(input: { id: $groupId, groupPatch: { name: $name } }) {
                            group {
                                name
                            }
                        }
                    }
                `,
                variables: {
                    groupId,
                    name
                }
            });
            await dispatch('refresh');
        },

        async createChallenge({ dispatch, state }, { groupId, challengeType, challengeText, settings }) {
            await apolloClient.mutate({
                mutation: createChallengeGql,
                variables: {
                    groupId,
                    challengeType,
                    start: new Date(),
                    challengeText,
                    settings: JSON.stringify(settings)
                }
            });
            await dispatch('refresh');
        },

        async deleteChallenge({ dispatch, state }, { challengeId }) {
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: gql`
                    mutation($challengeId: UUID!) {
                        deleteChallengeById(input: { id: $challengeId }) {
                            deletedChallengeId
                        }
                    }
                `,
                variables: {
                    challengeId
                }
            });
            await dispatch('refresh');
        },

        async createNewGroupWithChallengeAndInvitation(
            { dispatch, state },
            { challengeType, challengeText, settings }
        ) {
            const r1 = await apolloClient.mutate({
                mutation: createGroupGql,
                variables: {
                    private: true,
                    name: 'groupname'
                }
            });

            const groupId = r1.data.createGroup.group.id;

            await apolloClient.mutate({
                mutation: createMemberGql,
                variables: {
                    groupId,
                    userId: state.userId
                }
            });

            const r2 = await apolloClient.mutate({
                mutation: createGroupInvitationGql,
                variables: {
                    groupId,
                    email: ''
                }
            });

            const r3 = await apolloClient.mutate({
                mutation: createChallengeGql,
                variables: {
                    groupId,
                    challengeType,
                    start: new Date(),
                    challengeText,
                    settings
                }
            });
            await dispatch('refresh');

            return {
                groupId,
                groupInvitationId: r2.data.createGroupInvitation.groupInvitation.id,
                challengeId: r3.data.createChallenge.challenge.id
            };
        },

        async createGroupInvitation({ dispatch, state }, { groupId, email }) {
            await apolloClient.mutate({
                mutation: createGroupInvitationGql,
                variables: {
                    groupId,
                    email
                }
            });
            await dispatch('refresh');
        },

        async deleteGroupInvitation({ dispatch, state }, { groupInvitationId }) {
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: gql`
                    mutation($groupInvitationId: UUID!) {
                        deleteGroupInvitationById(input: { id: $groupInvitationId }) {
                            deletedGroupInvitationId
                        }
                    }
                `,
                variables: {
                    groupInvitationId
                }
            });
            await dispatch('refresh');
        },

        async acceptGroupInvitation({ dispatch, state }, { groupInvitationId }) {
            const groupInvitation = state.groupInvitations.find(x => x.id === groupInvitationId);
            const groupId = groupInvitation.groupId;
            const userId = emailToUserMapping[groupInvitation.email];

            await apolloClient.mutate({
                mutation: createMemberGql,
                variables: {
                    groupId,
                    userId
                }
            });
            await dispatch('deleteGroupInvitation', { groupInvitationId });
            await dispatch('refresh');
        },

        async createMember({ dispatch, state }, { groupId }) {
            await apolloClient.mutate({
                mutation: createMemberGql,
                variables: {
                    groupId,
                    userId: state.userId
                }
            });
            await dispatch('refresh');
        },

        async deleteMember({ dispatch, state }, { memberId }) {
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: gql`
                    mutation($memberId: UUID!) {
                        deleteMemberById(input: { id: $memberId }) {
                            deletedMemberId
                        }
                    }
                `,
                variables: {
                    memberId
                }
            });
            await dispatch('refresh');
        },

        async createActivity({ dispatch, state }, { challengeId }) {
            await apolloClient.mutate({
                mutation: createActivityGql,
                variables: {
                    userId: state.userId,
                    app: 'game-shopping',
                    action: 'game-completed',
                    score: 1,
                    // gameLevel: '0',
                    challengeId
                }
            });
            // id: UUID
            // userId: UUID!
            // app: String!
            // gameLevel: String
            // action: String!
            // challengeId: UUID
            // completion: Boolean
            // success: Boolean
            // score: Int
            await dispatch('refresh');
        }
    },

    mutations: {
        setGroups(state, groups) {
            state.groups = groups;
        },
        setGroupInvitations(state, groupInvitations) {
            state.groupInvitations = groupInvitations;
        },
        updateConfiguration(state, configuration) {
            Object.assign(state, configuration);
        }
    },

    getters: {
        // invitations (state) {
        //   return state.invitations
        // },
        privateGroups(state) {
            return state.groups.filter(g => {
                return g.private && g.membersByGroupIdList.some(m => m.userId === state.userId);
            });
        },
        publicGroups(state) {
            return state.groups.filter(g => !g.private);
        },
        getGroup: (state, getters) => groupId => {
            return state.groups.find(g => g.id === groupId);
        },
        getChallenge: (state, getters) => challengeId => {
            let groupWithTheChallenge = state.groups.find(group =>
                group.challengesByGroupIdList.some(challenge => challenge.id === challengeId)
            );
            return groupWithTheChallenge
                ? groupWithTheChallenge.challengesByGroupIdList.find(challenge => challenge.id === challengeId)
                : null;
        },
        getGroupInvitation: state => groupInvitationId => {
            return state.groupInvitations.find(x => x.id === groupInvitationId);
        }
    }
};
