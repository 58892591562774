export default {
    title: 'Vocab Guru',
    title_no: 'Gloseguru',
    subtitle: 'Words & Facts',
    subtitle_no: 'Gloser & Fakta',

    techniques: ['similaritysys', 'chainsys'],
    articles: ['language-similarity'],

    // tags: ['native words', 'foreign words', 'facts'],
    // tags_no: ['gloser', 'fremmedord', 'fakta'],

    levelTexts: {
        level0:
            'You find yourself lost in a new world with strange languages. Your survival depends on how fast you can learn enough vocabularies to make yourself understood. Have you got it in you to rapidly learn vast amounts of words?',
        level0_no:
            'Du har havnet i en ny verden med rare språk. Din eneste mulighet for å overleve er å hurtig lære deg nok gloser for å gjøre deg forstått. Har du det som skal til for å lære deg mange nye gloser i et lynrakst tempo?',

        level1:
            'You’re in the world of Clockwork Orange and you need to master its ‘Nadsat’ language to survive. Will you learn the meaning of its words in time?',
        level1_no:
            "Du er i den farlige 'Clockwork Orange'-verdenen og må mestre språket Nadsat for å overleve. Har du det som skal til for å lære ordene tidsnok?",
        level2:
            "You're in the world of the Elves and you need to master an ancient  dialect to defend yourself against spells and dark magic. Can you save your soul?",
        level2_no:
            'Du er i alvenes verden og må lære en gammel alvedialekt for å beskytte deg mot trolldom og svart magi. Klarer du å redde sjelen din?',
        level3:
            'In the Star Trek dimension your language challenge is Klingon. You better be ready, or galactic pulverization will be your fate. Are you up for it?',
        level3_no:
            'Du er nå i Star Trek og språket er Klingon. Vær klar til å snakke deg ut av farlige situasjoner, hvis ikke blir galaktisk pulverisering din skjebne.',
        level4:
            "You're on the blue planet Pandora and the language is ‘Navi’. Can you learn enough before the native warriors reach you? How badly do you want to survive?",
        level4_no:
            'Du er på den blå planeten og språket er ‘Navi’. Din eneste mulighet for å overleve er å lære nok før de innfødte krigerne når deg. Er du tøff og hurtig nok?'
    },

    testLabels: {
        wordTest: 'Translation',
        wordTest_no: 'Oversettelse'
    },

    MemorizeItemInstruction: {
        instruction: 'Memorize this',
        instruction_no: 'Memorer'
    },

    Question: {
        question: 'Which word belongs to this card?',
        question_no: 'Hvilket ord tilhører dette kortet?'
    },

    english: 'english',
    english_no: 'engelsk',
    norwegian: 'norwegian',
    norwegian_no: 'norsk',
    nadsat: 'nadsat',
    nadsat_no: 'nadsat',
    klingon: 'klingon',
    klingon_no: 'klingon',
    elvish: 'elvish',
    elvish_no: 'alvisk',
    navi: 'navi',
    navi_no: 'navi'
};
