/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'lock3': {
    width: 18,
    height: 23,
    viewBox: '0 0 18 23',
    data: '<path pid="0" d="M11.481 6.984c0-5.518-5.92-5.482-5.92.098H2.603c0-9.41 11.839-9.443 11.839-.098h-2.96zm-2.15 8.509c.255 1.191.406 1.888.406 3.122H7.17c0-1.234.135-1.964.39-3.155-.991-.664-1.316-1.275-1.274-1.79.183-2.208 4.65-2.011 4.471.135-.04.494-.436 1.07-1.426 1.688zM1.574 8.114C.708 8.114 0 8.822 0 9.687c0 17.047 17.043 18.44 17.043 0 0-.865-.708-1.573-1.573-1.573H1.574z" fill-rule="evenodd"/>'
  }
})
