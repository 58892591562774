/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'camerasvg': {
    width: 13,
    height: 13,
    viewBox: '0 0 13 13',
    data: '<path pid="0" d="M1.603 13h9.769c.902 0 1.628-.743 1.628-1.698V4.059c0-.955-.726-1.724-1.628-1.724H1.603C.701 2.335 0 3.105 0 4.059v7.243C0 12.231.726 13 1.603 13zm9.769-9.047c.05 0 .1.053.125.133v7.243c0 .053-.05.106-.125.106H1.603c-.05 0-.1-.053-.1-.106v-7.27c0-.053.05-.106.1-.132h9.769v.026zM4.909 1.592h3.157c.425 0 .751-.345.751-.796C8.817.345 8.491 0 8.066 0H4.884c-.425 0-.751.345-.751.796 0 .45.35.796.776.796zm1.578 9.286c1.679 0 3.031-1.433 3.031-3.21 0-1.778-1.352-3.21-3.03-3.21-1.679 0-3.031 1.432-3.031 3.21v.026c.025 1.75 1.377 3.184 3.03 3.184zm0-4.829c.852 0 1.528.716 1.528 1.618 0 .902-.676 1.619-1.528 1.619-.826 0-1.502-.717-1.527-1.592 0-.902.676-1.618 1.527-1.645 0 .027 0 .027 0 0z" _fill="#868686"/>'
  }
})
