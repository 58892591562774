import Vue from 'vue';
import VueI18n from 'vue-i18n';
import PortalVue from 'portal-vue';
import debounce from 'lodash-es/debounce';

import 'intersection-observer'; // Needed for Safari
import VueObserveVisibility from 'vue-observe-visibility';

import VueAxios from 'vue-axios';
import Vuelidate from 'vuelidate';
import axios from 'axios';

import DebugPlugin from '@/modules/debug/debug-plugin';
import ModalPlugin from '@/plugins/modal-helper';
import ComponentTranslationsPlugin from '@/plugins/component-translations';

import App from './App';
import router from './router';
import * as svgicon from 'vue-svgicon';
import VueLazyload from 'vue-lazyload';
import { abilitiesPlugin } from '@casl/vue';
import ability from '@/ability';

import * as Sentry from '@sentry/vue';
// import { Integrations } from "@sentry/tracing";

import store from './store';
import { SET_LOCALE } from './store/mutation-types';

import messages from './locales';
import localsPreprocess from './locales-preprocess';
import moment from 'moment';
import 'moment/locale/nb';

import IntercomPlugin from './intercom';
import settings from './settings';
import smoothscroll from 'smoothscroll-polyfill';
import Vue2TouchEvents from 'vue2-touch-events';

export default async function () {
  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      Vue,
      dsn: "https://994ea64cf3fb4700b962fc03b22db33d@o53952.ingest.sentry.io/5932098",
      environment: process.env.NODE_ENV,
      release: process.env.GIT_REVISION,
      // Enabling integrations require CORS on backend services
      // integrations: [
      //   new Integrations.BrowserTracing({
      //     routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      //     tracingOrigins: [
      //       "localhost",
      //       "app.memolife.com",
      //       /^\//
      //     ],
      //   }),
      // ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
  }

    smoothscroll.polyfill();

    Vue.config.productionTip = false;

    Vue.config.ignoredElements = [
        // 'my-custom-web-component',
        // 'another-web-component',
        // Use a `RegExp` to ignore all elements that start with "ion-"
        // 2.5+ only
        /^my-/,
        'memolanguage-root'
    ];

    Vue.use(VueAxios, axios);
    Vue.use(ComponentTranslationsPlugin);
    Vue.use(VueI18n);
    Vue.use(PortalVue);
    Vue.use(Vuelidate);
    Vue.use(svgicon, {
        tagName: 'svgicon'
    });

    Vue.use(VueLazyload);
    Vue.use(VueObserveVisibility);
    Vue.use(abilitiesPlugin, ability);

    Vue.use(DebugPlugin);
    Vue.use(ModalPlugin);
    Vue.use(Vue2TouchEvents);

    window.dbgToggleDebugButton = function () {
        store.dispatch('moduleApp/toggleDebugButton');
    };

    await store.dispatch('moduleApp/initApp');

    const i18n = new VueI18n({
      // Note: locale set below, in updateLocal
      silentTranslationWarn: true,
      messages: localsPreprocess(messages)
    });

    function updateLocal(language) {
      i18n.locale = language;
      moment.locale(language);
    }
    setupInitialLocale(updateLocal);

    if (process.env.NODE_ENV === 'production') {
      Vue.use(IntercomPlugin);
        if (location.hash) {
            history.replaceState(null, null, location.hash.substr(1) + location.search);
        }
    }

    await store.dispatch('moduleApp/activateApp');

    if (store.state.moduleAuth.isAuthenticated) {
        await store.dispatch('moduleApp/loadUser');
    }

    function updateViewHeightVariable() {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    window.addEventListener('resize', debounce(updateViewHeightVariable, 300, { leading: true, trailing: true }));
    updateViewHeightVariable();

    /* eslint-disable no-new */
    new Vue({
        store,
        i18n,
        el: '#app',
        router,
        render: h => h(App)
    });
}

async function setupInitialLocale(updateLocal) {
  if (store.state.moduleAuth.isAuthenticated) {
    const lang = store.state.moduleAuth.profile.language || 'no';
    store.commit(`moduleApp/${SET_LOCALE}`, lang);
    updateLocal(lang);
  } else {
    // NOTE: appLanguage initially loaded (syncronously) in persistence plugin. If it is blank, it probably means that this is the first time the user loads that app
    if (!store.state.moduleApp.memolife.appLanguage) {
      let suggestedLang = 'no'; // NOTE: default language
      try {
        const geoResponse = await axios.get(`${settings.endpoints.api}/geo/country/`);
        if (geoResponse.data.country_code !== 'NO') {
          suggestedLang = 'en';
        }
      } catch (err) {
        Sentry.captureException(err);
        // console.log('err', err) // eslint-disable-line no-console
      }
      store.commit(`moduleApp/${SET_LOCALE}`, suggestedLang);
    }
    updateLocal(store.state.moduleApp.memolife.appLanguage);
  }
}
