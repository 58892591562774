<template>
  <BlockContainer
    class="ModuleMemogenius"
    type="Module"
    label="moduleMemogenius">
    <ButtonPlain
      label="Reset memory onboarding results: memoryiqTheory"
      @click="resetMemoryOnboardingResults('memoryiqTheory')" />
    <ButtonPlain
      label="Reset memory onboarding results B"
      @click="resetMemoryOnboardingResults('memoryOnboardingTestB')" />
    <ButtonPlain
      label="Reset memory onboarding results A+B"
      @click="resetMemoryOnboardingResults()" />
    <br><br>
    <div class="games">
      <Game
        v-for="gameId of gameIds"
        :key="gameId"
        class="Game"
        :game-id="gameId" />
    </div>
  </BlockContainer>
</template>

<script>
import * as types from '@/store/mutation-types';

import BlockContainer from '../BlockContainer';
import ButtonPlain from '../ButtonPlain';

import Game from './Game';

export default {
    components: { BlockContainer, ButtonPlain, Game },
    computed: {
        gameIds() {
            return this.$store.state.moduleMemogenius.gameIds;
        }
    },
    methods: {
        resetMemoryOnboardingResults(name) {
            if (name) {
                this.$store.commit(`moduleMemogenius/${types.MEMOGENIUS_UPDATE_SETTINGS}`, {
                    settings: { [name]: null }
                });
                this.$store.dispatch('moduleMemogenius/updateSetting', { key: name, value: null });
                return;
            }
            this.$store.commit(`moduleMemogenius/${types.MEMOGENIUS_UPDATE_SETTINGS}`, {
                settings: { memoryOnboardingTestA: null }
            });
            this.$store.commit(`moduleMemogenius/${types.MEMOGENIUS_UPDATE_SETTINGS}`, {
                settings: { memoryiqTheory: null }
            });

            this.$store.commit(`moduleMemogenius/${types.MEMOGENIUS_UPDATE_SETTINGS}`, {
                settings: { memoryOnboardingTestB: null }
            });

            this.$store.dispatch('moduleMemogenius/updateSetting', { key: 'memoryOnboardingTestA', value: null });
            this.$store.dispatch('moduleMemogenius/updateSetting', { key: 'memoryiqTheory', value: null });
            this.$store.dispatch('moduleMemogenius/updateSetting', { key: 'memoryOnboardingTestB', value: null });
        }
    }
};
</script>

<style lang="scss" scoped>
.Game {
    &:not(:first-child) {
        margin-top: 1em;
    }
}
</style>
