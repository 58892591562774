// codes
import codesRaw from '../data/codes';

const names = {
    n33: {
        name: 'Kristoffer',
        codes: ['c1'],
        type: 'firstName'
    },
    n1: {
        id: 'n1',
        value: 'Harriet',
        type: 'firstName',
        codes: ['c2', 'c2b']
    },
    n2: {
        id: 'n2',
        value: 'Wright',
        type: 'lastName',
        codes: ['c3']
    },

    n3: {
        id: 'n3',
        value: 'Hege'
    },
    n4: {
        id: 'n4',
        value: 'Furseth',
        codes: ['c4']
    },
    n5: {
        id: 'n5',
        value: 'Hjertø',
        codes: ['c5']
    },

    n6: {
        id: 'n6',
        value: 'Espen'
    },
    n7: {
        id: 'n7',
        value: 'Thilesen'
    },

    n8: {
        id: 'n8',
        value: 'Nils'
    },
    n9: {
        id: 'n9',
        value: 'Einar'
    },
    n10: {
        id: 'n10',
        value: 'Nilsen'
    },

    n11: {
        id: 'n11',
        value: 'Lars'
    },
    n12: {
        id: 'n12',
        value: 'Nordbryhn'
    }
};

const codes = {
    c1: {
        code: 'Cross',
        lang: 'en',
        id: 'c1',
        explanation: ''
    },

    c2: {
        code: 'harke'
    },
    c2b: {
        code: 'lyn som ett-tall',
        explanation: 'harry potter lyn = harry'
    },
    c3: {
        code: 'vri'
    },
    c4: {
        code: 'furu'
    },
    c5: {
        code: 'hjerte-øy'
    }
};

const extraCodeMapping = {};
Object.entries(codesRaw).forEach(([key, value]) => {
    codes[key] = {
        code: value,
        id: key,
        explanation: ''
    };

    if (key.indexOf('/') !== -1) {
        key.split('/').forEach(k => {
            extraCodeMapping[k] = key;
        });
    }
});

function createNames(raw, gender) {
    raw.forEach(nameRaw => {
        let xx = [];
        if (codes[nameRaw]) {
            xx.push(nameRaw);
        } else if (extraCodeMapping[nameRaw]) {
            xx.push(extraCodeMapping[nameRaw]);
        }
        names[nameRaw] = {
            value: nameRaw,
            gender: 'M',
            codes: xx
        };
    });
}

createNames(
    [
        'Alf',
        'Andreas',
        'Anders',
        'Andre',
        'Arne',
        'Arvid',
        'Balder',
        'Bjørn',
        'Bjørnar',
        'Hans',
        'David',
        'Mathias',
        'Brage',
        'Ivan',
        'Alfred',
        'Filip',
        'Filipp',
        'Benjamin',
        'Gabriel',
        'Helge',
        'Inge',
        'Odd',
        'Pål',
        'Jørn',
        'Erlend',
        'Erling',
        'Elling',
        'Svein',
        'Sveinung',
        'Jonas',
        'Henrik',
        'Henry',
        'Tor(d)',
        'Torbjørn',
        'Torgrim',
        'Torleif',
        'Torstein',
        'Frode',
        'Emil',
        'William',
        'Leif',
        'Gard',
        'Alexander',
        'Isak',
        'Jakob',
        'John',
        'Klaus',
        'Dag',
        'Dag-Erik',
        'Kåre',
        'Kenneth',
        'Finn',
        'Kjartan',
        'Kjell',
        'Knut',
        'Harald',
        'Gustav',
        'Kristen',
        'Kristian',
        'Kristoffer',
        'Nils',
        'Sjur',
        'Sondre',
        'Lukas',
        'Lasse',
        'Lars',
        'Liam',
        'Brede',
        'Petter',
        'Markus',
        'Martin',
        'Marius',
        'Sindre',
        'Amund',
        'Oliver',
        'Eirik',
        'Erik',
        'Fredrik',
        'Leander',
        'Leon',
        'Adrian',
        'Per',
        'Remi',
        'Michael',
        'Mikkel',
        'Karl',
        'Ola',
        'Ole',
        'Elias',
        'Aslak',
        'Asle',
        'Ruben',
        'Rune',
        'Sander',
        'Ulrik',
        'Simen',
        'Sigurd',
        'Sigve',
        'Magnus',
        'Bård',
        'Gaute',
        'Kjetil',
        'Jan',
        'Frank',
        'Bernhard',
        'Oscar',
        'Stein',
        'Egil',
        'Stig',
        'Sverre',
        'Tobias',
        'Tom',
        'Tommy',
        'Thomas',
        'Truls',
        'Jarle',
        'Victor',
        'Viktor',
        'Jens',
        'Jo',
        'Johannes',
        'Josef',
        'Allan'
    ],
    'M'
);

createNames(
    [
        'Ella',
        'Alva',
        'Andrea',
        'Andrine',
        'Ann',
        'Annette',
        'Anja',
        'Astrid',
        'Karoline',
        'Marte',
        'Vibeke',
        'Bente',
        'Mia',
        'Jessica',
        'Bjørg',
        'Bodil',
        'Alexandra',
        'Ingeborg',
        'Ingvild',
        'Guro',
        'Ida',
        'Kari',
        'Vanja',
        'Yvonne',
        'Wenche',
        'Eva',
        'Lisa',
        'Amalie',
        'Mette',
        'Hilde',
        'Line',
        'Elisabeth',
        'Beate',
        'Jannike',
        'Tone',
        'Torild',
        'Hannah',
        'Hanne',
        'Jeanette',
        'Emilie',
        'Emily',
        'Helene',
        'Merete',
        'Hedda',
        'Liv',
        'Vilde',
        'T(h)erese',
        'Ina',
        'Siri',
        'Julie',
        'Grete',
        'Camilla',
        'Katrine',
        'Hege',
        'Mari',
        'Margrete',
        'Christine',
        'Kristin',
        'Britt',
        'Karianne',
        'Karine',
        'Lene',
        'Susanne',
        'Linnea',
        'Louise',
        'Emma',
        'Ellen',
        'Maren',
        'Marianne',
        'Maya',
        'Synne',
        'Nina',
        'Olivia',
        'Åse',
        'Ester',
        'Isabelle',
        'Malin',
        'Linda',
        'Bettina',
        'Vilgunn',
        'Inger',
        'Ingrid',
        'Mathilde',
        'Ulrika',
        'Cecilie',
        'Carina',
        'Charlotte',
        'Lise',
        'Benedikte',
        'Leah',
        'Janne',
        'Solveig',
        'Nora',
        'Ingunn',
        'Amanda',
        'Stine',
        'Elin',
        'Natalie',
        'Karen',
        'Renathe'
    ],
    'F'
);

export default {
    session: null,
    codes,
    names,
    categoryIds: ['c1', 'c2'],
    categories: {
        c1: {
            id: 'c1',
            place: 'Norway',
            type: 'male',
            color: '#B6EBED',
            name: 'Top 100 Male names in Norway',
            nameIds: [
                // NOTE: these are ids
                'Alf',
                'Andreas',
                'Anders',
                'Andre',
                'Arne',
                'Arvid',
                'Balder',
                'Bjørn',
                'Hans',
                'David',
                'Mathias',
                'Brage',
                'Ivan',
                'Alfred',
                'Filip',
                'Benjamin',
                'Gabriel',
                'Helge',
                'Inge',
                'Odd',
                'Pål',
                'Jørn',
                'Erlend',
                'Erling',
                'Elling',
                'Svein',
                'Sveinung',
                'Jonas',
                'Henrik',
                'Henry',
                'Tor(d)',
                'Torbjørn',
                'Torgrim',
                'Torleif',
                'Torstein',
                'Frode',
                'Emil',
                'William',
                'Leif',
                'Gard',
                'Alexander',
                'Isak',
                'Jakob',
                'John',
                'Klaus',
                'Dag',
                'Dag-Erik',
                'Kåre',
                'Kenneth',
                'Finn',
                'Kjartan',
                'Kjell',
                'Knut',
                'Harald',
                'Gustav',
                'Kristen',
                'Kristian',
                'Kristoffer',
                'Nils',
                'Sjur',
                'Sondre',
                'Lukas',
                'Lasse',
                'Lars',
                'Liam',
                'Brede',
                'Petter',
                'Markus',
                'Martin',
                'Marius',
                'Sindre',
                'Amund',
                'Oliver',
                'Eirik',
                'Erik',
                'Fredrik',
                'Leander',
                'Leon',
                'Adrian',
                'Per',
                'Remi',
                'Michael',
                'Mikkel',
                'Karl',
                'Ola',
                'Ole',
                'Elias',
                'Aslak',
                'Asle',
                'Ruben',
                'Rune',
                'Sander',
                'Ulrik',
                'Simen',
                'Sigurd',
                'Sigve',
                'Magnus',
                'Bård',
                'Gaute',
                'Kjetil',
                'Jan',
                'Frank',
                'Bernhard',
                'Oscar',
                'Stein',
                'Egil',
                'Stig',
                'Sverre',
                'Tobias',
                'Tom',
                'Tommy',
                'Thomas',
                'Truls',
                'Jarle',
                'Victor',
                'Jens',
                'Jo',
                'Johannes',
                'Josef',
                'Allan'
            ]
        },
        c2: {
            id: 'c2',
            place: 'Norway',
            type: 'female',
            color: '#F9CDCD',
            name: 'Top 100 Female names in Norway',
            // nameIds: ['Ella', 'Alva', 'Andrea', 'Andrine', 'Ann']
            nameIds: [
                'Ella',
                'Alva',
                'Andrea',
                'Andrine',
                'Ann',
                'Annette',
                'Anja',
                'Astrid',
                'Karoline',
                'Marte',
                'Vibeke',
                'Bente',
                'Mia',
                'Jessica',
                'Bjørg',
                'Bodil',
                'Alexandra',
                'Ingeborg',
                'Ingvild',
                'Guro',
                'Ida',
                'Kari',
                'Vanja',
                'Yvonne',
                'Wenche',
                'Eva',
                'Lisa',
                'Amalie',
                'Mette',
                'Hilde',
                'Line',
                'Elisabeth',
                'Beate',
                'Jannike',
                'Tone',
                'Torild',
                'Hannah',
                'Hanne',
                'Jeanette',
                'Emilie',
                'Emily',
                'Helene',
                'Merete',
                'Hedda',
                'Liv',
                'Vilde',
                'T(h)erese',
                'Ina',
                'Siri',
                'Julie',
                'Grete',
                'Camilla',
                'Katrine',
                'Hege',
                'Mari',
                'Margrete',
                'Christine',
                'Kristin',
                'Britt',
                'Karianne',
                'Karine',
                'Lene',
                'Susanne',
                'Linnea',
                'Louise',
                'Emma',
                'Ellen',
                'Maren',
                'Marianne',
                'Maya',
                'Synne',
                'Nina',
                'Olivia',
                'Åse',
                'Ester',
                'Isabelle',
                'Malin',
                'Linda',
                'Bettina',
                'Vilgunn',
                'Inger',
                'Ingrid',
                'Mathilde',
                'Ulrika',
                'Cecilie',
                'Carina',
                'Charlotte',
                'Lise',
                'Benedikte',
                'Leah',
                'Janne',
                'Solveig',
                'Nora',
                'Ingunn',
                'Amanda',
                'Stine',
                'Elin',
                'Natalie',
                'Karen',
                'Renathe'
            ]
        },
        c3: {
            id: 'c3',
            place: 'Norway',
            type: 'surnames',
            color: '#BEA2F5',
            name: 'Top 100 surnames in Norway',
            nameIds: []
        },
        c111: {
            id: 'c111',
            type: 'male',
            color: '#B6EBED',
            name: 'Top 100 surnames in Somewhere',
            nameIds: []
        }
    },
    places: [
        // structure categories
        { name: 'Norway', categoryIds: ['c1', 'c2', 'c3'] },
        { name: 'Greece', categoryIds: ['c111'] },
        { name: 'Japan', categoryIds: ['c111'] },
        { name: 'China', categoryIds: ['c111'] }
    ],
    namesByRegion: {
        Norway: []
    },
    structure: {
        firstname: {
            Norway: {
                male: [
                    'Alf',
                    'Andreas',
                    'Anders',
                    'Andre',
                    'Arne',
                    'Arvid',
                    'Balder',
                    'Bjørn/Bjørnar',
                    'Hans',
                    'David',
                    'Mathias',
                    'Brage',
                    'Ivan',
                    'Alfred',
                    'Filip/Filipp',
                    'Benjamin',
                    'Gabriel',
                    'Helge',
                    'Inge',
                    'Odd',
                    'Pål',
                    'Jørn',
                    'Erlend',
                    'Erling',
                    'Elling',
                    'Svein',
                    'Sveinung',
                    'Jonas',
                    'Henrik',
                    'Henry',
                    'Tor(d)',
                    'Torbjørn',
                    'Torgrim',
                    'Torleif',
                    'Torstein',
                    'Frode',
                    'Emil',
                    'William',
                    'Leif',
                    'Gard',
                    'Alexander',
                    'Isak',
                    'Jakob',
                    'John',
                    'Klaus',
                    'Dag',
                    'Dag-Erik',
                    'Kåre',
                    'Kenneth',
                    'Finn',
                    'Kjartan',
                    'Kjell',
                    'Knut',
                    'Harald',
                    'Gustav',
                    'Kristen',
                    'Kristian',
                    'Kristoffer',
                    'Nils',
                    'Sjur',
                    'Sondre',
                    'Lukas',
                    'Lasse',
                    'Lars',
                    'Liam',
                    'Brede',
                    'Petter',
                    'Markus',
                    'Martin',
                    'Marius',
                    'Sindre',
                    'Amund',
                    'Oliver',
                    'Eirik',
                    'Erik',
                    'Fredrik',
                    'Leander',
                    'Leon',
                    'Adrian',
                    'Per',
                    'Remi',
                    'Michael',
                    'Mikkel',
                    'Karl',
                    'Ola',
                    'Ole',
                    'Elias',
                    'Aslak',
                    'Asle',
                    'Ruben',
                    'Rune',
                    'Sander',
                    'Ulrik',
                    'Simen',
                    'Sigurd',
                    'Sigve',
                    'Magnus',
                    'Bård',
                    'Gaute',
                    'Kjetil',
                    'Jan',
                    'Frank',
                    'Bernhard',
                    'Oscar',
                    'Stein',
                    'Egil',
                    'Stig',
                    'Sverre',
                    'Tobias',
                    'Tom',
                    'Tommy',
                    'Thomas',
                    'Truls',
                    'Jarle',
                    'Victor/Viktor',
                    'Jens',
                    'Jo',
                    'Johannes',
                    'Josef',
                    'Allan'
                ],
                females: [
                    'Ella',
                    'Alva',
                    'Andrea',
                    'Andrine',
                    'Ann',
                    'Anna',
                    'Anne',
                    'Annette',
                    'Anja',
                    'Astrid',
                    'Karoline',
                    'Marte',
                    'Vibeke',
                    'Bente',
                    'Mie',
                    'Mia',
                    'Jessica',
                    'Bjørg',
                    'Bodil',
                    'Alexandra',
                    'Ingeborg',
                    'Ingvild',
                    'Guro',
                    'Ida',
                    'Kari',
                    'Vanja',
                    'Yvonne',
                    'Wenche',
                    'Eva',
                    'Lisa',
                    'Amalie',
                    'Mette',
                    'Hilde',
                    'Line',
                    'Elisabeth',
                    'Beate',
                    'Jannike',
                    'Tone',
                    'Torild',
                    'Hannah',
                    'Hanne',
                    'Jeanette',
                    'Emilie',
                    'Emily',
                    'Helene',
                    'Merete',
                    'Hedda',
                    'Liv',
                    'Live',
                    'Vilde',
                    'T(h)erese',
                    'Ina',
                    'Siri',
                    'Julie',
                    'Grete',
                    'Camilla',
                    'Katrine',
                    'Hege',
                    'Mari',
                    'Maria',
                    'Marie',
                    'Mari(t)',
                    'Margrete',
                    'Christine',
                    'Kristin',
                    'Kristina',
                    'Kristine',
                    'Britt',
                    'Karianne',
                    'Karine',
                    'Lene',
                    'Susanne',
                    'Linnea',
                    'Louise',
                    'Emma',
                    'Ellen',
                    'Maren',
                    'Marianne',
                    'Maya',
                    'Synne',
                    'Synnøve',
                    'Nina',
                    'Olivia',
                    'Åse',
                    'Åsne',
                    'Ester',
                    'Isabelle',
                    'Malin',
                    'Linda',
                    'Bettina',
                    'Vilgunn',
                    'Inger',
                    'Ingrid',
                    'Mathilde',
                    'Ulrika',
                    'Cecilie',
                    'Carina',
                    'Charlotte',
                    'Lise',
                    'Benedikte',
                    'Leah',
                    'Janne',
                    'Solveig',
                    'Solfrid',
                    'Nora',
                    'Ingunn',
                    'Amanda',
                    'Stine',
                    'Elin',
                    'Natalia',
                    'Natalie',
                    'Karen',
                    'Renathe'
                ]
            }
        },
        surname: {
            Norway: [
                'Haug/Hauge/Haugn',
                'Haugland',
                'Andersen/Andresen/Andreassen',
                'Arnesen',
                'Karlsen',
                'Bjerke',
                'Bjørnstad',
                'Aune',
                'Lund/Lunde',
                'Ali',
                'Brekke',
                'Han(s)sen/Johansen',
                'Nielsen/Nilsen',
                'Mathisen',
                'Edvardsen',
                'Jacobsen/Jakobsen',
                'Wold',
                'Mohamed',
                'Foss',
                'Gran',
                'Hammer',
                'Thoresen',
                'Løken',
                'Solbakken',
                'Solvang',
                'Steen/Stene',
                'Hagen',
                'Fjeld',
                'Rørvik',
                'Antonsen',
                'Lie/Lien',
                'Berg/Berge',
                'Berntsen',
                'Sæther',
                'Jensen/Jenssen',
                'Paulsen',
                'Engen',
                'Ellingsen/Erlingsen',
                'Gulbrandsen',
                'Halvorsen',
                'Thorsen',
                'Holm',
                'Nygård/Ødegård',
                'Olsen',
                'Abrahamsen',
                'Johnsen',
                'Danielsen',
                'Næss',
                'Knudsen/Knutsen',
                'Sørensen',
                'Kristensen/Kristiansen',
                'Christensen/Christiansen',
                'Kristoffersen',
                'Birkeland',
                'Larsen',
                'Eide',
                'Martinsen',
                'Pedersen',
                'Myhre',
                'Amundsen',
                'Rasmussen',
                'Helland',
                'Eriksen',
                'Aas/Aasen',
                'Fredriksen',
                'Pettersen',
                'Gundersen',
                'Mikkelsen',
                'Eliassen',
                'Bakke/Bakken',
                'Isaksen',
                'Madsen',
                'Sivertsen',
                'Sandvik',
                'Strand',
                'Rønning',
                'Solberg/Solheim',
                'Henriksen',
                'Bøe',
                'Strøm',
                'Myklebust',
                'Tangen',
                'Thomassen',
                'Tveit',
                'Skoglund',
                'Dahl',
                'Bugge',
                'Moe/Moen',
                'Nguyen',
                'Vik',
                'Johannessen',
                'Kvam',
                'Møller',
                'Eggen',
                'Løkken',
                'Aamodt',
                'Tran',
                'Sand',
                'Bolstad',
                'Grande'
            ]
        }
    },
    codesUserstate: {},
    namesUserstate: {}
};
