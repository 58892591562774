import store from '@/store';

const defaults = {
    bronze: {
        braindates: {
            videos: 30
        }
    },
    silver: {
        courses: 100 // percentage complete
    },
    gold: {
        scenarios: 100, // percentage complete
        games: {
            // lifeskill 02
            level: 4
        }
    }
};

const overrides = {
    '11': {
        gold: {
            memolanguage: {
                lists: 300
            }
        }
    }
};

// const lifeskills = store.getters['moduleApp/getLifeskillIds']

const rule = ls => {
    let rule = Object.assign(defaults, overrides[ls]);
    return rule;
};

// console.log(util.inspect(rules, {depth: null}))

const lifeskillFilter = ls => braindate => {
    return braindate.lifeskillNumber === ls;
};

const bronzeFilter = braindate => {
    return braindate.context === 'video';
};

const bronzeProgressCalc = total => {
    total.done += 1;
    if (total.done >= total.required) {
        total.completion = 100;
    } else {
        total.completion = Math.ceil((total.done * 100) / total.required) || 0;
    }
    return total;
};

const getLifeskillStats = ls => {
    return store.getters['moduleApp/getLifeskillStats'](ls);
    // return {
    //   numberOfSteps: 5,
    //   numberOfDoneSteps: 2
    // }
};

const silverProgressCalc = (ls, stats) => {
    const total = stats.numberOfSteps;
    const acquired = stats.numberOfDoneSteps;
    return {
        completion: Math.ceil((acquired * 100) / total) || 0,
        required: total,
        done: acquired
    };
};

const goldProgressCalc = (ls, stats) => {
    let total = stats.numberOfScenarios;
    let acquired = stats.scenariosDoneCount;

    if (ls === '11') {
        // memolanguage
        const learnedLists = braindates()
            .filter(b => b.lifeskillNumber === ls && b.context === 'language')
            .map(b => b.listId)
            .filter((b, i, a) => a.indexOf(b) === i).length; // unique
        total = total + rule(ls).gold.memolanguage.lists;
        acquired = acquired + learnedLists;
    }

    if (ls === '02') {
        // memogenius
        const gameIds = store.getters['moduleMemogenius/getGameIds'];
        const gameLevels = gameIds.map(gameId => store.getters['moduleMemogenius/getLevelsByGameId'](gameId));
        // console.log('game:', gameLevels)
        const totalGameLevels = gameLevels.reduce((total, g) => {
            return total + g.length;
        }, 0);
        // console.log('total game levels', totalGameLevels)
        const gameStats = gameIds.map(gameId => store.getters['moduleMemogenius/getHighestCompletedLevelInfo'](gameId));
        // console.log('gamestats:', gameStats)
        const doneGameLevels = gameStats.reduce((total, g) => {
            return total + (g && g.levelNumber) || 0;
        }, 0);
        // console.log('game levels done', doneGameLevels)
        total = total + totalGameLevels;
        acquired = acquired + doneGameLevels;
    }
    return {
        completion: Math.ceil((acquired * 100) / total) || 0,
        required: total,
        done: acquired
    };
};

const braindates = () => {
    return store.getters['moduleApp/braindates'];
    // return [{context: 'video', numbershape: '00'}]
};

const bronzeProgress = ls =>
    braindates()
        .filter(lifeskillFilter(ls))
        .filter(bronzeFilter)
        .reduce(bronzeProgressCalc, { completion: 0, required: rule(ls).bronze.braindates.videos, done: 0 });

const silverProgress = (ls, stats) => silverProgressCalc(ls, stats);

const goldProgress = (ls, stats) => goldProgressCalc(ls, stats);

const totalProgress = ls => {
    const stats = getLifeskillStats(ls);
    return {
        lifeskill: ls,
        bronze: bronzeProgress(ls),
        silver: silverProgress(ls, stats),
        gold: goldProgress(ls, stats)
    };
};

export const userProgress = ls => {
    return totalProgress(ls);
};

export const categoryProgress = (result, ls) => {
    const progress = userProgress(ls);
    if (progress) {
        result.lifeskills.push(progress);

        const raw = result.lifeskills.reduce(
            (total, item) => {
                total.bronzeRequired += item.bronze.required;
                total.bronzeDone += item.bronze.done;
                total.silverRequired += item.silver.required;
                total.silverDone += item.silver.done;
                total.goldRequired += item.gold.required;
                total.goldDone += item.gold.done;
                return total;
            },
            { bronzeRequired: 0, bronzeDone: 0, silverRequired: 0, silverDone: 0, goldRequired: 0, goldDone: 0 }
        );

        // const bronzeProgress = Math.ceil(raw.bronzeDone * 100 / raw.bronzeRequired) || 0
        // const silverProgress = Math.ceil(raw.silverDone * 100 / raw.silverRequired) || 0
        // const goldProgress = Math.ceil(raw.goldDone * 100 / raw.goldRequired) || 0

        // console.log(ls, 'bronze', raw.bronzeRequired, raw.bronzeDone, bronzeProgress)
        // console.log(ls, 'silver', raw.silverRequired, raw.silverDone, silverProgress)
        // console.log(ls, 'gold', raw.goldRequired, raw.goldDone, goldProgress)

        result.total = Math.ceil(
            ((raw.bronzeDone + raw.silverDone + raw.goldDone) * 100) /
                (raw.bronzeRequired + raw.silverRequired + raw.goldRequired)
        );
        // result.total = Math.ceil((bronzeProgress + silverProgress + goldProgress) * 100 / 300)
        // console.log(ls, 'total', result.total)
        return result;
    } else {
        return result;
    }
};

export default userProgress;

// console.log('user progress', userProgress())
