/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '02systems': {
    width: 44,
    height: 50,
    viewBox: '0 0 44 50',
    data: '<path pid="0" d="M44 32.84l-4.102-2.33-3.157 1.793-3.26-1.852V19.506l3.228-1.828 3.188 1.813L44 17.16V12.5l-4.1-2.33-4.102 2.33v3.632l-3.23 1.83-9.668-5.493v-3.66l3.2-1.819V2.33L22 0l-4.1 2.33v4.66l3.183 1.81v3.68l-9.616 5.463-3.264-1.854V12.5l-4.1-2.33L0 12.5v4.66l4.1 2.33 3.228-1.834 3.189 1.812v10.979l-3.27 1.852-3.147-1.789L0 32.84v4.661l4.101 2.328 4.1-2.328V33.82l3.181-1.8 9.7 5.51V41.2l-3.184 1.81v4.662l4.1 2.329 4.101-2.33v-4.661l-3.2-1.818v-3.65l9.714-5.52 3.185 1.81v3.67l4.1 2.329L44 37.5v-4.66zm-12.336-3.421l-7.832-4.45 7.832-4.435v8.885zm5.948-15.886l2.286-1.299 2.285 1.299v2.595l-2.286 1.299-2.285-1.298v-2.597zm-6.865 5.459L22.9 23.436v-8.903l7.848 4.459zM19.715 3.362l2.284-1.299 2.285 1.299v2.597L22 7.258l-2.285-1.299.001-2.597zm1.367 11.18v8.865l-7.8-4.432 7.8-4.433zm-16.98 2.885l-2.285-1.299v-2.596L4.1 12.234l2.285 1.299v2.595l-2.285 1.299zm8.232 3.072l7.863 4.467-7.863 4.453v-8.92zM6.386 36.47l-2.285 1.3-2.285-1.3v-2.596l2.285-1.297 2.285 1.297v2.595zm6.816-5.48l7.88-4.462v8.942l-7.88-4.48zm11.082 15.65L22 47.935l-2.285-1.297V44.04L22 42.744l2.285 1.297v2.597zM22.9 35.476v-8.975l7.899 4.487-7.899 4.488zm17 2.29l-2.285-1.299v-2.596l2.284-1.297 2.285 1.297v2.596l-2.284 1.3z" _fill="#000" fill-rule="nonzero"/>'
  }
})
