export const SET_ROOT = 'SET_ROOT';

export const UPDATE_DASHBOARD_FAVORITES_SELECTED = 'UPDATE_DASHBOARD_FAVORITES_SELECTED';
export const UPDATE_GET_STARTED_SHOWN = 'UPDATE_GET_STARTED_SHOWN';
export const UPDATE_DASHBOARD_ONBOARDING_SHOWN = 'UPDATE_DASHBOARD_ONBOARDING_SHOWN';
export const UPDATE_MEMOMAPS_ONBOARDING_SHOWN = 'UPDATE_MEMOMAPS_ONBOARDING_SHOWN';
export const UPDATE_MEMO_ONBOARDING_SHOWN = 'UPDATE_MEMO_ONBOARDING_SHOWN';
export const UPDATE_DID_EXIT_GETTING_STARTED_FOCUS = 'UPDATE_DID_EXIT_GETTING_STARTED_FOCUS';
export const UPDATE_WHATSNEW_VIEWED_ARTICLES = 'UPDATE_WHATSNEW_VIEWED_ARTICLES';

export const UPDATE_TIME = 'UPDATE_TIME';
export const UPDATE_VIDEO_PROGRESS = 'UPDATE_VIDEO_PROGRESS';
export const UPDATE_ALL_VIDEOS = 'UPDATE_ALL_VIDEOS';
export const VIDEO_PART_COMPLETED = 'VIDEO_PART_COMPLETED';

export const SET_COURSE_LIST = 'SET_COURSE_LIST';
export const UPDATE_STEP_MEMO_PROGRESS = 'UPDATE_STEP_MEMO_PROGRESS';

export const SET_LIFESKILL_SELECTED_VIDEO = 'SET_LIFESKILL_SELECTED_VIDEO';
export const SET_LIFESKILL_MARKED = 'SET_LIFESKILL_MARKED';
export const SET_LIFESKILLS_MARKED = 'SET_LIFESKILLS_MARKED';
export const SET_DAILY_REQUIRED_BRAINDATES = 'SET_DAILY_REQUIRED_BRAINDATES';

// New //

export const SET_COURSE_DATA = 'SET_COURSE_DATA';

export const SET_STEP_GROUP_DATA = 'SET_STEP_GROUP_DATA';
export const INIT_STEP_GROUP_QUIZ_PROGRESS = 'INIT_STEP_GROUP_QUIZ_PROGRESS';
export const SET_STEP_GROUP_QUIZ_PROGRESS = 'SET_STEP_GROUP_QUIZ_PROGRESS';
export const UPDATE_STEP_GROUP_QUIZ_STATE = 'UPDATE_STEP_GROUP_QUIZ_STATE';
export const UPDATE_STEP_GROUP_REPETITION = 'UPDATE_STEP_GROUP_REPETITION';

export const SET_STEP_DATA = 'SET_STEP_DATA';
export const SET_STEP_PROGRESS = 'SET_STEP_PROGRESS';
export const SET_STEP_READ_PROGRESS = 'SET_STEP_READ_PROGRESS';
export const SET_STEP_QUIZ_PROGRESS = 'SET_STEP_QUIZ_PROGRESS';
export const RECOMPUTE_STEP_PROGRESS = 'RECOMPUTE_STEP_PROGRESS';
export const RECOMPUTE_STEP_MEMO_PROGRESS = 'RECOMPUTE_STEP_MEMO_PROGRESS';

export const SET_MEMO_DATA = 'SET_MEMO_DATA';
export const SET_MEMO_PROGRESS = 'SET_MEMO_PROGRESS';
export const SET_MEMO_SUMMARY_PROGRESS = 'SET_MEMO_SUMMARY_PROGRESS';
export const SET_MEMO_GAME_PROGRESS = 'SET_MEMO_GAME_PROGRESS';

export const SET_SCENARIO_DATA = 'SET_SCENARIO_DATA';

// -------------------------//

export const UPDATE_VIDEO_DATA = 'UPDATE_VIDEO_DATA';
export const UPDATE_VIDEO_DATA_WITH_DESCRIPTION = 'UPDATE_VIDEO_DATA_WITH_DESCRIPTION';
export const UPDATE_LIFESKILL_DATA = 'UPDATE_LIFESKILL_DATA';

export const UPDATE_STEP_SCENARIO = 'UPDATE_STEP_SCENARIO';
export const UPDATE_LANGUAGE_REPETITION = 'UPDATE_LANGUAGE_REPETITION';

export const SET_MEMOSTREAK = 'SET_MEMOSTREAK';
export const ADD_BRAINDATE = 'ADD_BRAINDATE';
export const SET_BRAINDATES = 'SET_BRAINDATES';

export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_SET_PROFILE = 'AUTH_SET_PROFILE';

export const SET_LOCALE = 'SET_LOCALE';

export const MEMOGENIUS_UPDATE_SETTINGS = 'MEMOGENIUS_UPDATE_SETTINGS';
export const MEMOGENIUS_SUCCESS = 'MEMOGENIUS_SUCCESS';

export const MEMOLANGUAGE_UPDATE_LANGUAGE_STATE = 'MEMOLANGUAGE_UPDATE_LANGUAGE_STATE';
export const MEMOLANGUAGE_SET_CATEGORY_DATA = 'MEMOLANGUAGE_SET_CATEGORY_DATA';
export const MEMOLANGUAGE_UPDATE_SETTINGS = 'MEMOLANGUAGE_UPDATE_SETTINGS';
export const MEMOLANGUAGE_SET_ITEMS_USERSTATE = 'MEMOLANGUAGE_SET_ITEMS_USERSTATE';
export const MEMOLANGUAGE_UPDATE_ITEM = 'MEMOLANGUAGE_UPDATE_ITEM';
export const MEMOLANGUAGE_QUIZ_COMPLETED = 'MEMOLANGUAGE_QUIZ_COMPLETED';

export const SET_SHOW_DEBUG_BUTTON = 'SET_SHOW_DEBUG_BUTTON';
export const SET_DEBUG_SETTINGS = 'SET_DEBUG_SETTINGS';
export const UPDATE_DEBUG_SETTINGS = 'UPDATE_DEBUG_SETTINGS';
export const UPDATE_AUTOPLAY_VIDEO_PARTS = 'UPDATE_AUTOPLAY_VIDEO_PARTS';

export const UPDATE_APP_SETTINGS = 'UPDATE_APP_SETTINGS';

export const SET_MISSION = 'SET_MISSION';

export const SET_LIFEKEYS = 'SET_LIFEKEYS';

export const ADD_LIFEKEY = 'ADD_LIFEKEY';

export const SCENARIO_DONE = 'SCENARIO_DONE';

export const MH_COMPLETED = 'MH_COMPLETED';
export const MH_DAILY_COMPLETED = 'MH_DAILY_COMPLETED';

export const MIQ_COMPLETED = 'MIQ_COMPLETED';

export const REPETITION_UPDATE = 'REPETITION_UPDATE';
