/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'profile-icon': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M8 16c4.079 0 7.438-3.055 7.931-7H7.778l-5.027 5.027A7.955 7.955 0 008 16zM8 0v8h8a8 8 0 00-8-8zM0 8a7.96 7.96 0 002.04 5.324L7 8.364V.069C3.055.562 0 3.921 0 8z"/>'
  }
})
