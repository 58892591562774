<template>
  <div
    class="BraindatePopup"
    data-bg="light"
    :class="{ horizontalLayout, showBonus }"
    @click="$emit('signal')">
    <div class="body">
      <div class="animContainer">
        <AnimatedBraindate
          v-if="!showBonus"
          ref="animatedBraindate"
          :count="count"
          :goal="goal"
          :color="progressColor"
          live
          simplified-heart-anim
          @done="onAnimationCompleted()" />
        <AnimatedGift
          v-if="extraLifeskillIds.length && showBonus"
          @done="onBonusAnimationCompleted()" />
      </div>
    </div>

    <div class="footer">
      <!-- <LifeskillNumberTag
        :lifeskill-id="lifeskillId"
        size="large"
      /> -->

      <div class="title">
        {{ extraLifeskillIds.length ? $t('bonusTitle') : $t('title') }}
      </div>
      <!-- <div class="bonusSubtitle">
        {{ $t('bonusSubtitle') }}
      </div> -->
      <div class="lifeskillLabel">
        {{ $t(`dashboard.general.lifeskillLabels.${lifeskillId}`) }}
      </div>

      <div class="lifeskillTags">
        <LifeskillNumberTag
          :lifeskill-id="lifeskillId"
          size="large" 
          :isCategorySection="true"/>
        <template v-if="extraLifeskillIds.length">
          <span class="plusSign">+</span>
          <LifeskillNumberTag
            v-for="(bonusId, i) of extraLifeskillIds"
            :key="i"
            :lifeskill-id="bonusId"
            size="large" 
            :isCategorySection="true"/>
        </template>
      </div>
    </div>

    <!-- <div
      v-if="duration"
      class="countdownLine"
      :style="{ animationDuration: `${duration}ms` }"
    /> -->
  </div>
</template>

<translations>
  title: 'Brain Date'
  bonusTitle: 'Bonus Brain Date'
  bonusSubtitle: 'You found a special brain date'
</translations>

<script>
import { getCategoryIdFromLifeskillId } from '@/utils';
import categoryThemes from '@/category-themes';
import LifeskillNumberTag from './LifeskillNumberTag';
import AnimatedBraindate from './AnimatedBraindate';
import AnimatedGift from '@/components/AnimatedGift';
import soundService from '@/services/sound-service';
import braindateSound from '@/assets/sounds/braindate.mp3';

export default {
    components: { LifeskillNumberTag, AnimatedBraindate, AnimatedGift },
    props: {
        lifeskillId: {
            type: String
        },
        horizontalLayout: {
            type: Boolean,
            default: false
        },
        goal: {
            type: Number,
            required: true
        },
        count: {
            type: Number,
            required: true
        },
        extraLifeskillIds: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    data() {
        return {
            // duration: 0,
            showBonus: false
        };
    },
    created() {
        const categoryId = getCategoryIdFromLifeskillId(this.lifeskillId);
        this.progressColor = categoryId ? categoryThemes[categoryId].color : categoryThemes.default.color;
        if (this.extraLifeskillIds.length) {
            this.showBonus = true;
            soundService.play(braindateSound);
        }
    },
    mounted() {
        // this.duration = (this.$refs.animatedBraindate.duration || 0) + 1000 + (this.extraLifeskillIds.length ? 2000 : 0)
        // if (this.showBonus) {
        //   this.timeoutId = setTimeout(() => {
        //     this.showBonus = false
        //   }, 2000)
        // }
    },
    beforeDestroy() {
        clearTimeout(this.timeoutId);
    },
    methods: {
        onBonusAnimationCompleted() {
            console.log('onBonusAnimationCompleted');
            this.showBonus = false;
        },
        onAnimationCompleted() {
            this.timeoutId = setTimeout(() => {
                this.$emit('signal');
            }, 1000);
        },
        gotoStatistics() {
            this.$router.push({
                name: 'DashboardProfileView'
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.BraindatePopup {
    position: relative;
    background-color: white;
    color: black;
    width: 15em;
    border-radius: $app-popupBorderRadius;
    overflow: hidden;

    &.horizontalLayout {
        display: flex;
        width: 28em;

        .animContainer {
            $size: 8em;
            width: $size;
            height: $size;
        }

        .footer {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    &.showBonus {
        // .AnimatedBraindate {
        //   display: none;
        // }
    }
    &:not(.showBonus) {
        // .AnimatedGift {
        //   display: none;
        // }
    }
}

.body {
    padding: 1em;
}

.title {
    font-size: 140%;
    font-weight: 600;
}
.subtitle {
    color: rgba(black, 0.5);
    margin-top: 0.5em;
}

.animContainer {
    $size: 12em;
    width: $size;
    height: $size;
    position: relative;
    margin: auto;

    // outline: 1px solid green;
}
.AnimatedBraindate {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.AnimatedGift {
    $offset: -1em;
    position: absolute;
    top: $offset;
    right: $offset;
    bottom: $offset;
    left: $offset;
}

.footer {
    padding: 1em;
    background-color: mix($completionColor, white, 10%);
    text-align: center;
}

.lifeskillTags {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1em;
}
.plusSign {
    font-size: 120%;
    color: rgba(black, 0.7);
    margin: 0 0.3em;
}
.plusSign ~ * {
    margin-left: 0.5em;
}
.plusSign + * {
    margin-left: 0;
}

.bonusTitle {
    font-size: 120%;
    color: rgba(black, 0.9);
}
.bonusSubtitle {
    color: rgba(black, 0.5);
}
.lifeskillLabel {
    color: rgba(black, 0.5);
    font-size: 120%;
    margin-top: 0.3em;
}

// .countdownLine {
//   position: absolute;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   height: 3px;
//   background-color: rgba(black, 0.2);
//   transform-origin: top left;
//   animation-duration: 3s;
//   animation-timing-function: linear;
//   animation-name: growLine;

//   @keyframes growLine {
//     0% { transform: scale(0, 1); }
//     100% { transform: scale(1, 1); }
//   }
// }
</style>
