export default function (source) {
    function extract(item, suffix, validSuffixes) {
        if (item instanceof Array) {
            return item.map(function (arrayElem) {
                return extract(arrayElem, suffix, validSuffixes);
            });
        } else if (item instanceof Object) {
            let prunedObject = {};

            Object.keys(item).forEach(function (rawKey) {
                let value = item[rawKey];
                let potentialSuffix = rawKey.substr(-3);
                let key;
                if (validSuffixes[potentialSuffix]) {
                    key = rawKey.substr(0, rawKey.length - 3);
                    if (potentialSuffix === suffix) {
                        prunedObject[key] = value;
                    } else {
                        // Ignore
                    }
                } else {
                    key = rawKey;
                    if (!item.hasOwnProperty(key + suffix)) {
                        prunedObject[key] = extract(value, suffix, validSuffixes);
                    }
                }
            });
            return prunedObject;
        } else {
            return item;
        }
    }

    let validSuffixes = {
        _no: true
    };

    let out = {
        no: extract(JSON.parse(JSON.stringify(source)), '_no', validSuffixes),
        en: extract(JSON.parse(JSON.stringify(source)), null, validSuffixes)
    };
    return out;
}
