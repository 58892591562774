/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hand-right': {
    width: 67,
    height: 90,
    viewBox: '0 0 67 90',
    data: '<path pid="0" d="M64.3 18.6c1.9.6 3 3.1 2.7 5.7l-5.9 31-4.4 21.4-.5 13.3H29l-2-9.7c-.3-.8-.8-1.5-1.4-2.1L15.5 69c-.2-.1-.3-.2-.4-.4H15c0-.1-.1-.1-.1-.2L1.3 47.6c-.3-.4-.4-.9-.4-1.4 0-1.2.7-2.6 2-3.5 1.8-1.4 5.2-.1 6.3 1.1l13.3 15.1c.2.2.4.2.6 0 .1-.1.1-.1.1-.2l1.2-12.9-1.2-33c-.1-2.7 1.3-5 3.2-5.2 1.9-.2 3.7 1.7 4 4.4l3.5 30 2.2-37.1C36.3 2 37.9 0 39.7 0h.1c1.9.1 3.5 1.3 3.5 5l.5 36.4L49.5 12c.5-2.5 2-4.2 3.7-4.2.2 0 .4 0 .5.1 1.9.4 3.1 2.9 2.8 5.6l-3.8 33.1L60 22c.6-2.5 2.5-4 4.3-3.4z" fill-rule="evenodd"/>'
  }
})
