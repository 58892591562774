/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'male': {
    width: 79,
    height: 79,
    viewBox: '0 0 79 79',
    data: '<path pid="0" d="M30.9 78.2C13.9 78.2 0 64.3 0 47.3s13.9-30.9 30.9-30.9c6.3 0 12.7 2 17.9 5.7l.7.3L63.6 8H50.2V0h28v28h-8V14.6L55.8 28.7l.4.7c3.7 5.2 5.7 11.5 5.7 17.9-.1 17-14 30.9-31 30.9zm0-53.1c-12.3 0-22.2 10-22.2 22.2s10 22.2 22.2 22.2 22.2-10 22.2-22.2-9.9-22.2-22.2-22.2z" fill-rule="nonzero"/>'
  }
})
