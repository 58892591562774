import storage from './storage';
import { getHandler, buildDoc } from './doc-handlers';
import apolloClient from '@/apollo-client';
import gql from 'graphql-tag';
import debounce from 'lodash/debounce';
import mutationHooks from './mutation-hooks';

const persistence = {};

const myPlugin = store => {
    // eslint-disable-next-line
    let ignoreMutation = false;
    let appliedRevsMap = {};

    function loadApp() {
        readLocal(['memolife-general', 'memolife-debug-settings']);
        store.commit('setInitialAppStateLoaded');
    }
    async function loadUser() {
        store.commit('setInitialUserStateLoaded');
    }
    function unloadUser() {
        //
    }

    function applyToVuexState(doc, allDocs) {
        const handler = getHandler(doc._id);
        if (!handler) {
            return;
        }
        ignoreMutation = true;
        try {
            handler.apply(doc, store, allDocs);
        } catch (err) {
            console.error(err); // eslint-disable-line no-console
        }
        ignoreMutation = false;
        appliedRevsMap[doc._id] = doc._rev;
    }

    function localWrite(docName, options) {
        let doc = buildDoc(store, docName, options);
        storage.setItem(doc._id, JSON.stringify(doc));
    }

    // eslint-disable-next-line
    function requestLocalWrite(funcName, options) {
        localWrite(funcName, options);
    }

    function readLocal(docIds) {
        docIds.forEach(docId => {
            const value = storage.getItem(docId);
            if (value) {
                let doc = null;
                try {
                    doc = JSON.parse(value);
                } catch (err) {
                    console.error(err); // eslint-disable-line no-console
                    return;
                }
                applyToVuexState(doc);
            }
        });
    }

    Object.assign(persistence, {
        loadApp,
        loadUser,
        unloadUser
    });

    store.subscribe((mutation, state) => {
        if (ignoreMutation) {
            return;
        }

        if (mutationHooks[mutation.type]) {
            mutationHooks[mutation.type](mutation.payload, { requestLocalWrite, state });
        }
    });
};

const updateUserSetting = (prefix, key, value) => {
    console.log('updateUserSetting', prefix, key, value);
    key = `${prefix}${key}`;
    return apolloClient.mutate({
        fetchPolicy: 'no-cache',
        mutation: gql`
            mutation($key: String!, $value: JSON) {
                updateUserSetting(input: { _key: $key, _value: $value }) {
                    results {
                        key
                        value
                    }
                }
            }
        `,
        variables: { key, value }
    });
};

const updateUserSettingDebounced = debounce((prefix, key, value) => {
    return updateUserSetting(prefix, key, value);
}, 500);

export { myPlugin, updateUserSetting, updateUserSettingDebounced };
export default persistence;
