/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'steps': {
    width: 80,
    height: 92,
    viewBox: '0 0 80 92',
    data: '<g _fill="#000" fill-rule="nonzero"><path pid="0" d="M74.35 27.05a10 10 0 00.19-1.84c0-6.7-6.64-12.15-14.8-12.15s-14.8 5.45-14.8 12.15a10.75 10.75 0 003 7.3 4.56 4.56 0 011.26 3c.05 2.26.13 6.12.13 8.47a6.68 6.68 0 01-.86 3.02 8.66 8.66 0 00-1.3 5.73A8.72 8.72 0 0064 54.8a78.84 78.84 0 0010-25.93l.35-1.82z"/><ellipse pid="1" cx="42.61" cy="6.57" rx="5.49" ry="6.49"/><ellipse pid="2" cx="54.49" cy="4.62" rx="3.54" ry="4.19"/><ellipse pid="3" cx="63.97" cy="5.55" rx="2.61" ry="3.08"/><ellipse pid="4" cx="71.57" cy="8.75" rx="1.91" ry="2.25"/><ellipse pid="5" cx="77.58" cy="13.55" rx="1.91" ry="2.25"/><path pid="6" d="M32.09 64a10.75 10.75 0 003-7.3c0-6.7-6.64-12.15-14.8-12.15S5.48 50 5.48 56.7a10 10 0 00.19 1.84L6 60.36a78.84 78.84 0 0010 25.93 8.72 8.72 0 1015.51-7.77 6.67 6.67 0 01-.83-3.07c0-2.35.08-6.21.13-8.47A4.56 4.56 0 0132.09 64z"/><ellipse pid="7" cx="37.39" cy="38.06" rx="5.49" ry="6.49"/><ellipse pid="8" cx="25.51" cy="36.11" rx="3.54" ry="4.19"/><ellipse pid="9" cx="16.03" cy="37.05" rx="2.61" ry="3.08"/><ellipse pid="10" cx="8.43" cy="40.25" rx="1.91" ry="2.25"/><ellipse pid="11" cx="2.42" cy="45.05" rx="1.91" ry="2.25"/></g>'
  }
})
