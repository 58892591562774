/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'envelope': {
    width: 16,
    height: 16,
    viewBox: '0 0 128 128',
    data: '<path pid="0" class="st0" d="M112.8 31.8c-.7-1.2-1.7-2.1-2.9-2.8-1.1-.6-2.4-1-3.8-1H22c-1.4 0-2.6.3-3.8 1-1.2.6-2.2 1.6-2.9 2.7-.9 1.2-1.3 2.7-1.3 4.3v56c0 1.5.4 2.9 1.1 4.1.7 1.2 1.6 2.1 2.8 2.8 1.2.7 2.6 1.1 4.1 1.1h84c1.5 0 2.9-.4 4.1-1.1 1.2-.7 2.1-1.6 2.8-2.8.7-1.2 1.1-2.6 1.1-4.1V36c0-1.6-.4-3-1.2-4.2zm-6.8.2h.3L69.7 64c-2.6 2.2-6.9 2.3-9.6.1L21.9 32H106zM85.6 68.8l-2.8 2.8L107 95.8c-.3.1-.7.2-1 .2H22c-.4 0-.7 0-1-.2l24.3-24.2-2.8-2.8L18.2 93c-.2-.3-.2-.6-.2-1V36c0-.6.2-1.2.4-1.7l39.2 32.9c2 1.7 4.8 2.8 7.4 2.8 2.7 0 5.3-1.2 7.4-3l37.3-32.6c.2.5.3 1 .3 1.6v56c0 .4 0 .7-.2 1L85.6 68.8z"/>'
  }
})
