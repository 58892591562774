/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'eye-blocked': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M29.561.439a1.5 1.5 0 00-2.121 0l-6.318 6.318A17.626 17.626 0 0016 6C9.021 6 2.972 10.064 0 16a18.323 18.323 0 005.407 6.472L.439 27.44a1.5 1.5 0 102.122 2.121l27-27a1.5 1.5 0 000-2.121zM13 10a3 3 0 012.841 2.037l-3.804 3.804A3 3 0 0113 10zm-9.559 6a15.223 15.223 0 015.041-4.925A7.985 7.985 0 008 13.812c0 1.715.54 3.304 1.459 4.607l-1.904 1.904A15.219 15.219 0 013.441 16zM24 13.813c0-.849-.133-1.667-.378-2.434L13.566 21.435a8 8 0 0010.435-7.622z"/><path pid="1" d="M25.938 9.062L23.77 11.23l.118.074a15.212 15.212 0 014.67 4.697 15.223 15.223 0 01-4.67 4.697 14.642 14.642 0 01-11.45 1.864l-2.403 2.403a17.587 17.587 0 005.964 1.036c6.978 0 13.027-4.064 16-10a18.292 18.292 0 00-6.062-6.938z"/>'
  }
})
