/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pencil2': {
    width: 28,
    height: 28,
    viewBox: '0 0 28 28',
    data: '<path pid="0" d="M8.786 22.02l-5.372 3.253c-.489.228-.929-.244-.701-.702l3.252-5.376 2.821 2.825zm9.453-15.798l3.537 3.49-11.24 11.247a9352.095 9352.095 0 00-3.512-3.515L18.239 6.222zM5.11 17.068l-4.3 8.705c-.361.981.423 1.859 1.403 1.404l8.699-4.303c.375-.073.739-.219 1.029-.509L27.396 6.899a1.988 1.988 0 000-2.811L23.884.573a1.987 1.987 0 00-2.81 0L5.619 16.038c-.291.291-.435.655-.509 1.03z" fill-rule="evenodd"/>'
  }
})
