/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'lock2': {
    width: 384,
    height: 448,
    viewBox: '0 0 384 448',
    data: '<path pid="0" d="M224 288c0-17.673-14.327-32-32-32-17.673 0-32 14.327-32 32 0 9.17 3.857 17.438 10.036 23.272C160 339.685 160 384 160 384h64s0-44.315-10.036-72.728C220.144 305.438 224 297.17 224 288zm32-160c0-35.346-28.654-64-64-64-35.346 0-64 28.654-64 64 0 11.657 3.117 22.586 8.562 32h-68.53A128.231 128.231 0 0164 128C64 57.308 121.308 0 192 0c70.692 0 128 57.308 128 128 0 11.05-1.4 21.772-4.033 32h-68.53c5.446-9.414 8.563-20.343 8.563-32zM64 192h288.003C369.676 192 384 206.328 384 224.002v159.997C384 419.348 355.345 448 319.997 448H64.003C28.647 448 0 419.346 0 383.999V224.002C0 206.335 14.326 192 31.997 192H64z"/>'
  }
})
