/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'quiz-icon': {
    width: 7,
    height: 8,
    viewBox: '0 0 7 8',
    data: '<path pid="0" d="M3.817.575c-.04-.01-.092 0-.112.04L.589 4.697c-.03.039-.02.108.02.138.02.01.041.02.062.02h2.963l-.051 2.623c0 .06.04.099.101.099.031 0 .062-.01.082-.04l3.116-4.083c.03-.04.02-.109-.02-.138-.021-.01-.041-.02-.062-.02H3.837L3.888.674c0-.05-.03-.08-.071-.099z" _fill="#fff"/>'
  }
})
