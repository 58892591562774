import VueAuthenticate from 'vue-authenticate';
import settings from '@/settings';
import axios from 'axios';

let pathname = '/oauth-redirect/';
// NOTE: making sure that the url ends with a '/'. This is important. Without the '/' the server can cause a redirect that prevents facebook login from working.
// const pathname = window.location.pathname.endsWith('/') ? window.location.pathname : window.location.pathname + '/'
if (window.location.pathname.startsWith(process.env.GIT_BRANCH)) {
    pathname = '/' + process.env.GIT_BRANCH + pathname;
}

const vueAuth = VueAuthenticate.factory(axios, {
    tokenPath: 'token',
    tokenName: 'token', // 'access_token',
    baseUrl: settings.endpoints.bouncer,
    withCredentials: true,
    loginUrl: '/login/',
    logoutUrl: settings.endpoints.bouncer + '/logout/',
    registerUrl: '/users/',
    providers: {
        facebook: {
            clientId: settings.facebookClientId, // '919907441412097',
            scope: ['email', 'public_profile'],
            authorizationEndpoint: 'https://www.facebook.com/v3.2/dialog/oauth',
            redirectUri: window.location.origin + pathname
        }
    }
});

export default vueAuth;
