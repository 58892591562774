import store from '@/store';
import settings from '@/settings';

export default {
    install(Vue) {
        Vue.prototype.$intercom = new IntercomService();
        Vue.prototype.$intercom.waitReady();
    }
};

class IntercomService {
    waitReady() {
        this.install(settings.intercomId);
        this.boot();
        this.setupWatchers();
    }

    install(APP_ID) {
        /* eslint-disable */
        (function () {
            var w = window;
            var ic = w.Intercom;
            if (typeof ic === 'function') {
                ic('reattach_activator');
                ic('update', intercomSettings);
            } else {
                var d = document;
                var i = function () {
                    i.c(arguments);
                };
                i.q = [];
                i.c = function (args) {
                    i.q.push(args);
                };
                w.Intercom = i;
                function l() {
                    var s = d.createElement('script');
                    s.type = 'text/javascript';
                    s.async = true;
                    s.src = 'https://widget.intercom.io/widget/' + APP_ID;
                    var x = d.getElementsByTagName('script')[0];
                    x.parentNode.insertBefore(s, x);
                }
                if (w.attachEvent) {
                    w.attachEvent('onload', l);
                } else {
                    w.addEventListener('load', l, false);
                }
            }
        })();
        /* eslint-enable */
    }

    boot(config) {
        window.Intercom('boot', config || createIntercomSettings());
    }

    update(config) {
        window.Intercom('update', config || createIntercomSettings());
    }

    show() {
        window.Intercom('show');
    }

    hide() {
        window.Intercom('hide');
    }

    setupWatchers() {
        store.watch(
            state => state.moduleAuth.profile,
            profile => {
                this.boot(createIntercomSettings());
            },
            { deep: true }
        );

        store.watch(
            state => store.getters['moduleApp/gettingStartedTotalProgress'],
            val => {
                this.update({
                    getting_started: Math.ceil(val * 100)
                });
            }
        );
        store.watch(
            state => store.getters['moduleApp/braindates'],
            val => {
                this.update({
                    braindates: val.length
                });
            }
        );
        store.watch(
          state => store.getters['repetition/energyLevel'],
          val => this.update({ aura: val })
        );
    }
}

function createIntercomSettings() {
    const config = {
        app_id: settings.intercomId,
        hide_default_launcher: true
    };
    const profile = store.state.moduleAuth.profile;
    if (profile) {
        config.name = profile.name;
        config.email = profile.email;
        config.user_hash = profile.user_hash;
        config.user_id = profile._id;
        config.created_at = profile.created_at;
        config.plan = profile.subscription.plan_id;
        config.plan_status = profile.subscription.status;
        config.partner = null;
        config.getting_started = Math.ceil(store.getters['moduleApp/gettingStartedTotalProgress'] * 100 || 0);

        let roles = profile.roles;
        if (roles.indexOf('flytoget') > -1) {
            config.partner = 'flytoget';
        }
        if (roles.indexOf('lingu') > -1) {
            config.partner = 'lingu';
        }
    }
    return config;
}
