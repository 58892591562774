import moment from 'moment';
import Vue from 'vue';
import { fetchCourseProgress, fetchCourseScenarios, updateCourseProgress } from './api';
import { EventBus } from '@/event-bus';
import * as types from '@/store/mutation-types';
import uniq from 'lodash/uniq';

export default {
  namespaced: true,
  getters: {
    now: (state) => {
      return state.now;
    },

    byJourneyId: (state, getters) => (jid) => {
      return getters.scenarios
        .filter(({ sg }) => sg.id === jid);
    },

    journeys: (state, getters) => (scenarioId) => {
      return getters.scenarios
        .filter(({ scenario }) => scenario.id === scenarioId)
        .map(({ sg }) => sg);
    },

    byCourseId: (state) => (cid) => {
      return state.scenarios
        .filter(s => s.id === cid);
    },

    isDone: (state) => (scenarioId) => {
      return state.progress
        .some(p => p.status === "done" && p.contextId === scenarioId)
    },

    doneToday: (state) => (scenarioId) => {
      let scenarioUserData = state.progress
        .find(p => p.status === "done" && p.contextId === scenarioId)
      if(scenarioUserData){
        const date = moment(scenarioUserData.createdAt);
        return date.isSame(moment(), 'd');
      } else {
        return false;
      }
    },

    isOpen: (state, getters) => (scenarioId) => {
      const v = getters.open.some(s => s.scenario.id === scenarioId);
      return v;
    },

    lifeskillsToday: (state, getters) => {
     const today = state.progress
        .filter(p => p.status === 'done' && moment(p.createdAt).isSame(getters.now, 'day'))
        .map(p => getters.byScenarioId(p.contextId))
        .map(({ sg }) => getters.journeyLifeskill(sg.id))
      return today;
    },

    journeyLifeskill: (state, getters, rootState, rootGetters) => (id) => {
      const j = rootGetters['moduleCourse/getJourneyById'](id)
      if (j) return j.lifeskillNumber;
      return null;
    },

    open: (state, getters) => {
      return getters.scenarios.filter(({ scenario, sg }) => {
        const done = getters.isDone(scenario.id);
        if (done) return true;

        const sgDone = getters.journeyQuizDone(sg.id);
        if (!sgDone) {
          return false;
        }

        // max 1 lifeskill per day
        const ls = getters.journeyLifeskill(sg.id);
        if (getters.lifeskillsToday.indexOf(ls) > -1) return false;

        const journeyScenarios = getters.byJourneyId(sg.id);
        const curIndex = journeyScenarios.findIndex(js => {
          return (js.scenario.id === scenario.id);
        });
        if (curIndex === 0) {
          return true;
        } 
        if (curIndex > 0) {
          const prev = journeyScenarios[curIndex - 1];
          const progress = getters.progress(prev.scenario.id);
          if (progress) {
            return moment(progress.createdAt).isBefore(getters.now, 'day');
          }
        }
        return false;
      });
    },

    journeyQuizDone: (state) => (journeyId) => {
      const v = state.quizProgress.some(q => {
        return q.contextId === journeyId;
      });
      return v;
    },

    scenarios: (state) => {
      return state.scenarios
        .map(x => x.stepGroups.map(sg => ({ course: { id: x.id }, sg }))).flat()
        .map(({ course, sg }) => (sg.steps || []).map(step => ({ course, step, sg }))).flat()
        .map(({course, step, sg }) => (step.scenarios || []).map(scenario => ({ course, scenario, step, sg })))
        .flat();
    },

    next: (state, getters) => (courseId) => {
      const scenario = getters.scenarios
        .find(({ course, scenario }) => course.id === courseId && !getters.isDone(scenario.id));
      return scenario.scenario;
    },

    byScenarioId: (state, getters) => id => {
      return getters.scenarios.find(s => s.scenario.id === id);
    },

    progress: (state) => scenarioId => {
      return state.progress
        .find(x => x.status === 'done' && x.contextId === scenarioId);
    },

    notifications: (state, getters) => {
      return getters.open
        .filter(({ scenario }) => !getters.isDone(scenario.id))
        .map(({ scenario: s, step }) => ({
          id: s.id,
          componentName: 'ScenarioNotification',
          props: {
            id: s.id,
            stepId: step.id,
          }
        }))
        .slice(0, 1);
    },
  },

  actions: {
    load: ({ dispatch, commit }) => {
      commit('now');
      ['ls-00-no', 'ls-01-no', 'ls-50-group-a-no', 'ls-40-no', 'ls-37-no'].forEach(c =>
        dispatch('loadData', c)
      );
      EventBus.$on('journey-quiz-done', (contextId) => {
        commit('addQuizProgress', { contextId });
      });
    },

    loadData: ({ state, commit }, courseId) => {
      commit('now');
      if (state.scenarios.some(s => s.id === courseId)) {
        // already loaded
        return Promise.resolve();
      }
      return fetchCourseScenarios(courseId)
        .then(response => commit('scenarios', response.data))
        .catch(error => console.error(error));
    },

    loadProgress: ({ commit }) => {
      commit('now');
      return fetchCourseProgress()
        .then(response => {
          commit('progress', response.scenarios);
          commit('quizProgress', response.quiz);
        })
        .catch(error => console.error(error));
    },

    markAsDone: async ({ commit, dispatch, getters, rootGetters }, { scenarioId, stepId, lifeskill, courseId, stepGroupId }) => {
      commit('now');

      if (getters.isDone(scenarioId)) return;

      updateCourseProgress(scenarioId, courseId)
        .catch(console.error);

      const relatedLifeskills = getters['journeys'](scenarioId)
        .map(j => rootGetters['moduleCourse/getJourneyById'](j.id))
        .map(j => j.lifeskillNumber)
        .filter(ls => ls !== lifeskill);

      commit(types.SCENARIO_DONE, {
        contextId: scenarioId,
        status: 'done',
        stepId,
        lifeskillNumber: lifeskill,
        courseId: courseId,
        scenarioId,
        stepGroupId,
        relatedLifeskills: uniq(relatedLifeskills),
      });

      dispatch('scenarioDoneAnalytics', { stepId, scenarioId });
    },

    scenarioDoneAnalytics: (params, { stepId, scenarioId }) => {
      window.dataLayer.push({
        event: 'scenario_done',
        step: stepId,
        scenario: scenarioId
      });
    },
  },

  mutations: {
    now(state) {
      Vue.set(state, 'now', moment());
    },
    scenarios(state, data) {
      state.scenarios.push(data);
    },

    progress(state, data) {
      Vue.set(state, 'progress', data);
    },

    [types.SCENARIO_DONE](state, data) {
      state.progress.push(data);
    },

    quizProgress(state, data) {
      Vue.set(state, 'quizProgress', data);
    },

    addQuizProgress(state, data) {
      state.quizProgress.push(data);
    },
  },

  state: {
    now: moment(),
    scenarios: [],
    progress: [],
    quizProgress: [],
  },
}
