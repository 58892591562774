import { AbilityBuilder } from '@casl/ability'
import store from '@/store'

function subjectName (subject) {
  if (!subject || typeof subject === 'string') {
    return subject
  }
  if (typeof subject === 'object' && subject.modelName) {
    return subject.modelName
  }

  const Type = typeof subject === 'object' ? subject.constructor : subject
  return Type.modelName || Type.name
}

const ability = AbilityBuilder.define({ subjectName }, can => {
})

export function updateAbility (profile) {
  console.log('updating ability')
  const roles = profile ? profile.roles : []
  const isSubscribed = profile && profile.subscription && profile.subscription.plan_id && profile.subscription.status !== 'cancelled'
  let onlyExtended00Access = profile && profile.subscription.plan_id === 'free-forever'
  let paymentFailure = false
  if (isSubscribed) {
    paymentFailure = profile.subscription.status === 'payment-failure'
  }
  const unlockAllContent = roles.indexOf('admin') !== -1 && store.state.moduleApp.debugSettings.unlockAllContent
  const fullAccess = (isSubscribed && !onlyExtended00Access && !paymentFailure) || unlockAllContent

  if (!fullAccess && !onlyExtended00Access && roles.indexOf('techdata') !== -1) {
    onlyExtended00Access = true
  }

  const masterCourseIds = Object.values(store.state.moduleApp.lifeskills)
    .filter(ls => ls.masterCourseId)
    .map(ls => ls.masterCourseId)

  // ------------------------
  const additionalCourseIds = new Set()
  const mapping = {
    'inma': ['bundle1'],
    'superoffice': ['superoffice-course', 'superofficedagen-course'],
    'skjonnhaug': ['skjonnhaug1'],
    'superofficedagen': ['superofficedagen-course'],
    'user': store && store.state.moduleApp.debugSettings.showAllStandaloneCourses ? ['superofficedagen-course', 'superoffice-course', 'bundle1', 'skjonnhaug1'] : []
  }
  roles.forEach(role => {
    if (mapping[role]) {
      mapping[role].forEach(courseId => {
        if (masterCourseIds.indexOf(courseId) === -1) {
          additionalCourseIds.add(courseId)
        }
      })
    }
  })
  // -----------------------

  const rules = []

  if (!fullAccess) {
    rules.push({
      action: 'upgrade',
      subject: 'Account'
    })

    rules.push({
      action: 'play',
      subject: 'Game',
      conditions: {
        levelIndex: { '$in': [0, 1] }
      }
    })
  }
  if (unlockAllContent) {
    rules.push({
      action: 'play',
      subject: 'Game'
    })
    rules.push({
      action: 'view',
      subject: 'Step'
    })
    rules.push({
      action: 'use',
      subject: 'Course'
    })
  }
  if (fullAccess) {
    rules.push({
      action: 'play',
      subject: 'Game'
    })
    rules.push({
      action: 'view',
      subject: 'Step'
    })
    rules.push({
      action: 'use',
      subject: 'Course',
      conditions: {
        courseId: { '$in': masterCourseIds }
      }
    })
  } else {
    rules.push({
      action: 'view',
      subject: 'Step',
      conditions: {
        alwaysUnlocked: true
      }
    })
    rules.push({
      action: 'view',
      subject: 'Step',
      conditions: {
        courseId: { '$in': ['ls-40-no', 'periodic-table-of-elements', 'history-course'] }
      }
    })
    rules.push({
      action: 'use',
      subject: 'Course',
      conditions: {
        courseId: { '$in': masterCourseIds }
      }
    })

    // NOTE: I do not believe 00 has special access rules?
    // if (onlyExtended00Access) {
    //   rules.push({
    //     action: 'view',
    //     subject: 'Step',
    //     conditions: {
    //       courseId: { '$in': ['ls-00-no'] }
    //       // number: { '$in': ['1', '2', '3', '4', '5', '6', '7', '8'] }
    //     }
    //   })
    // } else {
    //   rules.push({
    //     action: 'view',
    //     subject: 'Step',
    //     conditions: {
    //       courseId: { '$in': ['ls-00-no'] },
    //       number: { '$in': ['0', '1', '2', '3', '4', '5', '6', '7', '8'] }
    //     }
    //   })
    // }
  }

  if (additionalCourseIds.size) {
    rules.push({
      action: 'use',
      subject: 'Course',
      conditions: {
        courseId: { '$in': Array.from(additionalCourseIds) }
      }
    })
    rules.push({
      action: 'view',
      subject: 'Step',
      conditions: {
        courseId: { '$in': Array.from(additionalCourseIds) }
      }
    })
  }
  ability.update(rules)
}

export default ability
