var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BlockContainer',{staticClass:"ModuleApp",attrs:{"type":"Module","label":"moduleApp"}},[_c('div',[_vm._v(" Set memomaps-data endpoint "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.memomapsDataEndpoint),expression:"memomapsDataEndpoint"}],domProps:{"value":(_vm.memomapsDataEndpoint)},on:{"input":function($event){if($event.target.composing){ return; }_vm.memomapsDataEndpoint=$event.target.value}}}),_c('button',{on:{"click":function($event){return _vm.setMemomapsDataEndpoint(_vm.memomapsDataEndpoint)}}},[_vm._v(" Set ")])]),_c('br'),_c('div',[_vm._v(" Goto lifeskill page "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.lifeskillId),expression:"lifeskillId"}],domProps:{"value":(_vm.lifeskillId)},on:{"input":function($event){if($event.target.composing){ return; }_vm.lifeskillId=$event.target.value}}}),_c('button',{on:{"click":function($event){_vm.$router.push({ name: 'DashboardLifeskillView', params: { lifeskillId: _vm.lifeskillId } });
        _vm.$emit('request-close');}}},[_vm._v(" Go ")])]),_c('div',{staticClass:"buttons"},[_c('ButtonPlain',{attrs:{"label":("useQuizCheatMode: " + (_vm.$store.state.moduleApp.debugSettings.useQuizCheatMode))},on:{"click":function($event){return _vm.$store.dispatch('moduleApp/dbgToggleQuizCheatMode')}}}),_c('ButtonWithCheckmark',{attrs:{"label":"noFullscreenReadOnMobile","done":_vm.$store.state.moduleApp.debugSettings.noFullscreenReadOnMobile},on:{"click":function($event){return _vm.toggleDebugSetting('noFullscreenReadOnMobile')}}}),_c('ButtonWithCheckmark',{attrs:{"label":"noActivityTransitions","done":_vm.$store.state.moduleApp.debugSettings.noActivityTransitions},on:{"click":function($event){return _vm.toggleDebugSetting('noActivityTransitions')}}}),_c('ButtonWithCheckmark',{attrs:{"label":"doNotStartQuizAutomatically","done":_vm.$store.state.moduleApp.debugSettings.doNotStartQuizAutomatically},on:{"click":function($event){return _vm.toggleDebugSetting('doNotStartQuizAutomatically')}}}),_c('ButtonPlain',{attrs:{"label":"Open Braindate Popup"},on:{"click":function($event){return _vm.openBraindatePopup()}}}),_c('ButtonPlain',{attrs:{"label":"Hide debug menu"},on:{"click":function($event){return _vm.$store.dispatch('moduleApp/toggleDebugButton')}}}),_c('ButtonPlain',{attrs:{"label":"goto games"},on:{"click":function($event){_vm.$router.push('/games/');
        _vm.$emit('request-close');}}}),_c('ButtonPlain',{attrs:{"label":"goto systems"},on:{"click":function($event){_vm.$router.push('/systems/');
        _vm.$emit('request-close');}}}),_c('ButtonPlain',{attrs:{"label":"goto social"},on:{"click":function($event){_vm.$router.push('/social/overview');
        _vm.$emit('request-close');}}}),_c('ButtonPlain',{attrs:{"label":"goto memoransier admin"},on:{"click":function($event){_vm.$router.push('/memoransier/admin');
        _vm.$emit('request-close');}}}),_c('ButtonPlain',{attrs:{"label":("toggle language [" + _vm.locale + "]")},on:{"click":function($event){return _vm.$store.dispatch('moduleApp/setLocaleNoReload', _vm.locale === 'en' ? 'no' : 'en')}}}),_c('ButtonWithCheckmark',{attrs:{"label":"(local) unlock all content","done":_vm.$store.state.moduleApp.debugSettings.unlockAllContent},on:{"click":function($event){return _vm.toggleUnlockAllContent()}}}),_c('ButtonPlain',{attrs:{"label":"Change user"},on:{"click":function($event){return _vm.changeUser()}}}),_c('ButtonPlain',{attrs:{"label":"print profile"},on:{"click":function($event){return _vm.printProfile()}}}),_c('ButtonPlain',{attrs:{"label":"Pretend outdated app"},on:{"click":function($event){return _vm.$store.commit('moduleApp/updateAppVersionInfo', { gitRevision: 'NEWER' })}}}),_c('ButtonPlain',{attrs:{"label":"Unpretend"},on:{"click":function($event){return _vm.$store.commit('moduleApp/updateAppVersionInfo', {
          gitRevision: _vm.$store.state.moduleApp.clientsideGitRevision
        })}}}),_c('ButtonPlain',{attrs:{"label":"Reset dailyRequiredBraindates"},on:{"click":function($event){_vm.$store.commit("moduleApp/SET_DAILY_REQUIRED_BRAINDATES", {
          value: 3,
          dailyRequiredBraindatesNotSetByUser: true
        });
        _vm.$emit('request-close');}}}),_c('ButtonPlain',{attrs:{"label":"Reset getting-started focus exit"},on:{"click":function($event){return _vm.$store.dispatch("moduleApp/markDidExitGettingStartedFocus", { reset: true })}}}),_c('ButtonPlain',{attrs:{"label":"TestQuizReporting"},on:{"click":function($event){return _vm.testQuizReporting()}}}),_c('ButtonWithCheckmark',{attrs:{"label":"Enable all standalone courses","done":_vm.$store.state.moduleApp.debugSettings.showAllStandaloneCourses},on:{"click":function($event){return _vm.toggleEnableAllStandaloneCourses()}}}),_c('ButtonPlain',{attrs:{"label":"ResetOnboardings"},on:{"click":function($event){return _vm.resetOnboardingStates()}}})],1),_c('br'),_c('div',[_c('span',[_vm._v("Belt-stuff: ")]),_c('ButtonPlain',{attrs:{"label":"inc"},on:{"click":function($event){return _vm.lifekeysInc()}}}),_c('ButtonPlain',{attrs:{"label":"dec"},on:{"click":function($event){return _vm.lifekeysDec()}}}),_c('ButtonPlain',{attrs:{"label":"add lk"},on:{"click":function($event){return _vm.addLifekey()}}}),_c('ButtonPlain',{attrs:{"label":"add double lk"},on:{"click":function($event){return _vm.addDoubleLifekey()}}}),_c('ButtonPlain',{attrs:{"label":"trigger popup"},on:{"click":function($event){return _vm.triggerBeltPopup()}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }