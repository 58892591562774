import apolloClient from '@/apollo-client';
import gql from 'graphql-tag';
import { updateUserSetting } from '@/store/persistence';

export default {
    updateLevel({ commit, state, rootGetters }, payload) {
        const { bestTime } = payload.data;
        const previousBestTime = state.levels[payload.levelIndex].bestTime;
        if (previousBestTime === 0 || bestTime < previousBestTime) {
            // payload.produceBraindate = true
            commit('updateLevelUserstate', payload);
        }
        const variables = {
            game: state.gameId,
            level: '' + payload.levelIndex,
            stars: '' + payload.data.stars,
            time: { seconds: bestTime / 1000 },
            userId: rootGetters['moduleAuth/profile'].id
        };
        apolloClient
            .mutate({
                mutation: gql`
                    mutation(
                        $game: String!
                        $level: String!
                        $stars: String!
                        $time: IntervalInput!
                        $userId: String!
                    ) {
                        createMemogeniusGameScore(
                            input: {
                                memogeniusGameScore: {
                                    game: $game
                                    level: $level
                                    stars: $stars
                                    time: $time
                                    userId: $userId
                                }
                            }
                        ) {
                            clientMutationId
                        }
                    }
                `,
                variables: variables
            })
            .catch(console.error);
    },

    updateQuestionServiceState({ commit, state }, payload) {
        const key = `questionServiceState:${state.gameId}`;
        commit('updateQuestionServiceState', payload);
        return updateUserSetting('', key, payload);
    },

    dbgClearLevels({ commit, state }) {
        state.levels.forEach(({ index }) => {
            commit('updateLevelUserstate', { levelIndex: index, data: { stars: 0, bestTime: 0 } });
        });
    }
};
