import moment from 'moment'
import groupBy from 'lodash/groupBy'
import { getBeltFromTotalPoints } from '@/belts'
import settings from '@/settings'
import { getSkillMissionById } from './missions'
import { getCategoryIdFromLifeskillId } from '@/utils'

export default {
  mission: state => () => {
    return state.memolife.mission
  },

  selectedHabitMissions: state => () => {
    if (state.memolife.selectedTeamId === '0') {
      return state.memolife.selectedHabitMissions.filter(mission => mission.isSelected)
    } else {
      var habitMissions = []
      const teamsList = state.memolife.teamsList
      if (teamsList.length > 0) {
        const team = teamsList.find(t => t.id === parseInt(state.memolife.selectedTeamId))
        if (team && team.tags) {
          const tags = [...team.tags]
          const habitTags = tags.filter(mission => mission.includes('habitMission:'))
          const habitMissionIds = habitTags.map(str => str.replace('habitMission:', ''))
          habitMissions = state.memolife.selectedHabitMissions.filter(mission =>
            habitMissionIds.some(missionId => mission.missionId === missionId)
          )
        }
      }
      return habitMissions
    }
  },

  selectedTeamSkillMission: state => {
    var skillMission = ''
    const teamsList = state.memolife.teamsList
    const team = teamsList.find(t => t.id === parseInt(state.memolife.selectedTeamId))
    if (team && team.tags) {
      const tags = [...team.tags]
      const skillTag = tags.find(mission => mission.includes('skillsMission:'))
      if (skillTag) {
        skillMission = skillTag.replace('skillsMission:', '')
      }
    }
    return skillMission
  },

  appNeedsReload: state => {
    return state.clientsideGitRevision !== state.serversideGitRevision
  },
  didShowMemoOnboardingPopup: state => state.memolife.didShowMemoOnboardingPopup,
  didShowMemomapsOnboardingPopup: state => state.memolife.didShowMemomapsOnboardingPopup,

  lifeskills: state => state.lifeskills,

  getLifeskillIdByNumbershape: state => numbershape => {
    return state.lifeskillsList.find(item => item.numbershape === numbershape).id
  },

  getLifeskillById: state => id => {
    return state.lifeskills[id]
  },
  getVideoById: state => id => {
    return state.videos[id]
  },
  getCategoryById: state => id => {
    return state.categories[id]
  },

  getLifeskillIds: state => {
    return Object.keys(state.lifeskills)
  },

  getEssentialLifeskills: state => {
    return Object.values(state.lifeskills)
      .filter(lifeskill => lifeskill.isEssential)
      .map(lifeskill => lifeskill.id)
  },

  getVideosForLifeskill: state => lifeskillId => {
    return Object.values(state.videos)
      .filter(video => video.lifeskillIds && video.lifeskillIds.indexOf(lifeskillId) !== -1)
      .map(video => {
        return { id: video.id, ordering: video.ordering[lifeskillId], playlist: video.playlists[lifeskillId] }
      })
  },

  getVideoBraindatesMap(state) {
    const videoBraindates = state.braindates.filter(bd => bd.context === 'video')
    return groupBy(videoBraindates, bd => bd.lifeskillNumber)
  },

  getOnboardingVideoBraindate(state) {
    const bd = state.braindates.find(bd => bd.videoId === settings.onboardingVideoDatabaseId)
    if (bd) {
      return bd
    }
    return null
  },

  getLifeskillStats: (state, getters, rootState, rootGetters) => lifeskillId => {
    const lifeskill = getters.getLifeskillById(lifeskillId)
    let courseIds = []
    if (lifeskill.masterCourseId) {
      courseIds.push(lifeskill.masterCourseId)
    }

    let stats = {
      totalTheoryBraindates: 0,
      totalPracticeBraindates: 0,

      totalTheoryLifekeys: 0,
      totalPracticeLifekeys: 0,

      acquiredTheoryBraindates: 0,
      acquiredPracticeBraindates: 0,

      acquiredTheoryLifekeys: 0,
      acquiredPracticeLifekeys: 0,

      numberOfScenarios: 0,
      scenariosDoneCount: 0,

      numberOfSteps: 0,
      numberOfDoneSteps: 0,
    }

    courseIds
      .map(courseId => rootGetters['moduleCourse/getCourseStats'](courseId))
      .forEach(courseStats => {
        Object.keys(stats).forEach(key => {
          stats[key] += courseStats[key]
        })
      })

    stats.acquiredVideoBraindates = (getters.getVideoBraindatesMap[lifeskillId] || []).length

    stats.totalBraindates = stats.totalTheoryBraindates + stats.totalPracticeBraindates
    stats.totalVideoLifekeys = 2 // NOTE: always 2, ignoring the possibility that the lifeskill does not have enough videos
    stats.acquiredVideoLifekeys = Math.min(stats.totalVideoLifekeys, Math.floor(stats.acquiredVideoBraindates / 25))

    stats.totalLifekeys = stats.totalTheoryLifekeys + stats.totalPracticeLifekeys + stats.totalVideoLifekeys
    stats.aquiredLifekeys = stats.acquiredTheoryLifekeys + stats.acquiredPracticeLifekeys + stats.acquiredVideoLifekeys
    stats.progress = stats.totalLifekeys ? stats.aquiredLifekeys / stats.totalLifekeys : 0

    return stats
  },

  getMarkedLifeskills: state => {
    return state.followedLifeskills
  },

  follows: state => ls => {
    return state.followedLifeskills.indexOf(ls) > -1
  },

  recommendedByMemolifeItems(state, getters, rootState, rootGetters) {
    const courseIds = ['ls-41-no', 'ls-40-no', 'history-course', 'lang-group-a-no', 'ls-44-no', 'ls-02-no']

    return courseIds
      .filter(courseId => {
        const courseStats = rootGetters['moduleCourse/getCourseStats'](courseId)
        return courseStats.theoryProgress < 1
      })
      .map(courseId => {
        return {
          courseId,
        }
      })
  },

  recommendedItems: (state, getters) => {
    return getters.getLifeskillIds
      .filter(ls => getters.follows(ls))
      .sort((a, b) => a.localeCompare(b, undefined, { numeric: true }))
  },

  recommendedVideos(state) {
    return state.recommendedVideos.map(v => {
      return {
        ...v,
        id: v.youtubeId,
        videoId: v.youtubeId,
        // videoId: v.id,
        lifeskillId: v.numbershape,
        duration: moment.duration(v.duration).asSeconds(),
      }
    })
  },

  followedVideos(state, getters) {
    const videoBucket = []
    const seen = {}
    getters.getMarkedLifeskills.forEach(ls => {
      const lsVideos = getters.getVideosForLifeskill(ls)
      const unwatched = []
      const max = 3
      for (let i = 0; lsVideos.length > i; i++) {
        const video = lsVideos[i]
        const vs = state.videos[video.id]
        if (vs && vs.progress) {
          // pass
        } else if (!seen[video.id]) {
          unwatched.push({ videoId: video.id, lifeskillId: ls })
          seen[video.id] = true
        }
        if (unwatched.length === max) {
          break
        }
      }
      videoBucket.push(...unwatched)
    })
    return videoBucket
  },

  interestsVideos(state, getters) {
    const videoBucket = []
    const seen = {}
    let remaining = 30
    for (let k = 0; k < getters.recommendedItems.length && videoBucket.length < 30; k++) {
      const ls = getters.recommendedItems[k]
      const lsVideos = getters.getVideosForLifeskill(ls)
      const unwatched = []
      let max = Math.floor(remaining / (getters.recommendedItems.length - k))
      if (max > lsVideos.length) {
        max = lsVideos.length
      }
      for (let i = 0; i < max; i++) {
        const video = lsVideos[i]
        const vs = state.videos[video.id]
        if (!vs || !vs.progress) {
          if (!seen[video.id]) {
            unwatched.push({ videoId: video.id, lifeskillId: ls })
            seen[video.id] = true
          }
        }
      }
      videoBucket.push(...unwatched)
      remaining -= unwatched.length
    }

    if (videoBucket.length < 30) {
      const recommendedVideos = getters.recommendedVideos
      for (let i = 0; i < recommendedVideos.length && videoBucket.length < 30; i++) {
        const video = recommendedVideos[i]
        const vs = state.videos[video.id]
        if (!vs || !vs.progress) {
          if (!seen[video.id]) {
            videoBucket.push({ videoId: video.id, lifeskillId: null })
            seen[video.id] = true
          }
        }
      }
    }
    return videoBucket
  },

  inProgressVideoItems(state) {
    return state.videos_in_progress
  },

  inProgressItems(state, getters, rootState, rootGetters) {
    const items = [
      ...rootGetters['moduleCourse/inProgressItems'],
      ...getters.inProgressVideoItems.map(v => ({ ...v, type: 'video' })),
    ]
    return items.sort((a, b) => moment(b.latestActivity).diff(moment(a.latestActivity)))
  },

  getGameBraindates(state, getters, rootState, rootGetters) {
    let gameBraindates = []
    const braindates = rootGetters['moduleApp/braindates'].filter(b => b.context === 'game')
    const selectedHabitMissions = getters.selectedHabitMissions()

    function isMissionSelected(missionId) {
      let isSelected = false
      Object.values(selectedHabitMissions).forEach(habitMission => {
        if (habitMission.missionId === missionId) {
          isSelected = true
        }
      })
      return isSelected
    }

    const games = rootState.moduleMemogenius.games
    const gameIds = rootState.moduleMemogenius.gameIds

    gameIds.forEach(game => {
      let doneToday = false
      const nextGameLevel = rootGetters['moduleMemogenius/getNextAvailableGameInfo'](games[game].id)

      braindates.forEach(braindate => {
        const date = moment(braindate.createdAt)

        if (date.isSame(moment(), 'd') && braindate.gameId === games[game].id) {
          doneToday = true
        }
      })
      if (nextGameLevel && isMissionSelected(games[game].id)) {
        gameBraindates.push({
          type: 'game',
          title: games[game].title,
          title_no: games[game].title_no,
          id: games[game].id,
          lifeskillId: '02',
          category: getCategoryIdFromLifeskillId('02'),
          nextLevel: nextGameLevel,
          doneToday: doneToday,
        })
      }
    })
    return gameBraindates
  },

  getMentalHygieneBraindates(state, getters, rootState, rootGetters) {
    let mentalHygieneBraindatesAll = []
    const selectedHabitMissions = getters.selectedHabitMissions()

    function isMissionSelected(missionId) {
      let isSelected = false
      Object.values(selectedHabitMissions).forEach(habitMission => {
        if (habitMission.missionId === missionId) {
          isSelected = true
        }
      })
      return isSelected
    }

    const joysTimesFinishedCount = rootGetters['moduleMentalhygiene/joysTimesFinishedCount']
    const frogsTimesFinishedCount = rootGetters['moduleMentalhygiene/frogsTimesFinishedCount']
    const focusChecksTimesFinishedCount = rootGetters['moduleMentalhygiene/focusChecksTimesFinishedCount']
    const dayplansTimesFinishedCount = rootGetters['moduleMentalhygiene/dayplansTimesFinishedCount']
    const diariesTimesFinishedCount = rootGetters['moduleMentalhygiene/diariesTimesFinishedCount']
    const areJoysFinishedToday = rootGetters['moduleMentalhygiene/areJoysFinishedToday']
    const areFrogsFinishedToday = rootGetters['moduleMentalhygiene/areFrogsFinishedToday']
    const areFocusChecksFinishedToday = rootGetters['moduleMentalhygiene/areFocusChecksFinishedToday']
    const isDayplanFinishedToday = rootGetters['moduleMentalhygiene/isDayplanFinishedToday']
    const areDiariesFinishedToday = rootGetters['moduleMentalhygiene/areDiariesFinishedToday']

    if (isMissionSelected('daily-joys')) {
      mentalHygieneBraindatesAll.push({
        type: 'mentalHygiene',
        title: 'Joys',
        title_no: 'Gleder',
        id: 'daily-joys',
        lifeskillId: '37',
        stepNumber: 0,
        category: getCategoryIdFromLifeskillId('70'),
        doneToday: areJoysFinishedToday,
        timesDone: joysTimesFinishedCount,
        locked: false,
      })
    }
    if (isMissionSelected('kiss-the-frog')) {
      mentalHygieneBraindatesAll.push({
        type: 'mentalHygiene',
        title: 'Frogs',
        title_no: 'Frosker',
        id: 'kiss-the-frog',
        lifeskillId: '35',
        stepNumber: 1,
        category: getCategoryIdFromLifeskillId('70'),
        doneToday: areFrogsFinishedToday,
        timesDone: frogsTimesFinishedCount,
        locked: false,
      })
    }
    if (isMissionSelected('focus-check')) {
      mentalHygieneBraindatesAll.push({
        type: 'mentalHygiene',
        title: 'Focus check',
        title_no: 'Fokussjekk',
        id: 'focus-checks',
        lifeskillId: '01',
        stepNumber: 2,
        category: getCategoryIdFromLifeskillId('70'),
        doneToday: areFocusChecksFinishedToday,
        timesDone: focusChecksTimesFinishedCount,
        locked: false,
      })
    }
    if (isMissionSelected('daily-plan')) {
      mentalHygieneBraindatesAll.push({
        type: 'mentalHygiene',
        title: 'Daily plan',
        title_no: 'Dagsplanen',
        id: 'daily-plan',
        lifeskillId: '80',
        stepNumber: 3,
        category: getCategoryIdFromLifeskillId('70'),
        doneToday: isDayplanFinishedToday,
        timesDone: dayplansTimesFinishedCount,
        locked: false,
      })
    }
    if (isMissionSelected('learning-diary')) {
      mentalHygieneBraindatesAll.push({
        type: 'mentalHygiene',
        title: 'Learning diary',
        title_no: 'Læringsdagboken',
        id: 'learning-diary',
        lifeskillId: '36',
        stepNumber: 4,
        category: getCategoryIdFromLifeskillId('70'),
        doneToday: areDiariesFinishedToday,
        timesDone: diariesTimesFinishedCount,
        locked: false,
      })
    }

    return mentalHygieneBraindatesAll
  },

  skillMissionMentalHygieneBraindates(state, getters, rootState, rootGetters) {
    let mentalHygieneBraindatesAll = []

    const joysTimesFinishedCount = rootGetters['moduleMentalhygiene/joysTimesFinishedCount']
    const frogsTimesFinishedCount = rootGetters['moduleMentalhygiene/frogsTimesFinishedCount']
    const focusChecksTimesFinishedCount = rootGetters['moduleMentalhygiene/focusChecksTimesFinishedCount']
    const dayplansTimesFinishedCount = rootGetters['moduleMentalhygiene/dayplansTimesFinishedCount']
    const diariesTimesFinishedCount = rootGetters['moduleMentalhygiene/diariesTimesFinishedCount']
    const areJoysFinishedToday = rootGetters['moduleMentalhygiene/areJoysFinishedToday']
    const areFrogsFinishedToday = rootGetters['moduleMentalhygiene/areFrogsFinishedToday']
    const areFocusChecksFinishedToday = rootGetters['moduleMentalhygiene/areFocusChecksFinishedToday']
    const isDayplanFinishedToday = rootGetters['moduleMentalhygiene/isDayplanFinishedToday']
    const areDiariesFinishedToday = rootGetters['moduleMentalhygiene/areDiariesFinishedToday']
    const journeyFinished = rootGetters['moduleMentalhygiene/journeyFinished']

    if (journeyFinished) {
      mentalHygieneBraindatesAll.push({
        type: 'mentalHygiene',
        title: 'Joys',
        title_no: 'Gleder',
        id: 'daily-joys',
        lifeskillId: '70',
        stepNumber: 0,
        category: getCategoryIdFromLifeskillId('70'),
        doneToday: areJoysFinishedToday,
        timesDone: joysTimesFinishedCount,
        locked: false,
      })

      mentalHygieneBraindatesAll.push({
        type: 'mentalHygiene',
        title: 'Frogs',
        title_no: 'Frosker',
        id: 'kiss-the-frog',
        lifeskillId: '70',
        stepNumber: 1,
        category: getCategoryIdFromLifeskillId('70'),
        doneToday: areFrogsFinishedToday,
        timesDone: frogsTimesFinishedCount,
        locked: false,
      })

      mentalHygieneBraindatesAll.push({
        type: 'mentalHygiene',
        title: 'Focus check',
        title_no: 'Fokussjekk',
        id: 'focus-checks',
        lifeskillId: '70',
        stepNumber: 2,
        category: getCategoryIdFromLifeskillId('70'),
        doneToday: areFocusChecksFinishedToday,
        timesDone: focusChecksTimesFinishedCount,
        locked: false,
      })

      mentalHygieneBraindatesAll.push({
        type: 'mentalHygiene',
        title: 'Daily plan',
        title_no: 'Dagsplanen',
        id: 'daily-plan',
        lifeskillId: '70',
        stepNumber: 3,
        category: getCategoryIdFromLifeskillId('70'),
        doneToday: isDayplanFinishedToday,
        timesDone: dayplansTimesFinishedCount,
        locked: false,
      })

      mentalHygieneBraindatesAll.push({
        type: 'mentalHygiene',
        title: 'Learning diary',
        title_no: 'Læringsdagboken',
        id: 'learning-diary',
        lifeskillId: '70',
        stepNumber: 4,
        category: getCategoryIdFromLifeskillId('70'),
        doneToday: areDiariesFinishedToday,
        timesDone: diariesTimesFinishedCount,
        locked: false,
      })
    }

    return mentalHygieneBraindatesAll
  },

  getHabitBraindates(state, getters) {
    let habitBraindatesNotDone = []
    let habitBraindatesDone = []

    let gameBraindatesAll = getters.getGameBraindates.slice()
    let gameBraindatesDone = gameBraindatesAll.filter(item => item.doneToday)
    let gameBraindatesNotDone = gameBraindatesAll.filter(item => !item.doneToday)

    let mentalHygieneBraindatesAll = getters.getMentalHygieneBraindates.slice()
    let mentalHygieneBraindatesDone = mentalHygieneBraindatesAll.filter(item => item.doneToday)
    let mentalHygieneBraindatesNotDone = mentalHygieneBraindatesAll.filter(item => !item.doneToday)

    for (let i = 0; 6 > i; i++) {
      if (gameBraindatesNotDone[0]) {
        habitBraindatesNotDone.push(gameBraindatesNotDone.shift())
      }
      if (mentalHygieneBraindatesNotDone[0]) {
        habitBraindatesNotDone.push(mentalHygieneBraindatesNotDone.shift())
      }
    }
    for (let i = 0; 6 > i; i++) {
      if (gameBraindatesDone[0]) {
        habitBraindatesDone.push(gameBraindatesDone.shift())
      }
      if (mentalHygieneBraindatesDone[0]) {
        habitBraindatesDone.push(mentalHygieneBraindatesDone.shift())
      }
    }

    const allHabitBraindates = habitBraindatesNotDone.concat(habitBraindatesDone)

    return allHabitBraindates
  },

  getTheoryBraindatesForMission(state, getters, rootState, rootGetters) {
    let selectedMission = getSkillMissionById(getters.mission())
    let theoryBraindates = []

    selectedMission.lifeskills.forEach(lifeskillId => {
      const lifeskill = getters.getLifeskillById(lifeskillId)
      const courseId = lifeskill.masterCourseId
      const course = rootGetters['moduleCourse/getCourseById'](courseId)

      if (course) {
        const IntroStep = rootGetters['moduleCourse/getStepById'](course.introStepId)

        if (IntroStep) {
          const date = moment(IntroStep.read.lastUpdated)
          if (IntroStep.read.progress != 1 || date.isSame(new Date(), 'd')) {
            theoryBraindates.push({
              type: 'introduction',
              title: 'Ferdighetsintro',
              lifeskillId: lifeskillId,
              category: getCategoryIdFromLifeskillId(IntroStep.fromLifeskill),
              doneToday: date.isSame(new Date(), 'd'),
              stepId: IntroStep.id,
              courseId: course.id,
              activities: IntroStep.activityNames,
            })
          }
        }

        course.stepGroupIds.forEach(stepGroupId => {
          const journeyStats = rootGetters['moduleCourse/getStepGroupStats'](stepGroupId)
          const journey = rootGetters['moduleCourse/getJourneyById'](stepGroupId)

          journey.stepIds.forEach(stepId => {
            const step = rootGetters['moduleCourse/getStepById'](stepId)
            const isMemo2andQuiz2Locked =
              (step.quiz2 && !step.quiz && step.read && step.read.progress === 0) ||
              (step.quiz2 && step.quiz && step.quiz.progress === 0)
                ? true
                : false

            if (step.read) {
              const date = moment(step.read.lastUpdated)

              if (step.read.progress !== 1 || date.isSame(moment(), 'day')) {
                theoryBraindates.push({
                  type: 'read',
                  title: step.title,
                  lifeskillId: lifeskillId,
                  category: getCategoryIdFromLifeskillId(lifeskillId),
                  doneToday: date.isSame(moment(), 'day'),
                  stepId: step.id,
                  courseId: course.id,
                  activities: step.activityNames,
                })
              }
            }

            if (step.memorize) {
              const date = moment(step.memorize.lastUpdated)
              const quizDate = moment(step.quiz?.lastUpdated ?? 0)
              if (
                (step.memorize.progress !== 1 && step.progress !== 1) ||
                date.isSame(moment(), 'day') ||
                date.isSame(moment(), 'day')
              ) {
                theoryBraindates.push({
                  type: 'memo',
                  title: step.title,
                  lifeskillId: lifeskillId,
                  category: getCategoryIdFromLifeskillId(lifeskillId),
                  doneToday: quizDate !== 0 ? quizDate.isSame(moment(), 'day') : date.isSame(moment(), 'day'),
                  stepId: step.id,
                  courseId: course.id,
                  locked: step.read && step.read.progress === 0,
                  activities: step.activityNames,
                })
              }
            }
            if (step.quiz) {
              const date = moment(step.quiz.lastUpdated)
              if (step.quiz.progress !== 1 || date.isSame(moment(), 'day')) {
                theoryBraindates.push({
                  type: 'quiz',
                  title: step.title,
                  lifeskillId: lifeskillId,
                  category: getCategoryIdFromLifeskillId(lifeskillId),
                  doneToday: date.isSame(moment(), 'day'),
                  stepId: step.id,
                  courseId: course.id,
                  locked: step.read && step.read.progress === 0,
                  activities: step.activityNames,
                })
              }
            }

            if (step.memorize2) {
              const date = moment(step.memorize2.lastUpdated)
              const quiz2Date = moment(step.quiz2?.lastUpdated ?? 0)
              if (
                (step.memorize2.progress !== 1 && step.progress !== 1) ||
                date.isSame(moment(), 'day') ||
                quiz2Date.isSame(moment(), 'day')
              ) {
                theoryBraindates.push({
                  type: 'memo2',
                  title: step.title,
                  lifeskillId: lifeskillId,
                  category: getCategoryIdFromLifeskillId(lifeskillId),
                  doneToday: quiz2Date !== 0 ? quiz2Date.isSame(moment(), 'day') : date.isSame(moment(), 'day'),
                  stepId: step.id,
                  courseId: course.id,
                  locked: isMemo2andQuiz2Locked,
                  activities: step.activityNames,
                })
              }
            }
            if (step.quiz2) {
              const date = moment(step.quiz2.lastUpdated)
              if (step.quiz2.progress !== 1 || date.isSame(moment(), 'day')) {
                theoryBraindates.push({
                  type: 'quiz2',
                  title: step.title,
                  lifeskillId: lifeskillId,
                  category: getCategoryIdFromLifeskillId(lifeskillId),
                  doneToday: date.isSame(moment(), 'day'),
                  stepId: step.id,
                  courseId: course.id,
                  locked: isMemo2andQuiz2Locked,
                  activities: step.activityNames,
                })
              }
            }
          })

          const date = moment(journey.quiz.done)
          if (journey.quiz.done === 0 || date.isSame(moment(), 'day')) {
            theoryBraindates.push({
              type: 'journeyQuiz',
              title: 'Journey Quiz',
              lifeskillId: lifeskillId,
              category: getCategoryIdFromLifeskillId(lifeskillId),
              doneToday: date.isSame(moment(), 'day'),
              id: journey.id,
              courseId: journey.courseId,
              locked: journeyStats.quizUnlockProgress < 1,
            })
          }
        })
      } else {
        return // Probably caused by vuex plugin
      }
    })

    return theoryBraindates
  },

  getVideoBraindatesForMission(state, getters, rootState, rootGetters) {
    let selectedMission = getSkillMissionById(getters.mission())
    let videoBraindates = null

    selectedMission.lifeskills.forEach(lifeskillId => {
      const braindates = rootGetters['moduleApp/braindates'].filter(
        b => b.context === 'video' && b.lifeskillNumber === lifeskillId
      )

      if (!videoBraindates && braindates.length < settings.video.maxBraindatesForLifekey) {
        videoBraindates = {
          type: 'video',
          lifeskillId: lifeskillId,
          category: getCategoryIdFromLifeskillId(lifeskillId),
          title: 'Video hjernedate - ' + (braindates.length + 1) + '/' + settings.video.maxBraindatesForLifekey,
          doneToday: false,
          locked: false,
        }
      }
    })

    return videoBraindates
  },

  getScenarioBraindatesForMission(state, getters, rootState, rootGetters) {
    let selectedMission = getSkillMissionById(getters.mission())
    let noJourneysDone = true
    let scenarioBraindates = []
    const mentalHygieneBraindates = getters.skillMissionMentalHygieneBraindates

    selectedMission.lifeskills.forEach(lifeskillId => {
      const lifeskill = getters.getLifeskillById(lifeskillId)
      const courseId = lifeskill.masterCourseId
      const course = rootGetters['moduleCourse/getCourseById'](courseId)

      if (course) {
        course.stepGroupIds.forEach(stepGroupId => {
          const journey = rootGetters['moduleCourse/getJourneyById'](stepGroupId)

          if (journey.quiz.done) {
            noJourneysDone = false
          }
        })
      } else {
        return // Probably caused by vuex plugin
      }

      const scenarios = rootGetters['scenario/byCourseId'](courseId)
        .map(x => x.stepGroups)
        .flat()
        .map(sg => sg.steps || [])
        .flat()
        .map(step => {
          return (step.scenarios || []).map(s => ({
            stepId: step.id,
            ...s,
          }))
        })
        .flat()

      if (scenarios) {
        for (var i = 0; i < scenarios.length; i++) {
          if (
            rootGetters['scenario/doneToday'](scenarios[i].id) ||
            (rootGetters['scenario/isOpen'](scenarios[i].id) && !rootGetters['scenario/isDone'](scenarios[i].id)) ||
            noJourneysDone
          ) {
            const scenarioStep = rootGetters['moduleCourse/getStepById'](scenarios[i].stepId)
            scenarioBraindates.push({
              type: 'scenario',
              title: scenarioStep.title,
              lifeskillId: lifeskillId,
              category: getCategoryIdFromLifeskillId(lifeskillId),
              id: scenarios[i].id,
              doneToday: rootGetters['scenario/doneToday'](scenarios[i].id),
              stepId: scenarios[i].stepId,
              courseId: courseId,
              locked: noJourneysDone,
            })
            break
          }
        }
      }
    })
    const allScenarios = scenarioBraindates.concat(mentalHygieneBraindates)
    return allScenarios
  },

  upNextMissionBraindates(state, getters) {
    const theoryBraindates = getters.getTheoryBraindatesForMission.slice()
    const videoBraindates = getters.getVideoBraindatesForMission
    const scenarioBraindates = getters.getScenarioBraindatesForMission.slice()
    let missionBraindatesList = []

    for (let i = 0; 4 > i; i++) {
      for (let t = 0; 3 > t; t++) {
        if (theoryBraindates[0]) {
          missionBraindatesList.push(theoryBraindates.shift())
        } else {
          break
        }
      }

      let addedScenarios = 0

      while (scenarioBraindates.length) {
        let scenarioBraindate = scenarioBraindates.shift()

        if (addedScenarios === 2) {
          break
        }

        if (scenarioBraindate.locked) {
          if (
            missionBraindatesList.includes(
              item => item.type === 'journeyQuiz' && item.lifeskillId === scenarioBraindate.lifeskillId
            )
          ) {
            missionBraindatesList.push(scenarioBraindate)
            addedScenarios = addedScenarios + 1
          }
        } else {
          missionBraindatesList.push(scenarioBraindate)
          addedScenarios = addedScenarios + 1
        }
      }

      if (videoBraindates) {
        missionBraindatesList.push(videoBraindates)
      }
      if (theoryBraindates.length === 0 && scenarioBraindates.length === 0) {
        break
      }
    }

    let missionBraindatesNotDoneToday = missionBraindatesList.filter(item => !item.doneToday)

    return missionBraindatesNotDoneToday
  },

  notifications(state, getters, rootState, rootGetters) {
    return rootGetters['scenario/notifications']
  },

  availableLanguagesFlytoget(state) {
    return state.memolanguage.availableLanguages.filter(languageId => {
      if (languageId === 'english') {
        return false
      } else if (languageId === 'norwegian') {
        return false
      }
      return true
    })
  },
  availableLanguages(state) {
    return state.memolanguage.availableLanguages
  },

  // local state stuff
  getLifeskillSelectedVideo: state => lifeskillId => {
    return state.lifeskills[lifeskillId].selectedVideoId
  },

  braindates(state) {
    return state.braindates
  },

  streakLength(state) {
    return state.memostreak
  },

  getBraindatesInTimeRange: state => (start, end) => {
    return state.braindates.filter(({ createdAt }) => {
      return moment(createdAt).isBetween(start, end)
    })
  },
  braindatesForCurrentDay: (state, getters) => {
    return getters
      .getBraindatesInTimeRange(state.time.startOfCurrentDay, state.time.endOfCurrentDay)
      .filter(bd => !bd.isBonus)
  },
  braindatesForCurrentWeek: (state, getters) => {
    return getters
      .getBraindatesInTimeRange(state.time.startOfCurrentWeek, state.time.endOfCurrentWeek)
      .filter(bd => !bd.isBonus)
  },
  braindatesForCurrentMonth: (state, getters) => {
    return getters.getBraindatesInTimeRange(state.time.startOfCurrentMonth, state.time.endOfCurrentMonth)
  },
  locale: state => {
    return state.memolife.appLanguage || 'en'
  },
  gettingStartedTotalProgress: (state, getters) => {
    let items = getters['gettingStartedItems']
    return items.filter(item => item.progress === 1).length / items.length
  },
  gettingStartedHasFocus(state, getters, rootState, rootGetters) {
    const dashboardHasVisibleSectionsAboveGettingStarted = rootGetters['moduleMemoransier/publishedGames'].length > 0

    return (
      !state.memolife.didExitGettingStartedFocus &&
      !dashboardHasVisibleSectionsAboveGettingStarted &&
      getters.gettingStartedTotalProgress < 1
    )
  },

  gettingStartedItems(state, getters, rootState) {
    let memoryiqProgress = 0
    if (getters.hasAwardedLifekey('02', 'memoryiq')) {
      memoryiqProgress = 1
    }

    // if user did legacy memoryiq
    const mSettings = rootState.moduleMemogenius.settings
    if (mSettings.memoryiqTheory && mSettings.memoryOnboardingTestB) {
      memoryiqProgress = 1
    }

    return [
      {
        id: 'memoryiq',
        progress: memoryiqProgress,
        locked: 0,
      },
      {
        id: 'setDailyGoal',
        progress: state.memolife.dailyRequiredBraindatesNotSetByUser ? 0 : 1,
        locked: 0,
      },
      {
        id: 'firstVideo',
        progress: getters['getOnboardingVideoBraindate'] === null ? 0 : 1,
        locked: 0,
      },
      {
        id: 'selectFavorites',
        progress: state.memolife.didSelectFavoriteLifeskills ? 1 : 0,
        locked: 0,
      },
    ]
  },

  autoplayVideoParts: state => {
    return state.memolife.autoplayVideoParts
  },

  lifekeyCount(state) {
    return state.lifekeys.length
  },

  lifekeys: state => state.lifekeys,

  hasAwardedLifekey: (state, getters) => (lifeskill, context) => {
    return getters.lifekeys.some(l => l.lifeskill === lifeskill && l.context === context)
  },

  hasAwardedBraindate: (state, getters) => ({ context, lifeskillNumber, activity }) => {
    return (
      getters.braindates.findIndex(
        b => b.lifeskillNumber === lifeskillNumber && b.context === context && b.activity === activity
      ) > -1
    )
  },

  belt: (state, getters) => {
    // return getBeltFromTotalPoints(state.lifekeys.length)
    return getBeltFromTotalPoints(getters.lifekeyCount)
  },
}
