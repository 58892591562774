/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menu/categories': {
    width: 22,
    height: 22,
    viewBox: '0 0 22 22',
    data: '<g _fill="#658CBD" fill-rule="nonzero"><circle pid="0" cx="11.023" cy="4.005" r="1.449"/><path pid="1" d="M5.007 5.815a1.324 1.324 0 01-.14-.06c-.843.022-.833 1.132.028 1.132h3.058L5.007 5.815zM13.273 12.634l2.862 4.963a.812.812 0 01-1.407.813l-1.454-2.55v-3.226zM8.788 12.634l-2.861 4.963a.812.812 0 001.407.813l1.454-2.55v-3.226zM17.194 5.754a1.3 1.3 0 01-.142.061l-2.946 1.072h3.058c.862 0 .871-1.11.03-1.133z"/><path pid="2" d="M16.516 4.342l-3.68 1.413H9.224l-3.68-1.413c-.818-.3-1.206.767-.388 1.065l4.068 1.48v12.304a.813.813 0 001.624 0l.001-6.482h.362l-.01-.023h.018l-.008.023-.003 6.482a.812.812 0 001.624 0l.004-11.415v-.889l4.068-1.48c.818-.298.43-1.365-.388-1.065z"/><path pid="3" d="M11 1.142c5.445 0 9.859 4.413 9.859 9.858S16.445 20.859 11 20.859 1.142 16.445 1.142 11 5.555 1.142 11 1.142zM11 0a10.961 10.961 0 00-7.778 3.222A10.96 10.96 0 000 11a10.957 10.957 0 003.222 7.778 10.952 10.952 0 003.496 2.357C8.075 21.709 9.516 22 11 22s2.925-.29 4.282-.865a10.957 10.957 0 003.496-2.357A10.957 10.957 0 0022 11a10.965 10.965 0 00-3.222-7.778A10.957 10.957 0 0011 0z"/></g>'
  }
})
