/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'play-triangle': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M6 4l20 12L6 28z"/>'
  }
})
