/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'presentation-icon': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<path pid="0" d="M60 8V0H0v8h2v40H1a1 1 0 100 2h28v2c0 .047.021.088.027.134C27.292 52.571 26 54.131 26 56c0 2.206 1.794 4 4 4s4-1.794 4-4c0-1.869-1.292-3.429-3.027-3.866.006-.046.027-.087.027-.134v-2h28a1 1 0 100-2h-1V8h2zM32 56c0 1.103-.897 2-2 2s-2-.897-2-2 .897-2 2-2 2 .897 2 2zM2 2h56v4H2V2zm54 46H4V8h52v40z"/><path pid="1" d="M30 44c8.822 0 16-7.178 16-16s-7.178-16-16-16-16 7.178-16 16 7.178 16 16 16zm0-30c7.72 0 14 6.28 14 14s-6.28 14-14 14-14-6.28-14-14 6.28-14 14-14z"/><path pid="2" d="M25.519 35.877a1.004 1.004 0 001.018-.033l11-7a1.002 1.002 0 000-1.688l-11-7a1.008 1.008 0 00-1.019-.033A1.002 1.002 0 0025 21v14c0 .365.199.701.519.877zM27 22.821L35.138 28 27 33.179V22.821z"/>'
  }
})
