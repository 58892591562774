import * as types from '../../mutation-types'
import moment from 'moment'
import debounce from 'lodash-es/debounce'
import store from '@/store'
import { updateAbility } from '@/ability'
import router from '../../../router'
import { startOfMinute, startOfDay, endOfDay, startOfWeek, endOfWeek, startOfMonth, endOfMonth } from '@/utils'

import { EventBus } from '@/event-bus'
import memomapsDataHelper from '../module-course/memomaps-data-helper'
import persistence from '@/store/persistence'
import apolloClient from '@/apollo-client'
import gql from 'graphql-tag'
import CreateBraindateQ from './CreateBraindate.gql'
import BraindatesList from './BraindatesList.gql'
import CreateOrUpdateVideoProgress from './CreateOrUpdateVideoProgress.gql'

export default {
  initApp() {
    persistence.loadApp()
  },

  activateApp({ dispatch }) {
    dispatch('updateTime')
    setInterval(() => dispatch('updateTime'), 60000) // every 60 seconds
  },

  loadBraindates({ commit }) {
    apolloClient
      .query({
        fetchPolicy: 'no-cache',
        query: BraindatesList,
        variables: { userId: store.getters['moduleAuth/profile'].id },
      })
      .then(result => {
        result.data.braindatesList.forEach(bd => {
          if (bd.language === 'isBonus') {
            bd.isBonus = true
          }
        })
        commit(types.SET_BRAINDATES, result.data.braindatesList)
        commit(types.SET_MEMOSTREAK, result.data.memostreak)
      })
      .catch(err => {
        console.error(err) // eslint-disable-line no-console
      })
  },
  async cleanupAfterUserLogout() {
    await apolloClient.resetStore()
  },

  async loadUser({ dispatch, rootState }) {
    await apolloClient.resetStore()
    await dispatch('loadSettings')
    dispatch('moduleAuth/updateAuthToken', null, { root: true })
    dispatch('loadBraindates')
    dispatch('loadLifekeys')
    dispatch('repetition/load', null, { root: true })

    dispatch(
      'moduleSocial/configure',
      { userId: rootState.moduleAuth.profile._id, email: rootState.moduleAuth.profile.email },
      { root: true }
    )

    await dispatch('loadLifeskillsList')
    dispatch('loadFavorites')
    await dispatch('moduleCourse/loadCoursesList', null, { root: true })

    await persistence.loadUser()
    dispatch('moduleCourse/loadCourseProgress', null, { root: true })
    dispatch('moduleMemogenius/init', null, { root: true })
  },

  async unloadUser() {
    await persistence.unloadUser()
  },
  async setLocaleNoReload({ commit, dispatch, rootState }, language) {
    commit(types.SET_LOCALE, language)

    if (rootState.moduleAuth.isAuthenticated) {
      await dispatch('moduleAuth/updateMe', { language }, { root: true })
    }
  },
  async setLocale({ commit, dispatch, rootState }, language) {
    commit(types.SET_LOCALE, language)

    if (rootState.moduleAuth.isAuthenticated) {
      await dispatch('moduleAuth/updateMe', { language }, { root: true })
      // NOTE: we reload to be on the safe side. Might not be needed in all cases
      router.replace('/home')
    }
  },
  updateTime({ state, commit }) {
    const time = state.time
    const now = Date.now()
    const payload = {}
    const startOfCurrentMinute = startOfMinute(now)
    if (time.startOfCurrentMinute !== startOfCurrentMinute) {
      payload.startOfCurrentMinute = startOfCurrentMinute

      const startOfCurrentDay = startOfDay(now)
      if (time.startOfCurrentDay !== startOfCurrentDay) {
        payload.startOfCurrentDay = startOfCurrentDay
        payload.endOfCurrentDay = endOfDay(now)

        const startOfCurrentWeek = startOfWeek(now)
        if (time.startOfCurrentWeek !== startOfCurrentWeek) {
          payload.startOfCurrentWeek = startOfCurrentWeek
          payload.endOfCurrentWeek = endOfWeek(now)

          const startOfCurrentMonth = startOfMonth(now)
          if (time.startOfCurrentMonth !== startOfCurrentMonth) {
            payload.startOfCurrentMonth = startOfCurrentMonth
            payload.endOfCurrentMonth = endOfMonth(now)
          }
        }
      }
      commit(types.UPDATE_TIME, payload)
    }
  },

  unlockTeamSection({ commit, dispatch }, value) {
    commit(types.UPDATE_APP_SETTINGS, {
      settings: {
        teamSectionUnlocked: value,
      },
    })
    dispatch('updateSetting', { key: 'teamSectionUnlocked', value })
  },

  selectTeamId({ commit, dispatch }, value) {
    commit(types.UPDATE_APP_SETTINGS, {
      settings: {
        selectedTeamId: value,
      },
    })
    dispatch('updateSetting', { key: 'selectedTeamId', value })

    dispatch('moduleTeam/load', value, { root: true })
  },

  setAutoplayVideoParts({ commit, dispatch }, value) {
    commit(types.UPDATE_AUTOPLAY_VIDEO_PARTS, value)
    dispatch('updateSetting', { key: 'autoplayVideoParts', value })
  },
  markGetStartedPopupAsShown({ commit, dispatch }, payload) {
    const done = !payload || payload.reset !== true
    commit(types.UPDATE_GET_STARTED_SHOWN, { done })
    dispatch('updateSetting', { key: 'didShowGetStartedPopup', value: done })
  },

  markFavoriteLifeskillsSelected({ commit, dispatch }, payload) {
    const done = !payload || payload.reset !== true
    commit(types.UPDATE_DASHBOARD_FAVORITES_SELECTED, { done })
    dispatch('updateSetting', { key: 'didSelectFavoriteLifeskills', value: done })
  },
  markDashboardOnboardingAsShown({ commit, dispatch }, payload) {
    const done = !payload || payload.reset !== true
    commit(types.UPDATE_DASHBOARD_ONBOARDING_SHOWN, { done })
    dispatch('updateSetting', { key: 'didShowDashboardOnboarding', value: done })
  },
  markMemomapsOnboardingAsShown({ commit, dispatch }, payload) {
    const done = !payload || payload.reset !== true
    commit(types.UPDATE_MEMOMAPS_ONBOARDING_SHOWN, { done })
    dispatch('updateSetting', { key: 'didShowMemomapsOnboardingPopup', value: done })
  },
  markMemoOnboardingAsShown({ commit, dispatch }, payload) {
    const done = !payload || payload.reset !== true
    commit(types.UPDATE_MEMO_ONBOARDING_SHOWN, { done })
    dispatch('updateSetting', { key: 'didShowMemoOnboardingPopup', value: done })
  },
  markDidExitGettingStartedFocus({ commit, dispatch }, payload) {
    const done = !payload || payload.reset !== true
    commit(types.UPDATE_DID_EXIT_GETTING_STARTED_FOCUS, { done })
    dispatch('updateSetting', { key: 'didExitGettingStartedFocus', value: done })
  },
  updateWhatsNewViewedArticles({ commit, dispatch, state }, { id = null, ids = [] }) {
    if (id) {
      const prevIds = state.memolife.whatsNewViewedArticles
      if (prevIds.indexOf(id) !== -1) {
        return
      }
      ids = [...prevIds, id]
    }
    commit(types.UPDATE_WHATSNEW_VIEWED_ARTICLES, { ids })
    dispatch('updateSetting', { key: 'whatsNewViewedArticles', value: ids })
  },

  videoPartCompleted({ commit }, { lifeskillNumber, videoId, partIndex }) {
    commit(types.VIDEO_PART_COMPLETED, { lifeskillNumber, videoId, partIndex })
  },

  updateProgressOnVideo({ commit, state }, { id, value, lifeskillId }) {
    const video = state.videos[id]
    if (video.duration - value < 2) {
      // completed if 2 left
      value = video.duration
    }
    commit(types.UPDATE_VIDEO_PROGRESS, {
      id,
      value,
      timestamp: moment(),
      forBraindateCalculation: { progress: video.progress, duration: video.duration, lifeskillId },
    })

    persistVideoProgress(id)
  },

  async loadLifeskillsList({ commit }) {
    const lifeskills = await memomapsDataHelper.loadLifeskillsList()

    commit('SET_LIFESKILLS_LIST', lifeskills)

    const mappedLifeskills = lifeskills.map(entry => {
      return {
        _id: entry.id,
        number: entry.numbershape,
        numberOfVideos: entry.videocount,
        recommenedVideoIds: entry.videoLifeskillsList.map(x => x.video.youtubeId),
      }
    })

    commit(types.UPDATE_LIFESKILL_DATA, { lifeskills: mappedLifeskills })
  },

  async loadAllVideos({ commit, state }) {
    if (state.loadStatus.didLoadAllVideos || state.loadStatus.isLoadingAllVideos) {
      return
    }

    commit('updateLoadStatus', { isLoadingAllVideos: true })
    const videos = await memomapsDataHelper.loadAllVideos()
    commit(types.UPDATE_ALL_VIDEOS, videos)
    commit('updateLoadStatus', { didLoadAllVideos: true })
  },

  async loadVideosForLifeskill({ commit }, { lifeskillId }) {
    let videos = await memomapsDataHelper.loadVideosForLifeskill(lifeskillId)
    commit(types.UPDATE_ALL_VIDEOS, videos)
    let videoIds = []
    for (const videoData of videos) {
      commit(types.UPDATE_VIDEO_DATA_WITH_DESCRIPTION, {
        videoId: videoData.id,
        description: videoData.description,
      })
      videoIds.push(videoData.id)
    }
    commit(types.UPDATE_LIFESKILL_DATA, { lifeskillId: lifeskillId, data: { relatedVideos: videoIds } })
    return videos
  },

  async loadVideosInProgress({ commit, getters }) {
    const videos = await memomapsDataHelper.loadVideosInProgress()
    commit('VIDEOS_IN_PROGRESS', videos)
    commit(types.UPDATE_ALL_VIDEOS, getters['inProgressVideoItems'])
  },

  async loadVideo({ commit }, videoId) {
    let video = (await memomapsDataHelper.loadVideo(videoId)) || {}
    commit(types.UPDATE_VIDEO_DATA, { videoId: video.id, data: video })
  },

  async loadRecommendedVideos({ commit, getters }) {
    const videos = await memomapsDataHelper.loadRecommendedVideos()
    commit('RECOMMENDED_VIDEOS', videos)
    commit(types.UPDATE_ALL_VIDEOS, getters['recommendedVideos'])
  },

  setDailyRequiredBraindates({ commit, dispatch }, payload) {
    commit(types.SET_DAILY_REQUIRED_BRAINDATES, payload)
    dispatch('updateSetting', { key: 'dailyRequiredBraindates', value: payload.value })
  },

  registerBraindate({ commit }, braindate) {
    if (!braindate.createdAt) {
      braindate.createdAt = Date.now()
    }
    commit(types.ADD_BRAINDATE, braindate)
    braindate.userId = store.getters['moduleAuth/profile'].id
    if (braindate.isBonus) {
      braindate.language = 'isBonus' // NOTE: temp: reuse prop (waiting with backend change)
    }

    // do mutation
    apolloClient
      .mutate({
        fetchPolicy: 'no-cache',
        mutation: CreateBraindateQ,
        variables: braindate,
      })
      .then(result => {
        commit(types.SET_MEMOSTREAK, parseInt(result.data.createBraindate.query.memostreak, 10))
      })
      .catch(e => {
        console.log(e) // eslint-disable-line no-console
        const updateError = e.graphQLErrors.map(({ message }) => {
          return message
        })
        console.log(updateError) // eslint-disable-line no-console
        // TODO: retry on network error?
      })

    const dailyRequiredBraindates = store.state.moduleApp.memolife.dailyRequiredBraindates
    const weeklyRequiredBraindates = dailyRequiredBraindates * 7
    const today = store.getters['moduleApp/braindatesForCurrentDay'].length
    const thisWeek = store.getters['moduleApp/braindatesForCurrentWeek'].length
    const dailyBraindateProgress = Math.min(1, today / dailyRequiredBraindates)
    const weeklyBraindateProgress = Math.min(1, thisWeek / weeklyRequiredBraindates)
    window.dataLayer.push({
      event: 'braindate',
      daily_braindate_progress: dailyBraindateProgress,
      weekly_braindate_progress: weeklyBraindateProgress,
    })
    EventBus.$emit('newBraindate', braindate)
  },

  // local state stuff
  setLifeskillSelectedVideo({ commit }, payload) {
    commit(types.SET_LIFESKILL_SELECTED_VIDEO, payload)
  },
  toggleLifeskillFollow({ commit, getters }, payload) {
    const following = getters.follows(payload.lifeskillId)
    const follow = !following

    commit(types.SET_LIFESKILL_MARKED, {
      lifeskillId: payload.lifeskillId,
      follow,
    })

    apolloClient.mutate({
      fetchPolicy: 'no-cache',
      mutation: gql`
        mutation($lifeskill: String!, $following: Boolean!) {
          followLifeskill(input: { _lifeskill: $lifeskill, _following: $following }) {
            results {
              lifeskill
              following
            }
          }
        }
      `,
      variables: {
        lifeskill: payload.lifeskillId,
        following: follow,
      },
    })
  },

  toggleDebugButton({ commit, state }) {
    commit(types.SET_SHOW_DEBUG_BUTTON, { show: !state.localState.showDebugButton })
  },
  dbgToggleQuizCheatMode({ commit, state }) {
    commit(types.UPDATE_DEBUG_SETTINGS, { key: 'useQuizCheatMode', value: !state.debugSettings.useQuizCheatMode })
  },
  dbgSetDebugButtonPosition({ commit }, position) {
    commit(types.UPDATE_DEBUG_SETTINGS, { key: 'debugButtonPosition', value: position })
  },
  dbgToggleShowAllStandaloneCourses({ commit, state, rootState }) {
    commit(types.UPDATE_DEBUG_SETTINGS, {
      key: 'showAllStandaloneCourses',
      value: !state.debugSettings.showAllStandaloneCourses,
    })
    updateAbility(rootState.moduleAuth.profile)
  },

  dbgToggleUnlockAllContent({ commit, state, rootState }) {
    commit(types.UPDATE_DEBUG_SETTINGS, { key: 'unlockAllContent', value: !state.debugSettings.unlockAllContent })
    updateAbility(rootState.moduleAuth.profile)
  },

  loadFavorites({ commit }) {
    return apolloClient
      .query({
        query: gql`
          query {
            followedLifeskillsList {
              following
              lifeskill
            }
          }
        `,
      })
      .then(result => result.data)
      .then(data => {
        const following = data.followedLifeskillsList.filter(item => item.following).map(item => item.lifeskill)
        commit(types.SET_LIFESKILLS_MARKED, following)
      })
  },

  setMission({ dispatch, commit }, mission) {
    commit(types.SET_MISSION, mission)
    console.log(mission, ' mission from action')
    dispatch('updateSetting', { key: 'mission', value: mission })
  },

  addHabitMission({ state, dispatch, commit }, mission) {
    commit('addHabitMission', mission)
    dispatch('updateSetting', { key: 'selectedHabitMissions', value: state.memolife.selectedHabitMissions })
  },

  selectHabitMission({ state, dispatch, commit }, missionId) {
    commit('selectHabitMission', missionId)
    dispatch('updateSetting', { key: 'selectedHabitMissions', value: state.memolife.selectedHabitMissions })
  },

  deselectHabitMission({ state, dispatch, commit }, missionId) {
    commit('deselectHabitMission', missionId)
    dispatch('updateSetting', { key: 'selectedHabitMissions', value: state.memolife.selectedHabitMissions })
  },

  updateSelectedHabitMissionsOnBraindate({ state, dispatch, commit }, missionId) {
    commit('updateHabitMissionBraindates', missionId)
    dispatch('updateSetting', { key: 'selectedHabitMissions', value: state.memolife.selectedHabitMissions })
  },

  loadSettings({ commit }) {
    return apolloClient
      .query({
        fetchPolicy: 'no-cache',
        query: gql`
          query {
            userSettingsList {
              key
              value
            }
          }
        `,
      })
      .then(result => result.data)
      .then(data => {
        commit(types.UPDATE_APP_SETTINGS, {
          settings: data.userSettingsList
            .filter(setting => setting.key.startsWith('app:'))
            .reduce((acc, curr) => {
              const key = curr.key.split('app:')[1]
              acc[key] = curr.value
              return acc
            }, {}),
        })
      })
  },

  updateSetting({ commit }, { key, value }) {
    console.log(value, key, ' value and key')
    const prefix = 'app:'
    return apolloClient.mutate({
      fetchPolicy: 'no-cache',
      mutation: gql`
        mutation($key: String!, $value: JSON!) {
          updateUserSetting(input: { _key: $key, _value: $value }) {
            results {
              key
              value
            }
          }
        }
      `,
      variables: { key: `${prefix}${key}`, value },
    })
  },

  awardLifekey({ commit, getters }, { lifeskill, context, lifekeyType }) {
    if (getters.hasAwardedLifekey(lifeskill, context)) {
      return
    }
    commit(types.ADD_LIFEKEY, { lifeskill, context })

    EventBus.$emit('new-lifekey', {
      lifeskillId: lifeskill,
      lifekeyType: lifekeyType,
      lifekeyCount: getters.lifekeyCount,
      belt: getters.belt,
      context: context,
      continueToDashboard: context === 'firstvideo',
    })

    return apolloClient
      .mutate({
        fetchPolicy: 'no-cache',
        mutation: gql`
          mutation($lifeskill: String!, $context: String!) {
            createLifekey(input: { _lifeskill: $lifeskill, _context: $context }) {
              results {
                context
                createdAt
                lifeskill
              }
            }
          }
        `,
        variables: { lifeskill, context },
      })
      .then(result => {
        console.log('stored new lifekey', result)
      })
      .catch(console.warning)
  },

  loadLifekeys({ commit }) {
    return apolloClient
      .query({
        query: gql`
          {
            lifekeysList {
              context
              createdAt
              lifeskill
            }
          }
        `,
      })
      .then(result => commit(types.SET_LIFEKEYS, result.data.lifekeysList))
  },
}

var persistVideoProgress = debounce(
  videoId => {
    const userId = store.getters['moduleAuth/profile'].id
    const video = store.getters['moduleApp/getVideoById'](videoId)
    apolloClient
      .mutate({
        fetchPolicy: 'no-cache',
        mutation: CreateOrUpdateVideoProgress,
        variables: {
          videoId: video._id,
          userId: userId,
          progress: Math.floor(video.progress),
        },
      })
      .catch(e => {
        const updateError = e.graphQLErrors.map(({ message }) => {
          return message
        })
        console.log(updateError) // eslint-disable-line no-console
      })
  },
  1500,
  { maxWait: 5000 }
)
