/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menu/missions': {
    width: 24,
    height: 26,
    viewBox: '0 0 24 26',
    data: '<path pid="0" d="M1.64 25.516h20.307l-1.677-2.711h3.68l-6.853-11.438-1.937 3.214-2.602-4.191V7.58h7.014L16.63 4.252 19.572.924h-8.537v9.466l-2.454 3.954-1.777-2.974L-.05 22.807h3.36l-1.67 2.709zM17.09 14.329l4.173 6.955h-1.937l-3.261-5.249 1.025-1.706zm-7.323 7.143l2.03-2.396 2.031 2.394 2-2.963 3.404 5.483H4.38l3.396-5.467 1.99 2.95zm-7.143-.19l4.173-6.955.88 1.47-3.406 5.485H2.623z" _fill="#658CBD" fill-rule="nonzero"/>'
  }
})
