/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'team-step-icon': {
    width: 7,
    height: 7,
    viewBox: '0 0 7 7',
    data: '<path pid="0" d="M1.789 5.556c-.529.141-1.037-.044-.874.566.164.61.725.99 1.253.848.529-.141.824-.75.66-1.361-.163-.61-.51-.195-1.04-.053zM1.686 5.179c.993-.266.735-.602.845-1.267.102-.623-.78-2.534-1.792-2.263-1.011.271-.812 2.362-.418 2.856.437.547.372.94 1.365.674zM4.238 3.933c-.529-.142-.876-.557-1.04.053-.164.61.132 1.22.66 1.361.529.142 1.09-.238 1.253-.848.164-.61-.345-.424-.873-.566zM5.287.026C4.276-.245 3.393 1.666 3.496 2.29c.11.664-.149 1 .844 1.266s.929-.126 1.365-.674C6.1 2.39 6.3.298 5.287.026z" _fill="#fff"/>'
  }
})
