import general from './general';
import belts from './belts';
import dashboard from './dashboard';
import account from './account';
import memogenius from './memogenius';
import memomaps from './memomaps';
import memolanguage from './memolanguage';
import systems from './systems';
import country from './country';

export default {
    hello: 'hello-default',
    hello_no: 'hello-default-no',
    general,
    belts,

    dashboard,
    account,
    memogenius,
    memomaps,
    memolanguage,
    systems,
    country
};
