<template>
  <div
    class="DailyGoalPopup"
    data-bg="light"
    :class="{ useAsBraindatePopup }">
    <transition name="fade">
      <SettingsPanel
        v-if="showingSettings"
        key="settingSection"
        class="settingsSection"
        @request-exit="hideSettings" />

      <div
        v-else
        key="braindateSection"
        class="braindateSection">
        <div
          v-if="!useAsBraindatePopup"
          class="header">
          <div class="title">
            {{ $t('title') }}
          </div>
          <div
            class="settingsButton --global-clickable"
            @click="showSettings()">
            <svgicon icon="settings" />
          </div>
        </div>

        <div class="tabsSection">
          <TabsWithSlidingIndicator
            class="tabs"
            :index.sync="activeTabIndex"
            :items="tabs"
            :pulse-index="pulseTabIndex" />
        </div>

        <div class="body">
          <transition :name="transitionName">
            <DailyPanel
              v-if="activeTabIndex === 0"
              :show-header="useAsBraindatePopup"
              :hide-footer="useAsBraindatePopup"
              :goal-just-reached="useAsBraindatePopup && firstTime"
              :required-daily-braindates="requiredDailyBraindates"
              :braindate-count-today="braindateCountToday"
              :streak-length="streakLength"
              :lifeskill-id="firstTime ? lifeskillId : ''"
              class="panel"
              @animation-completed="$emit('animation-completed')" />
            <WeeklyPanel
              v-else-if="activeTabIndex === 1"
              :show-header="useAsBraindatePopup"
              :hide-footer="useAsBraindatePopup"
              class="panel" />
            <LifelongPanel
              v-else-if="activeTabIndex === 2"
              :show-header="useAsBraindatePopup"
              :hide-footer="useAsBraindatePopup"
              class="panel"
              @request-close="$emit('close')" />
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<translations>
  title: 'Your Goals'
  title_no: 'Dine mål'

  braindateTitle: 'Great Job, {name}'
  braindateSubtitle: 'You have completed your daily goal'

  dailyTab: 'Daily'
  dailyTab_no: 'Daglig'
  weeklyTab: 'Weekly'
  weeklyTab_no: 'Ukentlig'
  lifelongTab: 'Lifelong'
  lifelongTab_no: 'Livslang'
</translations>

<script>
import SettingsPanel from './SettingsPanel';
import DailyPanel from './DailyPanel';
import WeeklyPanel from './WeeklyPanel';
import LifelongPanel from './LifelongPanel';

import categoryThemes from '@/category-themes';
import TabsWithSlidingIndicator from './TabsWithSlidingIndicator';

export default {
    components: { SettingsPanel, TabsWithSlidingIndicator, DailyPanel, WeeklyPanel, LifelongPanel },
    props: {
        showSettingsInitially: {
            type: Boolean,
            default: false
        },
        useAsBraindatePopup: {
            type: Boolean,
            default: false
        },
        lifeskillId: {
            type: String,
            default: ''
        },

        // In conjuction with useAsBraindatePopup
        goal: {
            type: Number
        },
        count: {
            type: Number
        },
        streak: {
            type: Number
        }
    },
    data() {
        return {
            showingSettings: this.showSettingsInitially,
            progressColor: categoryThemes.default.color,
            progress: 0,
            showingGoalReached: false,
            activeTabIndex: 0,
            transitionName: '',
            firstTime: true,
            pulseTabIndex: -1,
            tabs: [{ label: this.$t('dailyTab') }, { label: this.$t('weeklyTab') }, { label: this.$t('lifelongTab') }]
        };
    },
    computed: {
        profile() {
            return this.$store.getters['moduleAuth/profile'];
        },
        requiredDailyBraindates() {
            return this.goal === undefined ? this.$store.state.moduleApp.memolife.dailyRequiredBraindates : this.goal;
        },
        braindateCountToday() {
            return this.count === undefined
                ? this.$store.getters['moduleApp/braindatesForCurrentDay'].length
                : this.count;
        },
        streakLength() {
            return this.streak === undefined ? this.$store.getters['moduleApp/streakLength'] : this.streak;
        }
    },
    watch: {
        activeTabIndex(newIndex, oldIndex) {
            this.transitionName = newIndex < oldIndex ? 'slideRight' : 'slideLeft';
            this.firstTime = false;
            this.pulseTabIndex = -1;
        }
    },
    mounted() {
        if (this.useAsBraindatePopup) {
            this.$once('animation-completed', () => {
                // this.activeTabIndex = 1 // NOTE: Automatically move to weekly-tab
                this.pulseTabIndex = 1;
            });
        }
    },
    methods: {
        showSettings() {
            this.showingSettings = true;
        },
        hideSettings(shouldClose = false) {
            this.showingSettings = false;
            if (shouldClose) {
                this.$emit('close');
            }
        }
    }
};
</script>

<style lang="scss" scoped>
$transitionDuration: 0.5s;
$sidePadding: 2em;

.DailyGoalPopup {
    position: relative;
    color: black;
    display: flex;
    width: 23em;
    max-width: 100%;
    height: 32em;
    overflow: hidden;

    &.useAsBraindatePopup {
        .header {
            display: block;
            padding-top: 2em;
        }
        .braindateSection {
            .tabsSection {
                order: 1;
            }
        }

        .tabsSection {
            display: flex;
            justify-content: center;
        }
    }
    &:not(.useAsBraindatePopup) {
        .header {
            padding-bottom: 0;
        }
    }
}

.tabsSection {
    padding: 1em $sidePadding;
}

.braindateSection,
.settingsSection {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    flex-direction: column;

    background-color: white;
}
.braindateSection {
    &.fade-enter-active,
    &.fade-leave-active {
        transition: transform $transitionDuration;
        transform: translateY(0%);
    }
    &.fade-enter,
    &.fade-leave-to {
        transform: translateX(-100%);
    }
}
.settingsSection {
    &.fade-enter-active,
    &.fade-leave-active {
        transition: transform $transitionDuration, opacity $transitionDuration;
        transform: translateY(0%);
    }
    &.fade-enter,
    &.fade-leave-to {
        transform: translateX(100%);
        opacity: 0;
    }
}

.settingsButton {
    .svg-icon {
        $size: 1em;
        width: $size;
        height: $size;
        fill: $completionColor;
    }
}

.body {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.panel {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: mix($completionColor, white, 10%);

    $transitionDuration: 0.5s;
    $distance: 100%;
    &.slideLeft-enter-active,
    &.slideLeft-leave-active,
    &.slideRight-enter-active,
    &.slideRight-leave-active {
        transition: transform $transitionDuration ease-out;
    }
    &.slideLeft-enter,
    &.slideRight-leave-to {
        transform: translate3d($distance, 0, 0);
    }
    &.slideLeft-leave-to,
    &.slideRight-enter {
        transform: translate3d(-$distance, 0, 0);
    }
}

.header {
    display: flex;
    justify-content: space-between;
    padding: 1em $sidePadding;
}
.title {
    text-align: center;
    font-size: 160%;
    font-weight: 600;
}
.subtitle {
    text-align: center;
    color: rgba(black, 0.5);
    margin-top: 0.5em;
}

@include media('>mobile') {
    .DailyGoalPopup {
        &.useAsBraindatePopup {
            width: 23em * 1.2;
            height: 28em * 1.2;
        }
    }
}
</style>
