export default {
    validationMessages: {
        required: 'is required',
        required_no: 'er påkrevd!',

        invalid: 'is not valid',
        invalid_no: 'er ikke gyldig',

        mustBeIdentical: 'must be identical',
        mustBeIdentical_no: 'må være likt',

        minLength: 'must be at least {min} character | must be at least {min} characters',
        minLength_no: 'må ha minst {min} tegn',

        general: 'You have errors, please correct them',
        general_no: 'Du har feil, vennligst rett på dem',

        couponError: 'Your coupon does not exist',
        couponError_no: 'Kupongen eksisterer ikke'
    },

    formLabels: {
        name: 'Name',
        name_no: 'Navn',
        email: 'Email',
        email_no: 'E-post',
        password: 'Password',
        password_no: 'Passord',
        repeatedPassword: 'Repeat password',
        repeatedPassword_no: 'Repeter passord'
    },

    legalDocuments: {
        salesTerms: {
            label: 'Sales Terms',
            label_no: 'Salgsbetingelser',
            url: 'legal-documents/terms-and-conditions-en.md',
            url_no: 'legal-documents/sales-terms-no.md'
        },

        termsOfUse: {
            label: 'Terms of Use',
            label_no: 'Brukerbetingelser',
            url: 'legal-documents/terms-and-conditions-en.md',
            url_no: 'legal-documents/terms-and-conditions-no.md'
        },
        privacyPolicy: {
            label: 'Privacy Policy',
            label_no: 'Personvern',
            url: 'legal-documents/privacy-policy-en.md',
            url_no: 'legal-documents/privacy-policy-no.md'
        }
    },

    AccountCheckoutView: {
        orderSummary: 'Order Summary',
        orderSummary_no: 'Din ordre',

        subscribe: 'Get started', // Place Order',
        subscribe_no: 'Kom i gang', // 'Meld deg inn',
        subscription: 'membership', // 'Subscription',
        subscription_no: 'medlemskap', // 'Abonnement',

        selectedPlan: 'Your plan',
        selectedPlan_no: 'Valgt medlemskap',

        total: 'Total',
        total_no: 'Totalt',
        totalAfterTrial: 'Total (after {trialPeriod} days)',
        totalAfterTrial_no: 'Totalt (etter {trialPeriod} dager)',
        addCoupon: 'Add Coupon',
        addCoupon_no: 'Legg til kupong',
        trialInfo: 'You pay $0 for the first {trialPeriod} days',
        trialInfo_no: 'Du betaler kr 0 for de første {trialPeriod} dagene',
        cancelInfo: 'You can cancel your membership anytime you want.',
        cancelInfo_no: 'Du kan kansellere medlemskapet ditt når du vil.',
        // "customer": {
        //   "legend": "Personal information",
        //   "legend_no": "Personalia",
        //   "first_name": "First name",
        //   "first_name_no": "Fornavn",
        //   "last_name": "Last name",
        //   "last_name_no": "Etternavn",
        //   "email": "Email",
        //   "email_no": "Epost"
        // },
        planNames: {
            month: 'Monthly',
            month_no: 'Månedlig',
            annual: 'Annual',
            annual_no: 'Årlig',
            free: 'Free forever',
            free_no: 'Gratis for alltid'
        },
        planDurations: {
            month: '/month',
            month_no: '/måned',
            annual: '/year',
            annual_no: '/år',
            free: '/forever',
            free_no: '/for alltid'
        },
        planBestValue: 'Save {youSave}',
        planBestValue_no: 'Spar {youSave}',
        billingAddress: {
            legend: 'Address', // 'Billing Address',
            legend_no: 'Adresse', // 'Faktura adresse',
            first_name: 'First name',
            first_name_no: 'Fornavn',
            last_name: 'Last name',
            last_name_no: 'Etternavn',
            line1: 'Address',
            line1_no: 'Addresse',
            city: 'City',
            city_no: 'By',
            state: 'State/Province/Region',
            state_no: 'Fylke',
            zip: 'ZIP/Postal code',
            zip_no: 'Postkode',
            country: 'Country',
            country_no: 'Land',

            blankCountryOption: '-- Select your country --',
            blankCountryOption_no: '-- Velg ditt land --'
        },
        payment: {
            legend: 'Billing Information',
            legend_no: 'Betalingsmetode',
            // coupon: 'Coupon',
            // coupon_no: 'Kupong',
            // coupon_error: 'Your coupon does not exist',
            // coupon_error_no: 'Kupongen eksisterer ikke',
            discount: 'Discount',
            discount_no: 'rabatt',
            discount_percentage: '{{percentage}}% discount first period',
            discount_fixed: 'Discount first period',
            first_name: 'First name',
            first_name_no: 'Fornavn',
            last_name: 'Last name',
            last_name_no: 'Etternavn',
            card_number: 'Card number',
            card_number_no: 'Kortnummer',
            name: 'Cardholder name',
            name_no: 'Fullt navn på kortholder',
            expiry_month: 'Expiry month',
            expiry_month_no: 'Utløpsmåned',
            expiry_year: 'Expiry year',
            expiry_year_no: 'Utløpsår',
            cvv: 'CVV',
            cvv_no: 'CVV',
            cvv_description: 'The last 3 digits on the back',
            cvv_description_no: 'De 3 siste sifrene på baksiden',
            error: 'Please check your credit card details',
            error_no: 'Sjekk dine kortopplysninger'
        },
        // removeCoupon: 'Remove coupon',
        // removeCoupon_no: 'Fjern kupong',
        // couponAddButton: 'Add',
        // couponAddButton_no: 'Legg til',
        // couponChecking: 'Checking...',
        // couponChecking_no: 'Sjekker...',

        testimonial: {
            name: 'Andreas Villand',
            image: '/images/checkout-testimonial.png',
            quote: '“I was surprised at how much I was able to learn in such a short time.”',
            quote_no: '“Jeg var overrasket over hvor mye jeg klarte å lære på såpass kort tid.”'
        },

        whatsnext: {
            title: "What's next?",
            title_no: 'Hva er det neste?',
            text: 'Get ready to start improving your life at super speed – while commuting or exercising',
            text_no:
                'Gjør det klar til å lære ny kunnskap og ferdigheter lynraskt – mens du pendler, trener eller tar en pause i hverdagen'
        },
        sales_terms_pre: 'Buy clicking «Get Started» you agree to our',
        sales_terms_pre_no: 'Ved å klikke på «Kom i gang» er du enig i våre',
        sales_terms_linklabel: 'Terms and Conditions',
        sales_terms_linklabel_no: 'Salgsbetingelser'
    }
};
