import coverImage from './images/cover.jpg';
import appImage from './images/app-icon.png';
import themeSound from './sounds/theme.mp3';
import appMessages from './i18n';

export default {
    windowBackground: '#666',
    background: '#1F1F1F',
    levelItem: {
        gradient: 'rgba(255,255,255,0.1)',
        gradientActive: '#DEC661'
    },
    rankingRow: {
        colorActive: '#DEC661'
    },
    primaryActiveColor: '#E3E69E',
    primaryColor: '#E3E69E',
    textColorOnPrimaryColor: 'black',
    coverImage,
    appImage,
    themeSound,
    appMessages: appMessages
};
