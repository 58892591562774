/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'open-modal': {
    width: 21,
    height: 21,
    viewBox: '0 0 21 21',
    data: '<g fill-rule="evenodd"><path pid="0" d="M20.199 0H5.423c-.44 0-.801.36-.801.801v3.824H.8a.8.8 0 00-.801.8V20.2A.8.8 0 00.801 21h14.774a.8.8 0 00.8-.801v-3.82H20.2c.44 0 .801-.361.801-.802V.801c0-.44-.36-.801-.801-.801zm-5.426 19.398H1.604V6.227h3.019v9.35c0 .44.36.801.8.801h9.352v3.02zm4.625-4.622H6.224V1.602h13.174v13.174z"/><path pid="1" d="M17 4.793v4.909a.794.794 0 01-1.587 0V6.708l-6.058 6.06a.787.787 0 01-.56.232.782.782 0 01-.561-.233.789.789 0 010-1.121l6.058-6.06h-2.993a.794.794 0 010-1.586h4.907a.763.763 0 01.236.037.784.784 0 01.52.521.663.663 0 01.038.235z"/></g>'
  }
})
