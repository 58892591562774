
export function postProcessLanguageBundleData(categories, activeSourceLanguage, activeTargetLanguage) {
    let _processCategory = (category, parents) => {
        // NOTE: temp hack until backend returns translated name on top categories
        if (activeSourceLanguage === 'norwegian') {
            if (category.id === 1) {
                category.name = 'Gloser';
            } else if (category.id === 470) {
                category.name = 'Uttale';
            } else if (category.id === 479) {
                category.name = 'Setninger';
            }
        }

        if (category.id === 580) {
            // For Flytoget category
            category.imageFormat = 'plain';
            category.image = '/memolanguage/images/flytoget-badge.png';
            category.name = 'Memolife Express'; // NOTE: Just until real data is updated
        }

        category.children.forEach(child => {
            if (!child.children.length && child.icon && child.icon.endsWith('similar.svg')) {
                extendWithAdhocLists(child, activeSourceLanguage, activeTargetLanguage);
            }
            parents[category.id] = true;

            if (child.children.length) {
                _processCategory(child, Object.assign({}, parents));
            } else {
                _processList(child, parents);
            }
        });
        category.children = category.children.filter(child => {
            return child.children.length || child.items.length;
        });
    };

    let _processList = (list, parents) => {
        if (list.pronunciations && list.pronunciations.length) {
            list.items = list.pronunciations;
            delete list.pronunciations;
        }
        list.access = 3;
        if (parents['580']) {
            // Lists in flytoget category
            list.access = 1;
        }
        if (parents['13']) {
            // Lists in 'get started'
            list.access = 2;
        }
        // not authenticated: 0
        // temp user: 1,
        // signed up user: 2,
        // subscribed user: 3
    };

    categories.forEach(category => {
        _processCategory(category, {});
    });
    return categories;
}

function extendWithAdhocLists(category, activeSourceLanguage, activeTargetLanguage) {
    category.name = activeSourceLanguage === 'norwegian' ? 'Ord som ligner' : 'Similar Words';

    let numberOfItems = category.items.length;

    if (numberOfItems < 14) {
        return category;
    }

    const preferedGroupSize = 10;
    const maxGroupCount = 10;
    let groups = [];
    let groupCount = 0;

    let addGroupForRemainder = false;

    groupCount = Math.floor(numberOfItems / preferedGroupSize);
    let remainder = numberOfItems % preferedGroupSize;
    if (remainder > 6 && groupCount < maxGroupCount) {
        addGroupForRemainder = true;
    } else if (groupCount === 1) {
        groupCount += 1;
    } else if (groupCount >= maxGroupCount) {
        groupCount = maxGroupCount;
    }

    if (addGroupForRemainder) {
        for (let i = 0; i < groupCount; i++) {
            groups.push(preferedGroupSize);
        }
        groups.push(remainder);
    } else {
        let baseSize = Math.floor(numberOfItems / groupCount);
        remainder = numberOfItems % groupCount;
        for (let i = 0; i < groupCount; i++) {
            groups.push(baseSize + (remainder ? (remainder--, 1) : 0));
        }
    }

    let offset = 0;
    let allItems = category.items;
    category.children = groups.map((size, index) => {
        let adhocId = `${category.id}-${activeSourceLanguage}-${activeTargetLanguage}-${index + 1}`;
        let items = allItems.slice(offset, offset + size);
        offset += size;
        return {
            children: [],
            has_phrases: false,
            id: adhocId,
            useGrandparentInListCompletedPopup: true,
            image: `/memolanguage/images/similar-words/similar-${index + 1}.jpg`,
            items: items,
            name: activeSourceLanguage === 'norwegian' ? `Del ${index + 1}` : `Part ${index + 1}`
        };
    });

    category.items = [];
    category.avoidCategoryView = true;
    return category;
}

/*
{
learning-items: [
  {
  id: <word id, or pronunciation id>
  type: // needed for pronunciation, but probably useful for other types too. 'pronunciation' | 'word' | 'phrase',
  language: 'italian', // Might not be needed...
  article: //
  word: //
  pronunciation: //
  mnemonics: [] // Possibly (optionally) requested in separate query,
  occurs_in_lists: [] //
  tts: //
  ... fields can vary depending on the item type.

  // NOTE: translations prop not needed for pronunciation items
  translations: [ // Usually only one item
    {id, type:, word: ...} // NOTE: does not include mnemonics
  ]
  },
  ...
]
}
*/

export function postProcessWordgroupsData(data, itemIds, activeSourceLanguage, activeTargetLanguage) {
    let targetItemsMap = {};
    let sourceItemsMap = {};
    data.forEach(wordGroupInfo => {
        let targets = [];
        let sources = [];
        wordGroupInfo.words.forEach(word => {
            if (word.language === activeTargetLanguage) {
                if (!targetItemsMap[word.id]) {
                    targetItemsMap[word.id] = {
                        id: word.id,
                        article: word.article,
                        factText: [word.article, word.word].join(' ').trim(),
                        title: [word.article, word.word].join(' ').trim(),
                        word: word.word,
                        conjugation: word.conjugation,
                        pronunciations: word.pronunciations,
                        tts: {
                            male: word.tts_male,
                            female: word.tts_female,
                            slow: word.tts_slow
                        },
                        wtype: word.wtype,
                        language: word.language,
                        fantasies: word.mnemonics.map(mnemonic => postProcessMnemonic(mnemonic, word.id)),
                        quiz: [], // Need?
                        // Need fantasyIndex: 0 ?
                        _sources: new Set()
                    };
                    if (word.is_phrase) {
                        let relatedWords = word.relations || [];
                        relatedWords.sort((a, b) => a.start - b.start);
                        targetItemsMap[word.id].relatedWords = relatedWords;
                    }
                }
                targets.push(word.id);
            }
            if (word.language === activeSourceLanguage) {
                if (!sourceItemsMap[word.id]) {
                    sourceItemsMap[word.id] = {
                        id: word.id,
                        article: word.article,
                        word: word.word
                        // Need factText ?
                        // Need title?
                        // Need type?
                        // Need language?
                    };
                }
                sources.push(word.id);
            }
        });

        targets.forEach(target => {
            sources.forEach(source => {
                targetItemsMap[target]._sources.add(source);
            });
        });
    });

    return itemIds.map(itemId => {
        let itemFixture = targetItemsMap[itemId];
        itemFixture.translations = Array.from(itemFixture._sources).map(sourceId => sourceItemsMap[sourceId]);
        delete itemFixture._sources;
        return itemFixture;
    });
}

export function postProcessPronunciationsData(pronunciations, itemIds, activeSourceLanguage, activeTargetLanguage) {
    let fixtures = [];
    pronunciations
        .filter(p => itemIds.indexOf(p.id) !== -1)
        .filter(p => p.from_language === activeSourceLanguage && p.to_language === activeTargetLanguage)
        .forEach(p => {
            let item = {
                id: p.id,
                model: 'PronunciationModel',
                type: 'pronunciation',
                title: p.word,
                language: p.to_language
            };

            item.examples = p.examples.map(exampleRaw => {
                return {
                    example: exampleRaw.example,
                    pronunciation: exampleRaw.pronunciation,
                    translation: exampleRaw.translation,
                    tts: { male: exampleRaw.tts }
                };
            });

            if (p.examples.length === 1) {
                p.examples.forEach(example => {
                    let memo = {
                        example: example.example,
                        factText: `${p.word} : ${example.example} = ${example.translation}`,
                        pronunciation: example.pronunciation,
                        explanation: p.explanation,
                        translation: example.translation,
                        tts: { male: example.tts },
                        wrongs: [example.wrong1, example.wrong2, example.wrong3].filter(w => w !== null),
                        quiz: [],
                        fantasies: p.mnemonics.map(mnemonic => postProcessMnemonic(mnemonic, 'p-' + p.id))
                    };
                    Object.assign(item, memo);
                });
            } else {
                // logger.warn('Pronunciation missing examples', p)
            }
            fixtures.push(item);
        });
    return fixtures;
}

function createFakeAvatarUrl(mnemonicId, username) {
    let frankAvatar = '/memolanguage/images/frank-avatar.jpg';
    let avatarUrl = '';
    const mapping = {
        Frank: frankAvatar,
        frank: frankAvatar,
        fb_FrankWedde: frankAvatar
    };
    const fakeMapping = [
        '/memolanguage/images/fake-avatars/carole.jpg',
        '/memolanguage/images/fake-avatars/catherine.jpg',
        '/memolanguage/images/fake-avatars/jared.jpg',
        '/memolanguage/images/fake-avatars/jean.jpg',
        '/memolanguage/images/fake-avatars/levi.jpg',
        '/memolanguage/images/fake-avatars/sarah.jpg'
    ];

    avatarUrl = mapping[username];
    if (!avatarUrl && !username) {
        avatarUrl = frankAvatar;
    }
    if (!avatarUrl) {
        avatarUrl = fakeMapping[mnemonicId % fakeMapping.length];
    }
    return avatarUrl;
}

function postProcessMnemonic(mnemonic, itemId) {
    let avatarUrl = createFakeAvatarUrl(mnemonic.id, mnemonic.username);

    return {
        id: mnemonic.id,
        wordId: itemId,
        fantasy: mnemonic.fantasy,
        code: mnemonic.code,
        from_language: mnemonic.from_language,
        public: mnemonic.public,
        username: mnemonic.username,
        videoUrl: mnemonic.video,
        videoPosterUrl: mnemonic.video_thumbnail,
        avatarUrl: avatarUrl
    };
}
