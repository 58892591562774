export default {
    beltGrades: {
        white: 'white',
        white_no: 'hvitt',

        yellow: 'yellow',
        yellow_no: 'gult',

        orange: 'orange',
        orange_no: 'oransje',

        green1: 'green',
        green1_no: 'grønt',
        green2: 'dark green',
        green2_no: 'mørkegrønt',

        blue1: 'blue',
        blue1_no: 'blått',
        blue2: 'dark blue',
        blue2_no: 'mørkeblått',

        brown1: 'brown',
        brown1_no: 'brunt',
        brown2: 'dark brown',
        brown2_no: 'mørkebrunt',

        pink1: 'pink',
        pink1_no: 'rosa',
        pink2: 'dark pink',
        pink2_no: 'mørkerosa',

        red1: 'red',
        red1_no: 'rødt',
        red2: 'dark red',
        red2_no: 'mørkerødt',

        purple1: 'purple',
        purple1_no: 'lilla',
        purple2: 'dark purple',
        purple2_no: 'mørkelilla',

        black: 'black',
        black_no: 'svart',
        infinite: 'black',
        infinite_no: 'svart'
    },

    beltGradesDefinite: {
        white: '@:belts.beltGrades.white',
        white_no: 'hvite',

        yellow: '@:belts.beltGrades.yellow',
        yellow_no: 'gule',

        orange: '@:belts.beltGrades.orange',
        orange_no: 'oransje',

        green1: '@:belts.beltGrades.green1',
        green1_no: 'grønne',
        green2: '@:belts.beltGrades.green2',
        green2_no: 'mørkegrønne',

        blue1: '@:belts.beltGrades.blue1',
        blue1_no: 'blå',
        blue2: '@:belts.beltGrades.blue2',
        blue2_no: 'mørkeblå',

        brown1: '@:belts.beltGrades.brown1',
        brown1_no: 'brune',
        brown2: '@:belts.beltGrades.brown2',
        brown2_no: 'mørkebrune',

        pink1: '@:belts.beltGrades.pink1',
        pink1_no: 'rosa',
        pink2: '@:belts.beltGrades.pink2',
        pink2_no: 'mørkerosa',

        red1: '@:belts.beltGrades.red1',
        red1_no: 'røde',
        red2: '@:belts.beltGrades.red2',
        red2_no: 'mørkerøde',

        purple1: '@:belts.beltGrades.purple1',
        purple1_no: 'lilla',
        purple2: '@:belts.beltGrades.purple2',
        purple2_no: 'mørkelilla',

        black: '@:belts.beltGrades.black',
        black_no: 'svarte',

        infinite: '@:belts.beltGrades.infinite',
        infinite_no: 'svarte'
    }
};
