import axios from 'axios';
import settings from '@/settings';
import {
    postProcessLanguageBundleData,
    postProcessWordgroupsData,
    postProcessPronunciationsData
} from './data-post-processing';
import gql from 'graphql-tag';
import apolloClient from '@/apollo-client';

const bundlesRaw = {};

class MemolanguageDataHelper {
    getLanguageData(sourceLanguage, targetLanguage) {
        const url = `${settings.endpoints.api}/language/category/?source=${sourceLanguage}&target=${targetLanguage}`;
        let storedData = bundlesRaw[url];
        if (storedData) {
            return Promise.resolve(
                postProcessLanguageBundleData(JSON.parse(storedData), sourceLanguage, targetLanguage)
            );
        }
        return axios.get(url).then(response => {
            bundlesRaw[url] = JSON.stringify(response.data);
            return postProcessLanguageBundleData(response.data, sourceLanguage, targetLanguage);
        });
    }

    fetchPronunciationItemsContent({ sourceLanguage, targetLanguage, listIds, itemIds }) {
        return Promise.all(
            listIds.map(listId => {
                const url = `${settings.endpoints.api}/language/category/${listId}/?source=${sourceLanguage}&target=${targetLanguage}`;
                return axios.get(url).then(response => {
                    return response.data;
                });
            })
        )
            .then(dataArr => {
                let seen = {};
                let pronunciations = [];
                dataArr.forEach(d => {
                    d.pronunciations.forEach(p => {
                        if (!seen[p.id]) {
                            pronunciations.push(p);
                            seen[p.id] = true;
                        }
                    });
                });
                return { pronunciations: pronunciations };
            })
            .then(data => {
                return postProcessPronunciationsData(data.pronunciations, itemIds, sourceLanguage, targetLanguage);
            });
    }

    fetchOtherItemsContent({ sourceLanguage, targetLanguage, queryItemIds, itemIds }) {
        const url =
            `${settings.endpoints.api}/language/wordgroup/?source=${sourceLanguage}&target=${targetLanguage}&` +
            queryItemIds.map(wId => `words=${wId}`).join('&');
        return axios.get(url).then(response => {
            return postProcessWordgroupsData(response.data, itemIds, sourceLanguage, targetLanguage);
        });
    }

    fetchLearnedItems(language) {
        const url = `${settings.endpoints.api}/language/learned/?language=${language}`;
        return axios.get(url).then(response => {
            return response.data.reduce((acc, curr, i) => {
                acc[curr.word] = { learnStatus: 1 };
                return acc;
            }, {});
        });
    }

    reportLearnedItems(ids) {
        const url = `${settings.endpoints.api}/language/learned/`;
        let data = ids.map(id => {
            return { word: id };
        });
        return axios.post(url, data).then(response => {
            return response.data;
        });
    }

    fetchFavoriteMnemonics() {
        const url = `${settings.endpoints.api}/language/fav-mnemonic/`;
        return axios.get(url).then(response => {
            return response.data;
        });
    }

    setFavoriteMnemonic(mnemonicId) {
        const url = `${settings.endpoints.api}/language/fav-mnemonic/`;
        return axios.post(url, { mnemonic: mnemonicId }).then(response => response.data);
    }

    getUnlockedPhrases({ targetLanguage }) {
        const url = `${settings.endpoints.api}/language/phrases/?language=${targetLanguage}`;
        return axios.get(url).then(response => {
            return response.data.phrases;
        });
    }

    newFantasy(data) {
        const url = `${settings.endpoints.api}/language/mnemonic/`;
        return axios.post(url, data).then(response => response.data);
    }

    updateFantasy({ id, data }) {
        const url = `${settings.endpoints.api}/language/mnemonic/${id}/`;
        return axios.patch(url, data).then(response => response.data);
    }

    getRepetitions() {
        const url = `${settings.endpoints.repetition}/repetitions/`;
        return axios.get(url).then(response => {
            return response.data;
        });
    }
    createRepetition({ data }) {
        const url = `${settings.endpoints.repetition}/repetitions/`;
        return axios.post(url, data).then(response => response.data);
    }
    updateRepetition({ id, data }) {
        const url = `${settings.endpoints.repetition}/repetitions/${id}/`;
        return axios.put(url, data).then(response => response.data);
    }

    sendXapiStatement(data) {
        const url = `${settings.endpoints.api}/xapi/`;
        return axios.post(url, data).then(response => response.data);
    }

    fetchSettings() {
        return apolloClient
            .query({
                query: gql`
                    query {
                        userSettingsList {
                            key
                            value
                        }
                    }
                `
            })
            .then(result => result.data);
    }

    updateSetting(key, value) {
        const prefix = 'memolanguage:';
        key = `${prefix}${key}`;
        return apolloClient.mutate({
            fetchPolicy: 'no-cache',
            mutation: gql`
                mutation($key: String!, $value: JSON!) {
                    updateUserSetting(input: { _key: $key, _value: $value }) {
                        results {
                            key
                            value
                        }
                    }
                }
            `,
            variables: { key, value }
        });
    }
}

const memolanguageDataHelper = new MemolanguageDataHelper();
export default memolanguageDataHelper;
