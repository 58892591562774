<template>
  <div class="AppDebug">
    <ModuleApp
      class="Module"
      @request-close="$emit('request-close')" />
    <ModuleCourse
      class="Module"
      @request-close="$emit('request-close')" />
    <ModuleNames
      class="Module"
      @request-close="$emit('request-close')" />
    <ModuleMemogenius
      class="Module"
      @request-close="$emit('request-close')" />
  </div>
</template>

<script>
import ModuleApp from '../components/ModuleApp';
import ModuleCourse from '../components/ModuleCourse';
import ModuleMemogenius from '../components/ModuleMemogenius';
import ModuleNames from '../components/ModuleNames';

export default {
    components: { ModuleApp, ModuleCourse, ModuleMemogenius, ModuleNames }
};
</script>

<style lang="scss" scoped>
.AppDebug {
    padding: 1em;
    min-width: 40em - 2em;
}
.Module {
    margin-top: 1em;
}
</style>
