import generateRoutes from '@/modules/systems-shared/generateRoutes';
const App = () => import('./App');
const CategorySelectionView = () =>
    import('@/modules/system-olpa/views/CategorySelectionView');
const BrowseView = () => import('@/modules/systems-shared/views/BrowseView');

export default generateRoutes({
    App,
    path: '/system/double',
    overrideViews: {
        browse: CategorySelectionView
    },
    extraRoutes: [
        {
            props: true,
            path: 'browse/:range',
            component: BrowseView
        }
    ]
});
