<template>
  <div class="DebugMenuPopup">
    <component
      :is="entry.component"
      v-for="entry in entries"
      :key="entry.uid"
      class="Entry"
      :instance="entry.instance"
      @request-close="$emit('request-close')" />
    <div @click="refresh()">
      Refresh
    </div>
  </div>
</template>

<script>
import { menu } from '../debug-plugin';

export default {
    data() {
        return {
            expanded: false,
            entries: [],
            items: [{}, {}]
        };
    },
    computed: {},
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            this.entries = Array.from(menu.entries.values());
        }
    }
};
</script>

<style lang="scss" scoped>
.DebugMenuPopup {
    background-color: white;
    width: 40em;
    overflow-y: auto;
}

.Entry {
    &:not(:last-child) {
        border-bottom: 1px solid #eee;
    }
}
</style>
