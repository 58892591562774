/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'lightning-bolt': {
    width: 512,
    height: 512,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M377.416 204.666a8.945 8.945 0 00-7.152-5.859l-100.394-14.43 46.056-102.799a8.97 8.97 0 00-.669-8.526 8.948 8.948 0 00-7.504-4.078H167.55a8.945 8.945 0 00-8.941 8.625l-6.456 182.688a8.943 8.943 0 007.667 9.167l69.798 10.045-93.387 168.584a8.959 8.959 0 002.595 11.591 8.9 8.9 0 005.234 1.682 8.92 8.92 0 006.582-2.894L375.58 213.717a8.949 8.949 0 001.836-9.051z"/>'
  }
})
