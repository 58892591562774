/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mountain-color': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<g _fill="none" fill-rule="evenodd"><ellipse pid="0" _fill="#30B8F3" cx="31.385" cy="32.491" rx="31.385" ry="31.26"/><path pid="1" d="M59.942 45.053l-8.292-12.51-11.523 18.462-16.77-25.159L8.615 51.005l3.169 5.226 8.661 5.566 9.066 1.866h9.846l7.205-3.82 5.088-3.612 7.021-9.492 1.27-1.686z" _fill="#FFF"/><path pid="2" _fill="#D8D8D8" d="M19.74 32l5.077 6.359-2.538 6.072 7.308 4.8-2.554 7.384 11.403 6.505H27.033l-11.446-4.65-8.202-8.008zM44.92 44.458l6.23 4.318 8.01-5.7 1.27 1.382-4.418 8.485-7.952 5.382-3.14 1.094-4.305-9.101z"/><path pid="3" _stroke="#979797" stroke-width="1.232" _fill="#D8D8D8" d="M23.385 7.385v10.394h15.002l-3.64-5.197 3.64-5.197z"/><path pid="4" d="M25.846 7.385v9.132c5.774.518 9.466.518 11.077 0 1.611-.52.886-1.858-2.177-4.016l3.641-3.62-12.54-1.496z" _fill="#FFF"/><path pid="5" d="M32 0C14.35 0 0 14.35 0 32s14.35 32 32 32 32-14.35 32-32S49.65 0 32 0zm0 2.066c16.504 0 29.934 13.43 29.934 29.934 0 3.805-.718 7.458-2.028 10.81l-8.303-12.712L39.786 48.58 24.769 24.479v-5.896h15.244l-3.717-6.199 3.717-6.198H22.715v18.28L7.32 48.92A29.747 29.747 0 012.066 32C2.066 15.496 15.496 2.066 32 2.066zm-8.265 24.655l20.347 32.655a29.156 29.156 0 01-5.77 1.878L28.17 56.277l3.061-7.143-8.214-5.128 2.986-5.984-5.53-6.135 3.262-5.166zM24.77 8.252h11.59l-2.47 4.132 2.483 4.133h-11.59V8.252h-.013zM8.643 50.696l10.696-16.995 4.195 4.661-3.2 6.4 8.302 5.19-3.137 7.32 9.247 4.536c-.907.088-1.814.126-2.746.126-9.436 0-17.865-4.397-23.357-11.238zm37.316 7.773l-4.964-7.95 10.62-16.617 7.295 11.175A30.118 30.118 0 0145.96 58.47z" _fill="#000" fill-rule="nonzero"/></g>'
  }
})
