/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'practice-section-icon': {
    width: 30,
    height: 29,
    viewBox: '0 0 30 29',
    data: '<g fill-rule="nonzero"><path pid="0" d="M1.09 1.783l.713-.702L3.91 3.217l-.712.702zM.496 5.493l.008-1 2 .014-.008 1zM5.493 2.496l.014-2 1 .008-.014 2zM25.086 24.792l.708-.707 2.12 2.123-.708.707zM23 26h1v2h-1zM27 21h2v1h-2zM3.265 12.802a2.252 2.252 0 00-.206 2.93l-.475.475a2.578 2.578 0 000 3.633l1.59 1.59-.91.908a.976.976 0 000 1.362l1.817 1.817c.37.37.993.37 1.363 0l.908-.908 1.59 1.59a2.578 2.578 0 003.632 0l.476-.476c.878.655 2.135.59 2.93-.206l.909-.908a2.257 2.257 0 000-3.18l-3.18-3.178 4.542-4.541 3.179 3.179a2.257 2.257 0 003.179 0l.908-.909a2.252 2.252 0 00.206-2.93l.475-.476a2.578 2.578 0 000-3.633l-1.59-1.589.909-.908a.976.976 0 000-1.363L23.7 3.265a.976.976 0 00-1.362 0l-.908.908-1.59-1.59a2.578 2.578 0 00-3.633 0l-.475.476a2.252 2.252 0 00-2.93.206l-.909.908a2.257 2.257 0 000 3.179l3.18 3.179-4.542 4.541-3.179-3.179a2.257 2.257 0 00-3.179 0l-.908.909zm.908.908l.908-.908a.947.947 0 011.363 0l9.536 9.536a.947.947 0 010 1.362l-.908.909a.947.947 0 01-1.362 0l-9.537-9.537a.947.947 0 010-1.362zm8.629-8.629l.908-.908a.947.947 0 011.362 0l9.537 9.537a.947.947 0 010 1.362l-.909.908a.947.947 0 01-1.362 0l-9.536-9.536a.947.947 0 010-1.363zm-9.31 12.035l.454-.454 8.174 8.174-.454.454a1.268 1.268 0 01-1.816 0l-6.358-6.358a1.268 1.268 0 010-1.816zm13.17-13.17l.454-.454a1.268 1.268 0 011.816 0L25.29 9.85a1.268 1.268 0 010 1.816l-.454.454-8.174-8.174zM4.4 23.02l.681-.68L6.444 23.7l-.681.682L4.4 23.019zm7.04-7.039l4.54-4.54 1.363 1.362-4.541 4.54-1.363-1.362zM22.337 5.081l.681-.68 1.363 1.362-.682.68-1.362-1.362z"/></g>'
  }
})
