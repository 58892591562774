<template>
  <AnimatedNumbershapeLottie
    v-if="useLottie"
    class="AnimatedNumbershape"
    v-bind="lottieProps" />
  <AnimatedNumbershapeSprite
    v-else
    class="AnimatedNumbershape"
    v-bind="spriteProps" />
</template>

<script>
import Color from 'color';
import AnimatedNumbershapeLottie from './AnimatedNumbershapeLottie';
import AnimatedNumbershapeSprite from './AnimatedNumbershapeSprite';

const lottieNumbers = new Set([
    '3',
    '01',
    '02',
    '04',
    '05',
    '06',
    '07',
    '09',
    '10',
    '11',
    '14',
    '18',
    '19',
    '22',
    '23',
    '26',
    '27',
    '32',
    '33',
    '34',
    '37',
    '41',
    '42',
    '44',
    '48',
    '53',
    '54',
    '57',
    '58',
    '59',
    '63',
    '64',
    '67',
    '69',
    '71',
    '73',
    '74',
    '75',
    '76',
    '79',
    '82',
    '83',
    '85',
    '87',
    '88',
    '89',
    '90',
    '94',
    '95',
    '97',
    '98',
    '99'
]);

export default {
    components: { AnimatedNumbershapeSprite, AnimatedNumbershapeLottie },
    props: {
        number: {
            type: String,
            required: true
        },
        color: {
            type: String,
            default: 'black'
        },
        play: {
            type: Boolean,
            default: false
        },
        reversed: {
            type: Boolean,
            default: false
        },
        paused: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        spriteProps() {
            return {
                number: this.number,
                inverted: Color(this.color).isLight(),
                play: this.play,
                reversed: this.reversed,
                paused: this.paused
            };
        },
        lottieProps() {
            return {
                number: this.number,
                color: this.color,
                play: this.play,
                reversed: this.reversed,
                paused: this.paused
            };
        },
        useLottie() {
            return lottieNumbers.has(this.number);
        }
    }
};
</script>
