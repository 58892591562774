import upperFirst from 'lodash-es/upperFirst';
import settings from '@/settings';

export { getCategoryIdFromLifeskillId, getLifeskillRangeFromCategoryId } from '@/categories';

const { preferredRangeLength: PREFERRED_RANGE_LENGTH, remainderThreshold: REMAINDER_THRESHOLD } = settings.video;

export function chunkArray(myArray, chunkSize) {
    let results = [];
    myArray = myArray.slice();
    while (myArray.length) {
        results.push(myArray.splice(0, chunkSize));
    }
    return results;
}

export function wait(duration) {
    return new Promise(resolve => {
        setTimeout(() => resolve(), duration);
    });
}

export function getOffsetLeft( elem, rootElem )
{
    return getPosition(elem).left - getPosition(rootElem).left;
}

function getPosition(element) {
    var clientRect = element.getBoundingClientRect();
    return {left: clientRect.left + document.body.scrollLeft,
            top: clientRect.top + document.body.scrollTop};
}

export function youtubeDurationToSeconds(duration) {
    let match = duration.match(/PT(\d+H)?(\d+M)?(\d+S)?/);

    let hours = parseInt(match[1], 10) || 0;
    let minutes = parseInt(match[2], 10) || 0;
    let seconds = parseInt(match[3], 10) || 0;

    return hours * 3600 + minutes * 60 + seconds;
}

export function calculateCompletedRangeCount(
    totalLength,
    progress,
    preferredRangeLength = PREFERRED_RANGE_LENGTH,
    remainderThreshold = REMAINDER_THRESHOLD
) {
    let count = Math.floor(progress / preferredRangeLength);
    const remainder = totalLength % preferredRangeLength;
    if (remainder >= remainderThreshold) {
        if (progress >= totalLength) {
            count++;
        }
    } else {
        if (progress < totalLength && totalLength - progress < remainder) {
            count--;
        }
    }
    return count;
}

export function calculateRangesCount(
    totalLength,
    preferredRangeLength = PREFERRED_RANGE_LENGTH,
    remainderThreshold = REMAINDER_THRESHOLD
) {
    let numberOfParts = Math.floor(totalLength / preferredRangeLength);
    if (totalLength % preferredRangeLength >= remainderThreshold) {
        numberOfParts++;
    }
    return numberOfParts || 1;
}
export function calculateVideoPartsCount(duration) {
    return calculateRangesCount(duration, PREFERRED_RANGE_LENGTH, REMAINDER_THRESHOLD);
}

export function calculateRanges(
    totalLength,
    preferredRangeLength = PREFERRED_RANGE_LENGTH,
    remainderThreshold = REMAINDER_THRESHOLD
) {
    let ranges = [];
    let numberOfParts = Math.floor(totalLength / preferredRangeLength);
    let remaining = totalLength % preferredRangeLength;

    for (let i = 0; i < numberOfParts; i++) {
        let start = i * preferredRangeLength;
        ranges.push([start, start + preferredRangeLength]);
    }

    if (numberOfParts && remaining < remainderThreshold) {
        ranges[ranges.length - 1][1] = totalLength;
    } else {
        ranges.push([numberOfParts * preferredRangeLength, totalLength]);
    }
    return ranges;
}

// Time stuff

export function startOfMinute(time) {
    let date = new Date(time);
    date.setSeconds(0, 0);
    return date.getTime();
}
export function startOfDay(time) {
    let date = new Date(time);
    date.setHours(0, 0, 0, 0);
    return date.getTime();
}
export function endOfDay(time) {
    let date = new Date(time);
    date.setHours(23, 59, 59, 999);
    return date.getTime();
}
export function startOfWeek(time) {
    const weekStartsOn = 1;
    let date = new Date(time);
    let day = date.getDay();
    let diff = (day < weekStartsOn ? 7 : 0) + day - weekStartsOn;
    date.setDate(date.getDate() - diff);
    date.setHours(0, 0, 0, 0);
    return date.getTime();
}
export function endOfWeek(time) {
    const weekStartsOn = 1;
    let date = new Date(time);
    let day = date.getDay();
    let diff = (day < weekStartsOn ? -7 : 0) + 6 - (day - weekStartsOn);
    date.setDate(date.getDate() + diff);
    date.setHours(23, 59, 59, 999);
    return date.getTime();
}
export function startOfMonth(time) {
    let date = new Date(time);
    date.setDate(1);
    date.setHours(0, 0, 0, 0);
    return date.getTime();
}
export function endOfMonth(time) {
    let date = new Date(time);
    let month = date.getMonth();
    date.setFullYear(date.getFullYear(), month + 1, 0);
    date.setHours(23, 59, 59, 999);
    return date.getTime();
}

export function copyStringToClipboard(str) {
    // Create new element
    const el = document.createElement('textarea');
    // Set value (string to be copied)
    el.value = str;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute('readonly', '');
    el.style = { position: 'absolute', left: '-9999px' };
    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand('copy');
    // Remove temporary element
    document.body.removeChild(el);
}

export function getStepGroupTitle(stepGroup, $t, $tc) {
    return (
        stepGroup.name ||
        upperFirst(
            $t(`dashboard.general.ordinals.o${stepGroup.index + 1}`) + ' ' + $tc('dashboard.general.theoryJourney')
        )
    );
}
