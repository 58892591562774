<template>
  <div
    v-show="navigating"
    class="RouteNavigationBlockingLayer"
    :class="{ blocking: navigating }">
    <div class="progressbar" />
  </div>
</template>

<script>
export default {
  computed: {
    navigating() {
      return this.$store.state.routerIsNavigating;
    }
  }
};
</script>

<style lang="scss" scoped>
$duration: 15s;

.RouteNavigationBlockingLayer {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    pointer-events: none;
    margin: 2px;
}

.progressbar {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: white;
    animation-name: rollme;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: linear;
}

@keyframes rollme {
  0% { margin-left: -1%; }
  50% { margin-left: 50%; }
  100% { margin-left: 100%; }
}

</style>
