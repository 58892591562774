import * as types from './mutation-types';
import apolloClient from '@/apollo-client';
import gql from 'graphql-tag';

const crystalPlugin = (store) => {
  store.subscribe((mutation, state) => {
    if (mutation.type === 'repetition/' + types.REPETITION_UPDATE) {
      const { app, listId } = mutation.payload;
        apolloClient
            .mutate({
                fetchPolicy: 'no-cache',
                mutation: gql`
                mutation CreateCrystal($context:String, $lifeskill:String, $userId:String!) {
                  __typename
                  createCrystal(input: {crystal: {context: $context, lifeskill: $lifeskill, userId: $userId}}) {
                    clientMutationId
                  }
                }`,
                variables: {
                  context: `${app}:${listId}`,
                  lifeskill: null,
                  userId: store.getters['moduleAuth/profile'].id,
                },
            })
        window.dataLayer.push({
            event: 'crystal',
        });
    }
  });
};

const crystalCount = () => {
  return apolloClient
    .query({
      fetchPolicy: 'no-cache',
      query: gql`
        query CrystalCount {
          __typename
          crystals {
            totalCount
          }
        }
      `,
      variables: {},
    }).then(r => r.data.crystals.totalCount);
}

export default crystalPlugin;
export { crystalCount };
