import apollo from '@/apollo-client'
import gql from 'graphql-tag'
import _ from 'lodash'
import { getSkillMissionById, getHabitMissionById } from '@/store/modules/module-app/missions.js'

async function fetchData(teamId) {
  const result = await apollo.query({
    fetchPolicy: 'no-cache',
    query: gql`
      query TeamLeaderboard($teamId: Int!) {
        __typename
        teamMembersList(condition: { teamId: $teamId }) {
          teamId
          teamName
          displayName
          avatar
          isAdmin
          userId
        }
        teamsList(condition: { id: $teamId }) {
          id
          name
          tags
        }
        teamLeaderboardFullsList(condition: { teamId: $teamId }) {
          avatar
          braindates
          crystals
          displayName
        }
        teamLifekeysList(condition: { teamId: $teamId }) {
          userId
          lifeskillNumber
          context
          lifekeyId
        }
        teamBraindatesList(condition: { teamId: $teamId }) {
          userId
          lifeskillNumber
          activity
          stepId
          courseId
          context
          braindates
        }
      }
    `,
    variables: { teamId },
  })
  return result.data
}

const state = {
  allData: {},
  teamId: null,
  name: '',
  tags: [],
  members: {},
}

const getters = {
  getMemberById: state => memberId => {
    return state.members[memberId]
  },

  missions: state => {
    return state.tags
      .filter(tag => tag.startsWith('skillsMission:'))
      .map(tag => {
        return getSkillMissionById(tag.replace('skillsMission:', ''))
      })
      .filter(m => m)
  },

  habitMissions: state => {
    return state.tags
      .filter(tag => tag.startsWith('habitMission:'))
      .map(tag => {
        return getHabitMissionById(tag.replace('habitMission:', ''))
      })
      .filter(m => m)
  },

  primaryTag: state => {
    return state.tags.filter(tag => !tag.includes(':'))[0]
  },

  isLoaded: state => {
    return !!state.teamId
  },

  teamLeaderboardFullsList: state => {
    const list = state.allData.teamLeaderboardFullsList?.slice() ?? []
    const byLifepoints = (a, b) => b.braindates + b.crystals - (a.braindates + a.crystals)
    list.sort(byLifepoints)
    return list
  },

  getLifeskillStats: (state, getters, rootState, rootGetters) => lifeskillId => {
    const personalStats = rootGetters['moduleApp/getLifeskillStats'](lifeskillId)
    const stats = personalStats

    const memberCount = state.allData.teamMembersList?.length ?? 0

    const mapping = _.chain(state.allData.teamLifekeysList ?? [])
      .filter(item => item.lifeskillNumber === lifeskillId)
      .groupBy(item => item.context.split(':')[0])
      .value()

    // NOTE: Team data should be validated against course data, and lifekeys to non-existing items should be ignored

    const aquiredLifekeys =
      ((mapping['habit-journey']?.length ?? 0) +
        (mapping['theory-journey']?.length ?? 0) +
        (mapping[`${lifeskillId}-video1`]?.length ?? 0) +
        (mapping[`${lifeskillId}-video2`]?.length ?? 0)) /
      memberCount

    const totalLifekeys = stats.totalTheoryLifekeys + stats.totalPracticeLifekeys + stats.totalVideoLifekeys
    return {
      totalLifekeys,
      aquiredLifekeys,
      progress: totalLifekeys ? aquiredLifekeys / totalLifekeys : 0,
    }
  },

  getLifeskillsMissionStats: (state, getters) => {
    const mission = getters.missions[0]
    if (!mission) {
      return {
        progress: 0,
      }
    }
    // find lifeskills for mission
    let accumulatedProgress = 0
    mission.lifeskills.forEach(lifeskillId => {
      const lifeskillStats = getters.getLifeskillStats(lifeskillId)
      accumulatedProgress += lifeskillStats.progress
    })

    return {
      progress: accumulatedProgress / mission.lifeskills.length,
    }
  },
}

const actions = {
  async load({ commit }, _teamId) {
    const teamId = parseInt(_teamId)
    const data = await fetchData(teamId)
    commit('setAllData', data)
    return data
  },
}

const mutations = {
  setAllData(state, allData) {
    state.allData = allData

    const team = allData.teamsList[0]
    if (team) {
      state.teamId = team.id
      state.name = team.name
      state.tags = team.tags ?? []
      // state.members = _.keyBy(allData.teamMembersList, 'userId')
      state.members = {
        m1: {
          name: 'John',
          progress: Math.random(),
          braindates: [],
        },
        m2: {
          name: 'Jane',
          progress: Math.random(),
          braindates: [],
        },
        m3: {
          name: 'Jill',
          progress: Math.random(),
          braindates: [],
        },
      }
    } else {
      state.teamId = null
      state.name = ''
      state.tags = []
      state.members = {}
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
