<template>
  <div
    class="GoalOption"
    :class="{ selected, saving }">
    <div
      class="circle --global-clickable"
      @click="$emit('click')">
      <div class="innerCircle" />
      <div class="timeLabel">
        {{ numberOfBraindates * averageBraindateDuration }}'
      </div>
    </div>
    <div class="label">
      {{ numberOfBraindates }} {{ $tc('dashboard.general.braindate', numberOfBraindates) }}
    </div>
  </div>
</template>

<script>
import settings from '@/settings';

export default {
    props: {
        numberOfBraindates: {
            type: Number,
            required: true
        },
        selected: {
            type: Boolean,
            required: true
        },
        saving: {
            type: Boolean,
            required: true
        }
    },
    created() {
        this.averageBraindateDuration = settings.averageBraindateDuration;
    }
};
</script>

<style lang="scss" scoped>
$transitionDuration: 0.3s;

.GoalOption {
    &.selected {
        .circle {
            position: relative;
            background-color: $standardButtonColor;
            color: white;
        }
    }
    &:not(.selected) {
        .innerCircle {
            opacity: 0;
            transform: scale(0.7);
        }
    }
    &.saving {
        .circle {
            animation: bulge 0.5s;

            @keyframes bulge {
                0% {
                    transform: scale(1);
                }
                50% {
                    transform: scale(1.1);
                }
                100% {
                    transform: scale(1);
                }
            }
        }
    }
}

.circle {
    $size: 4em;
    position: relative;
    margin: auto;
    width: $size;
    height: $size;
    border: 2px solid $standardButtonColor;
    color: $standardButtonColor;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color $transitionDuration;
}
.innerCircle {
    $offset: 2px;
    position: absolute;

    top: $offset;
    right: $offset;
    bottom: $offset;
    left: $offset;
    border-radius: 50%;
    border: 1px solid rgba(white, 0.5);
    background-color: $standardButtonColor;
    transition: opacity $transitionDuration, transform $transitionDuration;
}
.timeLabel {
    position: relative;
    font-weight: 600;
    font-size: 120%;
}
.label {
    margin-top: 0.5em;
    font-size: 90%;
    color: rgba(black, 0.7);
    text-align: center;
}
</style>
