/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'heart-smile': {
    width: 66,
    height: 60,
    viewBox: '0 0 66 60',
    data: '<path pid="0" d="M48.208.909C41.679.909 35.991 4.498 33 9.808 30.009 4.498 24.321.909 17.792.909 8.156.909.345 8.72.345 18.356c0 22.55 21.847 38.6 32.656 40.735 10.808-2.135 32.656-18.185 32.656-40.735C65.656 8.72 57.844.909 48.208.909zM18.303 25a3.777 3.777 0 117.554 0 3.777 3.777 0 01-7.554 0zM44.74 36.32c-1.768 4.958-6.485 8.289-11.739 8.289-5.255 0-9.973-3.331-11.74-8.289a1 1 0 011.884-.672A10.487 10.487 0 0033 42.609c4.41 0 8.371-2.797 9.854-6.961a1 1 0 011.886.672zm-.82-7.543a3.777 3.777 0 110-7.554 3.777 3.777 0 010 7.554z" fill-rule="evenodd"/>'
  }
})
