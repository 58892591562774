import englishLifeAreas from '@/assets/lifeareas_en.json';
import norwegianLifeAreas from '@/assets/lifeareas_no.json';

const lifeskillLabels = {};

englishLifeAreas.forEach(({ number, data }) => {
    lifeskillLabels[number] = data.life_area;
});
norwegianLifeAreas.forEach(({ number, data }) => {
    lifeskillLabels[`${number}_no`] = data.life_area;
});

export default lifeskillLabels;
