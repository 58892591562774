<template>
  <div class="QuizItemDebug">
    QuizItemDebug
    <br>
    <ButtonPlain
      label="Cheat"
      @click="cheat()" />
  </div>
</template>

<script>
import ButtonPlain from '../components/ButtonPlain';

export default {
    components: { ButtonPlain },
    props: ['instance'],
    methods: {
        cheat() {
            this.instance.$refs.test.cheat();
            this.$emit('request-close');
        }
    }
};
</script>

<style lang="scss" scoped>
.QuizItemDebug {
    padding: 1em;
}
</style>
