/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fullscreen': {
    width: 92,
    height: 92,
    viewBox: '0 0 92 92',
    data: '<g fill-rule="evenodd"><path pid="0" d="M86 92H64a6 6 0 010-12h16V64a6 6 0 0112 0v22a6 6 0 01-6 6zM28 92H6a6 6 0 01-6-6V64a6 6 0 0112 0v16h16a6 6 0 010 12zM6 34a6 6 0 01-6-6V6a6 6 0 016-6h22a6 6 0 010 12H12v16a6 6 0 01-6 6zM86 34a6 6 0 01-6-6V12H64a6 6 0 010-12h22a6 6 0 016 6v22a6 6 0 01-6 6z"/></g>'
  }
})
