/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'notification': {
    width: 18,
    height: 23,
    viewBox: '0 0 18 23',
    data: '<path pid="0" d="M16.07 15.805V9.976c0-.108-.01-.208-.01-.316a7.8 7.8 0 00-2.3-5.369c-.07-.072-.15-.144-.22-.216a6.765 6.765 0 00-2.61-1.538v-.172a1.97 1.97 0 10-3.86 0v.172a6.765 6.765 0 00-2.61 1.538c-.07.072-.15.144-.22.216a7.8 7.8 0 00-2.3 5.369c-.01.108-.01.208-.01.316v5.829L0 18.012v2.113h5.85a3.163 3.163 0 006.3 0H18v-2.113z"/>'
  }
})
