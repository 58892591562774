export default {
  currentId: 'news9',
  previousIds: [],
  meta: {
    news1: {
      timestamp: 1577236143316,
      languages: ['no', 'en']
    },
    news2: {
      timestamp: 1580605566742,
      languages: ['no']
    },
    news3: {
      timestamp: 1583072512685,
      languages: ['no']
    },
    news4: {
      timestamp: 1583466272890,
      languages: ['no']
    },
    news5: {
      timestamp: 1590066574653,
      languages: ['no']
    },
    news6: {
      timestamp: 1591755018014,
      languages: ['no']
    },
    news7: {
      timestamp: 1594000549510,
      languages: ['no']
    },
    news8: {
      timestamp: 1596016182888,
      languages: ['no']
    },
    news9: {
      timestamp: 1596016182999,
      languages: ['no']
    }
  }
}
