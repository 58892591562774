/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'game-icon-2': {
    width: 19,
    height: 11,
    viewBox: '0 0 19 11',
    data: '<path pid="0" d="M13.366 0H5.634C2.523 0 0 2.463 0 5.5c0 2.693 1.984 4.93 4.602 5.403l8.764.097C16.477 11 19 8.537 19 5.5S16.477 0 13.366 0zM13.2 2.653c.546 0 .99.432.99.966a.979.979 0 01-.99.967.978.978 0 01-.99-.967c.001-.534.443-.966.99-.966zM8.654 6.41H6.7v1.906H4.835V6.411H2.882V4.59h1.953V2.683H6.7v1.906h1.953V6.41zm2.62.055a.978.978 0 01-.99-.966c0-.533.443-.967.99-.967.547 0 .99.434.99.967a.978.978 0 01-.99.966zM13.2 8.347a.978.978 0 01-.99-.966c0-.533.443-.966.99-.966.546 0 .99.433.99.966a.978.978 0 01-.99.966zm1.927-1.88a.978.978 0 01-.99-.967.98.98 0 01.99-.967c.547 0 .99.434.99.967a.977.977 0 01-.99.966z" fill-rule="nonzero"/>'
  }
})
