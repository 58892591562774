<template>
  <div class="TeamKey">
    <div class="TextButtonContainer">
      {{ $t('invited') }}
      <div class="button" @click.prevent="join()">{{ $t('accept') }} </div>
    </div>
  </div>
</template>

<translations>
  invited: You've been invited to join a team
  invited_no: Du har blitt invitert til et team
  accept: Accept
  accept_no: Aksepter
</translations>

<script>
import gql from 'graphql-tag'
import apollo from '@/apollo-client'

export default {
  filters: {},
  props: {
    teamKeyId: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {}
  },

  computed: {},

  mounted() {},

  methods: {
    join() {
      return apollo
        .mutate({
          mutation: gql`
            mutation MyMutation($teamKeyId: UUID!) {
              __typename
              teamKeyUnlock(input: { teamKeyId: $teamKeyId }) {
                clientMutationId
              }
            }
          `,
          variables: {
            teamKeyId: this.teamKeyId,
          },
        })
        .then(() => {
          this.$router.push({ name: 'DashboardHomeView' })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.TeamKey {
  display: flex;
  justify-content: center;
  align-items: center;
}
.TextButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.3em;
  color: white;
}
.button {
  display: flex;
  justify-content: center;
  cursor: pointer;
  color: white;
  background-color: #295dbc;
  margin-top: 1.2em;
  padding: 0.7em;
  font-size: 1em;
  width: 8em;
  border-radius: 0.5em;
}
</style>
