/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pen-memorytip': {
    width: 73,
    height: 70,
    viewBox: '0 0 73 70',
    data: '<g fill-rule="evenodd"><path pid="0" d="M19.5 69.1c.6 0 1-.4 1-1s-.4-1-1-1H6.7c4.8-1.6 11.4-4 11.5-4l49-49c2.7-2.7 2.7-7.2 0-9.9l-1.9-2c-1.3-1.3-3.1-2-5-2s-3.6.7-5 2l-49 49C6.2 51.3.7 67.4.7 67.4c-.1.3 0 .7.2.9.1.4.5.7.9.7h17.7v.1zm1.6-11.7c-.6.5-1.5.5-2.1 0-.3-.3-.4-.7-.4-1.1 0-.4.2-.8.4-1.1L56.3 18l2.1 2.1-37.3 37.3zM56.8 3.7c.9-.9 2.2-1.5 3.5-1.5 1.3 0 2.6.5 3.5 1.5l2 1.9c2 2 2 5.1 0 7.1L63.5 15l-9-9 2.3-2.3zm-2 12.9L17.6 53.8c-.6.6-1.5.6-2.1 0-.3-.3-.4-.7-.4-1.1 0-.4.2-.8.4-1.1l37.2-37.2 2.1 2.2zM12.1 48.4l37.3-37.3 1.9 1.9-37.2 37.3c-.5.5-1.4.5-2 0-.5-.5-.5-1.3 0-1.9zM8 52.5l2-2c.2.4.4.8.8 1.2.6.6 1.5 1 2.3 1v.1c0 .9.4 1.8 1 2.5.7.7 1.6 1 2.5 1 0 .9.4 1.8 1 2.5.4.4.8.6 1.3.8L17 61.5l-6.8 2.3c-.1-1.1-.7-2.2-1.5-3-.8-.9-1.9-1.4-3.1-1.5L8 52.5zM24.9 67.1c-.6 0-1 .4-1 1s.4 1 1 1H30c.6 0 1-.4 1-1s-.4-1-1-1h-5.1zM36 67.1c-.6 0-1 .4-1 1s.4 1 1 1h15.1c.6 0 1-.4 1-1s-.4-1-1-1H36zM71.1 63.1H66c-.6 0-1 .4-1 1s.4 1 1 1h5.1c.6 0 1-.4 1-1s-.4-1-1-1zM61 64.1c0-.6-.4-1-1-1H44.9c-.6 0-1 .4-1 1s.4 1 1 1H60c.6 0 1-.4 1-1z"/></g>'
  }
})
