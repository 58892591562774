<template>
  <BlockContainer
    class="Step"
    type="Step"
    :label="stepId"
    :progress="step.progress">
    <ButtonPlain
      label="Mark all"
      @click="markAll()" />
    <!-- <ButtonPlain
      label="Clear all"
      @click="clearAll()"
    /> -->
    <ButtonPlain
      label="Print"
      @click="print()" />
    <div class="activities">
      <component
        :is="activityComponent"
        v-for="{ activityComponent, activityName } of activityItems"
        :key="activityName"
        class="Activity"
        :step-id="stepId"
        :activity-name="activityName" />
    </div>

    <div class="scenarios">
      <Scenario
        v-for="(scenarioId, i) of step.scenarioIds"
        :key="i"
        class="Scenario"
        :step-id="stepId"
        :scenario-id="scenarioId"
        :scenario-index="i" />
    </div>
  </BlockContainer>
</template>

<script>
import BlockContainer from '../BlockContainer';
import ButtonWithCheckmark from '../ButtonWithCheckmark';
import ButtonPlain from '../ButtonPlain';
import Scenario from './Scenario';

import ActivityRead from './ActivityRead';
import ActivityMemorize from './ActivityMemorize';
import ActivityQuiz from './ActivityQuiz';

const activityComponentMap = {
    read: ActivityRead,
    memorize: ActivityMemorize,
    quiz: ActivityQuiz,
    memorize2: ActivityMemorize,
    quiz2: ActivityQuiz
};

export default {
    components: { Scenario, ButtonWithCheckmark, ButtonPlain, BlockContainer, ActivityRead },
    props: {
        stepId: {
            type: String,
            required: true
        }
    },
    computed: {
        step() {
            return this.$store.state.moduleCourse.steps[this.stepId];
        },
        activityItems() {
            return this.step.activityNames.map(activityName => {
                return {
                    activityComponent: activityComponentMap[activityName],
                    activityName
                };
            });
        }
    },
    methods: {
        markAll() {
            this.$store.dispatch('moduleCourse/dbgStepMarkAll', { stepId: this.stepId });
        },
        // clearAll () {
        //   this.$store.dispatch('moduleCourse/dbgStepMarkAll', { stepId: this.stepId, reset: true })
        // },
        print() {
            console.log(`Step data for ${this.stepId}:\n`, JSON.stringify(this.step, null, '  ')); // eslint-disable-line no-console
        }
    }
};
</script>

<style lang="scss" scoped>
.activities {
}
.Activity {
    margin-top: 1em;
}
.memoDetails {
    border: 1px solid gray;
    display: flex;
}
.scenarios {
    display: flex;
    margin-top: 1em;
}
.Scenario {
    margin: 3px;
}
.Memo {
    margin-top: 1em;
}
</style>
