<template>
  <BlockContainer
    class="ModuleApp"
    type="Module"
    label="moduleApp">
    <div>
      Set memomaps-data endpoint <input v-model="memomapsDataEndpoint"><button
        @click="setMemomapsDataEndpoint(memomapsDataEndpoint)">
        Set
      </button>
    </div>
    <br>
    <div>
      Goto lifeskill page <input v-model="lifeskillId"><button
        @click="
          $router.push({ name: 'DashboardLifeskillView', params: { lifeskillId } });
          $emit('request-close');
        ">
        Go
      </button>
    </div>

    <div class="buttons">
      <ButtonPlain
        :label="`useQuizCheatMode: ${$store.state.moduleApp.debugSettings.useQuizCheatMode}`"
        @click="$store.dispatch('moduleApp/dbgToggleQuizCheatMode')" />

      <ButtonWithCheckmark
        label="noFullscreenReadOnMobile"
        :done="$store.state.moduleApp.debugSettings.noFullscreenReadOnMobile"
        @click="toggleDebugSetting('noFullscreenReadOnMobile')" />
      <ButtonWithCheckmark
        label="noActivityTransitions"
        :done="$store.state.moduleApp.debugSettings.noActivityTransitions"
        @click="toggleDebugSetting('noActivityTransitions')" />
      <ButtonWithCheckmark
        label="doNotStartQuizAutomatically"
        :done="$store.state.moduleApp.debugSettings.doNotStartQuizAutomatically"
        @click="toggleDebugSetting('doNotStartQuizAutomatically')" />

      <ButtonPlain
        label="Open Braindate Popup"
        @click="openBraindatePopup()" />

      <ButtonPlain
        label="Hide debug menu"
        @click="$store.dispatch('moduleApp/toggleDebugButton')" />
      <ButtonPlain
        label="goto games"
        @click="
          $router.push('/games/');
          $emit('request-close');
        " />
      <ButtonPlain
        label="goto systems"
        @click="
          $router.push('/systems/');
          $emit('request-close');
        " />
      <ButtonPlain
        label="goto social"
        @click="
          $router.push('/social/overview');
          $emit('request-close');
        " />
      <ButtonPlain
        label="goto memoransier admin"
        @click="
          $router.push('/memoransier/admin');
          $emit('request-close');
        " />

      <ButtonPlain
        :label="`toggle language [${locale}]`"
        @click="$store.dispatch('moduleApp/setLocaleNoReload', locale === 'en' ? 'no' : 'en')" />
      <ButtonWithCheckmark
        label="(local) unlock all content"
        :done="$store.state.moduleApp.debugSettings.unlockAllContent"
        @click="toggleUnlockAllContent()" />
      <ButtonPlain
        label="Change user"
        @click="changeUser()" />
      <ButtonPlain
        label="print profile"
        @click="printProfile()" />
      <ButtonPlain
        label="Pretend outdated app"
        @click="$store.commit('moduleApp/updateAppVersionInfo', { gitRevision: 'NEWER' })" />
      <ButtonPlain
        label="Unpretend"
        @click="
          $store.commit('moduleApp/updateAppVersionInfo', {
            gitRevision: $store.state.moduleApp.clientsideGitRevision
          })
        " />

      <ButtonPlain
        label="Reset dailyRequiredBraindates"
        @click="
          $store.commit(`moduleApp/SET_DAILY_REQUIRED_BRAINDATES`, {
            value: 3,
            dailyRequiredBraindatesNotSetByUser: true
          });
          $emit('request-close');
        " />
      <ButtonPlain
        label="Reset getting-started focus exit"
        @click="$store.dispatch(`moduleApp/markDidExitGettingStartedFocus`, { reset: true })" />

      <ButtonPlain
        label="TestQuizReporting"
        @click="testQuizReporting()" />
      <ButtonWithCheckmark
        label="Enable all standalone courses"
        :done="$store.state.moduleApp.debugSettings.showAllStandaloneCourses"
        @click="toggleEnableAllStandaloneCourses()" />

      <ButtonPlain
        label="ResetOnboardings"
        @click="resetOnboardingStates()" />
    </div>
    <br>
    <div>
      <span>Belt-stuff: </span>
      <ButtonPlain
        label="inc"
        @click="lifekeysInc()" />

      <ButtonPlain
        label="dec"
        @click="lifekeysDec()" />

      <ButtonPlain
        label="add lk"
        @click="addLifekey()" />
      <ButtonPlain
        label="add double lk"
        @click="addDoubleLifekey()" />

      <ButtonPlain
        label="trigger popup"
        @click="triggerBeltPopup()" />
    </div>
  </BlockContainer>
</template>

<script>
import axios from 'axios';
import storage from '@/store/persistence/storage';
import settings, { MEMOMAPS_ENDPOINT_KEY } from '@/settings';
import BraindatePopup from '@/modules/dashboard/components/BraindatePopup';

import ButtonPlain from '../ButtonPlain';
import ButtonWithCheckmark from '../ButtonWithCheckmark';
import BlockContainer from '../BlockContainer';
import { EventBus } from '@/event-bus';

export default {
    components: { BlockContainer, ButtonPlain, ButtonWithCheckmark },
    props: ['instance'],
    data() {
        return {
            memomapsDataEndpoint: storage.getItem(MEMOMAPS_ENDPOINT_KEY) || '',
            lifeskillId: '00'
        };
    },
    computed: {
        locale() {
            return this.$store.getters['moduleApp/locale'];
        }
    },
    methods: {
        changeUser() {
            let q = prompt('Enter userid or email'); // eslint-disable-line no-alert
            this.$store.dispatch('moduleAuth/reAuth', { q, reload: true });
        },
        setMemomapsDataEndpoint(url) {
            if (url.trim()) {
                storage.setItem(MEMOMAPS_ENDPOINT_KEY, url.trim());
            } else {
                storage.removeItem(MEMOMAPS_ENDPOINT_KEY);
            }
            location.reload();
        },
        testQuizReporting() {
            let url = `${settings.endpoints.api}/course/quizreport/`;
            axios.post(url, {
                name: 'navnet på journey',
                correct: 6,
                total: 10,
                time: 20000
            });
        },
        toggleDebugSetting(key) {
            this.$store.commit('moduleApp/UPDATE_DEBUG_SETTINGS', {
                key,
                value: !this.$store.state.moduleApp.debugSettings[key]
            });
        },
        toggleEnableAllStandaloneCourses() {
            this.$store.dispatch('moduleApp/dbgToggleShowAllStandaloneCourses');
        },
        toggleUnlockAllContent() {
            this.$store.dispatch('moduleApp/dbgToggleUnlockAllContent');
        },
        openBraindatePopup() {
            this.$emit('request-close');
            this.$modal.open({
                target: 'app-modal',
                wrap: true,
                props: {
                    lifeskillId: '11',
                    goal: this.$store.state.moduleApp.memolife.dailyRequiredBraindates,
                    count: this.$store.getters['moduleApp/braindatesForCurrentDay'].length
                },
                hostOptions: { alignment: 'bottom' },
                component: BraindatePopup
            });
        },
        resetOnboardingStates() {
            this.$store.dispatch('moduleApp/markFavoriteLifeskillsSelected', { reset: true });
            this.$store.dispatch('moduleApp/markDashboardOnboardingAsShown', { reset: true });
            this.$store.dispatch('moduleApp/markMemomapsOnboardingAsShown', { reset: true });
            this.$store.dispatch('moduleApp/markMemoOnboardingAsShown', { reset: true });
        },
        printProfile() {
            console.log('profile: ', JSON.stringify(this.$store.state.moduleAuth.profile, null, '  '));
        },

        // Belt stuff
        lifekeysInc() {
            this.$store.state.moduleApp.lifekeys.push({});
        },
        lifekeysDec() {
            this.$store.state.moduleApp.lifekeys.pop();
        },
        addLifekey() {
          EventBus.$emit('new-lifekey',
            { lifeskillId: '48',
              lifekeyCount: 2,
              context: 'theory-journey:4e6197cf-e2e1-4d77-8442-3749affb88a3',
              lifekeyType: 'theory',
              continueToDashboard: false,
              belt: this.$store.getters['moduleApp/belt'],
            });

        },
        addDoubleLifekey() {
          EventBus.$emit('new-lifekey',
            { lifeskillId: '40',
              lifekeyCount: 1,
              context: 'theory-journey:0e2ed63b-3ce2-4761-a8e9-90e79930afbf ',
              lifekeyType: 'theory',
              continueToDashboard: false,
            });
          EventBus.$emit('new-lifekey',
            { lifeskillId: '42',
              lifekeyCount: 1,
              context: 'theory-journey:0e2ed63b-3ce2-4761-a8e9-90e79930afbf ',
              lifekeyType: 'theory',
              continueToDashboard: false,
            });
        },
        triggerBeltPopup() {
            EventBus.$emit('new-lifekey', {});
        }
    }
};
</script>

<style lang="scss" scoped>
.buttons {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1em;

    .ButtonPlain {
        margin: 0.2em;
    }
}
</style>
