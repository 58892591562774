/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'key-3': {
    width: 61,
    height: 59,
    viewBox: '0 0 61 59',
    data: '<g fill-rule="evenodd"><path pid="0" d="M30.151 8.947c.253 4.545-1.291 11.9-1.612 15.97l7.071 7.07c.472.477.943.477 1.415 0l6.364-6.363c.927-.937 2.532-.858 3.46.078l1.956 1.99c.927.937 1.167 2.652.24 3.589l-6.364 6.364c-.471.476-.472.937 0 1.414l4.597 4.596c.472.477 1.296.83 1.767.354l6.364-6.364c.928-.937 1.976-1.28 2.903-.343l1.957 1.99c.927.937 1.017 2.366.09 3.302l-7.072 7.071 2.83 2.829a3 3 0 010 4.242l-.708.708a3 3 0 01-4.242 0l-14.85-14.85a3.013 3.013 0 01-.387-.475L23.59 29.866c-4.08.409-9.89.77-14.305.157C-.24 28.712-4.503 14.19 10.717 10.666a.355.355 0 00.22-.221c3.91-16.923 18.827-8.836 19.214-1.498zm-15.678 5.99c-7.364 1.818-3.961 8.307-.785 8.446 2.086.07 8.76-.328 8.76-.63.119-.947.913-5.278.506-8.656-.523-4.162-6.77-5.946-8.394.752 0 .035-.052.088-.087.088z" fill-rule="nonzero"/><path pid="1" d="M54.349 48.605c-.707.707-.707 1.414 0 2.121.707.707-.236.707-2.829 0l.707-2.121h2.122z"/></g>'
  }
})
