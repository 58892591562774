<template>
  <div class="AuraPopup">

    <h2 class="header" :class="{show:showHeader}">
      <span v-if="energyLevel === 'green'">{{ $t('green') }}</span>
      <span v-if="energyLevel !== 'green'">{{ $t('yay') }}</span>
    </h2>

    <AuraAnimation class="auraRiveAnimation" :energy-level="energyLevel" />

    <div class="bottomLayoutWrapper" :class="{show:showBottomLayout}">

      <div class="energy" :class="{memoDialog: energyLevel === 'green'}">
        <div v-if="energyLevel === 'green'">
          {{ $t(memomanDialog) }}
        </div>
        <div v-if="energyLevel === 'yellow'">
          {{ $tc('repsLeft', repsLeft, { repsLeft }) }}
        </div>
        <div v-if="energyLevel === 'orange'">
          {{ $tc('repsLeft', repsLeft, { repsLeft }) }}
        </div>
        <div v-if="energyLevel === 'red'">
          {{ $tc('repsLeft', repsLeft, { repsLeft }) }}
        </div>
      </div>

      <div class="neurons">
        <div
          :key="n"
          v-for="n in range(dailyGoal)"
          class="neuron"
          :class="{completed:completed(n) }">
          <img 
            v-if="completed(n)"
            :src="neuronCompleted" height="35" width="35"
            :alt="n">
          <img 
            v-if="!completed(n)"
            :src="neuronNotCompleted" height="30" width="30">
        </div>
      </div>

      <div
        @click="gotoNext()"
        class="button"
        :class="{show: showButton}">
        <span v-if="energyLevel === 'green'">{{ $t('okGotIt') }}</span>
        <span v-if="energyLevel !== 'green'">{{ $t('continue') }}</span>
      </div>

    </div>
  </div>
</template>

<translations>
  green: Your aura is crystal clear!
  green_no: Auraen din er krystallklar!

  memoriesSafe: 'Your memories are safe ...'
  memoriesSafe_no: 'Hukommelsen din er trygg ...'

  thanksToMe: '... thanks to me!'
  thanksToMe_no: '... takket være meg!'

  nowGo: 'Now, go master new skills!'
  nowGo_no: 'Nå, lær noen nye ferdigheter!'

  repsLeft: Your aura is stronger. Light up {repsLeft} more memory crystal to make it perfect. | Your aura is stronger. Light up {repsLeft} more memory crystals to make it perfect.
  repsLeft_no: Auraen din er sterkere. Lys opp {repsLeft} huskekrystall til for å gjøre den perfekt. | Lys opp {repsLeft} huskekrystaller til for å gjøre auraen perfekt.

  continue: Next repetition
  continue_no: Neste repetisjon

  okGotIt: Ok, got it!
  okGotIt_no: Ok, er på saken!

  yay: Yay, you got a crystal!
  yay_no: Yay, du fikk en krystall!
</translations>

<script>
import { mapGetters } from 'vuex';
import AuraAnimation from './RiveAuraPopup.vue'
import neuronCompleted from './neuron-completed.svg';
import neuronNotCompleted from './neuron-not-completed.svg';

export default {
  components: { AuraAnimation },
  data: function() {
    return {
      showHeader: false,
      showBottomLayout: false,
      showButton: false,
      memomanDialog: '',
    }
  },

  mounted() {
    this.animatePopupLayout();
  },

  methods: {

    animatePopupLayout() {
      if(this.energyLevel === 'green'){
         this.animateBottomLayout();
         this.revelHeader();
         this.setMemomanDialog('memoriesSafe', 10000)
         this.setMemomanDialog('thanksToMe', 13500)
         this.setMemomanDialog('', 16000)
         this.setMemomanDialog('nowGo', 16500)
         this.revealButton(19500)
       } else {
         this.revelHeader();
         this.animateBottomLayout();
         this.revealButton(7000)
       }
    },

    revealButton(timer){
      setTimeout(() => {
        this.showButton = true;
      }, timer);
    },

    setMemomanDialog(text, timer){
      setTimeout(() => {
           this.memomanDialog = text;
      }, timer);
    },

    revelHeader(){
      setTimeout(() => {
           this.showHeader = true;
       }, 2000);
    },

    animateBottomLayout(){
      setTimeout(() => {
        this.showBottomLayout = true;
      }, 6000);
    },

    completed(n) {
      return n <= this.dailyGoal - this.repsLeft;
    },

    gotoNext() {
      this.$emit('close');
      if(this.energyLevel === 'green'){
        this.goHome();
      } else {
        this.gotoReps();
      }
    },

    gotoReps() {
      this.$emit('close');
      this.$router.push({ name: 'Repetitions' });
    },

    goHome() {
      this.$emit('close');
      this.$router.push("/");
    },

    range(n) {
      return Array(n).fill(undefined).map((_, idx) => (idx + 1));
    }
  },

  computed: {
    ...mapGetters( {
      energyLevel: 'repetition/energyLevel',
      repsLeft: 'repetition/repsLeft',
      due: 'repetition/due',
      dailyGoal: 'repetition/dailyGoal',
    }),

    neuronCompleted() {
      return neuronCompleted;
    },
    neuronNotCompleted() {
      return neuronNotCompleted;
    },
  },

  filters: {},
}
</script>

<style lang="scss" scoped>
.AuraPopup{
  background-image: linear-gradient(#22215E, #0b1847);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: center;
  text-align: center;
  transition: all 1s;
  padding-top: 1em;
  padding-bottom: 1em;

  .header {
    color: white;
    font-size: 2em;
    height: 1.5em;
    opacity: 0;
    transition: opacity 1s;
    @media (max-width: 620px) {
         font-size: 1.7em;; 
    }
    &.show {
      opacity: 1;
    }
  }
  .auraRiveAnimation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    height: 300px;
    @media (max-width: 620px) {
         height: 65vw; 
    }
  }
  .bottomLayoutWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    text-align: center;
    padding-top: 0.5em;
    padding-bottom: 2em;
    opacity: 0;
    height: 0em;
    transition: all 1s;
    &.show {
      opacity: 1;
      height: 16.5em;
    }

    .energy {
      width: 20em;
      align-self: center;
      font-size: 1.1em;
      color: hsla(0, 0%, 100%, 0.75);
      height: 2.5em; 
      &.memoDialog {
        font-style: italic;
        height: 1.3em; 
      }
    }
    .neurons {
      display: flex;
      align-content: center;
      justify-content: center;
      padding-top: 1em;
      padding-bottom: 2em;

      .neuron {
        width: 52px;
        height: 52px;
        border-radius: 50%;
        background-color: #1b3666;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0.5em;
        margin-left: 0.5em;
        img {
          @media (max-width: 620px) {
            width: 8vw;
            height: 8vw;
          }
        }
        &.completed {
          box-shadow: 0px 0px 16px 4px #40D4F41F;
          background-color: #2478bd62;
        }
        @media (max-width: 620px) {
          width: 12vw;
          height: 12vw;
           margin-bottom: 1vh;
        }
      }
    }
    .button {
      background:#2351CE;
      text-decoration: none;
      border-radius: 4.76651px;
      border: none;
      padding: 0.8em 2em;
      color: white;
      font-size: 1.1em;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &.show {
        opacity: 1;
        cursor: pointer;
      }
      @media (max-width: 620px) {
        margin-left: 16vw;
        margin-right: 16vw;
        padding: 3.5vw;
        margin-top: 0em;
        font-size: 3.8vw;
      }
    }
  }
}
</style>

