/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'video2': {
    width: 60,
    height: 54,
    viewBox: '0 0 60 54',
    data: '<path pid="0" d="M54 0H6a6 6 0 00-6 6v42a6 6 0 006 6h48a6 6 0 006-6V6a6 6 0 00-6-6zM21 6h6v6h-6V6zm-6 42H9v-6h6v6zm0-36H9V6h6v6zm6 7.71a1.5 1.5 0 012.22-1.32l13.38 7.29a1.5 1.5 0 010 2.64l-13.38 7.29A1.5 1.5 0 0121 34.29V19.71zM27 48h-6v-6h6v6zm12 0h-6v-6h6v6zm0-36h-6V6h6v6zm12 36h-6v-6h6v6zm0-36h-6V6h6v6z" fill-rule="nonzero"/>'
  }
})
