<template>
  <BlockContainer
    class="Memo"
    type="Memo"
    :label="memoId">
    <div class="memoDetails">
      <ButtonWithCheckmark
        :done="memo.summary && !!memo.summary.done"
        label="summary"
        @click="toggleMemoSummary()" />
      <ButtonWithCheckmark
        :done="memo.gameA && !!memo.gameA.done"
        label="gameA"
        @click="toggleMemoGame('gameA')" />
      <ButtonWithCheckmark
        :done="memo.gameB && !!memo.gameB.done"
        label="gameB"
        @click="toggleMemoGame('gameB')" />
    </div>
  </BlockContainer>
</template>

<script>
import BlockContainer from '../BlockContainer';
import ButtonWithCheckmark from '../ButtonWithCheckmark';

export default {
    components: { ButtonWithCheckmark, BlockContainer },
    props: ['stepId', 'memoId'],
    computed: {
        memo() {
            return this.$store.state.moduleCourse.memos[this.memoId];
        }
    },
    methods: {
        toggleMemoSummary() {
            this.$store.dispatch('moduleCourse/markMemoSummaryAsDone', {
                stepId: this.stepId,
                memoId: this.memoId,
                reset: !!this.memo.summary.done
            });
        },
        toggleMemoGame(gameId) {
            this.$store.dispatch('moduleCourse/markMemoGameAsDone', {
                stepId: this.stepId,
                memoId: this.memoId,
                gameId,
                reset: !!this.memo[gameId].done
            });
        }
    }
};
</script>

<style lang="scss" scoped></style>
