import TeamAdmin from './TeamAdmin'
import TeamDetails from './TeamDetails'
import TeamKey from './TeamKey'

export default [
  {
    path: '/team/admin/',
    name: 'TeamAdmin',
    component: TeamAdmin,
  },
  {
    path: '/team/detail/:teamId/',
    name: 'TeamDetails',
    component: TeamDetails,
    props: route => {
      return {
        teamId: parseInt(route.params.teamId),
      }
    },
  },
  {
    path: '/team/create-goal/',
    name: 'CreateTeamGoal',
    component: () => import('./CreateTeamGoal'),
    props: true,
  },
  {
    path: '/team/key/:teamKeyId',
    name: 'TeamKey',
    component: TeamKey,
    props: true,
  },
]
