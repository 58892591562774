/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chevron-down': {
    width: 20,
    height: 10.969,
    viewBox: '0 0 20 10.969',
    data: '<path pid="0" d="M10.746 10.689a1.176 1.176 0 01-1.523 0L.282 1.713a1.015 1.015 0 010-1.429 1 1 0 011.423 0l8.279 8.3 8.28-8.3a1 1 0 011.422 0 1.013 1.013 0 010 1.429z"/>'
  }
})
