const belts = [
    {
        id: 'white',
        index: 0,
        color: '#E2E2E2',
        requiredPoints: 2
    },
    {
        id: 'yellow',
        index: 1,
        color: '#FFE400',
        requiredPoints: 3
    },
    {
        id: 'orange',
        index: 2,
        color: '#F25700',
        requiredPoints: 3
    },
    {
        id: 'green1',
        index: 3,
        color: '#0BDA0B',
        requiredPoints: 3
    },
    {
        id: 'green2',
        index: 4,
        color: '#008F00',
        requiredPoints: 4
    },
    {
        id: 'blue1',
        index: 5,
        color: '#46C4ED',
        requiredPoints: 4
    },
    {
        id: 'blue2',
        index: 6,
        color: '#005991',
        requiredPoints: 4
    },
    {
        id: 'brown1',
        index: 7,
        color: '#A93900',
        requiredPoints: 5
    },
    {
        id: 'brown2',
        index: 8,
        color: '#772A00',
        requiredPoints: 5
    },
    {
        id: 'pink1',
        index: 9,
        color: '#FFB8B8',
        requiredPoints: 5
    },
    {
        id: 'pink2',
        index: 10,
        color: '#FF8787',
        requiredPoints: 5
    },

    {
        id: 'red1',
        index: 11,
        color: '#D83636',
        requiredPoints: 5
    },
    {
        id: 'red2',
        index: 12,
        color: '#861414',
        requiredPoints: 5
    },

    {
        id: 'purple1',
        index: 13,
        color: '#5454B6',
        requiredPoints: 5
    },
    {
        id: 'purple2',
        index: 14,
        color: '#372977',
        requiredPoints: 5
    },

    {
        id: 'black',
        index: 15,
        color: '#282828',
        requiredPoints: 5,
        number: 1
    }
];

function generateBeltOutsideRange(currentBeltIndex) {
    const lastBelt = belts[belts.length - 1];
    return {
        id: 'infinite',
        number: currentBeltIndex + 2 - belts.length, // NOTE: Start counting at 2
        index: currentBeltIndex,
        color: lastBelt.color,
        requiredPoints: lastBelt.requiredPoints
    };
}

function getBeltFromIndex(beltIndex, infiniteBelts) {
    if (beltIndex === -1) {
        return null;
    }
    return belts[beltIndex] || (infiniteBelts && generateBeltOutsideRange(beltIndex)) || null;
}

export default belts;

export function getBeltFromTotalPoints(totalPoints, infiniteBelts = true) {
    let remainingPoints = totalPoints;
    let currentBeltIndex = -1;
    for (let i = 0; i < belts.length; i++) {
        const belt = belts[i];

        if (remainingPoints < belt.requiredPoints) {
            break;
        }

        currentBeltIndex = i;
        remainingPoints -= belt.requiredPoints;
    }

    if (currentBeltIndex + 1 > belts.length - 1 && infiniteBelts) {
        currentBeltIndex += Math.floor(remainingPoints / 5);
        remainingPoints = remainingPoints % 5;
    }

    return {
        prev: getBeltFromIndex(currentBeltIndex - 1, infiniteBelts),
        current: getBeltFromIndex(currentBeltIndex, infiniteBelts),
        next: getBeltFromIndex(currentBeltIndex + 1, infiniteBelts),
        points: remainingPoints
    };
}

export function getTotalPointsForBelt(beltIndex) {
    let totalRequiredPoints = 0;
    belts.slice(0, beltIndex + 1).forEach(belt => {
        totalRequiredPoints += belt.requiredPoints;
    });
    if (beltIndex > belts.length - 1) {
        const lastBelt = belts[belts.length - 1];
        totalRequiredPoints += lastBelt.requiredPoints * (beltIndex - belts.length);
    }
    return totalRequiredPoints;
}
