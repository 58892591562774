import store from '@/store';

const CourseHomeView = () => import('./views/CourseHomeView');
const StepFlowView = () => import('./views/StepFlowView');
const ActivityTransitionView = () => import('./views/ActivityTransitionView');
const ReadView = () => import('./views/ReadView');
const QuizView = () => import('./views/QuizView');
const GameView = () => import('./views/GameView');
const MemoView = () => import('./views/MemoView');
const StepEndView = () => import('./views/StepEndView');

// const StepGroupWarmupView = () => import('./views/StepGroupWarmupView')
const StepGroupQuizIntroView = () => import('./views/StepGroupQuizIntroView');
const StepGroupQuizView = () => import('./views/StepGroupQuizView');

function assertAccessForCourse(to, from, next) {
    const courseId = to.params.courseId;
    if (!store.state.moduleCourse.courses[courseId]) {
        return next('/');
    }
    next();
}

function assertAccessForStep(to, from, next) {
    const stepId = to.params.stepId;
    const step = store.state.moduleCourse.steps[stepId];
    if (!step) {
        return next('/');
    }
    next();
}

export default [
    {
        path: '/course/:courseId',
        props: true,
        name: 'CourseHomeView',
        component: CourseHomeView,
        beforeEnter: assertAccessForCourse
    },
    {
        path: '/course/:courseId/step/:stepId',
        meta: {
            foobar: true
        },
        props: true,
        component: StepFlowView,
        beforeEnter: assertAccessForStep,
        children: [
            {
                path: '',
                name: 'StepFlow-LoadingView',
                redirect(to) {
                    const step = store.state.moduleCourse.steps[to.params.stepId];
                    return {
                        name: 'Course-ActivityTransition',
                        params: Object.assign({ activityName: step.activityNames[0], delay: 1000 }, to.params)
                    };
                }
            },
            {
                path: 'step-activity-redirect/:activityName',
                name: 'Course-ActivityRedirect',
                redirect(to) {
                    if (!store.state.moduleAuth.isAuthenticated) {
                        return {
                            path: '/login',
                            query: { next: to.fullPath }
                        };
                    }

                    const step = store.state.moduleCourse.steps[to.params.stepId];
                    if (!step) {
                        // NOTE: Can happen if stepId is not 'real'
                        return {
                            name: 'DashboardHomeView'
                        };
                    }
                    const activityName = to.params.activityName;

                    if (activityName === 'read') {
                        return {
                            name: 'ReadView',
                            params: to.params
                        };
                    } else if (activityName === 'memorize' || activityName === 'memorize2') {
                        const { activityName, stepId, courseId } = to.params;
                        const step = store.state.moduleCourse.steps[stepId];
                        const activity = step[activityName];
                        const memoId = activity.memoIds[0];
                        // console.lo
                        return {
                            name: 'MemoView',
                            params: {
                                activityName,
                                courseId,
                                stepId,
                                memoId
                            }
                        };
                    } else if (activityName === 'quiz' || activityName === 'quiz2') {
                        const doNotStartQuizAutomatically =
                            store.state.moduleApp.debugSettings.doNotStartQuizAutomatically;

                        return {
                            name: doNotStartQuizAutomatically ? 'Course-QuizView' : 'Course-QuizView-Play',
                            params: to.params
                        };
                    }
                }
            },
            {
                path: ':activityName/transition',
                name: 'Course-ActivityTransition',
                props: true,
                component: ActivityTransitionView
            },
            {
                path: ':activityName(read)',
                name: 'ReadView',
                props: true,
                component: ReadView
            },
            {
                path: ':activityName(memorize|memorize2)/memo/:memoId',
                name: 'MemoView',
                props: true,
                component: MemoView
            },
            {
                path: ':activityName(memorize|memorize2)/games',
                name: 'GamesView',
                props: true,
                component: MemoView
            },
            {
                path: ':activityName(memorize|memorize2)/game/:gameId?',
                name: 'GameView',
                props: true,
                component: GameView
            },
            {
                path: ':activityName(quiz|quiz2)',
                name: 'Course-QuizView',
                props: true,
                component: QuizView
            },
            {
                path: ':activityName(quiz|quiz2)/play',
                name: 'Course-QuizView-Play',
                props(route) {
                    return Object.assign({ playing: true }, route.params);
                },
                component: QuizView
            },
            {
                path: 'end',
                name: 'Course-StepEnd',
                props: true,
                component: StepEndView
            }
        ]
    },

    {
        path: '/course/:courseId/journey/:stepGroupId/quiz-intro',
        name: 'StepGroupQuizIntroView',
        props: true,
        meta: {
            foobar: true
        },
        component: StepGroupQuizIntroView,
        beforeEnter: assertAccessForCourse
    },
    {
        path: '/course/:courseId/journey/:stepGroupId/quiz',
        name: 'StepGroupQuizView',
        props: true,
        component: StepGroupQuizView,
        beforeEnter: assertAccessForCourse
    }
];
