/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'checkmark': {
    width: 76,
    height: 64,
    viewBox: '0 0 76 64',
    data: '<path pid="0" d="M64.162.643l10.723 8.998a1 1 0 01.122 1.412L31.301 63.139c-.22.263-.545.385-.861.355a1.012 1.012 0 01-.571-.235L.773 38.845a1.003 1.003 0 01-.13-1.416l8.998-10.723c.358-.426.99-.476 1.416-.118l17.621 14.786L62.75.768a1 1 0 011.412-.125z"/>'
  }
})
