import store from '@/store'

const DashboardView = () => import('./views/DashboardView')
const DashboardHomeView = () => import('./views/DashboardHomeView')
const DashboardProfileView = () => import('./views/DashboardProfileView')
const DashboardLifeskillView = () => import('./views/DashboardLifeskillView')
const DashboardVideoView = () => import('./views/DashboardVideoView')
const MemolifeTvView = () => import('./views/MemolifeTvView')
const ProfilePictureView = () => import('./views/ProfilePictureView')
const TeamProfilePage = () => import('./views/TeamProfilePage')

const DashboardOnboardingIntroView = () => import('./views/DashboardOnboardingIntroView')
const DashboardLifeskillsFavoritesView = () => import('./views/DashboardLifeskillsFavoritesView')
const DashboardLifeskillsVoteView = () => import('./views/DashboardLifeskillsVoteView')

const CreateTeamChallengeView = () => import('./views/TeamChallenge/CreateTeamChallenge')

export default [
  {
    path: '/do-not-use-this-path',
    component: DashboardView,
    meta: {
      requiresAuth: false,
    },
    children: [
      {
        path: '/home',
        name: 'DashboardHomeView',
        component: DashboardHomeView,
        meta: {
          canShowUpgrade: true,
        },
        beforeEnter(to, from, next) {
          if (!store.state.moduleApp.memolife.didShowDashboardOnboarding) {
            return next({
              name: 'DashboardOnboardingIntroView',
            })
          }
          next()
        },
      },
      {
        path: '/profile',
        name: 'DashboardProfileView',
        redirect: {
          name: 'DashboardProfileLifeViewWithTab',
          params: {
            tabId: 'life',
          },
        },
      },
      {
        path: '/profile/picture',
        name: 'ProfilePicture',
        props: true,
        component: ProfilePictureView,
      },

      {
        path: '/profile/:tabId',
        name: 'DashboardProfileLifeViewWithTab',
        props: true,
        component: DashboardProfileView,
      },

      {
        path: '/from-rep-email',
        redirect: {
          name: 'Repetitions',
        },
      },
      {
        path: '/videos',
        name: 'MemolifeTvView',
        component: MemolifeTvView,
      },
      {
        path: '/create-team-challange/:teamId',
        name: 'CreateTeamChallange',
        props: true,
        component: CreateTeamChallengeView,
      },
      {
        path: '/TeamProfilePage/:teamId',
        name: 'TeamProfilePage',
        props: route => {
          return {
            teamId: parseInt(route.params.teamId),
          }
        },
        component: TeamProfilePage,
      },
    ],
  },

  {
    path: '/video/:videoId/:lifeskillId?',
    name: 'DashboardVideoView',
    props: true,
    component: DashboardVideoView,
  },
  {
    path: '/lifeskill/:lifeskillId',
    name: 'DashboardLifeskillView',
    props: true,
    component: DashboardLifeskillView,
  },
  {
    path: '/lifeskill/02/practice/games',
    name: 'DashboardLifeskillViewActiveGamesTab',
    props: {
      lifeskillId: '02',
      tabName: 'practice',
      childTabName: 'games',
    },
    component: DashboardLifeskillView,
  },
  {
    path: '/lifeskill/02/practice/systems',
    name: 'DashboardLifeskillViewActiveSystemsTab',
    props: {
      lifeskillId: '02',
      tabName: 'practice',
      childTabName: 'systems',
    },
    component: DashboardLifeskillView,
  },
  {
    path: '/lifeskill/:lifeskillId/:tabName',
    name: 'DashboardLifeskillViewWithTab',
    props: true,
    component: DashboardLifeskillView,
  },
  {
    path: '/follow',
    name: 'FollowLifeskillView',
    component: DashboardLifeskillsFavoritesView,
  },
  {
    path: '/onboarding/intro',
    name: 'DashboardOnboardingIntroView',
    component: DashboardOnboardingIntroView,
  },
  {
    path: '/vote',
    component: DashboardLifeskillsVoteView,
  },
]
