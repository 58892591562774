import Vue from 'vue'
import * as types from '../../mutation-types'
import moment from 'moment'

export default {
  [types.UPDATE_APP_SETTINGS](state, { settings }) {
    if (settings.dailyRequiredBraindates) {
      settings.dailyRequiredBraindatesNotSetByUser = false
    }
    settings = Object.assign(state.memolife, settings)
    Vue.set(state, 'memolife', settings)
  },

  [types.SET_MISSION](state, mission) {
    Vue.set(state.memolife, 'mission', mission)
  },

  updateLoadStatus(state, payload) {
    Object.assign(state.loadStatus, payload)
  },
  [types.SET_LOCALE](state, language) {
    state.memolife.appLanguage = language
  },
  [types.UPDATE_TIME](state, payload) {
    Object.assign(state.time, payload)
  },

  [types.UPDATE_DASHBOARD_FAVORITES_SELECTED](state, { done }) {
    state.memolife.didSelectFavoriteLifeskills = done
  },
  [types.UPDATE_GET_STARTED_SHOWN](state, { done }) {
    state.memolife.didShowGetStartedPopup = done
  },
  [types.UPDATE_DASHBOARD_ONBOARDING_SHOWN](state, { done }) {
    state.memolife.didShowDashboardOnboarding = done
  },
  [types.UPDATE_MEMOMAPS_ONBOARDING_SHOWN](state, { done }) {
    state.memolife.didShowMemomapsOnboardingPopup = done
  },
  [types.UPDATE_MEMO_ONBOARDING_SHOWN](state, { done }) {
    state.memolife.didShowMemoOnboardingPopup = done
  },
  [types.UPDATE_DID_EXIT_GETTING_STARTED_FOCUS](state, { done }) {
    state.memolife.didExitGettingStartedFocus = done
  },
  [types.UPDATE_WHATSNEW_VIEWED_ARTICLES](state, { ids }) {
    state.memolife.whatsNewViewedArticles = ids
  },

  [types.VIDEO_PART_COMPLETED](state, { lifeskillNumber, videoId, partIndex }) {
    // see vuex braindate plugin
    console.log(types.VIDEO_PART_COMPLETED, { lifeskillNumber, videoId, partIndex })
  },

  [types.UPDATE_VIDEO_PROGRESS](state, { id, value, timestamp }) {
    if (!state.videos[id]) {
      Vue.set(state.videos, id, { id: id, progress: value, latestActivity: timestamp })
    } else {
      state.videos[id].progress = value
      state.videos[id].latestActivity = timestamp
    }
  },

  [types.UPDATE_LIFESKILL_DATA](state, { lifeskillId, lifeskills, data }) {
    if (lifeskillId) {
      const lifeskill = state.lifeskills[lifeskillId]

      Vue.set(state.lifeskills, lifeskillId, Object.assign({}, lifeskill || { follow: false }, data))
    } else if (lifeskills) {
      lifeskills.forEach(lifeskill => {
        Vue.set(
          state.lifeskills,
          lifeskill.number,
          Object.assign({}, lifeskill || { follow: false }, {
            numberOfRelatedVideos: lifeskill.numberOfVideos,
            recommenedVideoIds: lifeskill.recommenedVideoIds,
          })
        )
      })
    }
  },

  SET_LIFESKILLS_LIST(state, lifeskillsList) {
    state.lifeskillsList = lifeskillsList
  },

  [types.UPDATE_ALL_VIDEOS](state, videos) {
    videos.forEach(video => {
      Vue.set(state.videos, video.id, video)
    })
  },

  RECOMMENDED_VIDEOS(state, videos) {
    state.recommendedVideos = videos
  },

  VIDEOS_IN_PROGRESS(state, videos) {
    state.videos_in_progress = videos
  },

  [types.UPDATE_VIDEO_DATA](state, { videoId, data }) {
    Vue.set(state.videos, videoId, data)
  },
  [types.UPDATE_VIDEO_DATA_WITH_DESCRIPTION](state, { videoId, description }) {
    const video = state.videos[videoId]
    if (!video) {
      return
    }
    Vue.set(video, 'description', description)
  },

  [types.SET_SHOW_DEBUG_BUTTON](state, { show }) {
    state.localState.showDebugButton = show
  },
  [types.UPDATE_DEBUG_SETTINGS](state, { key, value }) {
    Vue.set(state.debugSettings, key, value)
  },
  [types.SET_DEBUG_SETTINGS](state, { data }) {
    Vue.set(state, 'debugSettings', data)
  },
  [types.SET_LIFESKILL_SELECTED_VIDEO](state, { lifeskillId, videoId }) {
    state.lifeskills[lifeskillId].selectedVideoId = videoId
  },
  [types.SET_LIFESKILL_MARKED](state, { lifeskillId, follow }) {
    if (follow) {
      state.followedLifeskills.push(lifeskillId)
    } else {
      let index = state.followedLifeskills.indexOf(lifeskillId)
      state.followedLifeskills.splice(index, 1)
    }
  },
  [types.SET_LIFESKILLS_MARKED](state, lifeskills) {
    Vue.set(state, 'followedLifeskills', lifeskills)
  },
  [types.SET_DAILY_REQUIRED_BRAINDATES](state, { value, dailyRequiredBraindatesNotSetByUser = false }) {
    state.memolife.dailyRequiredBraindates = value
    state.memolife.dailyRequiredBraindatesNotSetByUser = dailyRequiredBraindatesNotSetByUser
  },
  [types.SET_MEMOSTREAK](state, memostreak) {
    state.memostreak = parseInt(memostreak)
  },
  [types.ADD_BRAINDATE](state, braindate) {
    state.braindates.push(braindate)
  },
  [types.SET_BRAINDATES](state, braindates) {
    Vue.set(state, 'braindates', braindates)
  },
  [types.UPDATE_AUTOPLAY_VIDEO_PARTS](state, value) {
    state.autoplayVideoParts = value
  },

  updateVideosPageState(state, videosPageState) {
    Object.assign(state.videosPage, videosPageState)
  },

  setPracticePanelViewMode(state, viewMode) {
    state.memolife.practicePanelViewMode = viewMode
  },

  updateAppVersionInfo(state, { gitRevision }) {
    state.serversideGitRevision = gitRevision
  },

  setGameExitTo(state, payload) {
    state.memolife.gameExitTo = payload
  },

  setSystemExitTo(state, payload) {
    state.memolife.systemExitTo = payload
  },

  setInitiallyOpenNotificationDropdown(state, flag) {
    state.memolife.initiallyOpenNotificationDropdown = flag
  },

  addHabitMission(state, payload) {
    state.memolife.selectedHabitMissions.push(payload)
  },

  setTeamList(state, payload) {
    state.memolife.teamsList = payload
  },

  selectHabitMission(state, missionId) {
    const index = state.memolife.selectedHabitMissions.findIndex(mission => mission.missionId === missionId)
    state.memolife.selectedHabitMissions[index].isSelected = true
    console.log(state.memolife.selectedHabitMissions, ' state.memolife.selectedHabitMissions')
  },

  deselectHabitMission(state, missionId) {
    const index = state.memolife.selectedHabitMissions.findIndex(mission => mission.missionId === missionId)
    state.memolife.selectedHabitMissions[index].isSelected = false
  },

  updateHabitMissionBraindates(state, missionId) {
    const index = state.memolife.selectedHabitMissions.findIndex(mission => mission.missionId === missionId)
    const now = moment()
    let dayAdded = false
    if (state.memolife.selectedHabitMissions[index]) {
      state.memolife.selectedHabitMissions[index].days?.forEach(timestamp => {
        const day = moment(timestamp)
        if (day.isSame(now, 'd')) {
          dayAdded = true
        }
      })
      if (!dayAdded) {
        state.memolife.selectedHabitMissions[index].days.push(now)
      }
    }
  },

  [types.SET_LIFEKEYS](state, payload) {
    Vue.set(state, 'lifekeys', payload)
  },

  [types.ADD_LIFEKEY](state, payload) {
    state.lifekeys.push(payload)
  },

  [types.MIQ_COMPLETED](state) {
    // see vuex lifekey plugin
    console.log(types.MIQ_COMPLETED)
  },
}
