/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'memo-gamepad': {
    width: 20,
    height: 12,
    viewBox: '0 0 20 12',
    data: '<path pid="0" d="M16 .06L11 0H4S1 0 0 9a2 2 0 002 2c2 0 4-3 4-3h8s2 3.06 4 3.06a2 2 0 002-2c-1-9.06-4-9-4-9zM15.5 2a.5.5 0 110 1 .5.5 0 010-1zM5 4v1H4V4H3V3h1V2h1v1h1v1H5zm2.5 3a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm5 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm2-3a.5.5 0 110-1 .5.5 0 010 1zm1 1a.5.5 0 110-1 .5.5 0 010 1zm1-1a.5.5 0 110-1 .5.5 0 010 1z" fill-rule="nonzero"/>'
  }
})
