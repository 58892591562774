import memolanguageDataHelper from './memolanguage-data-helper';
import * as types from '@/store/mutation-types';
import store from '@/store';

export default {
    async loadLanguage({ commit, state }, { sourceLanguage, targetLanguage }) {
        commit(types.MEMOLANGUAGE_UPDATE_LANGUAGE_STATE, { targetLanguage, loadState: 'loading' });

        let bundle = await memolanguageDataHelper.getLanguageData(sourceLanguage, targetLanguage);
        let data = {
            id: 1
        };
        commit(types.MEMOLANGUAGE_SET_CATEGORY_DATA, { categoryId: 1, data: data });

        // await store.dispatch('moduleMemolanguage/loadLearnedItems')
        await store.dispatch('moduleMemolanguage/loadFavoriteMnemonics', { targetLanguage });
        await store.dispatch('moduleMemolanguage/loadSettings', { targetLanguage });

        commit(types.MEMOLANGUAGE_UPDATE_LANGUAGE_STATE, { targetLanguage, loadState: 'loaded' });

        return bundle;
    },

    loadLearnedItems({ commit }, { targetLanguage }) {
        return memolanguageDataHelper.fetchLearnedItems(targetLanguage).then(data => {
            return commit(types.MEMOLANGUAGE_SET_ITEMS_USERSTATE, { targetLanguage, data });
        });
    },

    loadFavoriteMnemonics({ commit }, { targetLanguage }) {
        return memolanguageDataHelper.fetchFavoriteMnemonics().then(data => {
            data.forEach(item => {
                commit(types.MEMOLANGUAGE_UPDATE_ITEM, {
                    targetLanguage,
                    prefixedItemId: item.mnemonic.word,
                    merge: true,
                    data: { currentFantasyId: item.mnemonic.id }
                });
            });
        });
    },

    unloadLanguage({ commit, state }, { sourceLanguage, targetLanguage }) {
        commit(types.MEMOLANGUAGE_UPDATE_LANGUAGE_STATE, { targetLanguage, loadState: '' });
    },
    fetchPronunciationItemsContent({ commit, state }, payload) {
        return memolanguageDataHelper.fetchPronunciationItemsContent(payload);
    },
    fetchOtherItemsContent({ commit, state }, payload) {
        return memolanguageDataHelper.fetchOtherItemsContent(payload);
    },

    reportLearnedItems({ commit, state }, payload) {
        return memolanguageDataHelper.reportLearnedItems(payload);
    },
    getUnlockedPhrases({ commit, state }, payload) {
        return memolanguageDataHelper.getUnlockedPhrases(payload);
    },

    newFantasy({ commit, state }, { data }) {
        return memolanguageDataHelper.newFantasy(data);
    },
    updateFantasy({ commit, state }, payload) {
        return memolanguageDataHelper.updateFantasy(payload);
    },

    getRepetitions({ commit, state }, payload) {
        return memolanguageDataHelper.getRepetitions(payload);
    },
    createRepetition({ commit, state }, payload) {
        return memolanguageDataHelper.createRepetition(payload);
    },
    updateRepetition({ commit, state }, payload) {
        return memolanguageDataHelper.updateRepetition(payload);
    },

    sendXapiStatement({ commit, state }, payload) {
        return memolanguageDataHelper.sendXapiStatement(payload);
    },

    markOnboardingAsSeen({ commit }) {
        commit(types.MEMOLANGUAGE_UPDATE_SETTINGS, { settings: { onboardingDone: true } });
        memolanguageDataHelper.updateSetting('onboardingDone', true);
    },
    markTooltipAsSeen({ commit, state }, { tooltipId }) {
        let completedTooltips = Object.assign({}, state.settings.completedTooltips);
        completedTooltips[tooltipId] = true;
        commit(types.MEMOLANGUAGE_UPDATE_SETTINGS, {
            settings: {
                completedTooltips
            }
        });
        memolanguageDataHelper.updateSetting('completedTooltips', completedTooltips);
    },

    setCurrentFantasyForItem({ commit }, { targetLanguage, prefixedItemId, fantasyId }) {
        commit(types.MEMOLANGUAGE_UPDATE_ITEM, {
            targetLanguage,
            prefixedItemId,
            merge: true,
            data: { currentFantasyId: fantasyId }
        });
        return memolanguageDataHelper.setFavoriteMnemonic(fantasyId);
    },
    markItemAsLearned({ commit }, { targetLanguage, prefixedItemId }) {
        commit(types.MEMOLANGUAGE_UPDATE_ITEM, {
            targetLanguage,
            prefixedItemId,
            merge: true,
            data: { learnStatus: 1 }
        });
        store.dispatch('moduleMemolanguage/reportLearnedItems', [prefixedItemId]);
    },

    loadSettings({ commit, state }) {
        return memolanguageDataHelper.fetchSettings().then(data => {
            commit(types.MEMOLANGUAGE_UPDATE_SETTINGS, {
                settings: data.userSettingsList
                    .filter(setting => setting.key.startsWith('memolanguage:'))
                    .reduce((acc, curr, i) => {
                        const key = curr.key.split('memolanguage:')[1];
                        acc[key] = curr.value;
                        return acc;
                    }, {})
            });
        });
    }
};
