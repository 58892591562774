/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'filter': {
    width: 56,
    height: 55,
    viewBox: '0 0 56 55',
    data: '<path pid="0" d="M30.285 14.397V2.5a2.5 2.5 0 10-5 0v12.022c-2.87 1.084-4.921 3.852-4.921 7.096 0 3.245 2.051 6.013 4.921 7.097v23.449a2.5 2.5 0 105 0V28.84c3.049-.986 5.261-3.85 5.261-7.222 0-3.372-2.212-6.234-5.261-7.221zm-2.33 9.812a2.594 2.594 0 01-2.591-2.591 2.593 2.593 0 012.591-2.59 2.593 2.593 0 012.591 2.59 2.594 2.594 0 01-2.591 2.591zm23.034 1.74V2.5a2.5 2.5 0 10-5 0v23.324c-3.049.986-5.261 3.85-5.261 7.222 0 3.372 2.212 6.234 5.261 7.221v11.897a2.5 2.5 0 105 0V40.142c2.87-1.084 4.921-3.852 4.921-7.096 0-3.245-2.051-6.013-4.921-7.097zm-2.67 9.687a2.593 2.593 0 01-2.591-2.59 2.594 2.594 0 012.591-2.591 2.594 2.594 0 012.591 2.591 2.593 2.593 0 01-2.591 2.59zm-38.058-9.687V2.5a2.5 2.5 0 10-5 0v23.324C2.212 26.81 0 29.674 0 33.046c0 3.372 2.212 6.234 5.261 7.221v11.897a2.5 2.5 0 105 0V40.142c2.87-1.084 4.921-3.852 4.921-7.096 0-3.245-2.051-6.013-4.921-7.097zm-2.67 9.687A2.593 2.593 0 015 33.046a2.594 2.594 0 012.591-2.591 2.594 2.594 0 012.591 2.591 2.594 2.594 0 01-2.591 2.59z" _fill="#6B6790" fill-rule="nonzero"/>'
  }
})
