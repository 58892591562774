/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'q-icon': {
    width: 76,
    height: 76,
    viewBox: '0 0 76 76',
    data: '<path pid="0" d="M37.776 52.504c3.276 0 6.258-.924 8.736-2.52l1.596 1.428a3.04 3.04 0 002.142.882c1.68 0 3.024-1.386 3.024-3.066 0-1.05-.504-1.848-1.176-2.436l-1.26-1.05c1.638-2.436 2.604-5.334 2.604-8.442v-.084c0-8.358-6.51-15.12-15.582-15.12-9.072 0-15.666 6.846-15.666 15.204v.084c0 8.358 6.51 15.12 15.582 15.12zm.882-9.366l3.066 2.604c-1.134.546-2.436.798-3.864.798-5.208 0-8.904-4.2-8.904-9.24v-.084c0-5.04 3.612-9.156 8.82-9.156s8.904 4.2 8.904 9.24v.084c0 1.47-.294 2.856-.798 4.032l-3.234-2.898c-.672-.588-1.26-.882-2.142-.882-1.68 0-3.024 1.386-3.024 3.066 0 1.05.504 1.848 1.176 2.436z" fill-rule="evenodd"/>'
  }
})
