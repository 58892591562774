const AdminView = () => import('./views/AdminView')
const Lobby = () => import('./views/Lobby')
const Warmup = () => import('./views/Warmup')
const FallingGame = () => import('./views/FallingGame')
const Quiz = () => import('./views/Quiz')
const PodiumView = () => import('./views/PodiumView')

export default [
  {
    path: '/memoransier/admin',
    component: AdminView,
  },
  {
    path: '/memoransier/lobby/:gameId',
    props: true,
    name: 'MemoransierLobby',
    component: Lobby,
  },
  {
    path: '/memoransier/warmup/:gameId',
    props: true,
    name: 'MemoransierWarmup',
    component: Warmup,
  },
  {
    path: '/memoransier/fallingGame/:gameId',
    props: true,
    name: 'FallingGame',
    component: FallingGame,
  },
  {
    path: '/memoransier/quiz/:gameId',
    props: true,
    name: 'MemoransierQuiz',
    component: Quiz,
  },
  {
    path: '/memoransier/podium/:gameId',
    props: true,
    name: 'MemoransierPodium',
    component: PodiumView,
  },
]
