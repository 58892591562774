<template>
  <div
    class="AnimatedBraindate"
    :class="{ playing, showingGoalReached }">
    <CircularProgress
      v-if="!surpassGoal"
      class="progressIndicator"
      :class="{ show: progress }"
      :progress="progress"
      :color="circleColor"
      track-color="rgba(0, 0, 0, 0.05)"
      :stroke-width="8" />
    <AnimatedCheckmark
      v-if="showingGoalReached"
      class="goalReachedIcon"
      :color="circleColor" />
    <AnimatedHeart
      v-else
      ref="heart"
      class="animatedHeart"
      :heart-color="color"
      :simplified="simplifiedHeartAnim" />

    <div
      v-if="!showingGoalReached"
      class="progressText">
      {{ count }}/{{ goal }}
    </div>
  </div>
</template>

<script>
import soundService from '@/services/sound-service';
import braindateSound from '@/assets/sounds/braindate.mp3';
import CircularProgress from '@/components/CircularProgress';
import { cssVariables } from '@/category-themes';
import AnimatedHeart from './AnimatedHeart';
import AnimatedCheckmark from './AnimatedCheckmark';

export default {
    components: { CircularProgress, AnimatedHeart, AnimatedCheckmark },
    props: {
        count: {
            type: Number,
            required: true
        },
        goal: {
            type: Number,
            required: true
        },
        live: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: cssVariables.completionColor
        },
        simplifiedHeartAnim: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            progress: 0,
            playing: false,
            showingGoalReached: false
        };
    },
    computed: {},
    created() {
        this.circleColor = cssVariables.completionColor;
        this.progressColor = 'red';
        this.surpassGoal = this.count > this.goal;
        this.reachGoal = this.count === this.goal;
        this.duration = 1000;
        if (this.live) {
            if (this.reachGoal) {
                this.duration = 3000;
            } else if (this.surpassGoal) {
                this.duration = 1000;
            } else {
                this.duration = 2000;
            }
        }
    },
    mounted() {
        if (this.live) {
            soundService.play(braindateSound);
            this.$nextTick(() => {
                this.$refs.heart.play();
            });

            setTimeout(() => {
                this.progress = Math.min(1, this.count / this.goal);
            }, 1000);

            if (this.reachGoal) {
                setTimeout(() => {
                    this.showingGoalReached = true;
                }, 2000);
            }
        } else {
            setTimeout(() => {
                this.progress = Math.min(1, this.count / this.goal);
            }, 0);
        }

        setTimeout(() => {
            this.playing = true;
        }, 0);

        setTimeout(() => {
            this.$emit('done');
        }, this.duration);
    },
    methods: {}
};
</script>

<style lang="scss" scoped>
.AnimatedBraindate {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
        content: '';
        display: block;
        padding-bottom: 100%;
    }

    &:not(.playing) {
        .progressText {
            opacity: 0;
        }
    }
    &.showingGoalReached {
        .progressIndicator {
            opacity: 0;
            transform: scale(1.2);
        }
    }
}

.progressIndicator {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    transition: opacity 0.5s, transform 0.5s;

    &:not(.show) {
        opacity: 0;
        transform: scale(0.5);
    }
}

.progressText {
    color: white;
    position: relative;
    font-weight: 600;
    font-size: 120%;
    top: -0.2em;

    transition: opacity 1s;
}

.animatedHeart {
    $offset: -2em;
    position: absolute;
    top: $offset;
    right: $offset;
    bottom: $offset;
    left: $offset;
    pointer-events: none;

    /deep/ {
        .heart {
            transform: scale(1.5);
            transform-origin: center;
        }
    }
}

.goalReachedIcon {
    position: absolute !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
</style>
