export default {
    title: 'Brainstorming',
    title_no: 'Brainstorming',
    subtitle: 'Workshop ideas & Creativity',
    subtitle_no: 'Workshop ideer & Kreativitet',

    techniques: ['rhymesys', 'alphabetsys', 'clonesys', 'symbolizationsys'],
    articles: ['nail-abstract-words-brainstorming'],

    // tags: ['concepts', 'ideas', 'abstract words'],
    // tags_no: ['konsepter', 'ideer', 'abstrakte ord'],

    levelTexts: {
        level0:
            'You’re a creative director leading a brainstorming session for a new brand. The best ideas often come overnight, so you need to remember them in the right sequence. Can you nail all the abstract words?',
        level0_no:
            'Du er kreativ direktør og gjennomfører en brainstormingsøkt for en ny merkevare. De beste ideene kommer oftest over natten, så du trenger å huske dem i riktig rekkefølge. Får du til å huske alle de abstrakte ordene?',

        level1: 'Your brainstorming session produces a lot of ideas. Can you remember them all?',
        level1_no: 'Du får mange ideer ut av en brainstorming-sesjon. Klarer du å huske alle sammen?',
        level2:
            'All right, here’s a longer brainstorming session with more complicated words. Are you up for the challenge?',
        level2_no:
            'Ok, dette er en lenger brainstormingsøkt med mer kompliserte ord. Har du det som skal til for å få med deg alt?',
        level3:
            "It's time to memorize and organize your ideas from the brainstorming session. Can you remember all the words with the correct color categories?",
        level3_no:
            'La oss memorere og organisere ideene fra brainstormingsøkten. Har du det som skal til for å huske alle ordene med riktig fargekategori?',
        level4:
            'You’re soon at the highest level! Now, you have to memorize more abstract words with even more color categories. Are you up for the challenge?',
        level4_no:
            'Du er snart på toppnivået! Nå gjelder det å memorere abstrakte ord med enda flere fargekategorier. Har du det som skal til?'
    },

    wordTest: 'Which word belongs to this card?',
    wordTest_no: 'Hvilket ord tilhører dette kortet?',
    numberTest: "What's the number of this card?",
    numberTest_no: 'Hva er nummeret for dette kortet?',
    colorTest: 'Pick the correct color',
    colorTest_no: 'Velg riktig farge',

    attributes: {
        wordTest: 'Word',
        wordTest_no: 'Ord',
        numberTest: 'Number',
        numberTest_no: 'Tall',
        colorTest: 'Color',
        colorTest_no: 'Farge'
    },

    testLabels: {
        wordTest: 'Word',
        wordTest_no: 'Ord',
        numberTest: 'Number',
        numberTest_no: 'Nummer',
        colorTest: 'Color',
        colorTest_no: 'Farge'
    }
};
