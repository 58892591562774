/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'summaries-overview': {
    width: 35,
    height: 41,
    viewBox: '0 0 35 41',
    data: '<path pid="0" d="M32.969 39.5H5.125c-.567 0-1.031-.444-1.031-.987v-4.936c0-.543.464-.987 1.031-.987h27.844c.567 0 1.031.444 1.031.987v4.936c0 .543-.464.987-1.031.987zm0-10.859H5.125c-.567 0-1.031-.444-1.031-.987v-4.245l-2.94-2.813c-.206-.198-.206-.494 0-.642l2.94-2.814v-4.294c0-.543.464-.987 1.031-.987h27.844c.567 0 1.031.444 1.031.987v14.808c0 .543-.464.987-1.031.987zm0-20.73H5.125c-.567 0-1.031-.445-1.031-.988V1.987c0-.543.464-.987 1.031-.987h27.844c.567 0 1.031.444 1.031.987v4.936c0 .543-.464.987-1.031.987z" _stroke="#1F415D" stroke-width="2" _fill="none"/>'
  }
})
