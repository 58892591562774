const levelIndexToGroupIndex = [0, 0, 1, 1, 1, 2, 2, 2, 3, 3, 3, 3];
const levelIndexToLocalIndex = [0, 1, 0, 1, 2, 0, 1, 2, 0, 1, 2, 3];

export default function (gameId) {
    return {
        gameId,
        moduleName: `moduleGame${gameId[0].toUpperCase()}${gameId.substr(1)}`,
        questionServiceState: {},

        levelGroups: [
            {
                number: 1,
                levelIndices: [0, 1]
            },
            {
                number: 2,
                levelIndices: [2, 3, 4]
            },
            {
                number: 3,
                levelIndices: [5, 6, 7]
            },
            {
                number: 4,
                levelIndices: [8, 9, 10, 11]
            }
        ],

        levels: (function () {
            let levels = [];

            for (let i = 0; i < 12; i++) {
                levels.push({
                    index: i,
                    localIndex: levelIndexToLocalIndex[i],
                    number: i + 1,
                    groupIndex: levelIndexToGroupIndex[i],

                    stars: 0,
                    bestTime: 0
                });
            }

            return levels;
        })(),

        rankings: (function () {
            let xx = [];
            for (let i = 0; i < 10; i++) {
                xx.push({
                    number: i + 1,
                    time: 23.3,
                    name: 'Peter Pan'
                });
            }
            xx[3].isYou = true;
            return xx;
        })()
    };
}
