/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'campfire-2': {
    width: 32,
    height: 42,
    viewBox: '0 0 32 42',
    data: '<g fill-rule="evenodd"><path pid="0" d="M16.08 28.651a8.093 8.093 0 005.644-2.322 7.729 7.729 0 002.31-5.542c0-4.11-6.537-10.768-7.268-11.527a.976.976 0 00-1.326 0c-.731.76-7.268 7.417-7.268 11.527-.026 4.301 3.508 7.815 7.908 7.864zm0-2.055c-1.666 0-3.017-1.32-3.017-2.95 0-1.027 1.6-2.635 3.017-3.708 1.372 1.073 3.017 2.726 3.017 3.709 0 1.628-1.35 2.949-3.017 2.949zm0-15.37c2.149 2.323 6.126 7.014 6.126 9.606a6.035 6.035 0 01-1.372 3.842c.066-.323.096-.653.092-.983 0-.446-.229-2.725-4.297-5.585a.932.932 0 00-1.052 0c-4.114 2.86-4.297 5.139-4.297 5.585-.004.33.026.66.092.983A6.035 6.035 0 0110 20.832c0-2.592 3.932-7.328 6.08-9.651v.045z" fill-rule="nonzero"/><path pid="1" d="M31.269 36.583a3.635 3.635 0 00-1.92-1.609c1.746-.754 2.62-2.683 2.015-4.454-.603-1.77-2.486-2.802-4.346-2.382l-11.155 3.039L4.71 28.094c-1.86-.42-3.743.61-4.347 2.381-.604 1.77.27 3.7 2.016 4.455-1.747.755-2.62 2.684-2.016 4.454.604 1.77 2.486 2.802 4.347 2.382l11.154-3.083 11.155 3.083a3.753 3.753 0 003.906-1.23 3.553 3.553 0 00.345-3.998v.045zm-3.749-6.747a1.905 1.905 0 011.816.457c.478.458.67 1.131.502 1.765a1.853 1.853 0 01-1.312 1.308l-2.514.67-6.857-1.876 8.365-2.324zM4.16 40.113c-.997.271-2.03-.299-2.308-1.274-.278-.974.305-1.985 1.303-2.256l2.468-.715 6.857 1.921-8.32 2.324zm25.646-1.251c-.288.954-1.302 1.51-2.286 1.25l-24.365-6.79a1.83 1.83 0 01-1.283-1.994c.124-.88.877-1.547 1.786-1.581l.502.09 24.412 6.746c.977.281 1.545 1.273 1.28 2.234l-.046.045z" fill-rule="nonzero"/><path pid="2" d="M5.595 6.152a.93.93 0 00-1.2.156.879.879 0 00.012 1.184l3.84 3.307a.93.93 0 001.2-.157.879.879 0 00-.012-1.184l-3.84-3.306zM24.595 10.799l3.84-3.307a.879.879 0 00.012-1.184.93.93 0 00-1.2-.156l-3.84 3.306a.879.879 0 00-.012 1.184.93.93 0 001.2.157zM16.086 5.406c.505 0 .914-.4.914-.893V.893A.904.904 0 0016.086 0c-.505 0-.915.4-.915.894v3.619c0 .493.41.893.915.893z"/></g>'
  }
})
