/* eslint-disable */
require('./02games')
require('./02systems')
require('./3dot')
require('./activities')
require('./admin-icon')
require('./arrow-down')
require('./arrow-left')
require('./arrow-right')
require('./arrow-up')
require('./article')
require('./backspace')
require('./bell-2')
require('./bell')
require('./belt-arrow')
require('./belt')
require('./book')
require('./bookmark')
require('./braindate')
require('./branch-shape')
require('./browse-fantasies')
require('./calendar')
require('./camerasvg')
require('./campfire-2')
require('./campfire')
require('./carousel-arrow-left')
require('./carousel-arrow-right')
require('./categories')
require('./check')
require('./checkmark')
require('./chevron-down')
require('./close')
require('./dashboard-icon')
require('./deep-dive')
require('./dumbbells')
require('./energy')
require('./envelope')
require('./exit')
require('./eye-blocked')
require('./eye')
require('./facebook')
require('./facebook2')
require('./female')
require('./filter')
require('./flag-with-checkmark')
require('./flag')
require('./fullscreen')
require('./game-icon-2')
require('./game-icon')
require('./getting-started-icons')
require('./glass-learn')
require('./habit-icon')
require('./hamburger')
require('./hand-left')
require('./hand-right')
require('./hands-scenario')
require('./head-with-heart')
require('./heart-broken')
require('./heart-like')
require('./heart-smile')
require('./heart')
require('./intro_badge')
require('./key-3')
require('./key')
require('./key2')
require('./keyboard-return')
require('./keyShort')
require('./known-word')
require('./leaf')
require('./library')
require('./library2')
require('./lifekey')
require('./lightning-bolt')
require('./loadwheel')
require('./lock')
require('./lock2')
require('./lock3')
require('./locked')
require('./male')
require('./map-with-steps')
require('./map')
require('./memo-gamepad')
require('./memo-image-2')
require('./memo-image')
require('./memo-story-2')
require('./memo-story')
require('./menu')
require('./minimize')
require('./mountain-bw')
require('./mountain-color')
require('./mountain-with-nature')
require('./music-mute')
require('./music')
require('./neuron-completed')
require('./neuron-frozen')
require('./neuron')
require('./notification')
require('./open-modal')
require('./options-horizontal-3-dots')
require('./pen-memorytip')
require('./pencil')
require('./pencil2')
require('./play-button')
require('./play-triangle')
require('./play')
require('./plus')
require('./plus2')
require('./practice_badge_color')
require('./practice-section-icon')
require('./presentation-icon')
require('./profile-icon')
require('./progress-key')
require('./q-icon')
require('./q')
require('./quiz-icon')
require('./reveal-summary')
require('./rocket')
require('./scenarios')
require('./settings')
require('./shuffle')
require('./sound-high')
require('./sound-low')
require('./sound-medium')
require('./sound-mute')
require('./spinner')
require('./star')
require('./steps-3')
require('./steps')
require('./steps2')
require('./summaries-overview')
require('./surnames')
require('./swipe')
require('./team-step-icon')
require('./theory_badge_color')
require('./theory-section-icon')
require('./trophy')
require('./trophy1')
require('./trophy2')
require('./unknown-word')
require('./video')
require('./video2')
require('./videos_icon')
require('./warning-triangle')
