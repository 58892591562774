import Vue from 'vue';
import * as types from '../../mutation-types';

export default {
    [types.SET_COURSE_LIST](state, courseIds) {
        state.courseIds = courseIds;
    },

    setLastStepId(state, { stepId }) {
        state.otherStuff.lastStepId = stepId;
    },
    setLastJourneyId(state, { journeyId }) {
        state.otherStuff.lastJourneyId = journeyId;
    },

    SET_PROGRESS_MAPPING(state, mapping) {
        state.progressMapping = mapping;
    },

    // ----------------------------------
    // Course
    // ----------------------------------

    [types.SET_COURSE_DATA](state, { courseId, data = {}, level }) {
        if (state.courses[courseId]) {
            state.courses[courseId] = Object.assign({}, state.courses[courseId], data);
        } else {
            Vue.set(state.courses, courseId, data);
        }

        // Keeping track of what kind of data is loading or loaded for this course. This helps avoid double loading and overwriting data

        let loadInfo = state.courses[courseId].loadInfo || {
            content: false,
            toc: false,
            list: false,
            contentLoading: false,
            tocLoading: false,
            listLoading: false,
        };
        if (level === 'content') {
            delete loadInfo.contentLoading;
            delete loadInfo.tocLoading;
            loadInfo.content = true;
            loadInfo.toc = true;
            loadInfo.list = true;
        } else if (level === 'toc') {
            delete loadInfo.tocLoading;
            loadInfo.toc = true;
            loadInfo.list = true;
        } else if (level === 'list') {
            loadInfo.list = true;
        } else if (level) {
            loadInfo[level] = true;
            if (level === 'contentLoading') {
                loadInfo.tocLoading = true;
            }
        }
        Vue.set(state.courses[courseId], 'loadInfo', loadInfo);
    },

    // ----------------------------------
    // StepGroup
    // ----------------------------------

    [types.SET_STEP_GROUP_DATA](state, { stepGroupId, data }) {
        const stepGroup = state.stepGroups[stepGroupId];
        if (stepGroup) {
            data.quiz = stepGroup.quiz;
        } else if (data.quiz) {
            data.quiz.done = 0;
        }
        Vue.set(state.stepGroups, stepGroupId, data);
    },

    [types.INIT_STEP_GROUP_QUIZ_PROGRESS](state, { stepGroupId, timestamp }) {
      const sg = state.stepGroups[stepGroupId];
      if (!sg) return;
      Vue.set(sg, 'quiz', { ...sg.quiz, done: timestamp,  });
    },

    [types.SET_STEP_GROUP_QUIZ_PROGRESS](state, { stepGroupId, timestamp }) {
        const stepGroup = state.stepGroups[stepGroupId];
        if (!stepGroup) {
            const msg = `SET_STEP_GROUP_QUIZ_PROGRESS: outdated stepGroupId? ${JSON.stringify({
                stepGroupId,
                timestamp
            })}`;
            console.warn(msg); // eslint-disable-line no-console
            return;
        }

        if (!stepGroup.quiz) {
            const msg = `SET_STEP_GROUP_QUIZ_PROGRESS: outdated user progress, journey has no quiz? ${JSON.stringify({
                stepGroupId,
                timestamp
            })}`;
            console.warn(msg); // eslint-disable-line no-console
            return;
        }
        stepGroup.quiz.done = timestamp;
    },

    [types.UPDATE_STEP_GROUP_QUIZ_STATE](state, payload) {
        Vue.set(state.stepGroupQuizStates, payload.stepGroupId, payload.reset ? null : payload);
    },

   // ----------------------------------
    // Step
    // ----------------------------------

    [types.SET_STEP_DATA](state, { stepsData, lifeskillNumber }) {
        stepsData.forEach(stepData => {
            const step = state.steps[stepData.id];

            if (step) {
                step.activityNames.forEach(activityName => {
                    if (stepData[activityName]) {
                        stepData[activityName].progress = step[activityName] ? step[activityName].progress : 0;
                        stepData[activityName].lastUpdated = step[activityName] ? step[activityName].lastUpdated : 0;
                    }
                });

                stepData.progress = step.progress;
            } else {
                ['read', 'memorize', 'quiz', 'memorize2', 'quiz2'].forEach(activityName => {
                    if (stepData[activityName]) {
                        stepData[activityName].progress = 0;
                        stepData[activityName].lastUpdated = 0;
                    }
                });
                stepData.progress = 0;
            }
            stepData.fromLifeskill = lifeskillNumber;
            Vue.set(state.steps, stepData.id, stepData);
        });
    },

    [types.SET_STEP_PROGRESS](state, { stepId, activity, timestamp }) {
      const step = state.steps[stepId];
      if (!step) return;
      const a = step[activity] || {};
      a.progress = timestamp ? 1 : 0;
      a.lastUpdated = timestamp;
      Vue.set(step, activity, a);
    },

    [types.SET_STEP_READ_PROGRESS](state, { stepId, timestamp }) {
        const step = state.steps[stepId];
        if (!step) {
            const msg = `SET_STEP_READ_PROGRESS: outdated stepId? ${JSON.stringify({ stepId, timestamp })}`;
            console.warn(msg); // eslint-disable-line no-console
            return;
        }
        if (!step.read) {
            const msg = `SET_STEP_READ_PROGRESS: outdated user-progress? ${JSON.stringify({
                stepId,
                timestamp,
                step
            })}`;
            console.warn(msg); // eslint-disable-line no-console
            return;
        }
        step.read.progress = timestamp ? 1 : 0;
        step.read.lastUpdated = timestamp;
    },

    [types.SET_STEP_QUIZ_PROGRESS](state, { stepId, activityName, timestamp }) {
        const step = state.steps[stepId];
        if (!step) {
            const msg = `SET_STEP_QUIZ_PROGRESS: outdated stepId? ${JSON.stringify({
                stepId,
                activityName,
                timestamp
            })}`;
            console.warn(msg); // eslint-disable-line no-console
            return;
        }
        const activity = step[activityName];
        if (!activity) {
            const msg = `SET_STEP_QUIZ_PROGRESS: outdated user-progress? ${JSON.stringify({
                stepId,
                activityName,
                timestamp,
                step
            })}`;
            console.warn(msg); // eslint-disable-line no-console
            return;
        }
        activity.progress = timestamp ? 1 : 0;
        activity.lastUpdated = timestamp;
    },

    [types.RECOMPUTE_STEP_PROGRESS](state, { stepId }) {
        const step = state.steps[stepId];
        if (!step) {
            const msg = `RECOMPUTE_STEP_PROGRESS: outdated stepId? ${JSON.stringify({ stepId })}`;
            console.warn(msg); // eslint-disable-line no-console
            return;
        }

        const activities = step.activityNames.map(activityName => step[activityName]);
        let fraction =
            activities.reduce((accumulator, activity) => accumulator + activity.progress || 0, 0) / activities.length;
        if (fraction < 1) {
            const requiredActivities = activities.filter(activity => !activity.optional);
            const requiredFraction =
                requiredActivities.reduce((accumulator, activity) => accumulator + activity.progress || 0, 0) /
                requiredActivities.length;
            if (requiredFraction === 1) {
                fraction = 1;
            }
        }

        Vue.set(step, 'progress', fraction);
    },

    [types.RECOMPUTE_STEP_MEMO_PROGRESS](state, { stepId, activityName = null }) {
        const step = state.steps[stepId];
        if (!step) {
            const msg = `RECOMPUTE_STEP_MEMO_PROGRESS: outdated stepId? ${JSON.stringify({ stepId, activityName })}`;
            console.warn(msg); // eslint-disable-line no-console
            return;
        }

        function update(activity) {
            let lastUpdated = 0;
            let accumulatedMemoProgress = 0;
            let numberOfMemos = 0;
            (activity.memoIds || [])
                .map(memoId => state.memos[memoId])
                .forEach(memo => {
                    let numberOfMemoActivities = 0;
                    let numberOfCompletedMemoActivities = 0;
                    if (memo.summary) {
                        numberOfMemoActivities++;
                        if (memo.summary.done) {
                            numberOfCompletedMemoActivities++;
                            lastUpdated = Math.max(lastUpdated, memo.summary.done);
                        }
                    }
                    if (memo.gameA) {
                        numberOfMemoActivities++;
                        if (memo.gameA.done) {
                            numberOfCompletedMemoActivities++;
                            lastUpdated = Math.max(lastUpdated, memo.gameA.done);
                        }
                    }
                    if (memo.gameB) {
                        numberOfMemoActivities++;
                        if (memo.gameB.done) {
                            numberOfCompletedMemoActivities++;
                            lastUpdated = Math.max(lastUpdated, memo.gameB.done);
                        }
                    }
                    if (memo.gameB && memo.gameB.done) {
                        accumulatedMemoProgress += 1;
                    } else {
                        accumulatedMemoProgress += numberOfCompletedMemoActivities / numberOfMemoActivities;
                    }
                    numberOfMemos++;
                });
            Vue.set(activity, 'progress', accumulatedMemoProgress / numberOfMemos);
            Vue.set(activity, 'lastUpdated', lastUpdated);
        }

        if (activityName) {
            update(step[activityName]);
        } else {
            if (step.memorize) {
                update(step.memorize);
            }
            if (step.memorize2) {
                update(step.memorize2);
            }
        }
    },

    // ----------------------------------
    // Memo
    // ----------------------------------

    [types.SET_MEMO_DATA](state, memosData) {
        memosData.forEach(memoData => {
            const memo = state.memos[memoData.id];

            if (memo) {
                // Keep existing user's state
                memoData.summary = Object.assign(memo.summary, memoData.summary);
                memoData.gameA = memo.gameA;
                memoData.gameB = memo.gameB;
            } else {
                if (memoData.summary) {
                    memoData.summary.done = 0;
                } else {
                    memoData.summary = { done: 0 };
                }
                memoData.gameA = { done: 0 };
                memoData.gameB = { done: 0 };
            }

            Vue.set(state.memos, memoData.id, memoData);
        });
    },

    [types.SET_MEMO_PROGRESS](state, { memoId, activity, timestamp }) {
      const memo = state.memos[memoId];
      if (!memo) return;
      Vue.set(memo, activity, { done: timestamp }); 
    },

    [types.SET_MEMO_GAME_PROGRESS](state, { stepId, memoId, gameId, timestamp }) {
        const memo = state.memos[memoId];
        if (!memo) {
            const msg = `SET_MEMO_GAME_PROGRESS: outdated memoId? ${JSON.stringify({
                stepId,
                memoId,
                gameId,
                timestamp
            })}`;
            console.warn(msg); // eslint-disable-line no-console
            return;
        }
        if (!memo[gameId]) {
            Vue.set(memo, gameId, { done: timestamp });
        } else {
            memo[gameId].done = timestamp;
        }
    },

    [types.SET_MEMO_SUMMARY_PROGRESS](state, { stepId, memoId, timestamp }) {
        const memo = state.memos[memoId];
        if (!memo) {
            const msg = `SET_MEMO_SUMMARY_PROGRESS: outdated memoId? ${JSON.stringify({ stepId, memoId, timestamp })}`;
            console.warn(msg); // eslint-disable-line no-console
            return;
        }
        if (!memo.summary) {
            Vue.set(memo, 'summary', { done: timestamp });
        } else {
            memo.summary.done = timestamp;
        }
    },
};
