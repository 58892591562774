/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'lock': {
    width: 46,
    height: 66,
    viewBox: '0 0 46 66',
    data: '<path pid="0" d="M41.954 28.006a51.195 51.195 0 01-4.734-1.438V14.614C37.22 6.986 31.014.78 23.386.78 15.758.78 9.553 6.986 9.553 14.614v11.954a51.32 51.32 0 01-4.735 1.438c-2.642.66-4 1.791-4 4v29.057a4 4 0 004 4h37.137a4 4 0 004-4V32.006c-.001-2.209-1.359-3.34-4.001-4zM26.136 43.753v7.202a2.75 2.75 0 01-5.5 0v-7.202a4.65 4.65 0 01-1.917-3.755 4.667 4.667 0 019.334 0 4.65 4.65 0 01-1.917 3.755zm6.084-18.824c-2.1-.542-4.585-.912-8.383-.956-.053-.002-.85-.002-.902 0-3.798.044-6.282.414-8.382.955V14.614c0-4.871 3.963-8.834 8.833-8.834 4.871 0 8.834 3.963 8.834 8.834v10.315z" fill-rule="evenodd"/>'
  }
})
