<template>
  <div class="StandardPopupBox">
    <slot />
    <div
      class="closeButton --global-clickable"
      :class="`mode-${bg}`"
      @click="$emit('close')">
      <svgicon
        class="svg-icon"
        icon="close" />
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            bg: 'dark'
        };
    },
    mounted() {
        if (this.$el.querySelector('[data-bg="light"]')) {
            this.bg = 'light';
        }
    }
};
</script>

<style lang="scss" scoped>
.StandardPopupBox {
    position: relative;
    border-radius: $app-popupBorderRadius;
    overflow: hidden;
    box-shadow: 0 0 1em rgba(black, 0.3);
}

.closeButton {
    position: absolute;
    right: 0;
    top: 0;
    padding: 1em;

    .svg-icon {
        $size: 0.8em;
        width: $size;
        height: $size;
    }

    &.mode-dark {
        .svg-icon {
            fill: rgba(white, 0.9);
        }
    }
    &.mode-light {
        .svg-icon {
            fill: rgba(black, 0.9);
        }
    }
}
</style>
