<template>
  <PanelBase class="DailyPanel">
    <template
      v-if="showHeader"
      #header
      class="header">
      <div class="title">
        {{ $t('title') }}
      </div>
      <div class="subtitle">
        {{ $t('subtitle') }}
      </div>
    </template>
    <template #body>
      <HeartGraph
        :data="braindatesForCurrentWeek"
        empty-color="#0000000d" />
    </template>
    <template
      v-if="!hideFooter"
      #footer>
      {{ $t('footerText') }}
    </template>
  </PanelBase>
</template>

<translations>
  title: 'Keep it up'
  title_no: 'Fortsett slik'
  subtitle: 'Fill up your weekly heart with daily brain dates'
  subtitle_no: 'Fyll ditt ukentlige hjerte med daglige hjernedates'

  footerText: Complete the building blocks of life
</translations>

<script>
import PanelBase from './PanelBase';
import HeartGraph from '@/modules/dashboard/views/DashboardProfileView/HeartGraph';

export default {
    components: { PanelBase, HeartGraph },
    props: {
        showHeader: {
            type: Boolean,
            default: false
        },
        hideFooter: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        braindatesForCurrentWeek() {
            return this.$store.getters['moduleApp/braindatesForCurrentWeek'];
        }
    }
};
</script>

<style lang="scss" scoped>
.WeeklyPanel {
}
.HeartGraph {
    width: 60%;
}
</style>
