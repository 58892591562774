/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dumbbells': {
    width: 55,
    height: 55,
    viewBox: '0 0 55 55',
    data: '<g fill-rule="nonzero"><path pid="0" d="M9.9 30.645A4.629 4.629 0 013.356 24.1L24.1 3.355a4.628 4.628 0 016.545 6.546L9.9 30.645zM18.848 1.153a3.935 3.935 0 00-5.565-.001L9.83 4.604 5.944.72.718 5.945 4.604 9.83l-3.452 3.452a3.935 3.935 0 005.566 5.565l12.13-12.13a3.934 3.934 0 000-5.564zM23.355 44.099a4.63 4.63 0 006.546 6.546L50.645 29.9a4.628 4.628 0 00-6.546-6.546L23.355 44.099zM53.846 36.152a3.935 3.935 0 01.002 5.566l-3.452 3.451 3.886 3.886-5.227 5.227-3.886-3.886-3.451 3.452a3.935 3.935 0 11-5.565-5.565l12.13-12.13a3.933 3.933 0 015.563-.001zM29.904 25.88l-1.784-1.784L24.024 20 19 25.024l4.096 4.096 1.784 1.784L28.976 35 34 29.976z"/></g>'
  }
})
