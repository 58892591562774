/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mountain-bw': {
    width: 58,
    height: 58,
    viewBox: '0 0 58 58',
    data: '<g _fill="none" fill-rule="evenodd"><path pid="0" d="M54.322 40.829l-7.515-11.336-10.442 16.73-15.197-22.8-13.36 22.8 2.87 4.736 7.85 5.045 8.217 1.69h8.923l6.529-3.461 4.61-3.274 6.364-8.602 1.151-1.528z" _fill="#FFF"/><path pid="1" _fill="#D8D8D8" d="M17.89 29l4.6 5.763-2.3 5.503 6.623 4.35-2.315 6.692 10.334 5.894H24.498l-10.372-4.214-7.434-7.257zM40.71 40.29l5.645 3.913 7.259-5.165 1.151 1.252-4.004 7.69-7.206 4.877-2.846.992-3.901-8.249z"/><path pid="2" _stroke="#979797" stroke-width="1.12" _fill="#D8D8D8" d="M21.192 6.692v9.42h13.596l-3.299-4.71 3.3-4.71z"/><path pid="3" d="M23.423 6.692v8.276c5.232.47 8.579.47 10.039 0 1.46-.47.802-1.683-1.973-3.639l3.3-3.28-11.366-1.357z" _fill="#FFF"/><path pid="4" d="M29 0C13.004 0 0 13.004 0 29s13.004 29 29 29 29-13.004 29-29S44.996 0 29 0zm0 1.872c14.957 0 27.128 12.171 27.128 27.128 0 3.448-.651 6.76-1.839 9.796l-7.524-11.52-10.71 16.75-13.609-21.842V16.84h13.815l-3.368-5.618 3.368-5.617H20.585V22.172L6.633 44.333A26.958 26.958 0 011.873 29C1.872 14.043 14.042 1.872 29 1.872zm-7.49 22.344L39.95 53.81a26.423 26.423 0 01-5.23 1.701l-9.19-4.51 2.774-6.473-7.445-4.647 2.706-5.424-5.012-5.56 2.957-4.68zm.936-16.738H32.95l-2.237 3.745 2.249 3.745H22.458v-7.49h-.012zM7.832 45.943l9.694-15.402 3.802 4.225-2.9 5.8 7.524 4.704-2.843 6.633 8.38 4.11c-.822.08-1.644.115-2.489.115-8.552 0-16.19-3.985-21.168-10.185zm33.818 7.045l-4.498-7.205 9.625-15.059 6.61 10.127A27.295 27.295 0 0141.65 52.988z" _fill="#000" fill-rule="nonzero"/></g>'
  }
})
