<template>
  <div class="teamDeletionPopup">
    <img
      class="image"
      src="../../modules/dashboard/views/DashboardProfileView/images/delete-account-illustration.svg"
    />

    <div class="text">
      {{ $t('text') }}
    </div>

    <div class="buttons">
      <div class="keepButton" @click="$emit('close')">
        {{ $t('button1') }}
      </div>
      <div class="deleteButton" @click="deleteTeam()">
        {{ $t('button2') }}
      </div>
    </div>
  </div>
</template>

<translations>

  text: Are you sure you want to delete this team? 
  text_no: Er du helt sikker du vil slette dette teamet?
  
  button1: Keep team
  button1_no: Behold teamet

  button2: Delete team
  button2_no: Slett teamet

</translations>

<script>
import apollo from '@/apollo-client'
import gql from 'graphql-tag'

export default {
  props: {
    teamId: {
      type: Number,
    },
  },
  methods: {
    async deleteAccount() {
      await this.$store.dispatch('moduleAuth/deleteAccount')
      this.$router.push({ name: 'AccountLogoutView' })
    },
    async deleteTeam() {
      await apollo.mutate({
        mutation: gql`
          mutation TeamDelete($teamId: Int) {
            __typename
            deleteTeam(input: { _teamId: $teamId }) {
              clientMutationId
            }
          }
        `,
        variables: { teamId: this.teamId },
      })
      this.$router.push({
        name: 'DashboardHomeView',
      })
      this.$store.dispatch('moduleApp/selectTeamId', '0')
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.teamDeletionPopup {
  background-image: linear-gradient(#22215e, #0b1847);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-content: center;
  text-align: center;
  //   transition: all 1s;

  height: 27em;
  width: 28em;

  .image {
    //   background-color: blueviolet;
    display: flex;
    justify-content: center;
    align-content: center;
    text-align: center;
    height: 15em;
    // margin-top: 2em;
  }
  .text {
    color: rgba(255, 255, 255, 0.8);
    margin-left: 2.1em;
    margin-right: 2.1em;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;
    height: 12em;
    padding-left: 2em;
    padding-right: 2em;

    .keepButton {
      background: #233299;
      padding: 1em;
      border-radius: 0.2em;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.8);
      text-align: center;
      margin-bottom: 0.6em;

      &:hover {
        cursor: pointer;
      }
    }
    .deleteButton {
      background: #2333993f;
      padding: 1em;
      border-radius: 0.2em;
      font-weight: 500;
      color: white;
      text-align: center;
      color: #ec4f48;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>
