/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'leaf': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M11.962 11.734C14.698 8.998 15.34 0 15.34 0s-1.858.969-4.826 1.223c-2.798.24-5.733 1.247-6.909 2.154C1.5 5-.325 8.058 1.01 10.417c1.468-2.305 8.507-4.593 8.507-4.593-4.334 3.1-6.543 5.503-9.516 9.739l1.458.39s1.086-1.96 2.03-2.907c2.367 1.68 5.98 1.183 8.474-1.312z" _fill="#D9E4F5"/>'
  }
})
