<template>
  <div class="LevelSuccessViewDebug">
    LevelSuccessViewDebug
    <button @click="showLevelCompletedPopup()">
      Test Level-completed popup
    </button>
  </div>
</template>

<script>
export default {
    props: ['instance'],
    methods: {
        showLevelCompletedPopup() {
            // this.instance.showingLevelGroupCompletedPopup = true;
        }
    }
};
</script>

<style lang="scss" scoped>
.LevelSuccessViewDebug {
    padding: 1em;
}
</style>
