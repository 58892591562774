import coverImage from './images/cover.jpg';
import appImage from './images/app-icon.png';
import themeSound from './sounds/theme.mp3';

import appMessages from './i18n';

export default {
    windowBackground: 'black',
    background: 'linear-gradient(#df4226, #ff5e3f)', // '#1F1F1F',
    levelItem: {
        gradient: 'rgba(255,255,255,0.1)',
        gradientActive: '#e74a2b'
    },
    rankingRow: {
        colorActive: '#e74a2b'
    },
    primaryActiveColor: '#E3E69E',
    primaryColor: 'white',
    textColorOnPrimaryColor: 'black',
    coverImage,
    appImage,
    themeSound,
    appMessages
};
