import * as types from './mutation-types'
import moment from 'moment'

const braindatePlugin = store => {
  store.subscribe((mutation, state) => {
    // console.log('mutation fired', mutation, state);

    // from course actions
    if (mutation.type === 'moduleCourse/' + types.SET_STEP_READ_PROGRESS) {
      const { stepId, lifeskillNumber, courseId, relatedLifeskills, isBonus } = mutation.payload
      store.dispatch(
        'moduleApp/registerBraindate',
        {
          context: 'course',
          activity: 'stepRead',
          lifeskillNumber,
          courseId,
          stepId,
          isBonus,
          bonusBraindates: relatedLifeskills || [],
        },
        { root: true }
      )
    }

    if (mutation.type === 'moduleCourse/' + types.SET_STEP_QUIZ_PROGRESS) {
      const { stepId, lifeskillNumber, courseId, isBonus, relatedLifeskills } = mutation.payload
      store.dispatch(
        'moduleApp/registerBraindate',
        {
          context: 'course',
          activity: 'stepQuiz',
          lifeskillNumber,
          courseId,
          stepId,
          isBonus: isBonus,
          bonusBraindates: relatedLifeskills || [],
        },
        { root: true }
      )
    }

    if (mutation.type === 'moduleCourse/' + types.SET_MEMO_GAME_PROGRESS) {
      const { stepId, gameId, lifeskillNumber, courseId } = mutation.payload
      if (gameId !== 'gameB') return
      store.dispatch(
        'moduleApp/registerBraindate',
        {
          context: 'course',
          activity: 'stepMemo',
          game: gameId,
          lifeskillNumber,
          courseId,
          stepId,
          isBonus: false,
          bonusBraindates: [],
        },
        { root: true }
      )
    }

    if (mutation.type === 'moduleCourse/' + types.SET_STEP_GROUP_QUIZ_PROGRESS) {
      const { lifeskillNumber, courseId, stepGroupId, related = [], isRelated } = mutation.payload

      if (isRelated) return

      const relatedLifeskills = related.map(({ lifeskill }) => lifeskill)
      ;[lifeskillNumber, ...relatedLifeskills].forEach((ls, idx) => {
        store.dispatch(
          'moduleApp/registerBraindate',
          {
            context: 'course',
            activity: 'stepGroupQuiz',
            lifeskillNumber: ls,
            courseId,
            stepGroupId,
            isBonus: idx ? true : false,
            bonusBraindates: idx ? [] : relatedLifeskills,
          },
          { root: true }
        )
      })
    }

    // from scenario actions
    if (mutation.type === 'scenario/' + types.SCENARIO_DONE) {
      const { stepId, lifeskillNumber, courseId, relatedLifeskills = [], scenarioId } = mutation.payload
      ;[lifeskillNumber, ...relatedLifeskills].forEach((ls, idx) => {
        store.dispatch(
          'moduleApp/registerBraindate',
          {
            context: 'course',
            activity: 'scenario',
            lifeskillNumber: ls,
            courseId,
            stepId,
            scenarioId,
            isBonus: idx ? true : false,
            bonusBraindates: idx ? [] : relatedLifeskills,
          },
          { root: true }
        )
      })
    }

    if (mutation.type === 'moduleMemogenius/' + types.MEMOGENIUS_SUCCESS) {
      const { gameId, levelIndex, testName } = mutation.payload
      if (['memoryOnboardingTestA', 'memoryOnboardingTestB'].indexOf(testName) === -1) {
        store.dispatch(
          'moduleApp/registerBraindate',
          {
            context: 'game',
            activity: 'quiz',
            lifeskillNumber: '02',
            gameId,
            levelIndex,
            isBonus: false,
            bonusBraindates: [],
          },
          { root: true }
        )

        store.dispatch('moduleApp/updateSelectedHabitMissionsOnBraindate', gameId)
      }
    }

    if (mutation.type === 'moduleApp/' + types.VIDEO_PART_COMPLETED) {
      const { lifeskillNumber, videoId, partIndex } = mutation.payload
      store.dispatch(
        'moduleApp/registerBraindate',
        {
          context: 'video',
          activity: 'watch',
          lifeskillNumber,
          videoId,
          partIndex,
          isBonus: false,
          bonusBraindates: [],
        },
        { root: true }
      )
    }

    if (mutation.type === 'moduleMentalhygiene/' + types.MH_COMPLETED) {
      const { step } = mutation.payload
      const today = moment().format('YYYY-MM-D')
      const activity = `step-${step}-${today}`
      if (state.moduleApp.braindates.find(bd => bd.context === 'mental-hygiene' && bd.activity === activity)) {
        return
      }

      const mapping = {
        '0': {
          lifeskill: '37',
          name: 'daily-joys',
        },
        '1': {
          lifeskill: '35',
          name: 'kiss-the-frog',
        },
        '2': {
          lifeskill: '01',
          name: 'focus-check',
        },
        '3': {
          lifeskill: '80',
          name: 'daily-plan',
        },
        '4': {
          lifeskill: '38',
          name: 'learning-diary',
        },
      }

      const info = mapping[step]
      let lifeskillNumber = ''

      if (info) {
        lifeskillNumber = info.lifeskill
        store.dispatch('moduleApp/updateSelectedHabitMissionsOnBraindate', info.name)
      }

      store.dispatch(
        'moduleApp/registerBraindate',
        {
          context: 'mental-hygiene',
          activity,
          lifeskillNumber: lifeskillNumber,
          isBonus: false,
          bonusBraindates: [],
        },
        { root: true }
      )
    }

    if (mutation.type === 'moduleMemolanguage/' + types.MEMOLANGUAGE_QUIZ_COMPLETED) {
      const { language, listId } = mutation.payload
      store.dispatch(
        'moduleApp/registerBraindate',
        {
          context: 'language',
          language,
          lifeskillNumber: '11',
          listId,
          activity: 'quiz',
          isBonus: false,
          bonusBraindates: [],
        },
        { root: true }
      )
    }
  })
}

export default braindatePlugin
