import moment from 'moment';
import * as types from '@/store/mutation-types';
import Vue from 'vue';
import gql from 'graphql-tag';
import apolloClient from '@/apollo-client';

const state = {
    now: moment(),
    joys: [],
    dayplans: [],
    learningDiary: [],
    focusChecks: [],
};

const getters = {
    now(state) {
      return state.now;
    },
    allJoys(state) {
        return state.joys;
    },
    joysTimesFinishedCount(state) {
        const unique = [...new Set(state.joys.map(item => item.createdAt.substring(0, 10)))];
        return unique.length;
    },
    frogsTimesFinishedCount(state) {
      return state.dayplans.reduce((acc, curr) => {
        if (curr.finishedFrogs > 0) {
          acc += 1;
        }
        return acc;
      }, 0);
    },
    focusChecksTimesFinishedCount(state) {
        return Math.floor(state.focusChecks.length / 4);
    },
    dayplansTimesFinishedCount(state) {
        var dailyPlans = state.dayplans.filter(function(item) {
            return item.createdAt.substring(0, 10)===item.date && item.plannedFrogs>0;
          });
        return dailyPlans.length;
    },
    diariesTimesFinishedCount(state) {
        return Math.floor(state.learningDiary.length / 4);
    },
    allFocusChecks(state) {
        return state.focusChecks;
    },
    dayplans(state) {
        return state.dayplans;
    },
    diaries(state) {
        return state.learningDiary;
    },
    todaysJoys(state, getters) {
        return state.joys
        .filter(item => moment(item.createdAt).isSame(getters.now, 'day'));
    },
    todaysDiary(state, getters) {
        return state.learningDiary
        .filter(item => moment(item.createdAt).isSame(getters.now, 'day'));
    },
    todaysFocusChecks(state, getters) {
        return state.focusChecks
        .filter(item => moment(item.createdAt).isSame(getters.now, 'day'));
    },
    dayplanToday(state, getters) {
      return state.dayplans
        .find(plan => moment(plan.date).isSame(getters.now, 'day'));
    },
    dayplanYesterday(state, getters) {
      const yesterday = moment(getters.now).subtract(1, 'days');
      return state.dayplans
        .find(plan => moment(plan.date).isSame(yesterday, 'day'));
    },
    isTotalPracticeFinished(state, getters) {
      const count =
      (
        parseInt(getters.joysTimesFinishedCount) +
        parseInt(getters.frogsTimesFinishedCount) +
        parseInt(getters.focusChecksTimesFinishedCount) +
        parseInt(getters.dayplansTimesFinishedCount) +
        parseInt(getters.diariesTimesFinishedCount)
      );
      return count >= 50;
    },
    lifekeyContext() {
      return 'mentalhygiene-practice';
    },
    hasAwardedLifekey(state, getters, rootState, rootGetters) {
      return rootGetters['moduleApp/lifekeys']
        .filter(l => l.context === getters.lifekeyContext)
        .length > 0;
    },

    journeyFinished(state, getters, rootState) {
      const id = 'aa5a756d-ae6f-4fef-bfa4-d443d8d07f8d';
      const j = rootState.moduleCourse.stepGroups[id];
      return j && j.quiz.done;
    },

    areJoysFinishedToday(state, getters) {
      return getters.todaysJoys.length > 0;
    },

    areFrogsFinishedToday(state, getters) {
      return getters.dayplanToday && getters.dayplanToday.finishedFrogs > 0;
    },

    areFocusChecksFinishedToday(state, getters) {
      return getters.todaysFocusChecks.length >= 4;
    },

    isDayplanFinishedToday(state, getters) {
      return getters.dayplanToday && getters.dayplanToday.plannedTasks > 0;
    },

    areDiariesFinishedToday(state, getters) {
      return getters.todaysDiary.length > 0;
    },

    doneToday(state, getters) {
      return getters.areJoysFinishedToday &&
        getters.areFrogsFinishedToday &&
        getters.areFocusChecksFinishedToday &&
        getters.isDayplanFinishedToday &&
        getters.areDiariesFinishedToday;
    }

};

let nowTimer;

const actions = {
    async load({ commit }) {
        if (!nowTimer) {
          nowTimer = setInterval(() => commit('now'), 60000); // every 60 seconds
        }
        const result = await apolloClient.query({
            fetchPolicy: 'no-cache',
            query: gql`
                query {
                    mentalHygieneJoysList {
                        joy
                        image
                        nodeId
                        createdAt
                    }
                    mentalHygieneFocusChecksList(orderBy: CREATED_AT_DESC) {
                        focusCheckType
                        rating
                        note
                        nodeId
                        createdAt
                    }
                    dayplans: mentalHygieneDayplansList {
                        done
                        createdAt
                        nodeId
                        date
                        finishedTasks
                        finishedFrogs
                        productivity
                        purpose
                        plannedTasks
                        plannedFrogs
                    }
                    mentalHygieneLearningDiariesList(orderBy: DATE_DESC, first: 300) {
                        date
                        nodeId
                        item
                        connotation
                        createdAt
                    }
                    mentalHygieneFrogDaysList {
                      count
                    }
                }
            `,
        });

        commit('setJoys', result.data.mentalHygieneJoysList);
        commit('setDayplans', result.data.dayplans);
        commit('setDiary', result.data.mentalHygieneLearningDiariesList);
        commit('setFocusCheck', result.data.mentalHygieneFocusChecksList);
    },

    async createFocusCheck({ commit, rootGetters }, { focusCheckType, rating, note }) {
        const userId = rootGetters['moduleAuth/profile'].id;
        const result = await apolloClient.mutate({
            fetchPolicy: 'no-cache',
            mutation: gql`
                mutation($userId: String!, $focusCheckType: FocusCheckType!, $rating: Int!, $note: String!) {
                    createMentalHygieneFocusCheck(
                        input: {
                            mentalHygieneFocusCheck: {
                                userId: $userId
                                focusCheckType: $focusCheckType
                                rating: $rating
                                note: $note
                            }
                        }
                    ) {
                        mentalHygieneFocusCheck {
                            nodeId
                            focusCheckType
                            rating
                            note
                            createdAt
                        }
                    }
                }
            `,
            variables: { userId, focusCheckType, rating, note }
        });
        commit('addFocusCheck', result.data.createMentalHygieneFocusCheck.mentalHygieneFocusCheck);
    },
    async updateFocusCheck({ commit }, { focusCheckType, rating, note, id }) {
        await apolloClient.mutate({
            fetchPolicy: 'no-cache',
            mutation: gql`
                mutation($id: ID!, $focusCheckType: FocusCheckType!, $rating: Int!, $note: String!) {
                    updateMentalHygieneFocusCheckByNodeId(
                        input: { patch: { focusCheckType: $focusCheckType, rating: $rating, note: $note }, nodeId: $id }
                    ) {
                        mentalHygieneFocusCheck {
                            id
                        }
                    }
                }
            `,
            variables: { focusCheckType, rating, note, id }
        });
        commit('updateFocusCheck', { focusCheckType, rating, note, id });
    },
    async deleteFocusCheck({ commit }, id) {
        await apolloClient.mutate({
            fetchPolicy: 'no-cache',
            mutation: gql`
                mutation($id: ID!) {
                    deleteMentalHygieneFocusCheckByNodeId(input: { nodeId: $id }) {
                        clientMutationId
                    }
                }
            `,
            variables: { id }
        });
        commit('deleteFocusCheck', id);
    },

    async addFrog(vuexContext, { userId, frog = 'frog' }) {
        await apolloClient.mutate({
            fetchPolicy: 'no-cache',
            mutation: gql`
                mutation($userId: String!, $frog: String!) {
                    createMentalHygieneFrog(input: { mentalHygieneFrog: { userId: $userId, frog: $frog } }) {
                        mentalHygieneFrog {
                            nodeId
                        }
                    }
                }
            `,
            variables: { userId, frog }
        });
    },

    async createDiary({ commit, rootGetters }, { connotation, item }) {
        const userId = rootGetters['moduleAuth/profile'].id;
        const result = await apolloClient.mutate({
            fetchPolicy: 'no-cache',
            mutation: gql`
                mutation($userId: String!, $connotation: LearningDiaryConnotation!, $item: String!) {
                    createMentalHygieneLearningDiary(
                        input: {
                            mentalHygieneLearningDiary: { userId: $userId, connotation: $connotation, item: $item }
                        }
                    ) {
                        mentalHygieneLearningDiary {
                            nodeId
                            createdAt
                            connotation
                            item
                        }
                    }
                }
            `,
            variables: { userId, connotation, item }
        });
        commit('addDiary', result.data.createMentalHygieneLearningDiary.mentalHygieneLearningDiary);
    },
    async updateDiary({ commit }, { item, id }) {
        await apolloClient.mutate({
            fetchPolicy: 'no-cache',
            mutation: gql`
                mutation($id: ID!, $item: String!) {
                    updateMentalHygieneLearningDiaryByNodeId(input: { patch: { item: $item }, nodeId: $id }) {
                        mentalHygieneLearningDiary {
                            id
                        }
                    }
                }
            `,
            variables: { item, id }
        });
        commit('updateDiary', { item, id });
    },
    async deleteDiary({ commit }, id) {
        await apolloClient.mutate({
            fetchPolicy: 'no-cache',
            mutation: gql`
                mutation($id: ID!) {
                    deleteMentalHygieneLearningDiaryByNodeId(input: { nodeId: $id }) {
                        clientMutationId
                    }
                }
            `,
            variables: { id }
        });
        commit('deleteDiary', id);
    },

    async createDayplan(
        { commit, rootGetters },
        { finishedTasks, finishedFrogs, productivity, purpose, plannedTasks, plannedFrogs, date}
    ) {
        const userId = rootGetters['moduleAuth/profile'].id;
        const result = await apolloClient.mutate({
            fetchPolicy: 'no-cache',
            mutation: gql`
                mutation(
                    $userId: String!
                    $finishedTasks: Int!
                    $finishedFrogs: Int!
                    $productivity: Int!
                    $purpose: Int!
                    $plannedTasks: Int!
                    $plannedFrogs: Int!
                    $date: Date
                ) {
                    createMentalHygieneDayplan(
                        input: {
                            mentalHygieneDayplan: {
                                userId: $userId
                                finishedTasks: $finishedTasks
                                finishedFrogs: $finishedFrogs
                                productivity: $productivity
                                purpose: $purpose
                                plannedTasks: $plannedTasks
                                plannedFrogs: $plannedFrogs
                                done: false
                                date: $date
                            }
                        }
                    ) {
                        mentalHygieneDayplan {
                            createdAt
                            nodeId
                            finishedTasks
                            finishedFrogs
                            productivity
                            purpose
                            plannedTasks
                            plannedFrogs
                            date
                        }
                    }
                }
            `,
            variables: { userId, finishedTasks, finishedFrogs, productivity, purpose, plannedTasks, plannedFrogs, date }
        });
        commit('addDayplan', result.data.createMentalHygieneDayplan.mentalHygieneDayplan);
    },

    async updateDayplan(
        { commit, state },
        { nodeId, finishedTasks, finishedFrogs, productivity, purpose, plannedTasks, plannedFrogs }
    ) {
        await apolloClient.mutate({
            fetchPolicy: 'no-cache',
            mutation: gql`
                mutation(
                    $nodeId: ID!
                    $finishedTasks: Int!
                    $finishedFrogs: Int!
                    $productivity: Int!
                    $purpose: Int!
                    $plannedTasks: Int!
                    $plannedFrogs: Int!
                ) {
                    updateMentalHygieneDayplanByNodeId(
                        input: {
                            patch: {
                                finishedTasks: $finishedTasks
                                finishedFrogs: $finishedFrogs
                                productivity: $productivity
                                purpose: $purpose
                                plannedTasks: $plannedTasks
                                plannedFrogs: $plannedFrogs
                            }
                            nodeId: $nodeId
                        }
                    ) {
                        mentalHygieneDayplan {
                            id
                            finishedTasks
                            finishedFrogs
                            productivity
                            purpose
                            plannedTasks
                            plannedFrogs
                        }
                    }
                }
            `,
            variables: { finishedTasks, finishedFrogs, productivity, purpose, plannedTasks, plannedFrogs, nodeId }
        });
        commit('updateDayplan', {
            finishedTasks,
            finishedFrogs,
            productivity,
            purpose,
            plannedTasks,
            plannedFrogs,
            nodeId,
        });
    },

    async createJoy({ commit, rootGetters }, { joy }) {
        const userId = rootGetters['moduleAuth/profile'].id;
        const result = await apolloClient.mutate({
            fetchPolicy: 'no-cache',
            mutation: gql`
                mutation($userId: String!, $joy: String!) {
                    createMentalHygieneJoy(input: { mentalHygieneJoy: { userId: $userId, joy: $joy } }) {
                        mentalHygieneJoy {
                            nodeId
                            joy
                            image
                            createdAt
                        }
                    }
                }
            `,
            variables: { userId, joy }
        });
        commit('addJoy', result.data.createMentalHygieneJoy.mentalHygieneJoy);
    },
    async updateJoy({ commit }, { joy, id, image }) {
        await apolloClient.mutate({
            fetchPolicy: 'no-cache',
            mutation: gql`
                mutation($id: ID!, $joy: String!, $image: JSON) {
          updateMentalHygieneJoyByNodeId(input: { patch: { joy: $joy, image: $image }, nodeId: $id }) {
                        mentalHygieneJoy {
                            id
                            joy
                            image
                        }
                    }
                }
            `,
            variables: { joy, id, image }
        });
        commit('updateJoy', { joy, id, image });
    },
    async deleteJoy({ commit }, id) {
        //commiting here because of the problem when deleting a card in shuffle mode -- needs fixing
        commit('deleteJoy', id);
        await apolloClient.mutate({
            fetchPolicy: 'no-cache',
            mutation: gql`
                mutation($id: ID!) {
                    deleteMentalHygieneJoyByNodeId(input: { nodeId: $id }) {
                        clientMutationId
                    }
                }
            `,
            variables: { id }
        });
    },
    async deleteJoys({ state }) {
        state.joys.forEach(joy => {
            apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: gql`
                    mutation($id: ID!) {
                        deleteMentalHygieneJoyByNodeId(input: { nodeId: $id }) {
                            clientMutationId
                        }
                    }
                `,
                variables: { id: joy.nodeId }
            });
        });
    }
};

const mutations = {
    now(state) {
      Vue.set(state, 'now', moment());
    },
    [types.MH_COMPLETED](state, { step }) {
      // see vuex braindate plugin
      console.log(types.MH_COMPLETED);
    },
    [types.MH_DAILY_COMPLETED](state) {
      // see vuex lifekey plugin
      console.log(types.MH_DAILY_COMPLETED);
    },
    setJoys(state, joys) {
        state.joys = joys;
    },
    addJoy(state, joy) {
        state.joys.push(joy);
    },
    setFrogs(state, frogs) {
        state.frogs = frogs;
    },
    setFrogDays(state, c) {
        state.frogDays = c;
    },
    setDayplans(state, dayplans) {
        state.dayplans = dayplans;
    },
    updateJoy(state, { joy, id, image }) {
        const j = state.joys.find(joy => joy.nodeId === id);
        j.joy = joy;
        j.image = image;
    },
    deleteJoy(state, id) {
        const index = state.joys.findIndex(joy => joy.nodeId === id);
        state.joys.splice(index, 1);
    },
    addDayplan(state, dayplan) {
      state.dayplans.push(dayplan);
    },
    updateDayplan(state, update) {
      const dp = state.dayplans.find(d => d.nodeId === update.nodeId);
      if (dp) {
        Object.assign(dp, update);
      }
    },
    setDiary(state, diary) {
        state.learningDiary = diary;
    },
    addDiary(state, diary) {
        state.learningDiary.push(diary);
    },
    updateDiary(state, { item, id }) {
        const j = state.learningDiary.find(diary => diary.nodeId === id);
        j.item = item;
    },
    deleteDiary(state, id) {
        const index = state.learningDiary.findIndex(diary => diary.nodeId === id);
        state.learningDiary.splice(index, 1);
    },
    setFocusCheck(state, focusCheck) {
        state.focusChecks = focusCheck;
    },
    addFocusCheck(state, focusCheck) {
        state.focusChecks.push(focusCheck);
    },
    updateFocusCheck(state, { focusCheckType, rating, note, id }) {
        const j = state.focusChecks.find(focusCheck => focusCheck.nodeId === id);
        j.rating = rating;
        j.focusCheckType = focusCheckType;
        j.note = note;
    },
    deleteFocusCheck(state, id) {
        const index = state.focusChecks.findIndex(focusCheck => focusCheck.nodeId === id);
        state.focusChecks.splice(index, 1);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
