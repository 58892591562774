import Vue from 'vue'
import apolloClient from '@/apollo-client'
import gql from 'graphql-tag'
import * as types from '../mutation-types'
import { updateUserSetting, updateUserSettingDebounced } from '../persistence'
import capitalize from 'lodash/capitalize'
import moment from 'moment'

const gameIds = ['faces', 'shopping', 'bankvault', 'brainstorm', 'timemachine', 'vocabguru']

const state = {
  gameIds,
  newSystemIds: ['single', 'double', 'alphabet', 'rhyme', 'olpa', 'journey'],
  newToOldSystemIdMap: {
    rhyme: 'rhymesys',
    double: 'doublesys',
    single: 'singlesys',
    olpa: 'olpasys',
    alphabet: 'alphabetsys',
    journey: 'journeysys',
    name: 'namesys'
  },
  settings: {
    backgroundSoundVolume: 0.5,
    effectSoundVolume: 1,
    memoryOnboardingTestA: null,
    memoryiqTheory: null,
    memoryOnboardingTestB: null
  },
  systems: [
    { id: 'singlesys', system: 'single' },
    { id: 'doublesys', system: 'double' },
    { id: 'olpasys', system: 'olpa' },
    { id: 'alphabetsys', system: 'alphabet' },
    { id: 'chainsys', system: '' },
    { id: 'clonesys', system: '' },
    { id: 'highlightsys', system: '' },
    { id: 'journeysys', system: 'journey' },
    { id: 'knowledgesys', system: '' },
    { id: 'namesys', system: 'name' },
    { id: 'rhymesys', system: 'rhyme' },
    { id: 'similaritysys', system: '' },
    { id: 'symbolizationsys', system: '' },
    { id: 'timesys', system: '' }
  ],
  memogenius: {
    games: gameIds
  },
  games: (function () {
    let map = {}
    gameIds.forEach(gameId => {
      map[gameId] = {
        id: gameId,
        title: '',
        title_no: '', 
        relatedLifeskills: []
      }
    })
    map.bankvault.relatedLifeskills.push('01')
    map.faces.relatedLifeskills.push('79')
    map.brainstorm.relatedLifeskills.push('88')
    map.shopping.relatedLifeskills.push('80')

    map.bankvault.title = 'Bank Vault'
    map.bankvault.title_no = 'Bankhvelvet'
    map.faces.title = 'Social Star'
    map.faces.title_no = 'Navnemester'
    map.brainstorm.title = 'Brainstorming'
    map.brainstorm.title_no = 'Brainstorming'
    map.shopping.title = 'Supermarket'
    map.shopping.title_no = 'Handlelisten'
    map.timemachine.title = 'Time Travel'
    map.timemachine.title_no = 'Tidsmaskin'
    map.vocabguru.title = 'Vocab Guru'
    map.vocabguru.title_no = 'Gloseguru'

    return map
  }()),
  initialUserstateLoaded: false
}

const getters = {
  getGameIds: state => state.memogenius.games,
  getSystemIds: state => state.systems.map(s => s.id),

  getLevelsByGameId: (state, getters, rootState, rootGetters) => (gameId) => {
    const moduleName = `moduleGame${gameId[0].toUpperCase()}${gameId.substr(1)}`

    const appState = rootState[moduleName]
    const currentLevelIndex = rootGetters[`${moduleName}/currentLevelIndex`]
    return appState.levelGroups.map(levelGroup => {
      return {
        number: levelGroup.number,
        locked: levelGroup.levelIndices[0] > currentLevelIndex,
        sublevels: levelGroup.levelIndices.map(levelIndex => {
          const level = appState.levels[levelIndex]
          return {
            index: level.index,
            locked: level.index > currentLevelIndex,
            stars: level.stars,
            time: level.bestTime
          }
        })
      }
    })
  },
  getHighestCompletedLevelInfo: (state, getters) => (gameId) => {
    const levels = getters.getLevelsByGameId(gameId)
    let result = null

    mainLoop: // eslint-disable-line no-labels
    for (let levelIndex = levels.length - 1; levelIndex >= 0; levelIndex--) {
      const sublevels = levels[levelIndex].sublevels
      for (let sublevelIndex = sublevels.length - 1; sublevelIndex >= 0; sublevelIndex--) {
        const sublevel = sublevels[sublevelIndex]
        if (sublevel.stars) {
          result = {
            levelNumber: levelIndex + 1,
            sublevelNumber: sublevelIndex + 1,
            stars: sublevel.stars
          }
          break mainLoop // eslint-disable-line no-labels
        }
      }
    }
    return result
  },

  getNextAvailableGameInfo: (state, getters) => (gameId) => {
    const levels = getters.getLevelsByGameId(gameId)

    let levelNumber = 0
    let sublevelNumber = 0

    mainLoop: // eslint-disable-line no-labels
    for (let levelIndex = levels.length - 1; levelIndex >= 0; levelIndex--) {
      let sublevels = levels[levelIndex].sublevels
      for (let sublevelIndex = sublevels.length - 1; sublevelIndex >= 0; sublevelIndex--) {
        let sublevel = sublevels[sublevelIndex]
        if (!sublevel.locked) {
          if (levelIndex === levels.length - 1 && sublevelIndex === sublevels.length - 1 && !sublevel.stars) {
            // NOTE: this sublevel is the very last one AND has been completed, we do not report level and sublevel numbers
          } else {
            levelNumber = levelIndex + 1
            sublevelNumber = sublevelIndex + 1
          }
          break mainLoop // eslint-disable-line no-labels
        }
      }
    }
    if (!levelNumber) {
      return null
    }
    return {
      levelNumber,
      sublevelNumber
    }
  }
}

const actions = {
  init ({ dispatch }) {
    dispatch('loadSettings')
    dispatch('loadGameScores')
  },

  loadGameScores ({ state, commit, dispatch }) {
    if (state.initialUserstateLoaded) {
      return
    }
    commit('setInitialUserstateLoaded', { value: true })
    console.info('fetch game scores')
    return apolloClient.query({
      query: gql`
        query
        {
          memogeniusGameHighscoresList {
            game
            level
            stars
            time {
              seconds
              minutes
            }
          }
        }`
    })
      .then(result => result.data)
      .then(data => {
        data.memogeniusGameHighscoresList.forEach(item => {
          console.info('loading scores for', item.game)
          const moduleName = `moduleGame${capitalize(item.game)}`
          const bestTime = moment.duration({
            minutes: item.minutes || 0,
            seconds: item.seconds || 0
          })
          commit(`${moduleName}/updateLevelUserstate`,
            {
              levelIndex: item.level,
              data: { stars: parseInt(item.stars), bestTime: bestTime.milliseconds() }
            },
            { root: true })
        })
      }).catch(err => {
        console.error(err)
        commit('setInitialUserstateLoaded', { value: false })
        setTimeout(() => { dispatch('loadGameScores') }, 1000)
      })
  },

  loadSettings ({ commit, state }) {
    const prefix = 'memogenius:'
    return apolloClient.query({
      query: gql`
        query {
          userSettingsList {
            key
            value
          }
        }`
    }).then(result => result.data)
      .then(data => {
        commit(
          types.MEMOGENIUS_UPDATE_SETTINGS,
          {
            settings: data.userSettingsList
              .filter(setting => setting.key.startsWith(prefix))
              .reduce((acc, curr, i) => {
                const key = curr.key.split(prefix)[1]
                acc[key] = curr.value
                return acc
              }, {})
          })
        data.userSettingsList
          .filter(setting => setting.key.startsWith('questionServiceState'))
          .forEach(setting => {
            const gameId = setting.key.split(':')[1]
            const moduleName = `moduleGame${capitalize(gameId)}`
            commit(`${moduleName}/updateQuestionServiceState`,
              setting.value,
              { root: true })
          })
      })
  },

  updateSetting ({ commit, state }, { key, value }) {
    return updateUserSetting('memogenius:', key, value)
  },

  updateSettingDebounced ({ commit, state }, { key, value }) {
    return updateUserSettingDebounced('memogenius:', key, value)
  },

  setBackgroundSoundVolume ({ commit, dispatch }, volume) {
    commit(types.MEMOGENIUS_UPDATE_SETTINGS, { settings: { backgroundSoundVolume: volume } })
    dispatch('updateSettingDebounced', { key: 'backgroundSoundVolume', value: volume })
  },

  setEffectSoundVolume ({ commit, dispatch }, volume) {
    commit(types.MEMOGENIUS_UPDATE_SETTINGS, { settings: { effectSoundVolume: volume } })
    dispatch('updateSettingDebounced', { key: 'effectSoundVolume', value: volume })
  },

  storeMemoryOnboardingResult ({ commit, dispatch, state, rootState, rootGetters }, { testName, data }) {
    if (testName === 'memoryOnboardingTestA') {
      const settings = { totalCount: data.totalCount, correctCount: data.correctCount, timeUsed: data.timeUsed }
      commit(types.MEMOGENIUS_UPDATE_SETTINGS, { settings: { memoryOnboardingTestA: settings } })
      dispatch('updateSetting', { key: 'memoryOnboardingTestA', value: settings })
    } else if (testName === 'memoryiqTheory') {
      const settings = true
      commit(types.MEMOGENIUS_UPDATE_SETTINGS, { settings: { memoryiqTheory: settings } })
      dispatch('updateSetting', { key: 'memoryiqTheory', value: settings })
    } else if (testName === 'memoryOnboardingTestB') {
      const settings = { totalCount: data.totalCount, correctCount: data.correctCount, timeUsed: data.timeUsed }
      commit(types.MEMOGENIUS_UPDATE_SETTINGS, { settings: { memoryOnboardingTestB: settings } })
      dispatch('updateSetting', { key: 'memoryOnboardingTestB', value: settings })
    }

    // --------------------------------------
    // Awarding life key for memoryiq
    // --------------------------------------

    if (testName === 'memoryiqTheory') {
      const alreadyAwarded = rootGetters['moduleApp/lifekeys'].some(key => key.context === 'memoryiqTheory')
      if (!alreadyAwarded) {
        dispatch('moduleApp/awardLifekey', { lifeskill: '02', context: 'memoryiqTheory' }, { root: true })
      }
    }
    if (testName === 'memoryOnboardingTestB') {
      const alreadyAwarded = rootGetters['moduleApp/lifekeys'].some(key => key.context === 'memoryiq')
      if (!alreadyAwarded) {
        dispatch('moduleApp/awardLifekey', { lifeskill: '02', context: 'memoryiq' }, { root: true })
      }
    }
  }
}

const mutations = {
  [types.MEMOGENIUS_SUCCESS](state, { gameId, levelIndex, testName, }) {
    console.log(types.MEMOGENIUS_SUCCESS, { gameId, levelIndex, testName });
  },

  [types.MEMOGENIUS_UPDATE_SETTINGS] (state, { settings, clear = false }) {
    if (clear) {
      settings = Object.assign({
        backgroundSoundVolume: 0.5,
        effectSoundVolume: 1,
        memoryOnboardingTestA: null,
        memoryiqTheory: null,
        memoryOnboardingTestB: null
      }, settings)
    }
    if (!clear) {
      settings = Object.assign(state.settings, settings)
    }
    Vue.set(state, 'settings', settings)
  },

  setInitialUserstateLoaded (state, { value = true }) {
    state.initialUserstateLoaded = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
