import localesPreprocess from '@/locales-preprocess';

export default {
    install(Vue) {
        // NOTE: Important to register this mixin BEFORE Vue.use(VueI18n) !
        Vue.mixin({
            beforeCreate: function () {
                if (this.$options.translations) {
                    this.$options.i18n = {
                        messages: localesPreprocess(this.$options.translations)
                        // messages: {
                        //   en: this.$options.translations
                        // }
                    };
                }
            }
        });
    }
};
