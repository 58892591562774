import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';

import dashboardRoutes from '@/modules/dashboard/routes';
import courseRoutes from '@/modules/course/routes';
import memolanguageRoutes from '@/modules/memolanguage/routes';
import accountRoutes from '@/modules/account/routes';
import socialRoutes from '@/modules/social/routes';
import memoransierRoutes from '@/modules/memoransier/routes';
import memoryIQRoutes from '@/modules/memoryiq_new/routes'
import memogeniusGameRoutes from '@/modules/memogenius-game/routes';
import systemNameRoutes from '@/modules/system-name/routes';
import systemAlphabetRoutes from '@/modules/system-alphabet/routes';
import systemRhymeRoutes from '@/modules/system-rhyme/routes';
import systemSingleRoutes from '@/modules/system-single/routes';
import systemDoubleRoutes from '@/modules/system-double/routes';
import systemOlpaRoutes from '@/modules/system-olpa/routes';
import systemJourneyRoutes from '@/modules/system-journey/routes';
import teamRoutes from '@/modules/team/routes';
import mentalhygieneRoutes from '@/modules/mentalhygiene/routes';
import scenarioRoutes from '@/modules/scenario/routes';

Vue.use(Router);

const branch = process.env.GIT_BRANCH;

const router = new Router({
    mode: 'history',
    base: window.location.pathname.startsWith(`/${branch}/`) ? `/${branch}/` : '/',
    // saveScrollPosition: true, // TODO

    routes: [
        {
            path: '/games',
            component: () => import('@/GamesView'),
        },

        {
            path: '/systems',
            component: () => import('@/SystemsView'),
        },

        {
            path: '/',
            redirect: { name: 'DashboardHomeView' }
        },
        {
            path: '/repetitions',
            name: 'Repetitions',
            component: () => import('@/modules/repetition'),
        },
        {
            path: '/-dummy-',
            name: 'RouteDataError',
            redirect: { name: 'DashboardHomeView' }
        },
        {
            path: '/-dummy-',
            name: 'RouteAccessError',
            redirect: { name: 'DashboardHomeView' }
        },

        {
            path: '/generic-redirect',
            name: 'GenericRedirectView',
            component: () => import('@/components/GenericRedirectView'),
        },

        {
            path: '/memobot',
            name: 'MemoBot',
            component: () => import('@/modules/game-faces/MemoBot'),
            meta: {
                requiresAuth: true
            }
        },

        {
          path: '/leaderboard',
          name: 'Leaderboard',
          component: () => import('@/modules/dashboard/views/LeaderboardsView'),
        },

        ...dashboardRoutes,
        ...courseRoutes,
        ...memolanguageRoutes,
        ...accountRoutes,
        ...socialRoutes,
        ...memoransierRoutes,
        ...memoryIQRoutes,

        ...systemNameRoutes,

        ...memogeniusGameRoutes,

        {
            path: '/systems/dummy',
            name: 'MemogeniusSystem',
            beforeEnter(to, from, next) {
                const systemId = to.params.systemId;
                const newSystemId = store.state.moduleMemogenius.systems.find(s => s.id === systemId).system;
                next({ path: `/system/${newSystemId}`, xreplace: true });
            }
        },
        ...systemAlphabetRoutes,
        ...systemRhymeRoutes,
        ...systemSingleRoutes,
        ...systemDoubleRoutes,
        ...systemOlpaRoutes,
        ...systemJourneyRoutes,
        ...teamRoutes,
        ...mentalhygieneRoutes,
        ...scenarioRoutes,

        {
            path: '*',
            redirect: { name: 'DashboardHomeView' },
            meta: {
                requiresAuth: false
            }
        }
    ]
});

router.beforeEach((to, from, next) => {
  if (window.swUpdate) {
    console.log('app updated, reloading');
    return (window.location = to.fullPath);
  }

  store.commit('routerStartNavigation');
  const isAuthenticated = store.getters['moduleAuth/isAuthenticated'];

  // NOTE: Since most routes require auth, meta.requiresAuth as undefined equals true
  if (to.matched.some(record => record.meta.requiresAuth !== false)) {
    if (!isAuthenticated) {
      next({
        path: '/login',
        query: { next: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

router.afterEach(() => {
  store.commit('routerEndNavigation');
});

router.onError(() => {
  store.commit('routerEndNavigation');
  // NOTE: If already on /home, nothing will happen here
  router.push({ name: 'DashboardHomeView' });
});

router.onReady(function () {});

export default router;
