const OverviewView = () => import('./views/OverviewView');
const GroupView = () => import('./views/GroupView');
const ChallengeView = () => import('./views/ChallengeView');
const GroupInvitationView = () => import('./views/GroupInvitationView');

export default [
    {
        path: '/social/overview',
        component: OverviewView
    },
    {
        path: '/social/group/:groupId',
        props: true,
        component: GroupView
    },
    {
        path: '/social/group/:groupId/challenge/:challengeId',
        props: true,
        component: ChallengeView
    },
    {
        path: '/social/group/:groupId/invitation/:groupInvitationId',
        props: true,
        component: GroupInvitationView
    }
];
