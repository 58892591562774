<template>
  <div class="root">
    <div>HomeView</div>
    <br>
    <ButtonPlain
      label="Clear learning progress"
      @click="clearLearningProgress()" />
  </div>
</template>

<script>
import ButtonPlain from '../components/ButtonPlain';

export default {
    components: { ButtonPlain },
    props: ['instance'],
    methods: {
        clearLearningProgress() {
            const vm = this.instance;
            Object.keys(vm.module.state.items).forEach(itemId => {
                vm.module.dispatch('setLearnStatus', { itemId, value: 0 });
            });
            this.$emit('request-close');
        }
    }
};
</script>

<style lang="scss" scoped>
.root {
    padding: 1em;
}
</style>
