export default {
    courseIds: [],

    progressMapping: null,
    courses: {},
    stepGroups: {},
    steps: {},
    memos: {},
    scenarios: {},

    stepGroupQuizStates: {},

    otherStuff: {
        lastStepId: null,
        lastJourneyId: null
    }
};
