/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'flag': {
    width: 12,
    height: 14,
    viewBox: '0 0 12 14',
    data: '<path pid="0" d="M1.995 14.008L0 1.14 6.927 0l1.105 7.049-5.93 1.024.858 5.772-.965.163zM7.004 9L8.91 7.353l-.827-4.955 2.87-.402 1.033 6.301L7.004 9z" _fill="#FFF" fill-rule="evenodd"/>'
  }
})
