/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'q': {
    width: 35.438,
    height: 48.66,
    viewBox: '0 0 35.438 48.66',
    data: '<path pid="0" d="M.002 25.45c0 12.22 6.912 19.41 17.612 19.41a18.417 18.417 0 007.165-1.38l3.725 5.17h6.944l-5.776-8.14c3.566-3.32 5.618-8.43 5.618-15.06v-5.99C35.29 7.27 28.346.01 17.614.01 6.914.01.002 7.24.002 19.46v5.99zm6.439-5.99c0-9.25 4.829-13.8 11.173-13.8 6.408 0 11.237 4.55 11.237 13.8v6.02c0 4.39-1.1 7.67-2.9 9.92l-3.282-4.39h-6.755l5.524 7.67a9.77 9.77 0 01-3.883.76c-6.313-.07-11.11-4.61-11.11-13.96v-6.02z"/>'
  }
})
