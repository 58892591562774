/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'getting-started-icons/scenario': {
    width: 61,
    height: 56,
    viewBox: '0 0 61 56',
    data: '<g _fill="#5081FF" fill-rule="nonzero"><path pid="0" d="M22.527 17.064a15.602 15.602 0 00-4.837-1.044 14.483 14.483 0 00-5.534.779l-9.671 3.512C.86 20.901.163 22.61.925 24.126l4.54 9.04a13.887 13.887 0 003.466 4.28 15.544 15.544 0 006.043 3.07c5.904 1.53 11.959-.664 14.869-5.17-4.214-2.579-12.98-7.774-17.15-9.02 1.59-.033 12.452 3.861 18.368 6.485 2.03-6.147-1.654-13.071-8.534-15.747z"/><path pid="1" d="M56.569.018L43.19 1.258a18.81 18.81 0 00-6.94 2.148 19.776 19.776 0 00-5.06 3.897c-2.324 2.476-3.838 5.363-4.573 8.325 4.01 2.443 6.751 6.251 7.68 10.397 5.475-5.927 12.322-12.663 13.81-13.328-3.695 3.342-9.817 12.32-13.551 18.036a13.709 13.709 0 01-.51 2.43c5.96 3.146 13.877 2.234 19.649-2.606 2.38-1.995 4.149-4.39 5.295-6.932 1.009-2.243 1.535-4.608 1.57-6.923l.057-13.085c.01-2.195-1.803-3.807-4.048-3.599zM33.095 36c-.3.388-.645.874-.645.874-.256.389-.54.76-.833 1.121A55.075 55.075 0 0132.454 56h7.163c-.687-7.045-2.526-13.626-5.26-19.436-.43-.173-.85-.36-1.262-.564z"/></g>'
  }
})
