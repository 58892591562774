/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'surnames': {
    width: 88,
    height: 106,
    viewBox: '0 0 88 106',
    data: '<g fill-rule="nonzero"><path pid="0" d="M69.563 65.858l.118-.118.355-.236.591-.47.355-.354.592-.472.355-.353.473-.471.355-.354.473-.471.473-.471.474-.472.473-.47.473-.472.473-.471.474-.472.473-.471.355-.471.473-.59.355-.47.355-.59.355-.471.355-.59.354-.588.355-.59.237-.588.355-.59.237-.589.354-.706.119-.236.473.471c1.065.471 2.721-1.296 3.786-3.77s1.183-4.948.118-5.42c-.71-.235-1.538.354-2.484 1.414l-.828-.118.828.118C85.06 24.15 76.543 13.43 76.543 13.43s-.592-10.84-4.496-8.837c-3.312 1.65-4.14 1.414-4.14 1.414s-1.893-9.661-4.97-4.477C61.282 4.239 52.29 4.71 44.719 11.19c-12.422 10.722-8.4 30.633-7.808 33.46-.71-.942-1.42-1.413-2.011-1.178-1.065.354-1.183 2.71-.355 5.302.828 2.592 2.485 4.477 3.55 4.124.354-.118.59-.471.827-1.06 2.603 6.244 7.454 11.428 12.068 14.609-1.657.353-2.721.59-2.721.59s-19.284 6.95-13.014 13.548c6.152 6.598 21.532 23.21 21.532 23.21s2.01 2.71 4.968 2.121l.237-.118.473-.117.355-.118.473-.236.474-.236.355-.235.828-.707c4.732-4.477 20.94-23.093 20.94-23.093s10.766-8.365-16.326-15.198z"/><path pid="1" d="M28.387 76.304c1.198-5.07 7.786-8.96 12.577-11.083a39.01 39.01 0 01-5.75-7.663c-2.635-.59-4.91-3.065-6.228-6.838-.479-1.415-1.437-5.188 0-8.017a6.876 6.876 0 011.318-1.769c-.6-5.07-.719-12.732 1.796-19.924-4.91-.118-10.18.825-13.774 2.947-3.713 2.122-6.468 3.537-10.42 10.493-3.953 6.602-.959 20.043 2.395 24.994 3.234 4.952.599 6.367-2.515 8.607C5.15 69.937 0 68.404 0 68.404s.599 2.83 2.515 4.009c3.354 2.004 4.911.707 3.354 2.004 0 0-2.036 1.297 2.276 4.244l2.755-.235c-2.036 1.886-3.114 3.89-1.078 6.012 5.27 5.542 18.445 19.69 18.445 19.69s2.037 2.829 5.15 1.532l-.239.236c.839-.118 1.797-.59 2.755-1.533 1.437-1.297 4.192-4.244 7.067-7.428-4.432-4.715-9.343-10.139-12.337-13.204-2.156-2.122-2.875-4.834-2.276-7.427z"/></g>'
  }
})
