<template>
  <transition
    name="modal"
    @after-leave="afterLeave">
    <div
      v-if="modalSession"
      class="ModalContainer"
      :class="{ fullscreen }">
      <div
        class="mask"
        :style="{ backgroundColor: maskColor }"
        @click="modalClose('cancel')" />

      <transition
        name="xxx"
        appear
        mode="out-in">
        <component
          :is="modalSession.component"
          v-if="fullscreen"
          class="componentFullscreen"
          v-bind="modalSession.props"
          @signal="modalClose"
          @close="modalClose" />
        <StandardPopupBox
          v-else
          :key="modalSessionId"
          class="component"
          :class="`alignment-${alignment}`"
          @close="modalClose('cancel')">
          <component
            :is="modalSession.component"
            class="componentInner"
            v-bind="modalSession.props"
            @signal="modalClose"
            @close="modalClose" />
        </StandardPopupBox>
      </transition>
    </div>
  </transition>
</template>

<script>
import { ModalHostMixin } from '@/plugins/modal-helper';
import StandardPopupBox from '@/components/StandardPopupBox';

export default {
    components: { StandardPopupBox },
    mixins: [ModalHostMixin],
    props: {
        fullscreen: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        alignment() {
            return this.modalSession.hostOptions.alignment || 'center';
        },
        maskColor() {
            return this.modalSession.hostOptions.maskColor || null;
        }
    },
    methods: {
        afterLeave() {}
    }
};
</script>

<style lang="scss" scoped>
$transitionDuraction: 0.3s;

.ModalContainer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    transition: opacity $transitionDuraction ease;

    &.modal-enter .mask,
    &.modal-leave-active .mask {
        opacity: 0;
    }

    &.modal-leave-active .component {
        transform: translate3d(0, 100px, 0);
        opacity: 0;
    }
}

.mask {
    background-color: rgba(black, 0.8);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: opacity $transitionDuraction ease;
}

.component {
    position: relative;
    transition: transform $transitionDuraction ease, opacity $transitionDuraction;
    margin: auto;

    &.alignment-top {
        margin-top: 0;
    }
    &.alignment-bottom {
        margin-bottom: 0;
    }
    &.alignment-top-right {
        margin-top: 0;
        margin-right: 0;
    }

    &.xxx-enter,
    &.xxx-leave-active {
        transform: translate3d(0, 100px, 0);
        opacity: 0;
    }
}

.componentFullscreen {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}

@include media('>=mobile') {
    .ModalContainer {
        padding: 2em;
    }
    .componentInner {
        max-height: calc(100vh - 4em);
        max-height: calc(var(--vh, 1vh) * 100 - 4em);
        max-width: calc(100vw - 4em);
    }
}

@include media('<mobile') {
    .ModalContainer {
        padding: 1em;
    }
    .componentInner {
        max-height: calc(100vh - 2em);
        max-height: calc(var(--vh, 1vh) * 100 - 2em);
        max-width: calc(100vw - 2em);
    }
}
</style>
