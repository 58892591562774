/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bookmark': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M4 1v15l4-5.03L12 16V1a1 1 0 00-1-1H5a1 1 0 00-1 1zm0 0"/>'
  }
})
