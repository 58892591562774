/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'book': {
    width: 64,
    height: 55,
    viewBox: '0 0 64 55',
    data: '<path pid="0" d="M7.619 0v44.306c9.747-.053 17.01 5.278 22.857 10.169V9.167C24.006 3.73 16.734-.008 7.62 0zm48.762 0c-9.115-.008-16.387 3.73-22.857 9.167v45.308c5.846-4.891 13.11-10.222 22.857-10.17V0zM0 6.111v44.306L26.667 55c-5.068-3.831-11.461-7.68-19.048-7.639H6.095c-.798 0-1.523-.728-1.524-1.528V6.756L0 6.11zm64 0l-4.571.645v39.077c0 .8-.726 1.528-1.524 1.528H56.38C48.794 47.32 42.401 51.17 37.333 55L64 50.417V6.11z" fill-rule="nonzero"/>'
  }
})
