/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'known-word': {
    width: 15,
    height: 16,
    viewBox: '0 0 15 16',
    data: '<path pid="0" d="M14.027 1.07a1.002 1.002 0 00-1.366.36L6.266 12.51l-3.578-3.3a1.001 1.001 0 00-1.414-.04.995.995 0 00-.046 1.41L5.8 14.8a1 1 0 001.413.04c.122-.11 7.18-12.41 7.18-12.41a.994.994 0 00-.366-1.36z" fill-rule="evenodd"/>'
  }
})
