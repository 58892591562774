export default function watchForHover() {
    let hasHoverClass = false;
    let container = document.body;
    let lastTouchTime = 0;

    function enableHover() {
        // filter emulated events coming from touch events
        if (new Date() - lastTouchTime < 500) return;
        if (hasHoverClass) return;

        container.classList.remove('hover-disabled');
        container.classList.add('hover-enabled');
        hasHoverClass = true;
    }

    function disableHover() {
        if (!hasHoverClass) return;

        container.classList.remove('hover-enabled');
        container.classList.add('hover-disabled');
        hasHoverClass = false;
    }

    function updateLastTouchTime() {
        lastTouchTime = new Date();
    }

    document.addEventListener('touchstart', updateLastTouchTime, true);
    document.addEventListener('touchstart', disableHover, true);
    document.addEventListener('mousemove', enableHover, true);

    enableHover();
}
