<template>
  <div
    v-if="belt.current"
    class="NextBeltProgressText">
    {{ $tc('progressText', belt.next.requiredPoints - belt.points, { beltGrade: nextBeltGrade, beltNumber }) }}
  </div>
  <div
    v-else
    class="NextBeltProgressText">
    {{ $tc('firstBeltProgressText', belt.next.requiredPoints - belt.points) }}
  </div>
</template>

<translations>
  progressText: '{count} life key left for the {beltNumber} {beltGrade} belt! | {count} life keys left for the {beltNumber} {beltGrade} belt!'
  progressText_no: '{count} livsnøkkel til det {beltNumber} {beltGrade} beltet! | {count} livsnøkler til det {beltNumber} {beltGrade} beltet!'

  firstBeltProgressText: '{count} life key left for your first belt | {count} life keys left for your first belt'
  firstBeltProgressText_no: '{count} livsnøkkel til ditt første belte | {count} livsnøkler til ditt første belte'
</translations>

<script>
export default {
    props: {
        belt: {
            type: Object,
            required: true
        }
    },
    computed: {
        nextBeltGrade() {
            return this.$t(`belts.beltGradesDefinite.${this.belt.next.id}`);
        },
        beltNumber() {
            return this.belt.next.number ? `${this.belt.next.number}. ` : '';
        }
    }
};
</script>
