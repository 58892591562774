import axios from 'axios';
import settings from '@/settings';

export function fetchRepetitions() {
  const url = `${settings.endpoints.repetition}/repetitions/`;
  return axios
    .get(url)
}

export function createRepetition(data) {
      const url = `${settings.endpoints.repetition}/repetitions/`;
      return axios
        .post(url, data)
}

export function updateRepetition(id, data) {
  const url = `${settings.endpoints.repetition}/repetitions/${id}/`;
  return axios
    .put(url, data)
}

export function deleteRepetition(id) {
  const url = `${settings.endpoints.repetition}/repetitions/${id}/`;
  return axios
    .delete(url)
}
