/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'memo-image': {
    width: 14,
    height: 10,
    viewBox: '0 0 14 10',
    data: '<path pid="0" d="M7.913 2.941c0-.649.546-1.176 1.218-1.176.67 0 1.217.527 1.217 1.176 0 .649-.546 1.177-1.217 1.177-.672 0-1.218-.528-1.218-1.177zM1.217 8.824v-1.34l3.891-2.778 3.824 2.928c.226.172.56.176.789.007l1.646-1.207 1.416 1.045v1.345H1.217zm11.566-7.648v4.818l-1.024-.809a.637.637 0 00-.78-.001L9.339 6.47 5.522 3.34a.637.637 0 00-.782-.013L1.217 6.02V1.176h11.566zM13.39 0H.61C.273 0 0 .256 0 .571V9.43c0 .315.273.571.609.571H13.39c.336 0 .609-.256.609-.571V.57C14 .256 13.727 0 13.391 0z" fill-rule="evenodd"/>'
  }
})
