export const skillsMissionArray = [
  { id: 'vekstkultur', name: 'Vekstkultur', lifeskills: ['41', '40', '70', '50'] },
  { id: 'memolifestyle', name: 'Memolifestyle', lifeskills: ['41', '40', '02', '18'] },
  { id: 'faster-change', name: 'Mestringsglede', lifeskills: ['41', '44', '70'] },
  { id: 'faster-growth', name: 'Vekstrakett', lifeskills: ['48', '42', '83'] },
  { id: 'faster-learning', name: 'Klisterhjerne', lifeskills: ['10', '02', '40'] },
  { id: 'problem-solver', name: 'Problemløser', lifeskills: ['88', '87', '42', '50'] },
  { id: 'quizmaster', name: 'Quizmaster', lifeskills: ['00', '18', '25', '11'] },
  { id: 'self-manager', name: 'Selvleder', lifeskills: ['01', '80', '70', '34'] },
]

export const habitsMissionArray = [
  { id: 'daily-joys', name: 'Daily Joys', name_no: 'Dagens gleder', type: 'mentalHygiene' },
  { id: 'kiss-the-frog', name: 'Kiss The Frog', name_no: 'Kyss Froskene', type: 'mentalHygiene' },
  { id: 'focus-check', name: 'Focus Check', name_no: 'Fokussjekk', type: 'mentalHygiene' },
  { id: 'daily-plan', name: 'Daily Plan', name_no: 'Dagsplan', type: 'mentalHygiene' },
  { id: 'learning-diary', name: 'Learning Diary', name_no: 'Læringsdagbok', type: 'mentalHygiene' },
  { id: 'faces', name: 'Social Star', name_no: 'Navnemester', type: 'game' },
  { id: 'shopping', name: 'Supermarket', name_no: 'Handlelisten', type: 'game' },
  { id: 'bankvault', name: 'Bank Vault', name_no: 'Bankhvelvet', type: 'game' },
  { id: 'brainstorm', name: 'Brainstorming', name_no: 'Brainstorming', type: 'game' },
  { id: 'timemachine', name: 'Time Travel', name_no: 'Tidsmaskin', type: 'game' },
  { id: 'vocabguru', name: 'Vocab Guru', name_no: 'Gloseguru', type: 'game' },
]

export function getMissionTitles(missionId, lifeskill) {
  if (missionId === 'vekstkultur') {
    if (lifeskill === '41') {
      return 'Fremtidsklar'
    } else if (lifeskill === '40') {
      return 'Innertier'
    } else if (lifeskill === '70') {
      return 'Mentalhygiene'
    } else if (lifeskill === '50') {
      return 'Sosial stjerne'
    }
  } else if (missionId === 'memolifestyle') {
    if (lifeskill === '41') {
      return 'Fremtidsklar'
    } else if (lifeskill === '40') {
      return 'Topp 20'
    } else if (lifeskill === '02') {
      return 'Topp 30'
    } else if (lifeskill === '18') {
      return 'Topp 100'
    }
  } else if (missionId === 'faster-change') {
    if (lifeskill === '41') {
      return 'Fremtidsklar'
    } else if (lifeskill === '44') {
      return 'Autopilot'
    } else if (lifeskill === '70') {
      return 'Mentalhygiene'
    }
  } else if (missionId === 'faster-growth') {
    if (lifeskill === '48') {
      return 'Mentalt fyrverkeri'
    } else if (lifeskill === '42') {
      return 'Den gode samtalen'
    } else if (lifeskill === '83') {
      return 'Den spennende sparringen'
    }
  } else if (missionId === 'faster-learning') {
    if (lifeskill === '10') {
      return 'Festmetoden'
    } else if (lifeskill === '02') {
      return 'Klisterhjerne'
    } else if (lifeskill === '40') {
      return 'Innertier'
    }
  } else if (missionId === 'problem-solver') {
    if (lifeskill === '88') {
      return 'A-ha-opplevelsen'
    } else if (lifeskill === '87') {
      return 'Den gode beslutningen'
    } else if (lifeskill === '42') {
      return 'Når budskapet lander'
    } else if (lifeskill === '50') {
      return 'Sosial stjerne'
    }
  } else if (missionId === 'quizmaster') {
    if (lifeskill === '00') {
      return 'Hjernen er stjernen'
    } else if (lifeskill === '18') {
      return 'Historiemester'
    } else if (lifeskill === '25') {
      return 'Laboratoriemester'
    } else if (lifeskill === '11') {
      return 'Språkgeni'
    }
  } else if (missionId === 'self-manager') {
    if (lifeskill === '01') {
      return 'Lynfokus'
    } else if (lifeskill === '80') {
      return 'Yes, ferdig!'
    } else if (lifeskill === '70') {
      return 'Dette går fint'
    } else if (lifeskill === '34') {
      return 'Smil i motgang'
    }
  }
}

export function getSkillMissionById(id) {
  return skillsMissionArray.find(mission => mission.id === id)
}

export function getHabitMissionById(id) {
  return habitsMissionArray.find(mission => mission.id === id)
}
