<template>
  <div class="PanelBase">
    <div class="header">
      <slot name="header" />
    </div>
    <div class="body">
      <slot name="body" />
    </div>
    <div
      class="footer"
      :class="{ '--global-clickable': footerClickable }"
      @click="footerClickable && $emit('click-footer')">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
    props: {
        footerClickable: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style lang="scss" scoped>
.PanelBase {
    display: flex;
    flex-direction: column;
    background-color: mix($completionColor, white, 10%);
}
.header {
    background-color: white;
    padding: 1em 1em;

    /deep/ {
        .title {
            text-align: center;
            font-size: 160%;
            font-weight: 600;
        }
        .subtitle {
            text-align: center;
            color: rgba(black, 0.5);
            margin-top: 0.5em;
        }
    }
    &:empty {
        display: none;
    }
}
.body {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.footer {
    background-color: white;
    color: rgba(black, 0.7);
    height: 4em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:empty {
        display: none;
    }
}
</style>
