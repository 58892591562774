/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'game-icon': {
    width: 64,
    height: 46,
    viewBox: '0 0 64 46',
    data: '<path pid="0" d="M12.016.004C5.992.004.004 6.063.004 15.984c0 9.957 6.059 30.012 16.016 30.012 9.992 0 9-10.984 9-16.016h13.996c0 5.032-1.028 16.016 9 16.016 9.921 0 15.98-20.055 15.98-30.012 0-9.921-5.988-15.98-11.976-15.98-6.024 0-6.13.319-9.426 4.004H21.406C18.11.323 18.004.004 12.016.004zm2.48 6.98a3.01 3.01 0 013.012 3.012 3.01 3.01 0 01-3.012 3.012c-1.665 0-2.976-1.347-2.976-3.012s1.31-3.012 2.976-3.012zm34.016 1.028h4.004V14h5.988v4.004h-5.988v5.988h-4.004v-5.988h-6.024V14h6.024V8.012zM8.508 13.008c1.665 0 3.012 1.346 3.012 2.976a3.01 3.01 0 01-3.012 3.012 3.01 3.01 0 01-3.012-3.012c0-1.63 1.347-2.976 3.012-2.976zm12.012 0a2.994 2.994 0 012.976 2.976c0 1.666-1.346 3.012-2.976 3.012a3.01 3.01 0 01-3.012-3.012c0-1.63 1.346-2.976 3.012-2.976zm-6.024 5.988a3.01 3.01 0 013.012 3.012c0 1.63-1.347 2.976-3.012 2.976a2.971 2.971 0 01-2.976-2.976c0-1.665 1.31-3.012 2.976-3.012z" fill-rule="evenodd"/>'
  }
})
