/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'swipe': {
    width: 28,
    height: 37,
    viewBox: '0 0 28 37',
    data: '<g fill-rule="evenodd"><path pid="0" d="M20.073 5.435a.652.652 0 000 .927.663.663 0 00.934 0l2.64-2.622a.652.652 0 000-.926L21.007.192a.663.663 0 00-.934 0 .652.652 0 000 .927l1.514 1.503H8.66a.658.658 0 00-.66.655c0 .362.295.655.66.655h12.93l-1.517 1.503zM6.759 16.815l1.94-2.672a1.967 1.967 0 013.218.033l-1.87 2.574a.667.667 0 00.139.923c.293.213.71.137.92-.152l2.327-3.202a1.967 1.967 0 013.217.032l-2.647 3.643a.667.667 0 00.14.923c.293.212.711.136.92-.153l2.327-3.202a1.967 1.967 0 013.217.033l-3.422 4.71a.667.667 0 00.14.923c.292.213.71.137.92-.152l6.206-8.542a1.967 1.967 0 012.753-.444 1.976 1.976 0 01.428 2.756l-7.169 9.867 3.173-1.424a1.953 1.953 0 012.596.994 1.99 1.99 0 01-1.005 2.61l-3.487 1.565c-1.25.701-2.378 1.99-4.81 3.545-2.675 1.71-5.178 2.47-5.489 2.898.29.219.348.636.142.92a.664.664 0 01-.928.14L1.13 29.043a.671.671 0 01-.152-.926.663.663 0 01.918-.149c1.141-1.57 1.75-6.869 4.862-11.152z"/></g>'
  }
})
