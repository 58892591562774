/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'categories/body': {
    width: 31,
    height: 39,
    viewBox: '0 0 31 39',
    data: '<g fill-rule="nonzero"><circle pid="0" cx="16.5" cy="3.5" r="3.5"/><path pid="1" d="M1.65 7.107A3.22 3.22 0 011.345 7c-1.814.04-1.793 2 .063 2H8L1.65 7.107zM21 23l6.743 11.241c.529.879.215 2.005-.7 2.512-.916.509-2.087.207-2.614-.672L21 30.307 21 23zM10 23L3.256 34.241c-.527.879-.214 2.005.7 2.512.918.509 2.088.207 2.616-.672l3.427-5.774L10 23zM29.654 7c-.095.04-.196.075-.304.107L23 9h6.592c1.857 0 1.877-1.96.062-2z"/><path pid="2" d="M28.074 5.114L19.64 8.173h-8.28l-8.433-3.06C1.053 4.466.162 6.775 2.038 7.419l9.322 3.204.003 12.546-.003 14.075c0 .97.835 1.757 1.862 1.757 1.029 0 1.862-.787 1.862-1.757l.002-14.025h.829l-.022-.05h.04l-.018.05-.006 14.025c0 .97.833 1.757 1.861 1.757s1.86-.787 1.86-1.757l.01-24.7-.001.003.001-1.924 9.322-3.204c1.876-.644.985-2.953-.888-2.304z"/></g>'
  }
})
