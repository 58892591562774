/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '3dot': {
    width: 3,
    height: 15,
    viewBox: '0 0 3 15',
    data: '<circle pid="0" cx="1.5" cy="1.5" r="1.5" transform="rotate(90 1.5 1.5)" _fill="#C4C4C4"/><circle pid="1" cx="1.5" cy="7.5" r="1.5" transform="rotate(90 1.5 7.5)" _fill="#C4C4C4"/><circle pid="2" cx="1.5" cy="13.5" r="1.5" transform="rotate(90 1.5 13.5)" _fill="#C4C4C4"/>'
  }
})
