/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'map': {
    width: 512,
    height: 512,
    viewBox: '0 0 512 512',
    data: '<g _fill="#231f20"><path pid="0" d="M472.9 136.6L368.2 66.3c-4.6-3.1-10.4-3.1-15.1 0L256 131.5l-97.1-65.2c-4.6-3.1-10.5-3.1-15.1 0L39.1 136.6c-4.4 2.9-7.1 8.3-7.1 14v281c0 5.9 2.8 11.3 7.4 14.3 4.6 2.9 10.3 2.8 14.8-.2l97.1-65.2 97.1 65.2c4.7 3.1 10.4 3.1 15.1 0l97.2-65.2 97.1 65.2c2.3 1.5 4.9 2.4 7.5 2.4 2.5 0 5-.7 7.2-2.1 4.6-2.9 7.4-8.4 7.4-14.3v-281c.1-5.8-2.6-11.2-7-14.1zM135 353.2l-71 49.4V160.9l71-49.4v241.7zm32-.4V230.4c1 .7 2 1.3 2.9 2l9.3-13c-3.8-2.7-8-5.3-12.2-7.5V111l73 49v108.7c-5.5-1.9-10.9-4.4-16.4-7.9l-8.5 13.6c6.8 4.3 13.6 7.5 20.7 9.8l4.2-12.8v130.3l-73-48.9zm105 48.9V288.3h.5c2.9-.2 5.8-.5 8.5-.9l-2.5-15.8c-2.1.3-4.3.6-6.6.7V160l73-49v119.2l-5.3-4.8c-3 3.3-5.8 6.6-8.6 9.7-1.8 2.1-3.6 4.1-5.3 6.1l12 10.6c1.8-2 3.6-4.1 5.4-6.2.6-.7 1.2-1.4 1.9-2.1v109.2l-73 49zm176 0l-71-48.4V216.1c3.6-1.6 7.4-2.8 11.1-3.6l-3.2-15.7c-2.7.5-5.3 1.3-8 2.1v-87.5l71 48.5v241.8z"/><path pid="1" d="M98.9 256c1.9-5.9 3.5-11 7.1-16.3l-13.2-9c-4.9 7.2-7.1 13.9-9.2 20.5l-.2.8 15.2 4.9.3-.9zM128.8 221.1c2.2-1.2-1.4-1.1 1-1.8l-4.9-15.2c-3.3 1.1-6.5 2.4-9.6 4.1-3.8 2.1-7.7 4.9-11.3 8.3l10.9 11.7c2.6-2.5 11.2-5.7 13.9-7.1zM296.3 266.1l7 14.4c6.6-3.2 12.8-7.4 19.1-13l-10.6-12c-5.2 4.6-10.2 8.1-15.5 10.6zM197.8 237.8l-3-3.6-12.2 10.4c1 1.2 2 2.3 2.9 3.5 3.6 4.3 7.3 8.7 11.6 12.8l11-11.6c-3.6-3.5-6.8-7.4-10.3-11.5zM407.7 229.7l10.3-10.4 10.3 10.4 11.4-11.4-10.4-10.3 10.4-10.3-11.4-11.4-10.3 10.4-10.3-10.4-11.4 11.4 10.4 10.3-10.4 10.3z"/></g>'
  }
})
