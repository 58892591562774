import last from 'lodash-es/last';
import gameHelper from '@/modules/games-shared/game-helper';

const module = {
    namespaced: true,
    state() {
        return {
            sessionId: null,
            sourceItems: [],
            queue: [],
            itemIndex: -1,
            items: [],
            lives: 3,
            // timeUsed: 0,
            // stars: 0,
            done: false,
            score: 0,
            loading: false
        };
    },
    actions: {
        async enter({ commit, state }) {
            commit('setLoading', true);
            const data = await gameHelper.sessions[state.sessionId].generateQuiz();
            data.queue = data.sourceItems.map((x, i) => i);
            commit('initialize', data);
            commit('setLoading', false);
        },
        leave({ commit, state }) {
            const timeUsed = state.items
                .map(item => item.timeUsed)
                .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            gameHelper.sessions[state.sessionId].reportQuiz({
                items: state.items,
                timeUsed,
                score: state.score,
                sourceItems: state.sourceItems
            });
        },
        fail({ commit }) {
            commit('update', { done: true, score: 0 });
        },
        success({ commit }) {
            commit('update', { done: true, score: 1 });
        },
        enterItem({ dispatch, state, commit }) {
            commit('shiftQueue');
            const sourceItemIndex = state.itemIndex;

            if (!state.items[sourceItemIndex]) {
                const sourceItem = state.sourceItems[sourceItemIndex];
                const typ = sourceItem.quizItemType;
                const testNames = gameHelper.sessions[state.sessionId].quizMapping[
                    typ
                ].generateTestNames(sourceItem.data, { sourceItem, sourceItems: state.sourceItems });

                const quizItem = {
                    sourceItemIndex,
                    index: sourceItemIndex,
                    timeUsed: 0,
                    score: 0,
                    done: false,
                    tests: [],
                    testNames,
                    type: typ
                };
                commit('addItem', quizItem);
            }
        },
        leaveItem({ commit, state, getters }) {
            const item = getters.currentItem;
            const itemIndex = item.sourceItemIndex;

            let timeUsed = 0;
            let allCorrect = true;
            item.tests.forEach(test => {
                timeUsed += test.end - test.start;
                if (test.score < 1) {
                    allCorrect = false;
                }
            });

            if (last(item.tests).skipped) {
                commit('pushQueue', itemIndex);

                commit('updateItem', {
                    itemIndex,
                    data: {
                        timeUsed
                    }
                });
                return;
            }

            commit('updateItem', {
                itemIndex,
                data: {
                    timeUsed,
                    done: true,
                    score: allCorrect ? 1 : 0
                }
            });
        },

        enterTest({ dispatch, state, commit, getters }) {
            const item = getters.currentItem;
            const previousTest = last(item.tests);
            const sourceItem = state.sourceItems[item.sourceItemIndex];

            const previousTestName = previousTest ? previousTest.name : '';
            const currentTestIndex = item.testNames.indexOf(previousTestName);

            // NOTE: only proceed to next test if previous answered correctly
            const testName =
                previousTest && previousTest.score < 1 ? previousTestName : item.testNames[currentTestIndex + 1];
            const data = gameHelper.sessions[state.sessionId].quizMapping[item.type].tests[
                testName
            ].generateTestData(sourceItem.data, { dataPool: state.sourceItems.map(x => x.data) });

            const test = {
                start: Date.now(),
                end: 0,
                skipped: false,
                score: 0,
                done: false,
                submittedAnswer: null,
                name: testName,
                data
            };
            commit('addTest', {
                itemIndex: item.sourceItemIndex,
                test
            });
        },
        leaveTest({ commit, state, getters }) {
            commit('updateTest', {
                itemIndex: state.itemIndex,
                data: {
                    end: Date.now(),
                    done: true
                }
            });
        },
        submitTestAnswer({ commit, dispatch, state, getters }, answer) {
            const quizItem = getters.currentItem;
            const quizItemTest = last(quizItem.tests);
            const sourceItem = state.sourceItems[quizItem.sourceItemIndex];
            const score = gameHelper.sessions[state.sessionId].quizMapping[quizItem.type].tests[
                quizItemTest.name
            ].calculateScore(sourceItem.data, answer.value, quizItemTest);

            commit('updateTest', {
                itemIndex: quizItem.sourceItemIndex,
                data: {
                    score: score,
                    submittedAnswer: answer.value
                }
            });

            if (quizItemTest.name === 'nameTest') {
                dispatch(
                    'moduleSystemName/reportNameLearning',
                    { nameId: sourceItem.data.name, score },
                    { root: true }
                );
            }

            if (score < 1) {
                commit('updateLives', state.lives - 1);
            }
        },
        skipItem({ commit, state }) {
            commit('updateTest', {
                itemIndex: state.itemIndex,
                data: {
                    skipped: true
                }
            });
        }
    },
    mutations: {
        setSessionId(state, sessionId) {
            state.sessionId = sessionId;
        },
        setLoading(state, flag) {
            state.loading = flag;
        },
        initialize(state, data) {
            Object.assign(state, data);
        },
        update(state, data) {
            Object.assign(state, data);
        },
        addItem(state, quizItem) {
            state.items.push(quizItem);
        },
        addTest(state, { itemIndex, test }) {
            state.items[itemIndex].tests.push(test);
        },
        updateTest(state, { itemIndex, data }) {
            const test = last(state.items[itemIndex].tests);
            Object.assign(test, data);
        },
        updateItem(state, { itemIndex, data }) {
            Object.assign(state.items[itemIndex], data);
        },
        updateLives(state, lives) {
            state.lives = lives;
        },

        shiftQueue(state) {
            if (state.queue.length) {
                state.itemIndex = state.queue.shift();
            } else {
                state.itemIndex = -1;
            }
        },
        pushQueue(state, itemIndex) {
            state.queue.push(itemIndex);
        }
    },

    getters: {
        progress(state) {
            const numberOfItems = state.sourceItems.length;
            const numberOfDoneItems = state.items.filter(item => item.done).length;
            return numberOfDoneItems / numberOfItems;
        },
        currentItem(state) {
            return state.items[state.itemIndex] || null;
        },
        getItem: (state, getters) => itemIndex => {
            return state.items[itemIndex] || null;
        }
    }
};

export default module;
