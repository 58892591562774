<template>
  <BlockContainer
    class="ModuleCourse"
    type="Module"
    label="moduleCourse">
    <div class="courses">
      <Course
        v-for="courseId of courseIds"
        :key="courseId"
        class="Course"
        :course-id="courseId" />
    </div>
  </BlockContainer>
</template>

<script>
import BlockContainer from '../BlockContainer';
import Course from './Course';

export default {
    components: { BlockContainer, Course },
    computed: {
        courseIds() {
            return this.$store.state.moduleCourse.courseIds;
        }
    }
};
</script>

<style lang="scss" scoped>
.Course {
    margin-top: 1em;
}
</style>
