/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'belt': {
    width: 248,
    height: 133,
    viewBox: '0 0 248 133',
    data: '<path pid="0" d="M0 0h247.194c-5.793 16.016-13.967 29.616-24.522 40.802h-54.714l40.288 74.018-43.369 12.154-38.09-69.109-32.298 74.797c-14.489-1.64-28.888-5.023-43.198-10.15l33.545-79.055h-58.82C11.305 24.385 2.633 9.9 0 0z" fill-rule="evenodd"/>'
  }
})
