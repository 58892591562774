<template>
  <BlockContainer
    class="Step"
    :type="activityName"
    :progress="activity.progress">
    <ButtonWithCheckmark
      :done="activity.progress === 1"
      label="Done"
      @click="toggleMemo()" />
    <div class="memos">
      <Memo
        v-for="memoId of activity.memoIds"
        :key="memoId"
        class="Memo"
        :step-id="stepId"
        :memo-id="memoId" />
    </div>
  </BlockContainer>
</template>

<script>
import BlockContainer from '../BlockContainer';
import ButtonWithCheckmark from '../ButtonWithCheckmark';
import Memo from './Memo';

export default {
    components: { ButtonWithCheckmark, BlockContainer, Memo },
    props: {
        stepId: {
            type: String,
            required: true
        },
        activityName: {
            type: String,
            required: true
        }
    },
    computed: {
        step() {
            return this.$store.state.moduleCourse.steps[this.stepId];
        },
        activity() {
            return this.step[this.activityName];
        }
    },
    methods: {
        toggleMemo() {
            this.$store.dispatch('moduleCourse/dbgMarkActivity', {
                stepId: this.stepId,
                activityName: this.activityName,
                reset: this.activity.progress === 1
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.Memo {
    margin-top: 1em;
}
</style>
