<template>
  <div
    class="LifeskillNumberTag"
    :class="`size-${size}`"
    :data-theme="category">
    <div
      v-if="showLabel"
      class="label">
      {{ $t(`dashboard.general.categoryLabels.${category}`) }}
    </div>
    
    <div class="sunContainer" :class="{'isCategorySection': isCategorySection}">
      <span class="number" v-if="lifeskill">{{ lifeskill.number }}</span>
      <img class="sunImage" :src="getSunImage" alt="" />
    </div>
    
  </div>
</template>

<script>
import { getCategoryIdFromLifeskillId } from '@/utils';
import sun1Image from './UniverseSection/assets/images/sun1.svg';
import sun2Image from './UniverseSection/assets/images/sun2.svg';
import sun3Image from './UniverseSection/assets/images/sun3.svg';
import sun4Image from './UniverseSection/assets/images/sun4.svg';
import sun5Image from './UniverseSection/assets/images/sun5.svg';
import sun6Image from './UniverseSection/assets/images/sun6.svg';
import sun7Image from './UniverseSection/assets/images/sun7.svg';

export default {
    name: 'LifeskillNumberTag',
    props: {
        categoryId: {
            type: String
        },
        lifeskillId: {
            type: String
        },
        showLabel: {
            type: Boolean
        },
        darken: {
            type: Boolean
        },
        useShadow: {
            type: Boolean
        },
        size: {
            type: String,
            default: 'normal',
            validator: function (value) {
                return ['normal', 'large'].indexOf(value) !== -1;
            }
        },
        isCategorySection: {
            type: Boolean
        }
    },
    computed: {
        category() {
            return this.categoryId || getCategoryIdFromLifeskillId(this.lifeskillId);
        },
        lifeskill() {
            return this.$store.getters['moduleApp/getLifeskillById'](this.lifeskillId);
        },
        getSunImage() { 
            if(this.category === 'brain'){
                return sun1Image;
            } else if(this.category === 'knowledge'){
                return sun3Image;
            } else if(this.category ==='mind'){
                return sun4Image;
            } else if(this.category === 'body'){
                return sun2Image;
            } else if(this.category === 'soul'){
                return sun5Image;
            } else if(this.category === 'career'){
                return sun6Image;
            } else if(this.category === 'leisure'){
                return sun7Image;
            }
            return sun1Image;
        },
    },
};
</script>

<style lang="scss" scoped>
.LifeskillNumberTag {
    margin-bottom: 0;
    margin-left: 2px;
    transform: translate3d(
        0,
        0,
        0
    ); // Needed for safari bug https://stackoverflow.com/questions/5472802/css-z-index-lost-after-webkit-transform-translate3d
    @media (max-width: 774px) {
        margin-left: 0;
    }
    &.size-large {
        .number {
            font-size: 90%;
            padding: 0.3em 0.8em;
            font-weight: 600;
        }
    }
}

.label {
    color: rgba(white, 0.8);
    text-transform: uppercase;
    letter-spacing: 0.2em;
    font-weight: 300;
    margin-right: 0.5em;
    font-size: 90%;
}

.sunContainer{
    $size: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
     width: $size;
    height: $size;
    position: absolute;
    z-index: 0;
    
    top: -0.7em;
    left: -0.7em;
   
}
.isCategorySection {
    position: relative;
    top: 0;
    left: 0;
}
.sunImage {
    $size: 3em;
    width: $size;
    height: $size;
}
.number {
    position: absolute;
    z-index: 1;
    border-radius: 2em;
    color: rgba(0, 0, 0, 0.787);
    font-size: 10px;
    text-align: center;

    &.use-shadow {
        box-shadow: 0 0 0.5em rgba(black, 0.3);
    }
}
</style>
