import store from '@/store';
// import { captiveNetworkAssistantCheck } from '@/captive-network-assistant-check'
// import experimentsService from '@/services/experiments-service'

const MemolanguageView = () => import('./views/MemolanguageView');
const MemolanguageFlytogetSignupView = () =>
    import('./views/MemolanguageFlytogetSignupView');
const MemolanguageFlytogetLanguagesView = () =>
    import('./views/MemolanguageFlytogetLanguagesView');
const MemolanguageAureliaRootView = () =>
    import('./views/MemolanguageAureliaRootView');

export default [
    {
        path: '/memolanguage/',
        component: MemolanguageView,
        meta: { requiresAuth: false },
        children: [
            {
                  // workaround to handle a wrong link on flytoget.no/memolife
                  path: 'flytoget/flytoget/memolanguage',
                  component: MemolanguageFlytogetLanguagesView,
                  meta: { requiresAuth: false }
            },

            {
                path: 'flytoget',
                redirect: to => {
                    return { name: 'MemolanguageFlytogetSignupView' };
                    // let variation = experimentsService.getVariation('forced-login')
                    // if (captiveNetworkAssistantCheck()) {
                    //   variation = 1
                    // }
                    // if (store.state.moduleAuth.isAuthenticated || variation === 0) {
                    //   return { name: 'MemolanguageFlytogetLanguagesView' }
                    // } else {
                    //   return { name: 'MemolanguageFlytogetSignupView' }
                    // }
                }
            },
            {
                path: '/memolanguage/flytoget/signup',
                name: 'MemolanguageFlytogetSignupView',
                component: MemolanguageFlytogetSignupView,
                meta: { requiresAuth: false },
                beforeEnter(to, from, next) {
                    if (store.state.moduleAuth.isAuthenticated) {
                        next({ name: 'MemolanguageFlytogetLanguagesView' });
                    } else {
                        next();
                    }
                }
            },
            {
                path: '/memolanguage/flytoget/languages',
                name: 'MemolanguageFlytogetLanguagesView',
                component: MemolanguageFlytogetLanguagesView,
                meta: { requiresAuth: false }
            },
            {
                path: ':targetLanguage/*',
                component: MemolanguageAureliaRootView
            },
            {
                path: ':targetLanguage',
                component: MemolanguageAureliaRootView
            },
        ]
    }
];
