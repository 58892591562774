<template>
  <div
    class="Scenario --global-clickable"
    :class="[`state-${state}`]"
    @click="openScenario()" />
</template>

<script>
export default {
    props: {
        stepId: {
            type: String,
            required: true
            // COMBAK will we have stepId?
        },
        scenarioId: {
            type: String,
            required: true
        },
        scenarioIndex: {
            type: Number,
            required: true
        }
    },
    computed: {
        course() {
            return this.$store.state.moduleCourse.courses[this.step.courseId];
        },
        step() {
            return this.$store.state.moduleCourse.steps[this.stepId];
        },
        scenario() {
            return this.$store.state.moduleCourse.scenarios[this.scenarioId];
        },
        state() {
            if (this.scenario.done) {
                return 'done';
            }

            const currentScenarioInfo = this.course.currentScenarioInfo;
            if (
                currentScenarioInfo &&
                currentScenarioInfo.stepId === this.stepId &&
                currentScenarioInfo.scenarioIndex === this.scenarioIndex
            ) {
                return currentScenarioInfo.pending ? 'pending' : 'active';
            }
            return 'locked';
        }
    },
    methods: {
        openScenario() {
            this.$router.push({
                name: 'ScenarioView',
                params: {
                    lifeskillId: this.course.lifeskillNumber,
                    stepId: this.stepId,
                    scenarioIndex: this.scenarioIndex
                }
            });
        }
        // toggleScenario () {
        //   this.$store.dispatch('moduleCourse/markStepScenarioAsDone', { stepId: this.stepId, scenarioIndex: this.scenarioIndex, reset: this.scenario.done })
        // }
    }
};
</script>

<style lang="scss" scoped>
$size: 1em;
.Scenario {
    $size: 1em;
    width: $size;
    height: $size;
    border-radius: 50%;
    background-color: gray;
    padding: 0 4px;
    &.state-done {
        background-color: $standardButtonColor;
    }
    &.state-pending {
        border: 3px solid orange;
    }
    &.state-active {
        border: 3px solid green;
    }
}
</style>
