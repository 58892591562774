/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'backspace': {
    width: 24,
    height: 18,
    viewBox: '0 0 24 18',
    data: '<path pid="0" d="M22 0H7c-.7 0-1.2.3-1.6.9L0 9l5.4 8.1c.4.5.9.9 1.6.9h15c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2zm-3 12.6L17.6 14 14 10.4 10.4 14 9 12.6 12.6 9 9 5.4 10.4 4 14 7.6 17.6 4 19 5.4 15.4 9l3.6 3.6z"/>'
  }
})
