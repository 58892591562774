export default {
    title: 'Social Star',
    title_no: 'Navnemester',
    subtitle: 'Names & Networking',
    subtitle_no: 'Navn & Nettverking',

    techniques: ['namesys', 'symbolizationsys', 'similaritysys', 'chainsys'],
    articles: ['memolifesys-single-and-double'],

    // tags: ['names', 'titles', 'hobbies'],
    // tags_no: ['navn', 'titler', 'hobbier'],

    levelTexts: {
        level0:
            'You’re at a party after an all-day event, where you’ve met many new people. Have you got it in you to remember all the names, titles and hobbies of the people you’ve met throughout the day?',
        level0_no:
            'Du er på fest etter en heldagsmesse hvor du har møtt mange mennesker. Klarer du å huske navnene, titlene og hobbiene til alle du har møtt?',

        level1:
            'You meet a lot of people at the event. Will you become the social star at the after-party or face the embarrassment of forgetting their names?',
        level1_no:
            'Du møter mange mennesker på jobbmessen. Husker du hva de heter senere, eller må du lide deg gjennom flauheten av å glemme navnene deres?',
        level2:
            'Will you remember the first names and surnames of the people you met at the event, or will you suffer the social embarrassment of forgetting someone’s name?',
        level2_no:
            'Husker du både fornavnene og etternavnene til menneskene du møter på messen, eller blir du den som glemmer noens navn mens de husker ditt?',
        level3:
            "Now it's time to remember the titles of the people you met as well. You wouldn’t want to offend the CEO by acting like he’s the middle manager, would you?",
        level3_no:
            'Nå gjelder det å huske titlene til dem du møter! Du vil vel ikke fornærme direktøren i selskapet ved å oppføre deg som om hen er en mellomleder?',
        level4:
            'Great, to really connect with people it’s important to know what they’re passionate about. Can you remember their hobbies as well?',
        level4_no:
            'Bra, for å virkelig skape gode relasjoner med mennesker er det viktig å vite hva de brenner for. Klarer du å huske hobbiene deres også?'
    },

    testLabels: {
        nameTest: 'Name',
        nameTest_no: 'Navn',
        surnameTest: 'Surname',
        surnameTest_no: 'Etternavn',
        professionTest: 'Profession',
        professionTest_no: 'Yrke',
        hobbyTest: 'Hobby',
        hobbyTest_no: 'Hobby'
    },

    Question: {
        M: 'his',
        M_no: 'hans',
        F: 'her',
        F_no: 'hennes',
        nameTest: "What's {pronoun} first name?",
        nameTest_no: 'Hva er {pronoun} fornavn?',
        surnameTest: "What's {pronoun} last name?",
        surnameTest_no: 'Hva er {pronoun} etternavn?',
        hobbyTest: "What's {pronoun} hobby?",
        hobbyTest_no: 'Hva er {pronoun} hobby?',
        professionTest: "What's {pronoun} profession?",
        professionTest_no: 'Hva er {pronoun} yrke?'
    },

    FaceCardForTest: {
        firstname: 'First name',
        firstname_no: 'Fornavn',
        surname: 'Surname',
        surname_no: 'Etternavn',
        profession: 'Profession',
        profession_no: 'Yrke',
        hobby: 'Hobby',
        hobby_no: 'Hobby'
    }
};
