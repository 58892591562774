/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" d="M23 2h-1V1c0-.6-.4-1-1-1s-1 .4-1 1v1H10V1c0-.6-.4-1-1-1S8 .4 8 1v1H7C3.1 2 0 5.1 0 9v14c0 3.9 3.1 7 7 7h16c3.9 0 7-3.1 7-7V9c0-3.9-3.1-7-7-7zM7 4h1v4c0 .6.4 1 1 1s1-.4 1-1V4h10v4c0 .6.4 1 1 1s1-.4 1-1V4h1c2.8 0 5 2.2 5 5v3H2V9c0-2.8 2.2-5 5-5zm16 24H7c-2.8 0-5-2.2-5-5v-9h26v9c0 2.8-2.2 5-5 5z" _fill="#000" fill-rule="nonzero"/>'
  }
})
