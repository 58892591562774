/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'unknown-word': {
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
    data: '<path pid="0" d="M11.717.28a1.014 1.014 0 00-1.425 0l-4.298 4.3L1.758.34a1 1 0 00-1.414 0 1.006 1.006 0 000 1.42L4.58 5.99.314 10.26a1.015 1.015 0 000 1.43c.394.39 1.032.39 1.426 0l4.266-4.27 4.236 4.24a1.002 1.002 0 101.415-1.42L7.42 6.01l4.297-4.3c.393-.4.393-1.03 0-1.43z" fill-rule="evenodd"/>'
  }
})
