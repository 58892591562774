import frankAvatarUrl from '@/assets/images/frank-avatar.jpg';

export default function transformCourseData(courseData, loadInfo = {}) {
    let stepGroupsData = courseData.stepGroups;
    delete courseData.stepGroups;
    courseData.stepGroupIds = stepGroupsData.map(stepGroup => stepGroup.id);

    let stepsData = [];
    let memosData = [];
    let scenariosData = [];
    let totalNumberOfScenarios = 0;

    if (courseData.introStep) {
        courseData.introStep.alwaysUnlocked = true; // Note: used in ability
        if (!courseData.introStep.read) {
            courseData.introStep.read = {};
        }
        courseData.introStep.courseId = courseData.id;

        stepsData.push(courseData.introStep);
        courseData.introStepId = courseData.introStep.id;
        delete courseData.introStep;
    }

    stepGroupsData.forEach((stepGroup, stepGroupIndex) => {
        stepGroup.steps.forEach(step => {
            step.courseId = courseData.id;
            step.stepGroupId = stepGroup.id;
        });
        if (stepGroupIndex === 0 && stepGroup.steps.length) {
            stepGroup.steps[0].alwaysUnlocked = true;
        }

        stepGroup.courseId = courseData.id;
        stepGroup.lifeskillNumber = courseData.lifeskillNumber;
        stepGroup.stepIds = stepGroup.steps.map(step => step.id);
        stepsData = stepsData.concat(stepGroup.steps);
        delete stepGroup.steps;
    });

    stepsData.forEach(step => {
        const activityNames = [];
        step.modelName = 'Step'; // Note: used in ability
        if (step.read) {
            activityNames.push('read');
            step.read.name = 'read';
        }
        if (step.memorize) {
            step.memorize.memoIds = step.memorize.memos.map(memo => memo.id);
            step.memorize.memos.forEach(memo => {
                memo.stepId = step.id;
                memosData.push(memo);
            });
            delete step.memorize.memos;
            // step.memoIds = step.memorize.memoIds
            activityNames.push('memorize');
            step.memorize.name = 'memorize';
            step.memorize.optional = true;
        }
        if (step.quiz) {
            activityNames.push('quiz');
            step.quiz.name = 'quiz';
        }
        if (step.memorize2) {
            step.memorize2.memoIds = step.memorize2.memos.map(memo => memo.id);
            step.memorize2.memos.forEach(memo => {
                memo.stepId = step.id;
                memosData.push(memo);
            });
            delete step.memorize2.memos;
            activityNames.push('memorize2');
            step.memorize2.name = 'memorize2';
            step.memorize2.optional = true;
        }
        if (step.quiz2) {
            activityNames.push('quiz2');
            step.quiz2.name = 'quiz2';
        }

        if (step.scenarios) {
            step.scenarioIds = step.scenarios.map(scenario => scenario.id);
            scenariosData = scenariosData.concat(step.scenarios);
            delete step.scenarios;

            totalNumberOfScenarios += step.scenarioIds.length;
        }

        step.activityNames = activityNames;
    });

    memosData.forEach(memoData => {
        if (memoData.fact) {
            const fact = memoData.fact;
            if (fact.imageUrl) {
                fact.image = fact.imageUrl;
                delete fact.imageUrl;
            }
            if (fact.options) {
                fact.options = JSON.parse(fact.options);
            }
        }
        if (memoData.fantasies) {
            memoData.fantasies.forEach(fantasy => {
                if (fantasy.options) {
                    fantasy.options = JSON.parse(fantasy.options);
                }
                if (fantasy.mnemonics) {
                    fantasy.mnemonics.forEach(mnemonic => {
                        if (mnemonic.extra) {
                            Object.assign(mnemonic, JSON.parse(mnemonic.extra));
                            delete mnemonic.extra;
                        }
                        if (mnemonic.tip) {
                            // delete mnemonic.tip // NOTE: disabling mnemonic tips for the time being...
                        }
                    });
                }
            });
        }
    });

    if (courseData.bannerImage) {
        courseData.imageLarge = courseData.bannerImage;
    }
    if (loadInfo.content && !courseData.author) {
        courseData.author = {
            image: frankAvatarUrl,
            name: 'Frank Wedde',
            title: 'CEO i Memolife'
        };
    }

    courseData.stepIds = stepsData.map(step => step.id);
    courseData.totalNumberOfScenarios = totalNumberOfScenarios;

    return {
        courseData,
        stepsData,
        stepGroupsData,
        memosData,
        scenariosData
    };
}
