/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'trophy1': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" clip-rule="evenodd" d="M36 18c0 5.59-3.827 10.272-9 11.606V40h6a1 1 0 110 2H15a1 1 0 110-2h6V29.606C15.827 28.272 12 23.59 12 18a7 7 0 01-7-7V9a1 1 0 011-1h6V7a3 3 0 00-3-3 1 1 0 110-2h30a1 1 0 110 2 3 3 0 00-3 3v1h6a1 1 0 011 1v2a7 7 0 01-7 7zM23 40h2V29.949c-.33.028-.662.051-1 .051s-.67-.023-1-.051V40zM7 10v1a5 5 0 005 5v-6H7zm5.978-6A4.962 4.962 0 0114 7v11c0 5.523 4.477 10 10 10 5.522 0 10-4.477 10-10V7c0-1.131.39-2.162 1.022-3H12.978zM41 10h-5v6a5 5 0 005-5v-1zM12 44h24a1 1 0 110 2H12a1 1 0 110-2z" fill-rule="evenodd"/>'
  }
})
