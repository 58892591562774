import themeSound from './sounds/theme.mp3';
import appMessages from './i18n';

export default {
    windowBackground: '#000',
    // background: 'linear-gradient(90deg, #A4AEB1, #5E6568)',
    background: '#262626',
    levelItem: {
        gradient: 'rgba(255,255,255,0.1)',
        gradientActive: '#555A5C'
    },
    rankingRow: {
        colorActive: '#555A5C'
    },
    primaryColor: '#A0ACAF',
    themeSound,
    appMessages
};
