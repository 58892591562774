/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hamburger': {
    width: 56.38,
    height: 35.563,
    viewBox: '0 0 56.38 35.563',
    data: '<path pid="0" d="M.01.011h56.35v5.611H.01V.011zm0 14.963h56.35v5.611H.01v-5.611zm0 14.963h56.35v5.607H.01v-5.611z" fill-rule="evenodd"/>'
  }
})
