/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'scenarios': {
    width: 37,
    height: 40,
    viewBox: '0 0 37 40',
    data: '<path pid="0" d="M13.125 23.167c-5.675 0-10.292-4.617-10.292-10.292S7.45 2.583 13.125 2.583 23.417 7.2 23.417 12.875a.792.792 0 001.583 0C25 6.327 19.673 1 13.125 1S1.25 6.327 1.25 12.875 6.577 24.75 13.125 24.75a.792.792 0 000-1.583zm-.003-16.625c-1.793 0-3.714 1.201-3.958 3.886l1.57.143c.212-2.328 2.006-2.446 2.366-2.446.557 0 2.35.146 2.35 2.029 0 .993-.579 1.476-1.443 2.18-.79.644-1.872 1.445-1.872 2.916v.792h1.584v-.792c0-.685.528-1.07 1.287-1.689.882-.718 2.076-1.612 2.076-3.407 0-2.16-1.592-3.612-3.96-3.612zm.597 11.083h-1.584v1.583h1.584v-1.583zm19.99-1.583h-14.25c-1.747 0-3.365 1.42-3.365 3.166v13.459c0 1.746 1.618 3.166 3.364 3.166h4.355l2.137 2.85a.791.791 0 001.267 0l2.137-2.85h4.354c1.747 0 2.97-1.42 2.97-3.166V19.208c0-1.746-1.223-3.166-2.97-3.166zm1.385 16.625c0 .872-.513 1.583-1.386 1.583h-4.75a.791.791 0 00-.633.317l-1.742 2.322-1.741-2.322a.791.791 0 00-.634-.317h-4.75c-.873 0-1.78-.71-1.78-1.583V19.208c0-.873.907-1.583 1.78-1.583h14.25c.873 0 1.386.71 1.386 1.583v13.459zm-9.368-4.352l-3.333-3.333-1.12 1.12 3.959 3.958a.79.79 0 00.56.232l.044-.002a.793.793 0 00.574-.295l6.333-7.917-1.237-.99-5.78 7.227z"/>'
  }
})
