<template>
  <div
    class="DebugButton --global-clickable"
    :style="position"
    @click="openMenu()" />
</template>

<script>
import Hammer from 'hammerjs';
import { EventBus } from '@/event-bus';

export default {
    computed: {
        position() {
            const position = this.$store.state.moduleApp.debugSettings.debugButtonPosition || { x: 0, y: 0.5 };
            return {
                left: `calc((100% - 2em) * ${position.x})`,
                top: `calc((100% - 2em) * ${position.y})`
            };
        }
    },
    mounted() {
        this.hammer = new Hammer.Manager(this.$el, {
            recognizers: [[Hammer.Pan, { xdirection: Hammer.DIRECTION_VERTICAL }]]
        });
        this.hammer.on('panmove', event => {
            let x = Math.max(0, Math.min(1, event.srcEvent.clientX / window.innerWidth));
            let y = Math.max(0, Math.min(1, event.srcEvent.clientY / window.innerHeight));
            this.setPosition(x, y);
        });
        this.hammer.on('panend', event => {});
    },
    beforeDestroy() {
        this.hammer.destroy();
    },
    methods: {
        openMenu() {
            EventBus.$emit('request-open-debug-menu');
        },
        setPosition(x, y) {
            this.$store.dispatch('moduleApp/dbgSetDebugButtonPosition', { x, y });
        }
    }
};
</script>

<style lang="scss" scoped>
.DebugButton {
    $size: 2em;
    position: absolute;
    z-index: 1000;
    border: 1px solid black;
    width: $size;
    height: $size;
    background-color: white;
    border-radius: 50%;
}
</style>
