/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'exit': {
    width: 52,
    height: 48,
    viewBox: '0 0 52 48',
    data: '<g fill-rule="nonzero"><path pid="0" d="M30.5 30.2c-1.1 0-2 .9-2 2v9.3c0 2.2-2.2 2.5-3.5 2.5H7.5C6.2 44 4 43.7 4 41.5v-35C4 4.3 6.2 4 7.5 4H25c1.3 0 3.5.3 3.5 2.5v9.3c0 1.1.9 2 2 2s2-.9 2-2V6.5C32.5 2.9 29.6 0 26 0H6.5C2.9 0 0 2.9 0 6.5v35C0 45.1 2.9 48 6.5 48H26c3.6 0 6.5-2.9 6.5-6.5v-9.3c0-1.1-.8-2-2-2z"/><path pid="1" d="M39 11c-.8-.8-2.1-.8-2.8 0-.8.8-.8 2 0 2.8l8.2 8.2H18.8c-1.1 0-2 .9-2 2s.9 2 2 2h25.5l-8 8c-.8.8-.8 2 0 2.8.4.4.9.6 1.4.6.5 0 1-.2 1.4-.6L52 24.1 39 11z"/></g>'
  }
})
