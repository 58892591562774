/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'activities/quiz': {
    width: 22,
    height: 29,
    viewBox: '0 0 22 29',
    data: '<path pid="0" d="M11.087 0L0 18.497h11.087V29L22 10.523H11.087z" _fill="#111" fill-rule="evenodd"/>'
  }
})
