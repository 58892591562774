export default {
    title: 'Time Travel',
    title_no: 'Tidsmaskin',
    subtitle: 'Numbers & historical dates',
    subtitle_no: 'Tall & historiske årstall',

    // tags: ['historic dates', 'birthdays', 'events'],
    // tags_no: ['historiske datoer', 'bursdager', 'hendelser'],

    techniques: ['symbolizationsys', 'doublesys', 'timesys'],
    articles: ['timetravel-history'],
    levelTexts: {
        level0:
            'You’ve stumbled upon a time machine which throws you into multiple time travels. It turns out that our history is quite different from what you previously thought. Have you got what it takes to remember the new historic events and when they happened?',
        level0_no:
            'Du har snublet borti en tidsmaskin som kaster deg inn i forskjellige tidsreiser. Det viser seg kjappt at historien er ganske annerledes enn det du har trodd til nå. Har du det som skal til for å huske de nye historiske hendelsene og når de skjedde?',

        level1:
            'Your first time travel is a rush! Can you remember the different years and the events as you’re thrown through time?',
        level1_no:
            'Din første tidsreise er et ordentlig kick! Klarer du å huske årstallene for de historiske hendelsene idet du kastes gjennom tiden?',
        level2:
            'Your time machine has become finetuned! You now have to remember the month and year of the event you travel to. Are you up for the challenge?',
        level2_no:
            'Tidsmaskinen din har blitt fininnstilt! Du må nå huske måneden og året da de historiske hendelsene skjedde. Er du klar for utfordringene?',
        level3:
            'Your time travels are becoming finetuned. You have to remember the date, month and year of the beginning and end of the events. Can you handle it?',
        level3_no:
            'Tidsmaskinen din har blitt enda mer fininnstilt! Du må nå huske dagen, måneden og året de historiske hendelsene skjedde. Er du klar for utfordringene?',
        level4:
            'Your time travels now happen over longer periods of time. Have you got what it takes to remember the start and end years of the different historic events?',
        level4_no:
            'Tidsreisene skjer nå over lengere tidsperioder. Klarer du å huske begynnelses- og sluttåret for de forskjellige historiske hendelsene?'
    },
    testLabels: {
        dateTest: 'Time',
        dateTest_no: 'Tid',
        multipleChoiceDateTest: 'Time',
        multipleChoiceDateTest_no: 'Tid'
    },

    Question: {
        question: 'When did this happen?',
        question_no: 'Når skjedde dette?'
    }
};
