import actions from './common-actions';
import state from './common-state';
import mutations from './common-mutations';
import getters from './common-getters';

export default function (gameId) {
    return {
        namespaced: true,
        state: state(gameId),
        getters,
        actions,
        mutations
    };
}
