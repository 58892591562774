/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'categories/leisure': {
    width: 33,
    height: 45,
    viewBox: '0 0 33 45',
    data: '<path pid="0" d="M20.88 4.467h-.917L21.247.47c0-.282.229-.47.458-.47.275 0 .459.235.459.47 0 .094-.046.188-.092.282L20.88 4.467zM17.807 25.11L32.62 6.16c.734-.94.367-1.693-.78-1.693H20.88l-1.422 4.467c2.018 1.129 3.486 3.057 2.43 5.502-.87 2.022-2.889 2.539-4.86 2.116l-1.422 4.42c0 .282-.23.47-.459.47a.466.466 0 01-.459-.47c0-.094.046-.188.092-.235l1.376-4.373a2.767 2.767 0 01-.55-.235c-2.34-1.035-3.027-3.339-2.156-5.408.872-2.069 2.935-3.056 5.183-2.069l1.33-4.09H1.159c-1.146 0-1.513.751-.78 1.692l14.814 18.95c.138.188.367.376.596.517v15.846C9.873 41.708 5.195 43.824 5.195 45h22.656c0-1.129-4.678-3.245-10.594-3.433V25.58c.183-.094.367-.282.55-.47zM16.064 9.922c-.504-.235-1.238.329-1.605 1.222-.367.894-.32 1.787.184 2.022.504.235 1.238-.329 1.605-1.222.367-.894.32-1.834-.184-2.022z" fill-rule="nonzero"/>'
  }
})
