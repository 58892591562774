import * as OfflinePluginRuntime from 'offline-plugin/runtime';
OfflinePluginRuntime.install({
  onUpdating: () => {
    console.log('SW Event:', 'onUpdating');
  },

  onUpdateReady: () => {
    console.log('SW Event:', 'onUpdateReady');
    OfflinePluginRuntime.applyUpdate();
  },

  onUpdated: () => {
    console.log('SW Event:', 'onUpdated');
    window.swUpdate = true;
  },

  onUpdateFailed: () => {
    console.log('SW Event:', 'onUpdateFailed');
  },
});

if (process.env.NODE_ENV === 'production') {
  setInterval(() => {
    fetch('/' + process.env.GIT_BRANCH + '/app-version.json', { cache: 'no-store' })
      .then(response => response.json())
      .then(({ GIT_REVISION }) => {
        if (process.env.GIT_REVISION !== GIT_REVISION) {
          window.swUpdate = true;
        }
      })
  }, 1000 * 60 * 1); // 1 minute
}


import './assets/styles/main.scss';

import initialize from './initialize';
initialize();
