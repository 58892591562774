<template>
  <div class="TeamDetails">
    <div v-for="team of teams" :key="team.id" class="teams">
      <h1 class="teamName">{{ team.name }}</h1>
      <!-- <h3>Tags</h3>
      <ul>
        <li
          v-for="t of team.tags"
          :key="t">
          {{ t }}  <button @click="removeTag(team.id, t)">remove</button>
        </li>
        <li>
          <input
            type="text"
            v-model.trim="tag"
            placeholder="new tag">
          <button @click="addTag(team.id)">Add tag</button>
        </li>
      </ul> -->
      <!-- <h3>Members</h3>
      <table>
        <thead>
          <th>Name</th>
          <th>Admin</th>
          <th />
        </thead>
        <tbody>
          <tr
            v-for="m of members"
            :key="m.avatar">
            <td>
              {{ m.displayName }}
            </td>
            <td>
              {{ m.isAdmin }}
            </td>
            <td v-if="isTeamAdmin">
              <button
                v-if="m.isAdmin"
                @click="updateAdmin(m.userId, team.id, false)">
                remove as admin
              </button>
              <button
                v-if="!m.isAdmin"
                @click="updateAdmin(m.userId, team.id, true)">
                make admin
              </button>
            </td>
          </tr>
        </tbody>
      </table> -->
      <!-- <h3>Invitations</h3> -->
      <CreateTeamInvitation :team-id="teamId" />

      <!-- <CreateTeamKey :team="team" /> -->

      <!-- <h3>Lifeskills</h3>
      <ul>
        <li
          v-for="ls of lifeskills"
          :key="ls.lifeskill">
          <EditTeamLifeskill
            @updated="fetch()"
            :team-id="team.id"
            :lifeskill="ls.lifeskill"
            :unlocked="ls.unlocked" />
        </li>
        <li>
          <AddTeamLifeskill
            @updated="fetch()"
            :team-id="team.id" />
        </li>
      </ul> -->

      <!-- <h3>Coupons</h3>
      <p>
        Members joining with these coupons
        automatically gets an invitation to
        join the team.
      </p>
      <ul>
        <li
          v-for="c of teamCouponsList"
          :key="c.id">
          {{ c.role }}
          <button
            @click="removeTeamCoupon(c.id)">
            remove
          </button>
        </li>
        <li>
          <AddTeamCoupon
            :team-id="team.id"
            @updated="fetch()" />
        </li>
      </ul> -->

      <form v-if="team.isAdmin" class="warn" @submit.prevent="openPopup()">
        <!-- Warning: Deleting a team is permanent. -->
        <button class="deleteButton" type="submit">{{ $t('deleteTeam') }}</button>
      </form>
    </div>
  </div>
</template>

<translations>
  sure: 'Are you sure you want to delete this team?'
  sure_no: 'Er du helt sikker du vil slette dette teamet?'
  yes: 'Yes'
  yes_no: 'Yes'
  no: 'No'
  no_no: 'No'
  deleteTeam: 'Delete team'
  deleteTeam_no: 'Slett teamet'
</translations>

<script>
import apollo from '@/apollo-client'
import gql from 'graphql-tag'
import teamDeletionPopup from './teamDeletionPopup.vue'

import CreateTeamInvitation from './CreateTeamInvitation'

export default {
  components: {
    CreateTeamInvitation,
  },

  filters: {},

  props: {
    teamId: Number,
  },
  data: function () {
    return {
      members: [],
      teams: [],
    }
  },

  computed: {
    isTeamAdmin() {
      const me = this.$store.getters['moduleAuth/profile'].id
      return this.members.filter(m => m.userId === me && m.isAdmin).length > 0
    },
  },

  mounted() {
    this.fetch()
  },

  methods: {
    async updateAdmin(userId, teamId, isAdmin) {
      await apollo.mutate({
        mutation: gql`
          mutation UpdateTeamAdmin($userId: String!, $teamId: Int!, $isAdmin: Boolean!) {
            __typename
            updateTeamAdmin(input: { _isAdmin: $isAdmin, _teamId: $teamId, _userId: $userId }) {
              isAdmins
            }
          }
        `,
        variables: { userId, teamId, isAdmin },
      })
      this.fetch()
    },

    async remove(teamId) {
      await apollo.mutate({
        mutation: gql`
          mutation TeamDelete($teamId: Int) {
            __typename
            deleteTeam(input: { _teamId: $teamId }) {
              clientMutationId
            }
          }
        `,
        variables: { teamId },
      })
      this.$router.push({
        name: 'DashboardHomeView',
      })
    },

    async fetch() {
      const teamId = parseInt(this.teamId)
      const result = await apollo.query({
        fetchPolicy: 'no-cache',
        query: gql`
          query TeamMembers($teamId: Int!) {
            teamMembersList(condition: { teamId: $teamId }, orderBy: DISPLAY_NAME_ASC) {
              avatar
              displayName
              teamId
              teamName
              isAdmin
              userId
            }
            teamsList(condition: { id: $teamId }, orderBy: NAME_ASC) {
              id
              name
              isAdmin
              tags
            }
            teamLifeskillsList(orderBy: UNLOCKED_ASC) {
              lifeskill
              teamId
              unlocked
            }
            teamCouponsList(condition: { teamId: $teamId }) {
              id
              role
              teamId
            }
          }
        `,
        variables: { teamId: teamId },
      })
      this.members = result.data.teamMembersList
      this.teams = result.data.teamsList
      this.lifeskills = result.data.teamLifeskillsList
    },
    openPopup() {
      this.$modal.open({
        component: teamDeletionPopup,
        hostOptions: { maskColor: 'rgba(0, 0, 0, 0.4)' },
        target: 'app-modal',
        props: {
          teamId: this.teamId,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.TeamDetails {
  position: relative;
  display: flex;
  justify-content: center;

  .teams {
    // border: 1px solid red;
    width: 30em;
  }
  .warn {
    padding: 1em;
  }
  .teamName {
    display: flex;
    // border: 1px solid red;
    color: white;
    justify-content: center;
    width: 100%;
  }
  .deleteButton {
    padding: 0.7em 1.3em;
    background-color: #8b0808;
    color: #fff;
    width: 100%;
    border-radius: 0.3em;
    border: 1px solid #980909;
    font-size: 1em;
    margin-top: 1em;
    opacity: 0.4;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }
  .teamDeletionPopup {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.351);

    .deletionPopupContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 35em;
      height: 20em;
      background-color: #0f1340;

      .deletionButtons {
      }
    }
  }
}
</style>
