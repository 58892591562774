/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'categories/brain': {
    width: 36,
    height: 36,
    viewBox: '0 0 36 36',
    data: '<path pid="0" d="M12.682 0c-.1 0-.173.006-.243.013h-.038C9.375.159 6.954 2.667 6.954 5.727c0 .421.13.796.218 1.19-2.968.952-5.127 3.718-5.127 6.992 0 .944.226 1.822.55 2.646C1.032 17.905 0 19.867 0 22.091 0 24.925 1.645 27.343 4 28.572c.266 2.683 2.517 4.807 5.267 4.807.262 0 .492-.077.742-.115C10.436 34.824 11.81 36 13.5 36c1.846 0 3.375-1.378 3.63-3.158.008-.052.03-.14.04-.243v-.025c.006-.072.012-.151.012-.256V2.864A2.876 2.876 0 0014.318 0h-1.636zm9 0a2.876 2.876 0 00-2.864 2.864v29.454c0 .105.006.184.013.256v.025c.009.103.03.191.038.243C19.125 34.622 20.654 36 22.5 36c1.689 0 3.064-1.177 3.49-2.736.25.038.48.115.742.115 2.75 0 5-2.124 5.267-4.807C34.355 27.343 36 24.925 36 22.091c0-2.224-1.032-4.186-2.595-5.536.324-.824.55-1.702.55-2.646 0-3.274-2.16-6.04-5.127-6.993.087-.393.218-.768.218-1.189 0-3.06-2.421-5.568-5.447-5.714h-.038A2.412 2.412 0 0023.318 0h-1.636z" fill-rule="nonzero"/>'
  }
})
