/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'map-with-steps': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<g data-name="Layer 1"><path pid="0" d="M37 42h-4a1 1 0 00-1 1c0 2.683 0 5 3 5s3-2.317 3-5a1 1 0 00-1-1zm-2 4c-.774 0-.959 0-.993-2h1.986c-.034 2-.22 2-.993 2zM34 30a3.51 3.51 0 00-2.954 2.298 6.937 6.937 0 001.19 7.347A.999.999 0 0033 40h4a1 1 0 00.976-.78 9.305 9.305 0 00-1.3-7.751A3.496 3.496 0 0034 30zm2.169 8h-2.683a4.876 4.876 0 01-.626-4.86c.348-.748.837-1.14 1.14-1.14a1.612 1.612 0 011.114.719A7.072 7.072 0 0136.169 38zM27 32h-4a1 1 0 00-1 1c0 2.683 0 5 3 5s3-2.317 3-5a1 1 0 00-1-1zm-2 4c-.774 0-.959 0-.993-2h1.986c-.034 2-.22 2-.993 2zM26 20a3.496 3.496 0 00-2.676 1.469 9.305 9.305 0 00-1.3 7.75A1 1 0 0023 30h4a.999.999 0 00.764-.355 6.937 6.937 0 001.19-7.347A3.51 3.51 0 0026 20zm.514 8H23.83a7.072 7.072 0 011.055-5.281A1.612 1.612 0 0126 22c.303 0 .792.392 1.14 1.14a4.876 4.876 0 01-.626 4.86zM37 22h-4a1 1 0 00-1 1c0 2.683 0 5 3 5s3-2.317 3-5a1 1 0 00-1-1zm-2 4c-.774 0-.959 0-.993-2h1.986c-.034 2-.22 2-.993 2zM33 20h4a1 1 0 00.976-.78 9.305 9.305 0 00-1.3-7.751A3.496 3.496 0 0034 10a3.51 3.51 0 00-2.954 2.298 6.937 6.937 0 001.19 7.347A.999.999 0 0033 20zm-.14-6.86c.348-.748.837-1.14 1.14-1.14a1.612 1.612 0 011.114.719A7.072 7.072 0 0136.169 18h-2.683a4.876 4.876 0 01-.626-4.86z"/><path pid="1" d="M59.447 9.105l-18-9a1 1 0 00-.825-.03l-21.58 8.827L1.447.105A1 1 0 000 1v50a1.001 1.001 0 00.594.914l18 8a.997.997 0 00.748.025l21.62-7.86 17.632 7.835A1 1 0 0060 59V10a1 1 0 00-.553-.895zM20 10.672L40 2.49V50.3l-20 7.272zM2 2.618l16 8v46.843L2 50.35zm56 54.843L42 50.35V2.618l16 8z"/></g>'
  }
})
