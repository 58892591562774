/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'keyboard-return': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" d="M38 14v8H11.66l7.17-7.17L16 12 4 24l12 12 2.83-2.83L11.66 26H42V14z"/>'
  }
})
