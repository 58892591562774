/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'getting-started-icons/video-part': {
    width: 53,
    height: 52,
    viewBox: '0 0 53 52',
    data: '<g _fill="#5081FF" fill-rule="nonzero"><circle pid="0" cx="10" cy="48" r="4"/><path pid="1" d="M50.853 46H14c.314.606.493 1.284.493 2s-.179 1.393-.493 2h36.853C52.04 50 53 49.104 53 48c0-1.105-.96-2-2.147-2zM2.158 46C.966 46 0 46.895 0 48c0 1.104.966 2 2.158 2H5a4.332 4.332 0 01-.496-2c0-.716.182-1.394.496-2H2.158zM46.861 0H6.139A6.14 6.14 0 000 6.143v30.714A6.14 6.14 0 006.139 43h40.722A6.14 6.14 0 0053 36.857V6.143A6.14 6.14 0 0046.861 0zm-29.98 34.37V9.305l21.694 12.533L16.88 34.371z"/></g>'
  }
})
