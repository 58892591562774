<template>
  <BlockContainer
    class="Game"
    type="Game"
    :label="gameId">
    <ButtonPlain
      label="Update level (better)"
      @click="updateLevel()" />
    <ButtonPlain
      label="clear levels"
      @click="clearLevels()" />

    <!-- {{ game }} -->
  </BlockContainer>
</template>

<script>
import upperFirst from 'lodash-es/upperFirst';

import BlockContainer from '../BlockContainer';
import ButtonPlain from '../ButtonPlain';

export default {
    components: { BlockContainer, ButtonPlain },
    props: ['gameId'],
    computed: {
        game() {
            return this.$store.state.moduleMemogenius.games[this.gameId];
        },
        gameModuleName() {
            return `moduleGame${upperFirst(this.gameId)}`;
        }
    },
    methods: {
        updateLevel() {
            this.$store.dispatch(`${this.gameModuleName}/updateLevel`, {
                levelIndex: 0,
                data: {
                    stars: 3,
                    bestTime: 1
                }
            });
        },
        clearLevels() {
            this.$store.dispatch(`${this.gameModuleName}/dbgClearLevels`);
        }
    }
};
</script>

<style lang="scss" scoped></style>
