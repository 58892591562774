export default {
    getStarted: 'Get started',
    getStarted_no: 'Kom i gang',

    of: 'of',
    of_no: 'av',

    showMore: 'Show more',
    showMore_no: 'Vis mer',
    showLess: 'Show less',
    showLess_no: 'Vis mindre',

    or: 'or',
    or_no: 'eller',

    forDiploma: 'to your diploma',
    forDiploma_no: 'til diplomet ditt',

    completed: 'completed',
    completed_no: 'fullført'
};
