/* eslint-disable */
require('./article')
require('./brain-date')
require('./brain')
require('./daily-goal')
require('./double-memory')
require('./favorite-selections')
require('./flag-with-checkmark')
require('./game')
require('./masterquiz')
require('./quiz')
require('./scenario')
require('./social-star')
require('./video-part')
