import gql from 'graphql-tag';
import apolloClient from '@/apollo-client';

export function updateCourseProgress(courseId, context, contextId, activity, status) {
    console.debug('updateCourseProgress', courseId, context, contextId, activity, status);
    return apolloClient.mutate({
        mutation: gql`
            mutation($courseId: String!, $context: String!, $contextId: String!, $activity: String!, $status: String!) {
                updateCourseProgress(
                    input: {
                        _courseId: $courseId
                        _context: $context
                        _contextId: $contextId
                        _activity: $activity
                        _status: $status
                    }
                ) {
                    results {
                        activity
                        context
                        contextId
                        courseId
                        createdAt
                        status
                    }
                }
            }
        `,
        variables: { courseId, context, contextId, activity, status }
    });
}

export function fetchCourseProgress(progressMapping) {
    console.info('fetchCourseProgress');
    return apolloClient
        .query({
            query: gql`
                query {
                    courseProgressesList {
                        activity
                        context
                        contextId
                        courseId
                        createdAt
                        status
                    }
                }
            `
        })
        .then(result => {
            return transformUserprogress(result.data.courseProgressesList, progressMapping);
        });
}

function transformUserprogress(arr, progressMappingRaw) {
    const { splits = {}, stepIdChanges = {}, journeyIdChanges = {} } = progressMappingRaw
        ? JSON.parse(progressMappingRaw)
        : {};

    const resultArr = [];

    console.log('---- transformUserprogress ----');

    const activityMap = {};
    arr.forEach(entry => {
        if (entry.context === 'step') {
            activityMap[`${entry.contextId},${entry.activity}`] = entry;
        }
    });

    arr.forEach(entry => {
        if (entry.status !== 'done') {
            return;
        }
        if (entry.context === 'step') {
            const newStepId = stepIdChanges[entry.contextId];
            if (newStepId) {
                entry.contextId = newStepId;
            }

            const splitIntruction = splits[entry.contextId];
            if (splitIntruction) {
                if (splitIntruction.courseIntroId) {
                    if (entry.activity === 'read') {
                        const activityId = `${entry.contextId},${entry.activity}`;
                        console.log('Split from step', entry.contextId, `-> clone ${entry.activity} to a courseIntro`);
                        if (!activityMap[activityId]) {
                            resultArr.push(
                                Object.assign({}, entry, {
                                    courseId: splitIntruction.courseId,
                                    contextId: splitIntruction.courseIntroId
                                })
                            );
                        }
                    }
                }
                splitIntruction.stepIds.forEach(stepId => {
                    if (entry.activity === 'read' || entry.activity === 'quiz') {
                        const activityId = `${entry.contextId},${entry.activity}`;

                        console.log('Split from step', entry.contextId, `-> clone ${entry.activity} to a step`);
                        if (!activityMap[activityId]) {
                            resultArr.push(
                                Object.assign({}, entry, { courseId: splitIntruction.courseId, contextId: stepId })
                            );
                        }
                    }
                });
            } else {
                resultArr.push(entry);
            }
        } else if (entry.context === 'stepgroup') {
            const newJourneyId = journeyIdChanges[entry.contextId];
            if (newJourneyId) {
                entry.contextId = newJourneyId;
            }
            resultArr.push(entry);
        } else {
            resultArr.push(entry);
        }
    });

    return arr;
}
