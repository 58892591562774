/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'video': {
    width: 50,
    height: 50,
    viewBox: '0 0 50 50',
    data: '<path pid="0" d="M4 0h42a4 4 0 014 4v42a4 4 0 01-4 4H4a4 4 0 01-4-4V4a4 4 0 014-4zm10.74 15h15.61a2 2 0 012 2v16a2 2 0 01-2 2H14.74a2.006 2.006 0 01-2-2V17a2.006 2.006 0 012-2zm20.55 6v7l4.91 4V16z" fill-rule="evenodd"/>'
  }
})
