import games from './memogenius-games';
import techniques from './memogenius-techniques';
export default {
    games,
    techniques,

    articlesMetadata: {
        'memolifesys-single': {
            systemId: 'doublesys',
            labelAsIntroButton: 'Start: single',
            labelAsIntroButton_no: 'Start: singel'
        },
        'memolifesys-double': {
            systemId: 'doublesys',
            labelAsIntroButton: 'Continue: double',
            labelAsIntroButton_no: 'Fortsett: dobbel'
        },
        journeysys: { systemId: 'journeysys' },
        'names-and-faces': { systemId: 'namesys' },
        clone: { systemId: 'clonesys' },
        alphabetsys: { systemId: 'alphabetsys' },
        rhymesys: { systemId: 'rhymesys' },
        timesystem: { systemId: 'timesys' },
        knowledgesystem: { systemId: 'knowledgesys' },
        similaritysys: { systemId: 'similaritysys' },
        highlightsys: { systemId: 'highlightsys' },
        symbolizationsys: { systemId: 'symbolizationsys' },
        linksys: { systemId: 'chainsys' },

        'bankvault-numbers': {},
        basics: {},
        curriculum: {},
        'language-similarity': {}, // ![banner](../images/article_images/similar-system-image.png)
        meeting: {},
        memoryareas: {},
        'nail-abstract-words-brainstorming': {},
        shoppinglist: {},
        'timetravel-history': {},
        'visualization-intro': {}
    },

    MemogeniusGameIntroView: {
        sideTitle: 'Memory systems',
        sideTitle_no: 'Huskesystemer',
        readArticle: 'Read the article',
        readArticle_no: 'Les artikkelen',

        resourcesLabel: 'Resources',
        resourcesLabel_no: 'Ressurser'
    },

    MemogeniusSystemIntroView: {
        articleButtonLabel: 'Read the article',
        articleButtonLabel_no: 'Les artikkelen',
        practiceButtonLabel: 'Learn & practise',
        practiceButtonLabel_no: 'Lær & Praktiser',

        readMore: 'Read more',
        readMore_no: 'Les mer'
    }
};
