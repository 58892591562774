/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'getting-started-icons/quiz': {
    width: 52,
    height: 52,
    viewBox: '0 0 52 52',
    data: '<g _fill="#5081FF" fill-rule="nonzero"><path pid="0" d="M.426 26.156l5.09 4.932c.569.55 1.49.55 2.057 0L16 22.925l8.426 8.163c.283.275.656.412 1.028.412.373 0 .745-.137 1.029-.412l5.09-4.932a1.377 1.377 0 000-1.992L23.149 16l8.426-8.163a1.377 1.377 0 000-1.992L26.483.914a1.487 1.487 0 00-2.057 0L16 9.076 7.574.914a1.487 1.487 0 00-2.057 0L.427 5.845a1.377 1.377 0 000 1.993L8.851 16 .426 24.163a1.379 1.379 0 000 1.993zm11.512-9.159a1.377 1.377 0 000-1.992L3.512 6.842l3.033-2.939 8.426 8.163c.568.55 1.49.55 2.057 0l8.426-8.163 3.034 2.939-8.426 8.162a1.377 1.377 0 000 1.993l8.426 8.163-3.034 2.938-8.426-8.162A1.474 1.474 0 0016 19.523c-.373 0-.745.138-1.029.413l-8.426 8.162-3.033-2.938 8.426-8.163z"/><path pid="1" d="M46.471 19.918a1.482 1.482 0 00-2.061 0L25.762 38.112l-9.172-8.95a1.482 1.482 0 00-2.061 0L9.427 34.14a1.397 1.397 0 000 2.01l15.305 14.934c.284.278.657.417 1.03.417.374 0 .747-.14 1.031-.417l24.78-24.177c.57-.555.57-1.456 0-2.011l-5.102-4.978zM25.762 48.067L12.52 35.145l3.04-2.966 9.173 8.95c.569.556 1.492.556 2.06 0L45.44 22.935l3.04 2.966-22.718 22.166z"/></g>'
  }
})
