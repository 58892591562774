<template>
  <BlockContainer
    class="StepGroup"
    type="StepGroup"
    :label="stepGroupId">
    <template v-if="stepGroup.quiz">
      <div>rep: {{ stepGroup.repetition }}</div>
      <ButtonWithCheckmark
        class="ButtonWithCheckmark"
        label="Toggle group-quiz done"
        :done="!!stepGroup.quiz.done"
        @click="toggleGroupQuiz(stepGroup.id)" />

      <ButtonPlain
        label="Update StepGroup repetition"
        @click="$store.dispatch('moduleCourse/updateStepGroupRepetition', { stepGroupId })" />
      <ButtonPlain
        label="Goto group quiz intro"
        @click="gotoStepGroupQuizIntro()" />
      <!-- <ButtonPlain
        label="Clear progress"
        @click="clearAllProgress()"
      /> -->
      <ButtonPlain
        label="print stats"
        @click="printStats()" />
    </template>
    <div class="steps">
      <Step
        v-for="stepId of stepGroup.stepIds"
        :key="stepId"
        class="Step"
        :step-id="stepId" />
    </div>
  </BlockContainer>
</template>

<script>
import BlockContainer from '../BlockContainer';
import ButtonPlain from '../ButtonPlain';
import ButtonWithCheckmark from '../ButtonWithCheckmark';

import Step from './Step';

export default {
    components: { Step, ButtonWithCheckmark, BlockContainer, ButtonPlain },
    props: {
        stepGroupId: {
            type: String,
            required: true
        }
    },
    computed: {
        stepGroup() {
            return this.$store.state.moduleCourse.stepGroups[this.stepGroupId];
        }
    },
    methods: {
        toggleGroupQuiz(stepGroupId) {
            this.$store.dispatch('moduleCourse/markStepGroupQuizAsDone', {
                stepGroupId,
                reset: this.stepGroup.quiz && this.stepGroup.quiz.done
            });
        },
        gotoStepGroupQuizIntro() {
            this.$router.push({
                name: 'StepGroupQuizIntroView',
                params: { courseId: this.stepGroup.courseId, stepGroupId: this.stepGroupId }
            });
        },
        // clearAllProgress () {
        //   this.$store.dispatch('moduleCourse/dbgResetJourneyState', { stepGroupId: this.stepGroupId })
        // },
        printStats() {
            console.log(
                `Journey stats for ${this.stepGroupId}:\n`,
                JSON.stringify(this.$store.getters['moduleCourse/getStepGroupStats'](this.stepGroupId), null, '  ')
            ); // eslint-disable-line no-console
        }
    }
};
</script>

<style lang="scss" scoped>
.Step {
    margin-top: 1em;
}
</style>
