/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'activities/memo-overview': {
    width: 32,
    height: 26,
    viewBox: '0 0 32 26',
    data: '<path pid="0" d="M21.535 16.708c1.626.171 3.155-.535 4.034-1.68a4.578 4.578 0 003.84-3.436 4.403 4.403 0 002.118-3.306c.258-2.45-1.538-4.62-4.04-4.883a4.31 4.31 0 00-1.527.088c-.616-1.365-1.97-2.313-3.597-2.484-1.189-.125-2.297.192-3.194.903-.736-.82-1.748-1.422-2.937-1.547-1.501-.158-2.892.439-3.884 1.45-.586-.434-1.318-.697-2.068-.775C7.777.775 5.57 2.524 5.312 4.973c-1.634.51-2.921 1.922-3.114 3.76-.052.49-.034.924.052 1.305-.834.717-1.45 1.767-1.573 2.93-.174 1.654.554 3.154 1.853 4.034l-.02.183c-.257 2.45 1.602 4.626 4.041 4.883a4.814 4.814 0 002.98-.678c.538.304 1.144.554 1.77.62.188.02.444-.016.632.004.975.165 1.12-2.957-.342 3.426 1.14-.2 2.247-.325 3.322-.374 0 0 1.03-2.297 1.78-4.409 2.158-.083 3.965-1.626 4.31-3.695.143-.17.338-.212.532-.254z" _fill="#111" fill-rule="nonzero"/>'
  }
})
