/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'steps2': {
    width: 16,
    height: 16,
    viewBox: '0 0 1000 1000',
    data: '<path pid="0" d="M757.29 11.12c-49.1 6.31-106.07 63.06-145.04 143.68-34.68 72.07-50 138.05-50 216.2 0 37.39 1.8 58.33 7.21 84.91 2.03 10.36 3.15 11.94 9.46 13.96 31.98 10.14 182.43 54.28 184.68 54.06 5.86-.23 55.63-65.31 75.22-98.19 23.87-40.54 37.16-77.25 46.17-126.79 6.08-34.23 6.76-105.17 1.13-137.38-15.31-88.73-55.4-145.49-106.3-151.34-4.96-.46-15.1-.24-22.53.89zM217.91 245.11c-81.98 12.61-128.6 156.75-98.87 304.94 12.16 60.35 40.09 114.4 93.24 180.62 12.16 15.32 23.2 27.7 24.55 27.7 3.15 0 190.75-55.85 192.11-56.98.45-.45 2.25-9.46 4.28-19.82 4.95-25.9 6.76-94.36 3.38-126.35-7.88-75.44-37.16-157.65-77.92-219.13-16.89-25.67-55.4-64.41-75.45-76.12-22.31-12.83-45.05-18.01-65.32-14.86zM549.65 550.72c-27.48 61.26-32.66 119.82-13.96 156.3 18.92 37.16 70.27 57.88 111.93 45.04 43.92-13.74 73.19-62.61 81.98-137.6 1.35-12.39 2.25-22.97 1.58-23.65-.45-.45-37.39-11.48-81.98-24.77s-84-25-87.38-26.12c-6.09-2.03-6.54-1.81-12.17 10.8zM357.09 798.46l-87.38 26.13v9.23c0 27.03 14.19 81.08 27.03 103.82 26.35 45.95 71.62 63.29 118.69 45.49 27.93-10.58 45.72-28.38 55.18-55.85 6.98-20.04 6.98-57.43-.23-86.71-4.5-18.7-20.94-63.51-24.55-66.66-.91-.9-40.77 10.14-88.74 24.55z"/>'
  }
})
