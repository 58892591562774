/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'close': {
    width: 19.72,
    height: 19.624,
    viewBox: '0 0 19.72 19.624',
    data: '<path pid="0" d="M19.16 3.199l-6.65 6.613 6.65 6.613a1.876 1.876 0 11-2.66 2.645l-6.64-6.613-6.64 6.613a1.889 1.889 0 01-2.66 0 1.853 1.853 0 010-2.645L7.2 9.812.56 3.199a1.853 1.853 0 010-2.645 1.889 1.889 0 012.66 0l6.64 6.613L16.5.554a1.876 1.876 0 012.66 2.645z" fill-rule="evenodd"/>'
  }
})
