import { Howl } from 'howler';

export class SoundService {
    constructor() {
        this.soundMap = {};
        this.layers = {};
    }

    setLayerVolume(layerName, volume) {
        if (this.layers[layerName]) {
            this.layers[layerName].volume = volume;
        } else {
            this.layers[layerName] = { volume };
        }
        Object.values(this.soundMap)
            .filter(sound => sound.layerName === layerName)
            .forEach(sound => {
                sound.volume(sound.originalVolume * volume);
            });
    }

    play(soundUrl, options = {}, layerName = 'effect') {
        const originalVolume = options ? options.volume || 1 : 1;
        const layerVolume = this.layers[layerName] ? this.layers[layerName].volume : 1;
        let sound = new Howl(
            Object.assign(
                {
                    src: [soundUrl]
                },
                options,
                { volume: originalVolume * layerVolume }
            )
        );
        sound.layerName = layerName;
        sound.originalVolume = originalVolume;
        sound.play();
        this.soundMap[soundUrl] = sound;
        // TODO
        // this.ea.publish('soundService:play', {
        //   initiatedByUser: initiatedByUser || false
        // })
    }

    stop(soundUrl, fadeDuration = 0) {
        const sound = this.soundMap[soundUrl];
        if (!sound) {
            return;
        }
        if (fadeDuration) {
            sound.once('fade', () => sound.stop());
            sound.fade(sound.volume(), 0, fadeDuration);
        } else {
            sound.stop();
        }
    }

    preload(soundUrls) {
        const sounds = soundUrls
            .map(soundUrl => {
                return this.soundMap[soundUrl] || new Howl({ src: [soundUrl] });
            })
            .filter(sound => {
                return sound.state() !== 'loaded';
            });
        return Promise.all(
            sounds.map(sound => {
                return new Promise(resolve => {
                    sound.once('load', resolve());
                    sound.once('onloaderror', resolve());
                });
            })
        );
        // Clear listener after first call.
        // sound.once('load', function() {
        //   sound.play();
        // });
        // return Promise.resolve()
    }
}

const soundService = new SoundService();
export default soundService;
