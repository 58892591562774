/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'steps-3': {
    width: 11,
    height: 17,
    viewBox: '0 0 11 17',
    data: '<g _fill="#000" fill-rule="evenodd"><path pid="0" d="M8.237 4.778c-1.39 0-2.18 1.576-2.18 3.864 0 1.273.96 1.822.922 3.199-.02.763-.482 1.658-.482 2.22 0 1.648.699 2.55 1.74 2.55s1.64-1.353 1.886-2.985c.314-2.084.63-3.434.63-4.707 0-2.287-1.127-4.141-2.515-4.141h-.001zM2.562 0C1.174 0 .048 1.854.048 4.142c0 1.273.314 2.623.629 4.706.246 1.633.844 2.986 1.886 2.986 1.042 0 1.74-.902 1.74-2.551 0-.562-.462-1.456-.482-2.22-.038-1.377.923-1.926.923-3.199C4.744 1.577 3.952 0 2.564 0h-.002z"/></g>'
  }
})
