/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'keyShort': {
    width: 21,
    height: 21,
    viewBox: '0 0 21 21',
    data: '<path pid="0" d="M9.932 3.916c.07 1.235-.36 3.233-.449 4.339l1.967 1.92c.132.13.263.13.394 0l1.77-1.728c.258-.254.705-.233.963.021l.544.541c.258.254.325.72.067.975l-1.77 1.729c-.131.13-.131.254 0 .384l1.279 1.248c.13.13.36.226.491.097l1.771-1.73c.258-.254.55-.347.808-.092l.544.54c.258.255.283.643.025.897l-1.968 1.921.788.769a.802.802 0 010 1.152l-.197.192a.849.849 0 01-1.18 0l-4.132-4.034a.822.822 0 01-.108-.129L8.106 9.6c-1.135.11-2.751.209-3.98.042-2.65-.356-3.836-4.301.399-5.259a.098.098 0 00.06-.06c1.089-4.597 5.239-2.4 5.347-.407zM5.57 5.544C3.52 6.038 4.468 7.8 5.35 7.838c.58.02 2.438-.089 2.438-.17.033-.258.254-1.435.14-2.352-.145-1.131-1.884-1.616-2.335.204 0 .01-.015.024-.024.024z" _fill="#EDE494"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M16.664 14.69c-.197.192-.197.384 0 .577.197.192-.066.192-.787 0l.197-.577h.59z" _fill="#EDE494"/>'
  }
})
