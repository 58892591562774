<template>
  <div class="RiveAuraPopup">
    <canvas
      ref="canvas" id="riveAnimation" class="riveContainer"
      height="500" width="500" />
  </div>
</template>


<script>
const rive = require("rive-js");
import RiveFile from './rive/aura-popup.riv'

export default {
  props: {
    energyLevel: {
      type: String,
      default: 'green',
    },
  },

  data() {
    return {
      r: null,
      inputs: null,
    };
  },

  mounted() {
    this.setRive();
  },

  computed: {
    auraNum() {
      return {
        'green': 1,
        'yellow': 2,
        'orange': 3,
        'red': 4,
      }[this.energyLevel];
    },
  },

  beforeDestroy(){
   this.r.stop();
   this.r.unsubscribeAll();
   this.r = null;
  },

  watch: {
      auraNum(v){
          this.inputs[0].value = v;
      }
  },

  methods: {
    setRive(){
      this.r = new rive.Rive({
          src: RiveFile,
          canvas: this.$refs.canvas,
          stateMachines: 'popup_aura_states',
          autoplay: true,
          layout: new rive.Layout({fit: 'fill', alignment: 'center'}),
          onload: () => {
            this.inputs = this.r.stateMachineInputs('popup_aura_states');
            this.playAuraRive(this.auraNum+1);

            setTimeout(() => {
                this.playCrystalRive();

                setTimeout(() => {
                  if(this.auraNum !== 1){
                    this.playAuraRive(this.auraNum);
                  } else {
                    this.animateAuraMemoman();
                  }
                }, 3000);
            }, 1000);
          },
      }); 
    },
    playAuraRive(v){
      this.inputs[0].value = v;
    },
    playCrystalRive(){
      this.inputs[1].value = true;
    },
    animateAuraMemoman(){
      this.playAuraRive(this.auraNum);
      setTimeout(() => {
        this.playAuraRive(this.auraNum-1);
        setTimeout(() => {
          this.playAuraRive(this.auraNum-2);
        }, 8500);
      }, 3000);
    }
  },
};
</script>

<style scoped lang="scss">
.RiveAuraPopup {
  padding-top: 2em;
  // height: 300px;
  // width: 300px;
}

.riveContainer {
  // width: 100%;
  @media (max-width: 620px) {
     width: 90vw;
  }
  
}
</style>
