import store from '@/store';

const MentalHygieneView = () => import('./views/MentalHygieneView');

export default [
  {
    path: '/mentalhygiene',
    name: 'MentalHygiene',
    component: MentalHygieneView,
    props: true,
    beforeEnter: (to, from, next) => {
      store.dispatch('moduleMentalhygiene/load').then(() => next());
    }
  },
];
