<template>
  <div
    class="ButtonPlain --global-clickable"
    @click="$emit('click')">
    <div class="label">
      {{ label }}
    </div>
  </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String
        }
    }
};
</script>

<style lang="scss" scoped>
$transitionSpeed: 0.5s;

.ButtonPlain {
    border-radius: 0.2em;
    color: white;
    padding: 0.3em 0.8em;

    display: inline-flex;
    align-items: center;

    background-color: $standardButtonColor;
}
.label {
    font-size: 90%;
}
</style>
