/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'campfire': {
    width: 300,
    height: 300,
    viewBox: '0 0 66 66',
    data: '<path pid="0" d="M23.9 41c.1-6.2 4.3-12 9.2-16 .3-.2.6-.3 1.1-.2 1.1.4 4.1 3.8 5.3 5.6 0 0 0 .1.1.1 2 2.9 3.7 6.7 3.6 11.2 8.1-3.6 13.6-13.6 6.7-25.2-1.2 5.2-5.7 7.3-5.9 7.4-.8.4-1.7-.4-1.5-1.3 2.5-9.8-4-18.1-7.4-21.6-.5 7.6-6.2 15.2-6.5 15.6-.6.8-2 .4-2-.7.1-2.8-1.5-5.1-2.7-6.4-.6 2.5-2.2 5.8-4.6 9.9-4.9 8.3-2.4 17.5 4.6 21.6z"/><path pid="1" d="M33.8 27.2c-4.4 3.8-8.1 9.4-7.7 14.9 3.9 1.5 11.1 1.5 14.8.4.7-7.4-4.7-13.1-7.1-15.3zM52.9 52.4l-7.5-3.9L33.2 55l14.4 7.5c-.9-4 1.5-8.6 5.3-10.1z"/><path pid="2" d="M56.4 36.8c0-.9-.2-1.9-.7-2.7-.3-.6-.8-1.2-1.3-1.6-2.3 6.7-9.1 13-19.2 13-3.9 0-6.6 0-10.3-1.5-1.1.6-2.1 1.2-11.8 6.3 3.9 1.5 6.3 6.1 5.4 10.1 20.2-10.7 6-3.2 34.9-18.6 1.8-1 3-2.9 3-5zM12.6 44.1l3.8 2c1.2-.6 4.2-2.2 6.3-3.3-3.5-2.1-6-5.4-7.3-9.4h-.1c-2.1 0-4.1 1.2-5 3.1-1.6 2.7-.5 6.2 2.3 7.6zM9.1 52.4C7 53.5 6.5 56.7 8 59.5c1.7 3.1 4.6 4 6.5 3 2.1-1.1 2.6-4.3 1.1-7.1-1.6-3.1-4.6-4-6.5-3zM56.9 54.5c-5.1-2.7-10.3 7.5-5.3 10.1 2.3 1.2 5.2-.5 6.5-3 1.2-2.5 1.1-5.9-1.2-7.1z"/>'
  }
})
