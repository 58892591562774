/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'star': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" data-name="2" d="M24.01 20.42L25.91 32 16 26.53 6.09 32l1.9-11.58L0 12.244l11.04-1.684L16 0l4.96 10.56L32 12.244z"/>'
  }
})
