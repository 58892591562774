/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'settings': {
    width: 28,
    height: 28,
    viewBox: '0 0 28 28',
    data: '<path pid="0" d="M27.8 17.4v-6.7h-5.1c-.1-.1-.1-.3-.1-.4l3.6-3.6L21.5 2l-3.6 3.6c-.1-.1-.3-.1-.4-.1V.4h-6.7v5.1c-.1.1-.3.1-.4.1L6.8 2 2.1 6.7l3.6 3.6c-.1.1-.1.3-.1.4H.5v6.7h5.1c.1.1.1.3.1.4l-3.6 3.6 4.7 4.7 3.6-3.6c.1.1.3.1.4.1v5.1h6.7v-5.1c.1-.1.3-.1.4-.1l3.6 3.6 4.7-4.7-3.6-3.6c.1-.1.1-.3.1-.4h5.1zM14 18.6c-2.5 0-4.6-2.1-4.6-4.6 0-2.5 2.1-4.6 4.6-4.6 2.5 0 4.6 2.1 4.6 4.6 0 2.5-2 4.6-4.6 4.6z" fill-rule="nonzero"/>'
  }
})
