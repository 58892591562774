import axios from 'axios';
import VueAuthenticate from 'vue-authenticate';
import * as types from '../mutation-types';
import settings from '@/settings';
import { updateAbility } from '@/ability';
import * as Sentry from '@sentry/vue';

let pathname = '/oauth-redirect/';
// NOTE: making sure that the url ends with a '/'. This is important. Without the '/' the server can cause a redirect that prevents facebook login from working.
// const pathname = window.location.pathname.endsWith('/') ? window.location.pathname : window.location.pathname + '/'
if (window.location.pathname.startsWith(process.env.GIT_BRANCH)) {
    pathname = '/' + process.env.GIT_BRANCH + pathname;
}

const vueAuth = VueAuthenticate.factory(axios, {
    tokenPath: 'token',
    tokenName: 'token', // 'access_token',
    baseUrl: settings.endpoints.bouncer,
    withCredentials: true,
    loginUrl: '/login/',
    logoutUrl: settings.endpoints.bouncer + '/logout/',
    registerUrl: '/users/',
    providers: {
        facebook: {
            clientId: settings.facebookClientId, // '919907441412097',
            scope: ['email', 'public_profile'],
            authorizationEndpoint: 'https://www.facebook.com/v3.2/dialog/oauth',
            redirectUri: window.location.origin + pathname
        }
    }
});

// function gravatar (email) {
//   const hash = md5(email)
//   this.avatar = `http://www.gravatar.com/avatar/${hash}?s=200`;
// }

function getProfile() {
    let profile = vueAuth.getPayload();
    return profile;
}

const state = {
    isAuthenticated: vueAuth.isAuthenticated() && getProfile() !== undefined,
    pending: false,
    profile: getProfile()
};

const getters = {
    isAuthenticated: state => {
        return state.isAuthenticated;
    },

    profile: state => {
        return state.profile;
    },

    avatar: state => {
        return state.profile.avatar;
    },

    isTempUser: state => {
        if (state.isAuthenticated) {
            const roles = state.profile.roles;
            return roles.indexOf('flytoget') !== -1 && roles.indexOf('tempuser') !== -1;
        } else {
            return false;
        }
    },
    isSubscribed: state => {
        return (
            state.profile &&
            state.profile.subscription &&
            state.profile.subscription.plan_id &&
            state.profile.subscription.status !== 'cancelled'
        );
    },
    token: state => {
        return vueAuth.getToken();
    },
    canAccessOldMemolanguage: state => {
        const profile = state.profile;
        if (!profile) {
            return false;
        }
        const oldUser = profile.created_at * 1000 < 1519772400828;
        const hasFlytogetRole = profile.roles.indexOf('flytoget') !== -1;
        return oldUser && !hasFlytogetRole;
    },
    plan: state => {
        if (state.isAuthenticated && state.profile && state.profile.subscription) {
            return state.profile.subscription.plan_id;
        }
        return null;
    }
};

const actions = {
    async login({ commit, dispatch }, { username, password }) {
        commit(types.AUTH_LOGIN);
        await vueAuth.login({ username, password });
        await dispatch('updateProfile');
        commit(types.AUTH_LOGIN_SUCCESS);
        await dispatch('moduleApp/loadUser', null, { root: true });
        window.dataLayer.push({ event: 'login' });
    },
    async authenticate({ commit, dispatch, rootGetters }, provider) {
        let language = rootGetters['moduleApp/locale'];
        let currency = language === 'no' ? 'nok' : 'usd';
        commit(types.AUTH_LOGIN);
        await vueAuth.authenticate(provider, { currency, language });
        await dispatch('updateProfile');
        commit(types.AUTH_LOGIN_SUCCESS);
        await dispatch('moduleApp/loadUser', null, { root: true });
        window.dataLayer.push({ event: 'authenticate' });
    },
    async register({ commit, dispatch }, { firstName, lastName, email, username, password, currency, language }) {
        commit(types.AUTH_LOGIN);
        try {
            await vueAuth.register({
                email,
                username,
                password,
                first_name: firstName,
                last_name: lastName,
                currency,
                language
            });
        } catch (err) {
            window.dataLayer.push({ event: 'register_failed' });
            throw new Error(err.response.data.error || '');
        }
        await dispatch('updateProfile');
        commit(types.AUTH_LOGIN_SUCCESS);
        await dispatch('moduleApp/loadUser', null, { root: true });
        window.dataLayer.push({ event: 'register_account' });
    },
    async logout({ commit, dispatch }) {
        await vueAuth.logout();
        commit(types.AUTH_LOGOUT);
        await dispatch('updateProfile');
        await dispatch('moduleApp/cleanupAfterUserLogout', null, { root: true });
        window.dataLayer.push({ event: 'logout' });
        location.reload(); // NOTE: Lazy solution for making sure the state is reset
    },

    async deleteAccount() {
      window.dataLayer.push({ event: 'delete-account' });
      const url = `${settings.endpoints.bouncer}/users/me/delete/`;
      await axios.post(url, {});
    },

    async subscribe({ dispatch }, data) {
        const url = `${settings.endpoints.bouncer}/subscribe`;
        try {
            await axios.post(url, data);
            await dispatch('updateAuthToken');
        } catch (err) {
            throw err.response;
        }
        window.dataLayer.push({ event: 'subscribe' });
    },

    async cancelSubscription({ dispatch }, data) {
        const url = `${settings.endpoints.bouncer}/cancel-subscription/`;
        try {
            await axios.post(url, data);
            await dispatch('updateAuthToken');
        } catch (err) {
            throw err.response;
        }
    },

    async addPaymentSource({ dispatch }, data) {
        const url = `${settings.endpoints.bouncer}/payment-source/`;
        try {
            await axios.post(url, data);
        } catch (err) {
            throw err.response;
        }
    },

    paymentIntent({ dispatch }, paymentRequest) {
        const url = `${settings.endpoints.bouncer}/payment-intent/`;
        return axios.post(url, paymentRequest);
    },

    confirmPaymentIntent({ dispatch }, paymentIntentId) {
        const url = `${settings.endpoints.bouncer}/confirm-payment-intent/`;
        return axios.post(url, { payment_intent_id: paymentIntentId });
    },

    updateMe({ commit, dispatch }, data) {
        const url = `${settings.endpoints.bouncer}/users/me/`;
        return axios.put(url, data).then(response => {
            return dispatch('updateAuthToken');
        });
    },

    async createTempUser({ commit, dispatch }, data) {
        const url = `${settings.endpoints.bouncer}/preauth/`;
        commit(types.AUTH_LOGIN);
        const response = await axios.post(url, data);
        vueAuth.setToken(response);
        await dispatch('updateProfile');
        commit(types.AUTH_LOGIN_SUCCESS);
        await dispatch('moduleApp/loadUser', null, { root: true });
        window.dataLayer.push({ event: 'create_temp_user' });
    },

    createOrUpdateUser({ commit, dispatch }, data) {
        const url = `${settings.endpoints.bouncer}/users/`;
        return axios
            .post(url, data)
            .then(response => {
                vueAuth.setToken(response);
                return dispatch('updateProfile');
            })
            .catch(err => {
                // throw new Error(err.response.data.message || '')
                throw err.response.data;
            });
    },

    requestPasswordReset({ commit }, { email }) {
        const url = `${settings.endpoints.bouncer}/forgot`;
        return axios
            .post(url, {
                email,
                template: 'password-reset_en'
            })
            .then(response => {
                window.dataLayer.push({ event: 'password_reset_request' });
            })
            .catch(err => {
                window.dataLayer.push({ event: 'password_reset_failed' });
                throw new Error(err.response.data.message || '');
            });
    },

    resetPassword({ commit }, { token, password }) {
        const url = `${settings.endpoints.bouncer}/reset`;
        return axios
            .post(url, {
                token,
                password
            })
            .then(response => {
                window.dataLayer.push({ event: 'password_reset_change_password' });
            })
            .catch(err => {
                throw new Error(err.response.data.message || '');
            });
    },

    updateAuthToken({ dispatch }) {
        const url = `${settings.endpoints.bouncer}/update-auth-token/`;
        return axios.post(url).then(response => {
            vueAuth.setToken(response);
            return dispatch('updateProfile');
        });
    },

    reAuth({ dispatch }, { q, reload = false }) {
        const url = `${settings.endpoints.bouncer}/reauth/`;
        return axios
            .post(url, { q })
            .then(response => {
                vueAuth.setToken(response);
                return dispatch('updateProfile');
            })
            .then(() => {
                if (reload) {
                    location.reload();
                }
            })
            .catch(err => {
                throw new Error(err.response.data.error || '');
            });
    },

    updateProfile({ commit }) {
        const profile = getProfile();
        commit(types.AUTH_SET_PROFILE, profile);
        updateAbility(profile);
        updateSentry(profile);
    },

    getPortalUrl() {
        const url = `${settings.endpoints.bouncer}/portal/`;
        window.dataLayer.push({ event: 'get_portal' });
        return axios.get(url).then(response => {
            return response.data.url;
        });
    }
};

const mutations = {
    [types.AUTH_LOGIN](state, payload) {
        state.pending = true;
    },
    [types.AUTH_LOGIN_SUCCESS](state, payload) {
        state.isAuthenticated = true;
        state.pending = false;
    },
    [types.AUTH_LOGOUT](state, payload) {
        state.isAuthenticated = false;
    },
    [types.AUTH_SET_PROFILE](state, payload) {
        state.profile = payload;
    }
};

function updateSentry(profile) {
  Sentry.configureScope(scope => {
    if (profile) {
      scope.setUser({
        email: profile.email,
        id: profile._id,
        username: profile.name
      });
    } else {
      scope.setUser({});
    }
  });
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
