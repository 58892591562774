import Vue from 'vue';
import * as types from '@/store/mutation-types';

export default {
    [types.MEMOLANGUAGE_SET_CATEGORY_DATA](state, payload) {
        // state.categories[payload.categoryId] = payload.data
        Vue.set(state.categories, payload.categoryId, payload.data);
    },
    [types.MEMOLANGUAGE_UPDATE_SETTINGS](state, { settings, clear = false }) {
        if (clear) {
            settings = Object.assign({ completedTooltips: {}, onboardingDone: false }, settings);
        }
        if (!clear) {
            settings = Object.assign(state.settings, settings);
        }
        Vue.set(state, 'settings', settings);
    },
    [types.MEMOLANGUAGE_SET_ITEMS_USERSTATE](state, { targetLanguage, data }) {
        Vue.set(state.languages[targetLanguage], 'itemsUserState', data);
    },
    [types.MEMOLANGUAGE_UPDATE_ITEM](state, { targetLanguage, prefixedItemId, data, merge = false }) {
        const itemsUserState = state.languages[targetLanguage].itemsUserState;
        if (merge) {
            data = Object.assign(itemsUserState[prefixedItemId] || {}, data);
        }
        Vue.set(itemsUserState, prefixedItemId, data);
    },
    [types.MEMOLANGUAGE_UPDATE_LANGUAGE_STATE](state, { targetLanguage, loadState }) {
        state.languages[targetLanguage].loadState = loadState;
    },

    [types.MEMOLANGUAGE_QUIZ_COMPLETED](state, { language, listId }) {
      console.log(types.MEMOLANGUAGE_QUIZ_COMPLETED, { language, listId });
    },
};
