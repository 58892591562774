import store from '@/store';
const App = () => import('./App');
const HomeView = () => import('./views/HomeView');
const CategoryView = () => import('./views/CategoryView');
const NamesView = () => import('./views/NamesView');

const SessionView = () => import('./views/SessionView');

function sessionGuard(to, from, next) {
    if (!store.state.moduleSystemName.session) {
        return next({ name: 'NameSystemCategoryView', params: { categoryId: to.params.categoryId }, replace: true });
    }
    next();
}

export default [
    {
        path: '/system/name',
        component: App,
        children: [
            {
                path: 'home',
                alias: '',
                name: 'NameSystemHomeView',
                component: HomeView
            },
            {
                path: 'category/:categoryId',
                name: 'NameSystemCategoryView',
                component: CategoryView
            },
            {
                path: 'category/:categoryId/session',
                name: 'NameSystemSessionView',
                component: SessionView,
                beforeEnter: sessionGuard
            },
            {
                path: 'category/:categoryId/session/names/:index',
                name: 'NameSystemNamesView',
                component: NamesView,
                beforeEnter: sessionGuard
            }
        ]
    }
];
