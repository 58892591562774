/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'key': {
    width: 141.732,
    height: 141.732,
    viewBox: '0 0 141.732 141.732',
    data: '<path pid="0" d="M82.095 35.352c0 6.201-5.024 11.229-11.229 11.229-6.203 0-11.229-5.028-11.229-11.229s5.027-11.23 11.229-11.23c6.203-.001 11.229 5.028 11.229 11.23m24.122 0C106.217 15.828 90.39 0 70.867 0 51.343 0 35.515 15.828 35.515 35.352c0 14.757 9.045 27.397 21.895 32.69v20.759l11.289 5.645-11.289 5.646v3.24l11.289 5.646-11.289 5.646v3.239l11.289 5.646-11.289 5.645v1.488l13.457 10.783 13.458-10.783V68.043c12.847-5.293 21.892-17.934 21.892-32.691"/>'
  }
})
