import bankvault from '@/modules/game-bankvault/i18n';
import faces from '@/modules/game-faces/i18n';
import brainstorm from '@/modules/game-brainstorm/i18n';
import shopping from '@/modules/game-shopping/i18n';
import vocabguru from '@/modules/game-vocabguru/i18n';
import timemachine from '@/modules/game-timemachine/i18n';

export default {
    faces,
    bankvault,
    brainstorm,
    shopping,
    vocabguru,
    timemachine
};
