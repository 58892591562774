import storage from '@/store/persistence/storage';
export const MEMOMAPS_ENDPOINT_KEY = 'memolife-memomapsDataEndpoint';

let config = {
    requiredUserstateSchemaVersion: 1,
    intercomId: process.env.NODE_ENV === 'production' ? 'fg7fka76' : 'pumwfbpx',

    onboardingVideoId: '8Gw3YJcaf70',
    onboardingVideoDatabaseId: '93ed9370-3ced-11ea-99cd-7782949f5790',

    averageBraindateDuration: 3, // In seconds
    video: {
        preferredRangeLength: 60 * 3,
        remainderThreshold: 60 * 1.8,
        braindatesForLifekey: 15,
        maxBraindatesForLifekey: 30,
    },
    lifekeys: {
        scenariosThreshold: 21,
        videosThreshold: 30
    }
};

let memomapsDataEndpoint = storage.getItem(MEMOMAPS_ENDPOINT_KEY) ?? `${process.env.VUE_APP_MEMOMAPS_ENDPOINT}`;

if (process.env.NODE_ENV === 'production') {
    config = Object.assign(config, {
        endpoints: {
            bouncer: 'https://bouncer.memolife.com',
            referee: 'https://referee.memolife.com.com',
            eurocup: 'http://eurocup.memolife.com',
            nanny: 'https://nanny.memolife.com',
            repetition: 'https://api.memolife.com',
            api: 'https://api.memolife.com',
            memomapsData: memomapsDataEndpoint,
            memobot: 'https://memobot.memolife.com',
            graph: 'https://graph.memolife.com/graphql' // https://memolifegraph.herokuapp.com/graphql'
        },
        facebookClientId: '355445051191675',
        memolanguageBase: `${location.origin}/${process.env.GIT_BRANCH}`,
        sentry: 'https://0c30dc82318f46f49cfca151127ce542@sentry.io/257035' // 'vue-memolife'
    });
} else {
    config = Object.assign(config, {
        endpoints: {
            bouncer: process.env.VUE_APP_BOUNCER_ENDPOINT,
            // bouncer: `http://${window.location.hostname}:5000`,
            referee: `http://${window.location.hostname}:5001`,
            eurocup: `http://${window.location.hostname}:3000`,
            nanny: `http://${window.location.hostname}:5002`,
            // repetition: `http://${window.location.hostname}:8000`,
            repetition: process.env.VUE_APP_API_ENDPOINT,
            // api: `http://${window.location.hostname}:8000`,
            api: process.env.VUE_APP_API_ENDPOINT,
            memobot: `http://${window.location.hostname}:5100`,
            memomapsData: memomapsDataEndpoint,
            // graph: `http://${window.location.hostname}:5050/graphql`
            graph: process.env.VUE_APP_GRAPHQL_ENDPOINT
        },
        facebookClientId: window.location.hostname.endsWith('.xip.io') ? '1074124162659407' : '919907441412097',
        memolanguageBase: `http://${window.location.hostname}:9001`,
        sentry: 'https://85a8fc98689d4a4cb3b00622a7cc5dc8@sentry.io/1187747' // 'vue-memolife-dev'
    });
}
export default config;
