/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bell-2': {
    width: 404,
    height: 512,
    viewBox: '0 0 404 512',
    data: '<g fill-rule="evenodd"><path pid="0" d="M57.09 315.55v-32.48c0-34.79-1-69.61.37-104.35 2.16-56.49 31.18-95.82 81.43-120 6.37-3.07 9.35-5.82 9.89-13.49 2.13-30.33 35.8-51.7 67.14-43.43 22.84 6 37.68 23.39 39.7 47.14.39 4.57 2.11 6.57 6.22 8.27 50.56 21 86.1 70.21 85 128.17-.81 41.59-.19 83.22-.21 124.83v5.12c12 5.49 23.39 9.52 33.53 15.64 15.9 9.6 24.06 24.2 23.42 43.3-.34 10 0 20 0 30-.06 12.34-6.19 18.74-18.3 18.75-122.327.04-244.66.04-367 0-10.95 0-17.28-5.59-17.55-16.51-.35-14.3-.55-28.69.62-42.92 1.26-15.17 10.72-26 23.34-33.33 9.84-5.73 20.82-9.56 32.4-14.71zM246.39 449c6 .91 9.44 4.72 9.31 10.75-.46 20.57-10.62 35.5-28.55 44.57-7.67 3.89-16.743 7.66-25 7.66-29.72-1.55-55.11-25.7-53.87-52.17.27-5.78 2.86-9.66 8.51-10.7"/></g>'
  }
})
