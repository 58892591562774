<template>
  <ContainerWithModalSide
    id="app"
    class="App"
    name="app-sidebar">

    <div
      v-if="!appIsReady"
      class="splash default-splash">
      <div class="heart" />
    </div>

    <transition
      slot="main"
      :name="transitionName">
      <router-view
        :key="routerViewKey"
        ref="routerView"
        class="routerView"
        :ready="true" />
    </transition>

    <DropdownHost
      class="dropdownHost"
      name="app-dropdown" />
    <ModalContainer
      class="modalContainer"
      name="app-modal" />
    <ModalContainer
      class="modalContainer"
      fullscreen
      name="app-modal-full" />
    <ModalContainer
      class="modalContainer"
      name="app-notifications" />

    <DebugButton v-if="showDebugButton" />
    <DebugMenuHost />

    <RouteNavigationBlockingLayer
      v-if="appIsReady" />
  </ContainerWithModalSide>
</template>

<script>
import FontFaceObserver from 'fontfaceobserver';
import setupSecretGesture from '@/setup-secret-gesture';
import watchForHover from '@/watch-for-hover';
import { EventBus } from '@/event-bus';
import ContainerWithModalSide from '@/components/ContainerWithModalSide';
import ModalContainer from '@/components/ModalContainer';
import DropdownHost from '@/components/DropdownHost';

import DebugButton from '@/modules/debug/components/DebugButton';

import LifekeyPopup from '@/modules/dashboard/components/LifekeyPopup/LifekeyPopup';

import BraindatePopup from '@/modules/dashboard/components/BraindatePopup';
import DailyGoalPopup from '@/modules/dashboard/components/DailyGoalPopup';

import RouteNavigationBlockingLayer from '@/components/RouteNavigationBlockingLayer';

import SimpleMarkdownViewer from '@/modules/account/components/SimpleMarkdownViewer';
import DebugMenuHost from '@/modules/debug/components/DebugMenuHost';
import whatsnew from '@/whatsnew';
import AuraPopup from '@/modules/repetition/AuraPopup';

import '@/icons'; // NOTE: makes icons available anywhere
import moment from 'moment';
import { updateAbility } from '@/ability';

export default {
    name: 'App',
    components: {
        ContainerWithModalSide,
        ModalContainer,
        DropdownHost,
        DebugButton,
        RouteNavigationBlockingLayer,
        DebugMenuHost
    },

    data() {
        return {
            transitionName: '',
            routerViewKey: '',
            defferedPromptEvent: null
        };
    },
    computed: {
        appIsReady() {
          return this.$store.getters['appIsReady'];
        },
        initialUserStateLoaded() {
          return this.$store.getters['initialUserStateLoaded'];
        },

        showDebugButton() {
            return this.$store.state.moduleApp.localState.showDebugButton;
        },
        doneRepsToday() {
          return this.$store.getters['repetition/today'];
        },
        repsLoaded() {
          return this.$store.getters['repetition/loaded'];
        },
        gettingStartedTotalProgress() {
          return this.$store.getters['moduleApp/gettingStartedTotalProgress'];
        },
        locale() {
          return this.$store.getters['moduleApp/locale'];
        },
        profile() {
          return this.$store.getters['moduleAuth/profile'];
        },
        lifeskills() {
          return this.$store.getters['moduleApp/lifeskills'];
        }
    },
    watch: {
      lifeskills() {
        updateAbility(this.profile);
      },
      profile(p) {
        updateAbility(p);
      },
      locale(curr) {
        this.$i18n.locale = curr;
        moment.locale = curr;
      },

      gettingStartedTotalProgress(progress, old) {
        if (progress > old) {
          window.dataLayer.push({
            event: 'getting_started_item_done',
            progress
          });
        }
      },

      doneRepsToday(curr, old) {
        if (!this.repsLoaded) {
          return;
        }
        if (curr.length > old.length)
          setTimeout(() => this.openAuraPopup(), 1000);
      },

      initialUserStateLoaded(loaded) {
        if (!loaded) { return }

        const current = whatsnew.currentId;
        const seen = this.$store.state.moduleApp.memolife.whatsNewViewedArticles;
        if (seen.find(a => a === whatsnew.currentId)) {
          return;
        }

        const userCreated = this.$store.getters['moduleAuth/profile'].created_at * 1000;
        const wtimestamp = whatsnew.meta[current].timestamp;
        if (userCreated > wtimestamp) {
          return;
        }

        this.showWhatsNew();
      },

      $route(newRoute, oldRoute) {
            const to = newRoute.matched[0];
            const from = oldRoute.matched[0];

            if (to && from) {
                if (
                    to &&
                    to.meta.foobar &&
                    from &&
                    from.meta.foobar &&
                    newRoute.params.stepId !== oldRoute.params.stepId
                ) {
                    this.transitionName = 'slideUp';
                    this.routerViewKey = newRoute.params.stepId;
                } else if (to.name === 'StepFlowView' && from.name === 'DashboardLifeskillViewWithTab') {
                    this.transitionName = 'fade';
                    this.routerViewKey = '';
                } else if (to.name === 'DashboardLifeskillViewWithTab' && from.name === 'StepFlowView') {
                    this.transitionName = 'fade';
                    this.routerViewKey = '';
                } else {
                    this.transitionName = '';
                    this.routerViewKey = '';
                }
            } else {
                this.transitionName = '';
                this.routerViewKey = '';
            }
        },
    },
    mounted() {
      updateAbility(this.profile);
      this.$nextTick(() => {
        const gls = document.getElementById('globalLoadingScreen');
        if (gls) {
          gls.remove();
        }
      });
        const font = new FontFaceObserver('roboto');
        font.load().then(() => {
            // NOTE: for some strange reason, the font is ready sooner when this block is run...
        });
        setupSecretGesture(() => {
            this.$store.dispatch('moduleApp/toggleDebugButton');
        });
        watchForHover();

        EventBus.$on('new-lifekey', ({ continueToDashboard, lifekeyCount, lifeskillId, lifekeyType, context }) => {
            const journeyId = (context || '').split(':')[1];
            const belt = this.$store.getters['moduleApp/belt'];
            setTimeout(() => {
                this.$modal.open({
                    component: LifekeyPopup,
                    props: {
                        belt,
                        lifeskillId,
                        lifekeyCount,
                        continueToDashboard,
                        lifekeyType,
                        context,
                        journeyId,
                    },
                    target: 'app-modal'
                });
            }, 1000);
        });
        EventBus.$on('newBraindate', braindate => {
            if (braindate.isBonus) {
                return;
            }
            const requiredDailyBraindates = this.$store.state.moduleApp.memolife.dailyRequiredBraindates;
            const braindateCountToday = this.$store.getters['moduleApp/braindatesForCurrentDay'].length;
            const justReachedGoal = braindateCountToday === requiredDailyBraindates;

            this.$modal.open({
                target: 'app-notifications',
                channelId: 'notifications',
                wrap: true,
                props: {
                    lifeskillId: braindate.lifeskillNumber,
                    goal: requiredDailyBraindates,
                    count: braindateCountToday,
                    extraLifeskillIds: braindate.bonusBraindates,
                    horizontalLayout: window.innerWidth >= 480 // NOTE: mobile breakpoint
                },
                hostOptions: { alignment: 'top-right', maskColor: 'transparent' },
                component: BraindatePopup
            });

            if (justReachedGoal) {
                this.$modal.open({
                    target: 'app-notifications',
                    channelId: 'notifications',
                    wrap: true,
                    props: {
                        lifeskillId: braindate.lifeskillNumber,
                        useAsBraindatePopup: true,
                        goal: requiredDailyBraindates,
                        count: braindateCountToday
                    },
                    component: DailyGoalPopup
                });
            }
        });

        EventBus.$on('showWhatsNew', () => {
            this.showWhatsNew();
        });

    },

    methods: {
      openAuraPopup() {
          this.$modal.open({
            component: AuraPopup,
            props: {
            },
            target: 'app-modal'
          });
      },

      showWhatsNew() {
        if (!whatsnew.currentId) {
          return; // NOTE: no support for showing older news ATM
        }
        const lang = this.$store.getters['moduleApp/locale'];

        const meta = whatsnew.meta[whatsnew.currentId];
        if (meta.languages.indexOf(lang) === -1) {
          return;
        }

        this.$store.dispatch('moduleApp/updateWhatsNewViewedArticles',
          { id: whatsnew.currentId });

        this.$modal.open({
          target: 'app-modal',
          wrap: true,
          component: SimpleMarkdownViewer,
          props: {
            url: `whats-new/${whatsnew.currentId}-${lang}.md`,
            content: 'some **bla bla** content',
            markdown: true
          }
        });
      }
    }
};

</script>

<style lang="scss" scoped>
$pageBackgroundColor: #171e36;

.App {
    color: #2c3e50;
    // background-color: $pageBackgroundColor; //$dashboard-background;
    background-color: #113978;
    height: 100%;
    .slide-leave-active,
    .slide-enter-active {
        transition: 1s;
        top: 10px;
    }
    .slide-enter {
        top: -100px;
    }
    .slide-leave-to {
        top: -100px;
    }
}
.routerView {
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    $transitionDuration: 0.5s;
    &.slideUp-enter-active,
    &.slideUp-leave-active {
        transition: transform $transitionDuration ease-out, opacity $transitionDuration;
    }
    &.slideUp-enter {
        transform: translate3d(0, 100%, 0);
    }
    &.slideUp-enter-to {
        transform: translate3d(0%, 0, 0);
    }
    &.slideUp-leave-to {
        transform: translate3d(0, -100%, 0);
    }

    &.fade-enter-active,
    &.fade-leave-active {
        transition: transform $transitionDuration ease-out, opacity $transitionDuration;
    }
    &.fade-enter {
        opacity: 0;
    }

    &.fade-leave-to {
        opacity: 0;
    }
}

.modalContainer {
    z-index: 9999;
}

.dropdownHost {
    z-index: 7;
}
</style>
