/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'shuffle': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M2.216 4.906a.85.85 0 01.854-.853l9.427.003-1.233 1.233.929.948 2.827-2.825L12.195.566l-.93.948 1.232 1.233-9.427-.003a2.194 2.194 0 00-2.182 2.18V7.58h1.327V4.907zM13.424 11.321a.64.64 0 01-.645.645l-9.655-.003 1.233-1.233-.929-.948-2.846 2.825 2.825 2.827.93-.948-1.232-1.233 9.654.003a1.971 1.971 0 001.974-1.972V8.382h-1.327l-.001 2.94h.019z" _fill="#fff"/>'
  }
})
