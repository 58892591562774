<template>
  <div
    v-if="show"
    class="DebugMenuHost"
    @click.self="show = false">
    <DebugMenuPopup @request-close="show = false" />
  </div>
</template>

<script>
import { EventBus } from '@/event-bus';
import DebugMenuPopup from './DebugMenuPopup';

export default {
    components: { DebugMenuPopup },
    data() {
        return {
            show: false
        };
    },
    mounted() {
        EventBus.$on('request-open-debug-menu', () => {
            this.show = true;
        });
    }
};
</script>

<style lang="scss" scoped>
.DebugMenuHost {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 1em;
}

.DebugMenuPopup {
    max-height: 100%;
    border: 1px solid black;
}
</style>
