/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'belt-arrow': {
    width: 7,
    height: 4,
    viewBox: '0 0 7 4',
    data: '<path pid="0" d="M6.11 3.848a.523.523 0 00.737 0 .517.517 0 000-.733L3.87.152a.523.523 0 00-.738 0L.153 3.115a.517.517 0 000 .733.523.523 0 00.737 0L3.5 1.25l2.61 2.598z" _fill="#ABABAB"/>'
  }
})
