import apolloClient from '@/apollo-client';
import gql from 'graphql-tag';
import { updateUserSetting } from '@/store/persistence';

export default {
    async load({ commit }) {
        await apolloClient
            .query({
                query: gql`
                    query {
                        userSettingsList {
                            key
                            value
                        }
                    }
                `
            })
            .then(result => result.data)
            .then(data => {
                data.userSettingsList
                    .filter(setting => setting.key.startsWith('system:name'))
                    .forEach(setting => {
                        commit(`setNamesUserstate`, setting.value);
                    });
            });
    },
    setSession({ commit }, session) {
        commit('setSession', session);
    },
    setFavoriteCode({ commit }, { nameId, codeId }) {
        //
    },
    reportNameLearning({ commit, state }, { nameId, score }) {
        if (score === 1) {
            commit('setNameUserstate', {
                nameId,
                data: {
                    repLevel: 1
                }
            });
            updateUserSetting('system:', 'name', { names: state.namesUserstate });
        }
    },
    dbgSetNameUserstate({ commit }, payload) {
        commit('setNameUserstate', payload);
    },
    unload({ commit }) {}
};
