<template>
  <Lottie
    class="AnimatedNumbershapeLottie"
    :style="{ '--color': color }"
    v-bind="lottieProps"
    @loopComplete="onLoopCompleted"
    @animCreated="handleAnimation" />
</template>

<script>
import Lottie from '@/components/Lottie';
const anims = require.context('./lottie-anims', true, /\.(json)$/);

export default {
    components: { Lottie },
    props: {
        number: {
            type: String,
            required: true
        },
        color: {
            type: String,
            default: 'black'
        },
        reversed: {
            type: Boolean,
            default: false
        },
        play: {
            type: Boolean,
            default: false
        },
        paused: {
            type: Boolean,
            default: false
        },
        speed: {
            type: Number,
            default: 1
        },
        rewindSpeed: {
            type: Number,
            default: 3
        }
    },
    data() {
        return {
        };
    },
    computed: {
        direction () {
          return 1;
        },

        lottieProps() {
            const animationData = anims(`./${this.number}.json`);
            return {
                options: {
                    autoplay: false,
                    animationData
                }
            };
        }
    },
    watch: {
        play(flag) {
            if (flag) {
                this.startAnimation();
            } else {
                this.anim.pause();
            }
        },
        paused(flag) {
            if (flag) {
              this.anim.pause();
            } else {
                this.anim.play();
            }
        }
    },
    beforeDestroy() {
        clearTimeout(this.startAnimationTimeout);
        clearTimeout(this.loopAnimationTimeout);
        this.anim.destroy();
    },
    mounted() {
      this.setInitialDirectionAndFrame();
    },
    methods: {
        setInitialDirectionAndFrame() {
            const totalFrames = this.anim.totalFrames;

            this.anim.setDirection(this.direction);
            this.anim.setSpeed(this.speed);

            if (this.reversed) {
                this.anim.goToAndStop(totalFrames - 0.001, true);
            } else {
                this.anim.goToAndStop(0);
            }
        },

        startAnimation() {
            this.setInitialDirectionAndFrame();
            this.startAnimationTimeout = setTimeout(() => {
              this.anim.goToAndPlay(0);
            }, 1000);
        },

        rewind() {
            this.anim.setSpeed(this.rewindSpeed);
            this.anim.setDirection(this.direction);
            this.anim.goToAndStop(0);
        },

        handleAnimation(anim) {
            this.anim = anim;
            if (this.play) {
                this.startAnimation();
            }
        },

        onLoopCompleted() {
          const lastFrame = this.anim.totalFrames - 0.001;
          this.anim.goToAndStop(lastFrame, true);
          if (this.play) {
            this.loopAnimationTimeout = setTimeout(() => {
              this.startAnimation();
            }, 1000);
          }
        }
    }
};
</script>

<style lang="scss" scoped>
.AnimatedNumbershapeLottie {
    /deep/ svg {
        * {
            stroke: var(--color) !important;
            fill: var(--color) !important;
        }
    }
    /deep/ svg #guides {
        opacity: 0;
        visibility: hidden;
    }
}
</style>
