const languageIds = ['german', 'french', 'italian', 'portuguese', 'spanish', 'english', 'norwegian'];

export default {
    settings: {
        completedTooltips: {},
        onboardingDone: false
    },
    languages: (function () {
        let map = {};
        languageIds.forEach(languageId => {
            map[languageId] = {
                loadState: '', // 'loading' | 'loaded'
                itemsUserState: {
                    // [prefixedItemId]: { learnStatus, currentFantasyId }
                }
            };
        });
        return map;
    })(),
    categories: {}
};
