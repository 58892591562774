export default {
    languageLabels: {
        english: 'English',
        english_no: 'Engelsk',
        norwegian: 'Norwegian',
        norwegian_no: 'Norsk',
        french: 'French',
        french_no: 'Fransk',
        italian: 'Italian',
        italian_no: 'Italiensk',
        german: 'German',
        german_no: 'Tysk',
        chinese: 'Chinese',
        chinese_no: 'Kinesisk',
        portuguese: 'Portuguese',
        portuguese_no: 'Portugisisk',
        spanish: 'Spanish',
        spanish_no: 'Spansk'
    }
};
