<template>
  <BlockContainer
    class="Course"
    type="Course"
    :label="courseId">
    <ButtonPlain
      label="Reset all scenarios"
      @click="resetAllScenarios()" />
    <!-- <ButtonPlain
      label="Clear all progress"
      @click="clearAllProgress()"
    /> -->
    <ButtonPlain
      label="print stats"
      @click="printStats()" />

    <IntroStep
      v-if="course.introStepId"
      class="introStep"
      :step-id="course.introStepId" />
    <div class="stepGroups">
      <StepGroup
        v-for="stepGroupId of course.stepGroupIds"
        :key="stepGroupId"
        class="StepGroup"
        :step-group-id="stepGroupId" />
    </div>
  </BlockContainer>
</template>

<script>
import BlockContainer from '../BlockContainer';
import ButtonPlain from '../ButtonPlain';
import StepGroup from './StepGroup';
import IntroStep from './IntroStep';

export default {
    components: { BlockContainer, ButtonPlain, StepGroup, IntroStep },
    props: {
        courseId: {
            type: String,
            required: true
        }
    },
    computed: {
        course() {
            return this.$store.state.moduleCourse.courses[this.courseId];
        }
    },
    methods: {
        resetAllScenarios() {
            let stepGroups = this.course.stepGroupIds.map(
                stepGroupId => this.$store.state.moduleCourse.stepGroups[stepGroupId]
            );
            stepGroups.forEach(stepGroup => {
                // COMBAK restAllScenarios
                stepGroup.stepIds
                    .map(stepId => this.$store.state.moduleCourse.steps[stepId])
                    .forEach(step => {
                        step.scenarios.forEach((scenario, i) => {
                            if (scenario.done) {
                                this.$store.dispatch('moduleCourse/markStepScenarioAsDone', {
                                    stepId: step.id,
                                    scenarioIndex: i,
                                    reset: true
                                });
                            }
                        });
                    });
            });
        },
        // clearAllProgress () {
        //   this.$store.dispatch('moduleCourse/dbgResetCourseState', { courseId: this.courseId })
        // },
        printStats() {
            console.log(
                `Course stats for ${this.courseId}:\n`,
                JSON.stringify(this.$store.getters['moduleCourse/getCourseStats'](this.courseId), null, '  ')
            ); // eslint-disable-line no-console
        }
    }
};
</script>

<style lang="scss" scoped>
.introStep {
    margin-bottom: 5px;
}
.StepGroup {
    margin-top: 1em;
}
</style>
