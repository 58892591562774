<template>
  <div class="CircularProgress">
    <svg class="svg" width="100%" height="100%" viewBox="0 0 110 110">
      <circle
        v-if="!hideTrack"
        class="circleOutline"
        cx="55"
        cy="55"
        r="50"
        :stroke="trackColor"
        :stroke-width="trackWidth || strokeWidth"
      />
      <circle
        class="circleProgress"
        :class="{ reset: progress > 0 }"
        :stroke="color"
        cx="55"
        cy="55"
        r="50"
        :stroke-dashoffset="strokeDashoffset"
        :stroke-width="strokeWidth"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    hideTrack: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: 'black',
    },
    trackColor: {
      type: String,
      default: 'rgba(0, 0, 0, 0.2)',
    },
    strokeWidth: {
      type: Number,
      default: 6,
    },
    trackWidth: {
      type: Number,
      default: undefined,
    },
  },
  computed: {
    strokeDashoffset() {
      let adjustment = 4
      let completionFraction = Math.min(1, this.progress)
      return '' + (315 - (315 - adjustment) * completionFraction)
    },
  },
}
</script>

<style lang="scss" scoped>
$colorA: rgba(black, 0.2);
$colorB: black;

.CircularProgress {
}

.svg {
  display: block;
  fill: transparent;
  width: 100%;
  height: 100%;
  display: block;
  fill: transparent;
  width: 100%;
  height: 100%;
}

.circleProgress {
  stroke-dasharray: 315;
  transition: stroke-dashoffset 1s linear;
  stroke-linecap: round;
  stroke-dasharray: 315;
  transition: stroke-dashoffset 1s linear;
  stroke-linecap: round;

  transform: rotate(-86deg); // Adjusted rotation because of round linecap
  transform-origin: center;

  &.reset {
    transition: stroke-dashoffset 1s linear;
  }
}
</style>
