/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'memo-image-2': {
    width: 67,
    height: 67,
    viewBox: '0 0 67 67',
    data: '<g fill-rule="evenodd"><path pid="0" d="M63.56 11.77l-8.33-8.33a9.96 9.96 0 00-14.125 0L30.603 14.035a9.145 9.145 0 000 12.947l3.802-3.803a3.712 3.712 0 010-5.251L45 7.243a4.527 4.527 0 016.428 0l8.33 8.33a4.527 4.527 0 010 6.428L49.164 32.595a3.712 3.712 0 01-5.252 0l-3.803 3.802a9.145 9.145 0 0012.948 0l10.502-10.593a9.96 9.96 0 000-14.124v.09zM17.836 34.405a3.712 3.712 0 015.252 0l3.803-3.802a9.145 9.145 0 00-12.948 0L3.441 41.196a9.96 9.96 0 000 14.124l8.33 8.24a9.96 9.96 0 0014.124 0l10.502-10.594a9.145 9.145 0 000-12.947l-3.802 3.803a3.712 3.712 0 010 5.251L22 59.757a4.527 4.527 0 01-6.428 0l-8.33-8.33a4.527 4.527 0 010-6.428l10.593-10.594z"/><path pid="1" d="M53.328 48.62l8.24 3.531 1.086.181a2.716 2.716 0 001.087-5.25L55.5 43.55a2.717 2.717 0 00-2.173 4.98v.09zM45.09 51.88a2.716 2.716 0 00-1.45 3.53l3.532 8.24a2.716 2.716 0 002.535 1.63l1.086-.181a2.716 2.716 0 001.358-3.531l-3.53-8.24a2.716 2.716 0 00-3.532-1.448zM13.672 18.38l-8.24-3.531a2.717 2.717 0 00-2.173 4.98l8.24 3.53 1.086.182a2.716 2.716 0 001.087-5.252v.09zM20.824 15.301l1.087-.18a2.716 2.716 0 001.448-3.532L19.83 3.35a2.716 2.716 0 10-4.98 2.082l3.53 8.24a2.716 2.716 0 002.445 1.63z" opacity=".357"/></g>'
  }
})
