/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'getting-started-icons/favorite-selections': {
    width: 110,
    height: 87,
    viewBox: '0 0 110 87',
    data: '<g _fill="none" fill-rule="evenodd"><path pid="0" d="M88.37 33H79v7l-4.5 3-5.5-6-10 9.5 7 6.95-1 2.05h-8.5v12.949a1446.163 1446.163 0 01-6.78 5.275 7.132 7.132 0 01-8.733 0C22.567 59.457 11.684 50.795 5.35 39.56c-9.606-16.996-3.791-31.405 8.37-37.047 11.418-5.29 26.352-.314 31.635 11.079C50.637 2.198 65.571-2.771 76.99 2.512 87.55 7.412 93.326 18.924 88.37 33z" opacity=".914"/><path pid="1" d="M110 65h-5.88a1.5 1.5 0 00-1.46 1.15 18.43 18.43 0 01-2.24 5.38 1.5 1.5 0 00.21 1.85l4.17 4.17-4.24 4.25-4.19-4.19a1.5 1.5 0 00-1.85-.22 18 18 0 01-5.36 2.2A1.5 1.5 0 0088 81v6h-6v-6a1.5 1.5 0 00-1.15-1.46 18.06 18.06 0 01-5.33-2.21 1.5 1.5 0 00-1.85.21l-4.23 4.26-4.24-4.24 4.23-4.23a1.5 1.5 0 00.21-1.85 18 18 0 01-2.21-5.33A1.5 1.5 0 0066 65h-6v-6h6a1.5 1.5 0 001.46-1.16 18.14 18.14 0 012.2-5.36 1.5 1.5 0 00-.22-1.85l-4.24-4.19 4.24-4.24 4.17 4.17a1.5 1.5 0 001.85.21 18 18 0 015.38-2.24A1.5 1.5 0 0082 42.88V37h6v5.86a1.5 1.5 0 001.16 1.46 18 18 0 015.41 2.23 1.5 1.5 0 001.85-.21l4.14-4.14 4.24 4.24-4.14 4.14a1.5 1.5 0 00-.21 1.85 18 18 0 012.23 5.41 1.5 1.5 0 001.46 1.16H110v6zM85.06 50.44c-6.383.006-11.554 5.184-11.55 11.567.004 6.383 5.18 11.555 11.563 11.553C91.456 73.558 96.63 68.383 96.63 62c-.011-6.384-5.186-11.554-11.57-11.56zm0 20.13A8.56 8.56 0 1193.63 62a8.57 8.57 0 01-8.57 8.56v.01z"/></g>'
  }
})
