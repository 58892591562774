export default {
    Alexandra: 'bøker (Alexandria)',
    Alva: 'alv / alveører (Liv Tyler)',
    Amalie: 'en tann - tann emalje',
    Amanda: 'en statue',
    'Anne/Anna/Anja': 'en and',
    Annette: 'et andenett (en and som slapper av i en hengekøye)',
    'Åse/Åsild': 'øse',
    Astrid: 'astroide',
    Beate: 'foldede hender (be)',
    Benedikte: 'skrift rull (bene + dikt)',
    Bente: 'et ben',
    Bettina: 'poker chips (to bet)',
    Bjørg: 'bjørkekvist',
    Bodil: 'en bod',
    Britt: 'et kritt',
    Camilla: 'en kamel',
    Cecilie: 'sildeboks (fisk ... Se sild)',
    Charlotte: 'sjal',
    Elin: 'stikkontakt (el-in)',
    Elisabeth: 'flisete sengeteppe (Bett)',
    Ella: 'Adidas merke (Ella McCartney)',
    Ellen: 'magasinet Elle',
    Emilie: 'hatten til Emil i lønneberget',
    Emma: 'm&m',
    Ester: 'påskeegg / kylling (easter)',
    Eva: 'en pupp',
    Grete: 'kam (gre)',
    Guro: 'buddha (gu-dommelig ro)',
    'Hanne/a': 'hanekam',
    Hege: 'Kjede',
    Heidi: 'vinkende hånd',
    'Helene/a': 'Hellboy som ung (hell)',
    Hilde: 'fille - vaskefille',
    Ida: 'cider',
    Ina: 'i NA (Nettavisen) logo',
    'Inger/Ingrid': 'ringer',
    Ingunn: 'spørsmålstegn (ingen ?)',
    Ingvild: 'brINGebær (VILLe)',
    Isabelle: 'penis (pen is)',
    Janne: 'en snøball (januar)',
    Jannike: 'fotball (ja - nikke)',
    Jeanette: 'hårnett (sjal + nett)',
    Jenny: 'yen  (valuta)',
    Jessica: 'binders (Jøssing)',
    'Johanne/a': 'yo-yo-med hanevinger',
    Julie: 'julenissen',
    Karen: 'stopp-skilt (faren)',
    Kari: 'curry krydder',
    Karianne: 'en biland (gul and med hjul på)',
    Karine: 'lekebilbane (car + inne)',
    Karoline: 'ruter kort (karo = ruter på tysk)',
    Kristin: 'et kors',
    Leah: 'smiley fjes (le ha)',
    Lene: 'lenestol',
    Lilly: 'vann lilje',
    Linda: 'pilleboks / medisin (lindre)',
    'Line/Lina': 'krok (line + krok)',
    'Linn / Linnea': 'linbukse',
    Lisa: 'en saxofon (Lisa Simpson)',
    Lise: 'skolisse',
    Liv: 'et hjerte',
    'Louise/a': 'en lue (strikkelue)',
    Malin: 'pensel (maling)',
    Maren: 'mår',
    Margrete: 'skjelett (benMARG)',
    'Mari/Marit/Margit': 'Freddy Kruger (mareritt) or his claws',
    Marianne: 'marihøne',
    Marie: 'kjeks',
    Marte: 'bart',
    Mathilde: 'salami (mat + Gilde)',
    Maya: 'maya-indianer',
    Merete: 'hest (merr)',
    Mette: 'feit mage (mett)',
    'Mie/a': 'en bie',
    Miriam: 'en kikkert (mirar)',
    Monica: 'Tennis racket (monica seles)',
    'Natalie/a': 'en stjerne (natt)',
    Nina: 'ninja',
    Nora: 'Ibsen - skinnskjegg',
    Oda: 'en ode .... Mao en note',
    Olivia: 'grønn oliven',
    Reidun: 'regner + dun',
    Renathe: 'tak - renna',
    Rita: 'pencilbrush (rita = tegne på svensk)',
    Sara: 'sand ...sahara',
    Sigrunn: 'sigarettsneip (bunn)',
    Silje: 'en sil',
    Siri: 'iPhone',
    Sofie: 'en veske (Tante Sofie)',
    'Solveig/Solfrid': 'sol',
    Stine: 'en sti (avlange prikker på et kart ...)',
    Susanne: 'lepper (suss)',
    Synne: 'mikrofon (synge)',
    Terese: 'te-ruse ... En hvit liten (te)pose med kokain',
    Thea: 'te pose',
    Tone: 'G-nøkkel',
    Torild: 'hammer-ild (Tor)',
    Ulrika: 'en sau (ull) med bling kjede (rik)',
    Vanja: 'dusjhode (vann ja)',
    Vibeke: 'vid bekk',
    Victoria: 'v-tegn (victory)',
    Vilde: 'hulder/troll (vill)',
    Vilgunn: 'revolver (wild gun)',
    Wenche: 'en hånd (vinke)',
    Yvonne: 'en dupp (i vannet),',
    Alexander: 'hvit hest (den store)',
    Alf: 'Alf the Alien',
    Alfred: 'en dynamittkube (Alfred Nobell)',
    Amund: 'en munn (= Susanne)',
    'Andreas/Anders/Andre': 'en and',
    Anders: ' en and bøyd som en binders',
    Arne: 'et arr på nesen',
    Arvid: 'et arr i vikene',
    'Aslak/Asle': 'rompe (arsle = svensk)',
    Balder: 'en basketball',
    Bård: 'Skateboard',
    Benjamin: 'en dollarseddel (Benjamin Franklin)',
    Bjørn: 'en bjørn',
    Bjørnar: 'bjørn med arr',
    Brede: 'en linjal (noe bredt)',
    Dag: 'en kalender (dag)',
    'Dag-Erik': 'Kalender m krone',
    David: 'david-stjernen',
    Egil: 'stetoskop (Eg ill ?? = syk)',
    Elias: 'et rødt pannebånd (Elias = Wilhelm Defoe i Platoon)',
    Emanuel: 'en pupp (Emanuelle = erotisk film)',
    Emil: 'hatten til Emil i lønneberget',
    Endre: 'et blindgateskilt (end)',
    'Erik/Eirik': 'Onkel Skrue',
    Erlend: 'do (lens)',
    'Erling/Elling': 'grevling',
    Filip: 'en delfin (Flipper)',
    Finn: 'en kikkert',
    Frank: 'et speil (ærlig)',
    Fredrik: 'peace (fred) tegn på en gullbarre (rik)',
    Fridtjof: 'St. Bernardshund (FRI fra snøskred + vOFF)',
    Frode: 'en hårete hobbitfot (Frodo)',
    Gabriel: 'en engel',
    Gard: 'en boksehanske = en guard',
    Gaute: 'skaut',
    Hans: 'Chewbacca (Han Solo)',
    'Harald/Gustav': 'en kongekrone',
    Helge: 'en festhatt = helg',
    Henrik: 'gullmynter (en rikdom)',
    Inge: 'finlandshette (ingen)',
    Isak: 'et isflak',
    Ivan: 'en dupp (i vannet)',
    Jan: 'en snøball = january',
    Jarle: 'tyrolerhatt (jodle)',
    Jens: 'YES = tommel opp',
    Johannes: 'yo-yo-med hanevinger',
    Jonas: 'en g-streng (Tom Jones)',
    Jørn: 'en gjøk (fugl)',
    Josef: 'en yo-yo ... seff ...',
    Kåre: 'en kårde',
    Karl: 'en ridderrustning (Karl den Store)',
    Kenneth: 'Ken barbiedukke (Toy Story 3)',
    Kjartan: 'kjakan = en ekstra hake ...',
    Kjell: 'en kjele',
    Kjetil: 'en skje',
    Klaus: 'julenissen (santa claus)',
    Knut: 'en knute',
    'Kristen/Kristian': 'et kors',
    Kristoffer: 'kniv med Jesus på = kors-kniv (offer)',
    Lars: 'en lastebil',
    Lasse: 'en lasso',
    Leander: 'pil og bue (Legolas i Ringenes Herre)',
    Leif: 'life = hjerte',
    Leon: 'en plante (fra filmen Leon)',
    Liam: 'lila solbriller',
    Lukas: 'light saber (George Lukas)',
    Magnus: 'en sjakkbrikke (Carlsen)',
    Marius: 'en maur',
    'Mark/Markus': 'en mark',
    Martin: 'en martini',
    'Mats/Mathias/Matheo': 'en dørmatte',
    Mikkel: 'en rev',
    Morten: 'more ten = 10+',
    Nicholas: 'face off (Nicholas cage) ... En bit av ansiktet rives av',
    Nils: 'en (jule)nisse',
    Odd: 'en fotball  (samme som Janikke)',
    'Ola/Olav': 'en bølge (hola = bølge på spansk)',
    Ole: 'et rødt forkle =  "ole"',
    Oliver: 'en oliven',
    Oscar: 'en statue',
    'Paul/Pål': 'en gitar (McCartney)',
    Per: 'prosenttegn = per cent',
    Petter: 'en lyspære (Petter Smart)',
    Remi: 'to sjakkbrikker som ligger flate',
    Rune: 'Runeskrift',
    Sander: 'sand',
    Sebastian: 'en giljotin (bastion)',
    Sigurd: 'sigarettur',
    Simen: 'Seigmenn',
    Sindre: 'mikrofon (synge)',
    Sjur: 'en ku (kyr)',
    Sondre: 'langrennski (Sondre merke)',
    Stein: 'en bautastein',
    Stig: 'en stige',
    Svein: 'en gris / et svin',
    Sverre: 'et rundt arabersverd',
    Thomas: 'tom eske',
    Tobias: 'et tårn (Tobias i tårnet)',
    Tommy: 'en tiger (og Tigern)',
    'Tor(d)': 'en hammer',
    Torbjørn: 'hammer-bjørn',
    Torgrim: 'hammer-sur fjes',
    Torleif: 'hammer-hjerte',
    Torstein: 'hammer-steain',
    Ulrik: 'en sau (ull) med bling halskjede (rik)',
    Victor: 'v-sign (victory)',
    William: 'en dreadlock (Will I Am)'
};
