<template>
  <div class="LifekeyPopup">
    <div class="header">
      <h2 v-if="!showBeltView" class="headerTitle">{{ lifeskillTitle }}</h2>
      <h2 v-if="showBeltView && !isfinalKey" class="headerTitle">{{ $t('beltProgress') }}</h2>
      <h2 v-if="showBeltView && isfinalKey" class="headerTitle">{{ getHeader }}</h2>

      <img v-if="!showBeltView" class="bossImage" :src="getCategoryBossImage(categoryName)" alt="" />
    </div>

    <div class="lifeskillProgress">
      <div v-if="!showBeltView" class="lifekeysCount">
        <p class="yourProgressTitle">{{ $t('yourProgress') }}</p>
        <p> {{ aquiredLifekeys }}/{{ totalLifekeys }} {{ $t('lifekey') }} </p>
      </div>

      <div class="sunContainer">
        <AnimatedNumbershape
          v-if="showLifeskillProgress && !showBeltView"
          class="animatedNumbershape"
          :number="lifeskillId"
          :play="activateButton"
          color="black"
          reversed
        />

        <CircularProgress
          v-if="totalLifekeys && showLifeskillProgress && !showBeltView"
          class="lifeskillCircularProgress"
          :progress="lifekeyProgress"
          track-color="rgba(255, 255, 255, 0.1)"
          color="rgba(255, 255, 255, 0.6)"
          :stroke-width="3"
        />

        <div class="riveAnimation" :class="{ finalKey: isfinalKey, showBelt: showBeltView }">
          <LifekeyPopupRive
            :show-belt="showBeltView"
            :belt="belt"
            :lifekey-count="aquiredLifekeys"
            :category-name="categoryName"
            @animateBeltLayout="skipToBeltLayout"
          />
        </div>
      </div>

      <div class="lifeskillProgressBottom">
        <div v-if="!showBeltView && hasTeam" class="teamProgress" :class="{ show: !showBeltView && showTeamProgress }">
          <div class="teamProgressInfoContainer">
            <div class="teamProgressTitle">
              <div>{{ $t('teamProgress') }}</div>
              <p>{{ teamLifekeys.length }}/{{ totalLifekeysToAchieveByTeamInCourse }} {{ $t('lifekey') }}</p>
            </div>
            <!-- activate when the leaderboards are added to the popup -->
            <!-- <div @click="gotoLeaderboard()" class="teamLeaderboardsLink">{{ $t('seeAll') }}</div> -->
          </div>
          <div class="progress-bar-wrapper">
            <div
              class="progress-bar"
              :style="{ width: (teamLifekeys.length / totalLifekeysToAchieveByTeamInCourse) * 100 + '%' }"
            />

            <img
              v-if="teamLifekeys.length != totalLifekeysToAchieveByTeamInCourse"
              class="keyIconGold"
              src="./assets/keyIconGold.png"
              alt=""
            />
            <img
              v-if="teamLifekeys.length === totalLifekeysToAchieveByTeamInCourse"
              class="keyIconFinal"
              src="./assets/keyIconWhite.png"
              alt=""
            />
          </div>
        </div>

        <div v-if="showBeltView" class="footer" :class="{ show: showFooter }">
          <template v-if="!isfinalKey">
            <NextBeltProgressText v-if="showTeaser" class="text" :belt="belt" />
          </template>

          <template v-else>
            <div v-show="showMemomanDialog" class="memomanDialog">
              {{ $t('memomanDialog') }}
            </div>
          </template>
        </div>

        <div
          class="button"
          :class="{ locked: !activateButton && !showBeltView, active: activateButton }"
          @click="button()"
        >
          {{ $t(buttonTitle) }}
        </div>
      </div>
    </div>

    <div class="hideCloseButton" :class="{ hide: showBeltView && showCloseButton }" />
  </div>
</template>

<translations>
  
  newBelt: Yay, you got the {beltName}!
  newBelt_no: Yay, du fikk det {beltName}!

  yourProgress: Your progress
  yourProgress_no: Din fremgang

  teamProgress: 'Team progress'
  teamProgress_no: 'Teamfremgang'

  seeAll: See all
  seeAll_no: Se alle

  newLifeKey: 'Yay, you got a new life key'
  newLifeKey_no: 'Yay, en ny livsnøkkel!'

  belt: 'belt'
  belt_no: 'beltet'

  memomanDialog: "We're the greatest of all time!"
  memomanDialog_no: "Vi er jo helt rå sammen!"

  continueButton: Continue
  continueButton_no: Forstett

  beltProgress: Belt progress
  beltProgress_no: Beltefremgang

  lifekey: Life keys
  lifekey_no: livsnøkler
  
</translations>

<script>
import { getCategoryIdFromLifeskillId } from '@/utils'
import apolloClient from '@/apollo-client'
import gql from 'graphql-tag'
import { mapGetters } from 'vuex'
import NextBeltProgressText from '../NextBeltProgressText'
import LifekeyPopupRive from './LifekeyPopupRive'
import CircularProgress from '@/components/CircularProgress'
import AnimatedNumbershape from '@/components/AnimatedNumbershape'

export default {
  components: { NextBeltProgressText, LifekeyPopupRive, CircularProgress, AnimatedNumbershape },

  props: {
    belt: {
      type: Object,
      required: true,
    },
    lifeskillId: String,
    lifekeyType: String,
    context: String,
    journeyId: String,
  },
  data() {
    return {
      showHeader: false,
      showTeaser: false,
      showMemomanDialog: false,
      showFooter: false,
      showBeltView: false,
      showLifeskillProgress: false,
      animateLifeskillProgress: false,
      showTeamProgress: false,
      activateButton: false,
      showCloseButton: false,
      skipToBeltAnimation: false,

      headerTitle: 'Creative problem solving',
      buttonTitle: 'beltProgress',

      teamMembers: [],
      teamLifekeysList: [],

      timers: [],
    }
  },
  computed: {
    ...mapGetters({
      getLifeskillStats: 'moduleApp/getLifeskillStats',
      profile: 'moduleAuth/profile',
    }),

    hasTeam() {
      return this.teamMembers.length > 0
    },

    teamLifekeys() {
      return this.teamLifekeysList
    },

    lifeskillTitle() {
      return this.$t(`dashboard.general.lifeskillLabels.${this.lifeskillId}`)
    },

    categoryName() {
      return getCategoryIdFromLifeskillId(this.lifeskillId)
    },

    totalCourseLifekeysCount() {
      return (
        this.lifeskillStats.totalTheoryLifekeys +
        this.lifeskillStats.totalPracticeLifekeys +
        this.lifeskillStats.totalVideoLifekeys
      )
    },
    totalLifekeysToAchieveByTeamInCourse() {
      return this.totalCourseLifekeysCount * this.teamMembers.length
    },

    getHeader() {
      if (this.isfinalKey) {
        return this.$t('newBelt', { beltName: this.currentBeltName })
      } else {
        return this.$t('newLifeKey')
      }
    },
    isfinalKey() {
      return this.belt.points === 0
    },
    currentBeltName() {
      return (
        this.$t(`belts.beltGradesDefinite.${this.belt.current.id}`) +
        ' ' +
        this.$t('belt') +
        (this.belt.current.number ? ` ${this.belt.current.number}` : '')
      )
    },

    lifeskillStats() {
      return this.$store.getters['moduleApp/getLifeskillStats'](this.lifeskillId)
    },
    aquiredLifekeys() {
      return this.lifeskillStats.aquiredLifekeys
    },
    totalLifekeys() {
      return this.lifeskillStats.totalLifekeys
    },
    lifekeyProgress() {
      if (this.animateLifeskillProgress) {
        return this.lifeskillStats.progress
      } else {
        return 0
      }
    },
  },
  mounted() {
    this.fetch()
    if (this.context === 'memoryiq' || this.context === 'firstvideo') {
      this.skipToBeltAnimation = true
    } else {
      this.timers.push(
        setTimeout(() => {
          this.animateLifekeyLayout()
        }, 1100)
      )
    }
  },
  beforeDestroy() {
    this.timers.forEach(t => clearTimeout(t))
  },
  methods: {
    async fetch() {
      const response = await apolloClient.query({
        fetchPolicy: 'no-cache',
        query: gql`
          query TeamLifekeys($lifeskillNumber: String!) {
            teamLifekeysList(condition: { lifeskillNumber: $lifeskillNumber }) {
              avatar
              context
              displayName
              lifeskillNumber
              teamId
              teamName
              userId
              lifekeyId
            }
            teamMembersList {
              avatar
              displayName
              userId
            }
          }
        `,
        variables: { lifeskillNumber: this.lifeskillId },
      })

      this.teamLifekeysList = response.data.teamLifekeysList
      this.teamMembers = response.data.teamMembersList
    },

    getCategoryBossImage(categoryName) {
      if (categoryName === 'brain') {
        return require('./assets/boss1.png')
      } else if (categoryName === 'knowledge') {
        return require('./assets/boss2.png')
      } else if (categoryName === 'mind') {
        return require('./assets/boss3.png')
      } else if (categoryName === 'body') {
        return require('./assets/boss4.png')
      } else if (categoryName === 'soul') {
        return require('./assets/boss5.png')
      } else if (categoryName === 'career') {
        return require('./assets/boss6.png')
      } else if (categoryName === 'leisure') {
        return require('./assets/boss7.png')
      }
    },

    button() {
      if (this.activateButton) {
        this.showBeltView = true
        this.animateBeltLayout()
        this.activateButton = false
      }
    },

    skipToBeltLayout() {
      if (this.skipToBeltAnimation) {
        this.showBeltView = true
        this.animateBeltLayout()
        this.activateButton = false
      }
    },

    animateLifekeyLayout() {
      this.timers.push(
        setTimeout(() => {
          this.showLifeskillProgress = true
        }, 2000)
      )
      this.timers.push(
        setTimeout(() => {
          this.animateLifeskillProgress = true
        }, 3000)
      )
      if (this.hasTeam) {
        this.timers.push(
          setTimeout(() => {
            this.showTeamProgress = true
          }, 4400)
        )

        this.timers.push(
          setTimeout(() => {
            this.activateButton = true
          }, 6300)
        )
      } else {
        this.timers.push(
          setTimeout(() => {
            this.activateButton = true
          }, 4400)
        )
      }
    },
    animateBeltLayout() {
      if (this.isfinalKey) {
        this.animateFinalKeyLayout()
      } else {
        this.animateKeyLayout()
      }
    },
    animateKeyLayout() {
      this.timers.push(
        setTimeout(() => {
          this.showHeader = true
        }, 2000)
      )
      this.timers.push(
        setTimeout(() => {
          this.showTeaser = true
          this.showFooter = true
        }, 3500)
      )
      this.timers.push(
        setTimeout(() => {
          this.showCloseButton = true
        }, 5300)
      )
    },
    animateFinalKeyLayout() {
      this.timers.push(
        setTimeout(() => {
          this.showHeader = true
        }, 5500)
      )
      this.timers.push(
        setTimeout(() => {
          this.showMemomanDialog = true
        }, 7500)
      )
      this.timers.push(
        setTimeout(() => {
          this.showCloseButton = true
        }, 9001)
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.LifekeyPopup {
  align-items: center;
  background-image: linear-gradient(#020a26, #100840);
  width: 30em;
  height: 44em;
  text-align: start;
  transition: all 1s ease-in-out;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 5.5em;
  // background-color: #1E1456;
  border-bottom: 1px solid rgba(255, 246, 246, 0.16);

  .headerTitle {
    color: rgba(255, 255, 255, 0.84);
    padding-left: 30px;
    font-weight: 600;

    @media (max-width: 620px) {
      padding-left: 7.5vw;
    }
  }
  .bossImage {
    // border: 1px solid red;
    height: 100%;
    width: auto;
    z-index: 2;
  }
}
.lifeskillProgress {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 84%;
  width: 30em;

  @media (max-width: 620px) {
    width: 100%;
  }

  .lifekeysCount {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 30px;
    // height: 5em;
    margin-top: 0.5em;
    width: 30em;
    color: #ffffff62;

    @media (max-width: 620px) {
      padding-left: 15vw;
    }

    .yourProgressTitle {
      color: #ffffff;
    }
    p {
      margin: 0.2em;
    }
    // border: 1px solid red;
  }
  .sunContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    .sunImage {
      width: 14em;
      height: 14em;
    }
    .lifeskillCircularProgress {
      position: absolute;
      // border: 1px solid red;
      width: 13em;
      height: 13em;
      margin-right: 0.3em;
    }
    .animatedNumbershape {
      position: absolute;
      width: 9em;
      height: 9em;
      margin-right: 0.3em;
      opacity: 0.7;
    }
  }

  .lifeskillProgressBottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    // border: 1px solid red;
    // height: 12.5em;
    // background: #216fc9;

    .teamProgress {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 0.3em;
      color: #99b1bf;
      background-color: #26196c7e;
      width: 90%;
      padding: 1.2em 0em;
      opacity: 0;
      transition: all 0.2s ease-in-out;
      margin-bottom: -2em;

      &.show {
        opacity: 1;
        margin-bottom: 0em;
      }
    }
    .teamProgressInfoContainer {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      width: 90%;

      .teamProgressTitle {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        color: #ffffff;
        p {
          margin: 0;
          margin-top: 0.3em;
          color: rgba(255, 255, 255, 0.505);
        }
      }
      .teamLeaderboardsLink {
        font-size: 0.95em;
        color: rgb(98, 98, 255);
        cursor: pointer;
        &:hover {
          color: rgb(187, 187, 255);
        }
      }
    }

    .progress-bar-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-self: flex-start;
      align-self: flex-start;
      background: #1f2362;
      width: 85%;
      margin-left: 5%;
      margin-top: 0.8em;
      border-radius: 30px;
      display: flex;

      .progress-bar {
        background: #00bdf8;
        height: 100%;
        border-radius: 30px;
        height: 0.6em;
        animation-name: progress-bar;
        animation-duration: 0.5s;

        @keyframes progress-bar {
          from {
            width: 0;
          }
        }
      }

      .keyIconGold {
        padding: 0.5em;
        padding: 0.5em;
        width: 2em;
        height: auto;
        margin-bottom: 0.4em;
        border-radius: 10em;
        background: #1f2362;
        position: absolute;
        right: 2.7em;
      }
      .keyIconFinal {
        padding: 0.5em;
        padding: 0.5em;
        width: 2em;
        height: auto;
        margin-bottom: 0.4em;
        border-radius: 10em;
        background: #00bdf8;
        position: absolute;
        right: 2.7em;
      }
    }
  }
}

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;

  background: #2e4fc8;
  border-radius: 0.3em;
  border: none;
  padding: 1.1em 4em;
  color: #ffffff;
  margin-top: 0.7em;
  margin-bottom: -2em;
  width: 90%;
  opacity: 0;
  transition: all 0s ease-in;

  &:hover {
    background: #5e6bff;
  }
  &.locked {
    opacity: 0.2;
    margin-bottom: 0;
  }
  &.active {
    cursor: pointer;
    transition: all 0.3s ease-in;
    opacity: 1;
    margin-bottom: 0;
  }
}

.riveAnimation {
  height: 11em;
  padding-top: 0.4em;
  padding-right: 0.4em;
  display: flex;
  justify-content: center;
  align-items: center;

  &.showBelt {
    height: 30em;
  }

  &.finalKey {
    // padding-top: 2em;
    // height: 30em;
  }
  @media (max-width: 620px) {
    // height: 30em;
    // padding-top: 5em;
  }
}

.footer {
  // padding: 2em;
  height: 2.5em;
  // background-color: rgba(0, 0, 0, 0);
  transition: all 1s;
  width: 100%;
  &.show {
    // background-color: rgba(0, 0, 0, 0.2);
  }
}
.beltTitle {
  position: absolute;
  top: 1.5em;
  left: 0em;
  width: 100%;
  font-weight: 600;
  font-size: 1.8em;
  color: rgba(rgb(255, 255, 255), 0.8);
  text-align: center;
  @media (max-width: 620px) {
    font-size: 1.7em;
  }
}
.text {
  color: rgba(rgb(255, 255, 255), 0.5);
  margin-top: 0.3em;
  text-align: center;
}
.memomanDialog {
  color: rgba(rgb(255, 255, 255), 0.5);
  // margin-top: 0.3em;
  position: absolute;
  bottom: 7em;
  left: 0em;
  width: 100%;
  font-style: italic;
  text-align: center;
}

.hideCloseButton {
  position: absolute;
  top: 0;
  right: 0;
  width: 2.4em;
  height: 2.4em;
  background-color: #020a26;
  z-index: 1;
  visibility: visible;

  &.hide {
    visibility: hidden;
  }
}
</style>
