import axios from 'axios';
import settings from '@/settings';
import transformCourseData from './transform-course-data';

import apolloClient from '@/apollo-client';
import allVideosGql from '@/graphql/AllVideos.gql';
import videoGql from '@/graphql/Video.gql';
import allLifeskillsGql from '@/graphql/AllLifeskills.gql';
import lifeskillVideosGql from '@/graphql/LifeskillVideos.gql';
import moment from 'moment';
import gql from 'graphql-tag';

import store from '@/store';

const sessionCache = {};

class MemomapsDataHelper {
    videoMap(videoRaw) {
        videoRaw = videoRaw.video || videoRaw;
        let progress = { progress: 0 };
        if (videoRaw.videoProgressesList) {
          progress = videoRaw.videoProgressesList.shift() || { progress: 0 };
        }
        if (videoRaw.progress) {
          progress = { progress: videoRaw.progress };
        }

        return {
            _id: videoRaw.id,
            id: videoRaw.youtubeId,
            videoId: videoRaw.youtubeId,
            title: videoRaw.title,
            channelTitle: videoRaw.channelTitle,
            thumbnail: videoRaw.thumbnail,
            description: videoRaw.description,
            recommended: videoRaw.recommended,
            duration: moment.duration(videoRaw.duration).asSeconds(),
            lifeskillId: videoRaw.numbershape,
            lifeskillIds: (videoRaw.videoLifeskillsList || []).map(x => x.lifeskill.numbershape),
            ordering: videoLifeskillOrdering(videoRaw.videoLifeskillsList || []),
            playlists: videoPlaylists(videoRaw.videoLifeskillsList || [], videoRaw.channelTitle, videoRaw.duration),
            progress: progress.progress || 0,
            latestActivity: progress.latestActivity || 0
        };
    }

    async loadLifeskillsList() {
        try {
            const response = await apolloClient.query({
                query: allLifeskillsGql
            });
            return response.data.lifeskillsList;
        } catch (err) {
            console.log('err', err); // eslint-disable-line no-console
            return [];
        }
    }

    async loadCoursesList() {
        let url = `${settings.endpoints.memomapsData}/courses-list.json`;
        return axios
            .get(url)
            .then(response => {
                return {
                    courses: response.data.courses.map(courseData => {
                        return transformCourseData(courseData);
                    }),
                    mapping: response.data.mapping
                };
            })
            .catch(error => {
                console.log(error); // eslint-disable-line no-console
            });
    }

    async loadCourseToc(courseId) {
        let url = `${settings.endpoints.memomapsData}/courses/${courseId}/toc.json`;
        return axios
            .get(url)
            .then(response => {
                return transformCourseData(response.data);
            })
            .catch(error => {
                console.log(error); // eslint-disable-line no-console
            });
    }

    async loadCourseFull(courseId) {
        let url = `${settings.endpoints.memomapsData}/courses/${courseId}/full.json`;
        return axios
            .get(url)
            .then(response => {
                return transformCourseData(response.data, { content: true });
            })
            .catch(error => {
                console.log(error); // eslint-disable-line no-console
            });
    }

    async loadCourseScenarios(courseId) {
        let url = `${settings.endpoints.memomapsData}/courses/${courseId}/scenarios.json`;
        return axios
            .get(url)
            .then(response => {
                let scenariosData = [];

                response.data.stepGroups.forEach(stepGroup => {
                    stepGroup.steps.forEach(step => {
                        if (step.scenarios) {
                            scenariosData = scenariosData.concat(step.scenarios);
                        }
                    });
                });
                return {
                    scenariosData
                };
            })
            .catch(error => {
                console.log(error); // eslint-disable-line no-console
            });
    }

    async loadVideo(videoId) {
        try {
            const response = await apolloClient.query({
                query: videoGql,
                variables: {
                    videoId
                }
            });
            return response.data.videosList.map(this.videoMap)[0] || null;
        } catch (err) {
            console.log('err', err); // eslint-disable-line no-console
            return null;
        }
    }

    async loadAllVideos() {
        try {
            const response = await apolloClient.query({
                query: allVideosGql
            });
            return response.data.videosList.map(this.videoMap);
        } catch (err) {
            console.log('err', err); // eslint-disable-line no-console
            return [];
        }
    }

    async loadVideosInProgress() {
        try {
            const response = await apolloClient.query({
                query: gql`
                    {
                        videosInProgressesList(orderBy: LATEST_ACTIVITY_DESC) {
                            id
                            likeCount
                            recommended
                            thumbnail
                            title
                            youtubeId
                            progress
                            numbershape
                            lifeskillId
                            latestActivity
                            duration {
                              seconds
                              minutes
                              hours
                            }
                        }
                    }
                `
            });
            return response.data.videosInProgressesList.map(this.videoMap);
        } catch (err) {
            console.log('err', err); // eslint-disable-line no-console
            return [];
        }
    }

    async loadVideosForLifeskill(lifeskillId) {
        console.log('loading videos for', lifeskillId);
        try {
            const response = await apolloClient.query({
                query: lifeskillVideosGql,
                variables: { lifeskillId }
            });

            return response.data.videoLifeskillsList.map(this.videoMap);
        } catch (err) {
            console.log('err', err); // eslint-disable-line no-console
            return [];
        }
    }

    async loadRecommendedVideos() {
        try {
            const response = await apolloClient.query({
                query: gql`
                    {
                        recommendedVideosList {
                            id
                            lifeskillId
                            likeCount
                            numbershape
                            recommended
                            thumbnail
                            title
                            youtubeId
                            duration {
                                seconds
                                minutes
                                hours
                            }
                        }
                    }
                `
            });
            return response.data.recommendedVideosList;
        } catch (err) {
            console.error(err);
            return [];
        }
    }

    async loadChemistryKb() {
        if (sessionCache['chemistry-kb']) {
            return sessionCache['chemistry-kb'];
        }
        let url = `${settings.endpoints.memomapsData}/knowledge-bases/chemistry-kb.json`;
        return axios
            .get(url)
            .then(response => {
                sessionCache['chemistry-kb'] = response.data;
                return response.data;
            })
            .catch(error => {
                console.log(error); // eslint-disable-line no-console
            });
    }

    async loadHistory100Kb() {
        if (sessionCache['history100-kb']) {
            return sessionCache['history100-kb'];
        }
        let url = `${settings.endpoints.memomapsData}/knowledge-bases/history100-kb.json`;
        return axios
            .get(url)
            .then(response => {
                sessionCache['history100-kb'] = response.data;
                return response.data;
            })
            .catch(error => {
                console.log(error); // eslint-disable-line no-console
            });
    }
}

const videoLifeskillOrdering = videoLifeskillsList => {
    let data = {};
    videoLifeskillsList.forEach(vll => {
        data[vll.lifeskill.numbershape] = vll.ordering;
    });
    return data;
};

const videoPlaylists = (videoLifeskillsList, channelTitle, duration) => {
    let playlists = {};
    videoLifeskillsList.forEach(vll => {
        playlists[vll.lifeskill.numbershape] = playlistName(
            vll.playlist,
            channelTitle,
            vll.lifeskill.numbershape,
            duration
        );
    });
    return playlists;
};

const playlistName = (playlist, channelTitle, numbershape, duration) => {
    const locale = store.getters['moduleApp/locale'];
    const localeIndex = locale === 'no' ? 1 : 0;
    const defaultPlaylist = 'Mixed Up | Blandet'.split('|')[localeIndex].trim();
    const longPlay = 'Chill & Deep Dive | Slapp av og dypdykk'.split('|')[localeIndex].trim();
    const fallback = () => (moment.duration(duration).asMinutes() >= 30 ? longPlay : defaultPlaylist);

    if (playlist) {
        return (playlist.split('|')[localeIndex] || playlist).trim();
    }

    return (
        {
            BrainCraft: () => (['00', '02'].includes(numbershape) ? 'BrainCraft' : defaultPlaylist),
            'Big Think': () => 'BigThink',
            'TED-Ed': () => (['01', '05', '10', '36', '48', '50'].includes(numbershape) ? defaultPlaylist : 'TED-Ed'),
            TED: () => 'TED',
            'TEDx Talks': () => 'TED'
        }[channelTitle] || (() => fallback())
    )();
};

const memomapsDataHelper = new MemomapsDataHelper();
export default memomapsDataHelper;
