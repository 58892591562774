import variables from '@/assets/styles/_variables.scss';

const themes = {
    brain: {
        color: variables.brain
    },
    knowledge: {
        color: variables.knowledge
    },
    mind: {
        color: variables.mind
    },
    body: {
        color: variables.body
    },
    soul: {
        color: variables.soul
    },
    career: {
        color: variables.career
    },
    leisure: {
        color: variables.leisure
    },

    default: {
        color: variables.standardButtonColor
    }
};

export { variables as cssVariables };
export default themes;
