/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'locked': {
    width: 31,
    height: 42,
    viewBox: '0 0 31 42',
    data: '<path pid="0" d="M27.226 17.09H26.11v-5.75C26.098 5.086 21.173 0 15.12 0 9.068.004 4.142 5.097 4.15 11.355l.008 5.743h-.4C1.683 17.106 0 18.85 0 21v17.105C0 40.255 1.693 42 3.774 42l23.463-.015c2.081 0 3.767-1.745 3.763-3.895V20.985c0-2.154-1.693-3.895-3.774-3.895zm-19.019.008l-.011-5.743c0-3.955 3.107-7.17 6.928-7.174 3.825 0 6.936 3.215 6.936 7.163l.008 5.746-13.86.008z" _fill="#222871"/>'
  }
})
