/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'trophy': {
    width: 60.12,
    height: 56.124,
    viewBox: '0 0 60.12 56.124',
    data: '<path pid="0" d="M60.05 14.97a11.23 11.23 0 01-11.24 11.222 9.227 9.227 0 01-1.31-.132c-2.52 7.339-8.45 13.951-15.57 14.959v11.358h5.63a1.871 1.871 0 010 3.741h-15a1.871 1.871 0 110-3.741h5.62V41.019c-7.12-1.008-13.05-7.62-15.57-14.959a9.236 9.236 0 01-1.3.132A11.232 11.232 0 01.06 14.97c0-7.484-1.6-7.482 11.25-7.482a7.486 7.486 0 017.5-7.482h22.5a7.493 7.493 0 017.5 7.482c12.84 0 11.24-.002 11.24 7.482zm-48.74-3.741c-7.43 0-7.5-.391-7.5 3.741a7.485 7.485 0 007.5 7.481V11.229zm33.75-3.741a4.065 4.065 0 00-3.75-3.741h-22.5a4.107 4.107 0 00-3.75 3.741V18.71c0 8.264 6.71 18.7 15 18.7s15-10.44 15-18.7V7.488zm3.75 3.741v11.222a7.492 7.492 0 007.5-7.481c0-4.132-.08-3.741-7.5-3.741z" fill-rule="evenodd"/>'
  }
})
