<template>
  <div class="LifekeyPopupRive">
    <canvas ref="canvas" id="lifekeyCanvas" class="riveContainer" width="500px" height="500px" />
  </div>
</template>


<script>
const rive = require("rive-js");
import RiveFile from '@/assets/rive/lifekey_popup.riv'

export default {

  props: {
    onboarding: {
      type: Boolean,
      default: false,
    },
    showBelt: {
      type: Boolean,
      default: false,
    },
    categoryName: {
      type: String,
      default: 'brain',
    },
    lifekeyCount: {
      type: Number,
      required: true,
    },
    belt: {
      type: Object,
    },
    
   
  },

  data() {
    return {
      r: null,
      inputs: null,
      timers: [],
    };
  },

  mounted() {
    this.setRive();
  },

  computed: {
    isFinalKey(){
      return this.belt.points === 0;
    },
    isFirstLifekey(){
      return this.lifekeyCount === 1;
    },
  },

  watch: {
    showBelt(v){
      if(v){
        this.inputs[4].value = true;
        this.animateBeltTransition();
      }
    }
  },

  beforeDestroy(){
   this.r.stop();
   this.r.unsubscribeAll();
   this.r = null;
   this.timers.forEach((t) => clearTimeout(t));
  },

  methods: {
    setRive(){
      this.r = new rive.Rive({
          src: RiveFile,
          canvas: this.$refs.canvas,
          stateMachines: 'lifekeys_states',
          autoplay: true,
          layout: new rive.Layout({fit: 'fill', alignment: 'center'}),
          onload: () => {
            this.inputs = this.r.stateMachineInputs('lifekeys_states');
            if(!this.onboarding) {
              this.setKeysAndBelts();
            } else {
              this.animateForOnboarding();
            }
            this.$emit('animateBeltLayout');
          },
      }); 
    },

    animateForOnboarding(){
        this.keysNumber(2);
        this.keyNumber(2);
        this.beltNumber(0);
        this.inputs[4].value = true;
        
        this.timers.push(setTimeout(() => {
          this.loopMemomanAnimation()
        }, 6500));
    },

    setKeysAndBelts(){
      this.setLifeskillCategory();
      if(this.isFinalKey){ 
         this.keysNumber(this.belt.current.requiredPoints);
         this.keyNumber(this.belt.current.requiredPoints);
       } else {
         this.keysNumber(this.belt.next.requiredPoints);
         this.keyNumber(this.belt.points);
         if(this.belt.current) {
           this.beltNumber(this.belt.current.index);
         } else {
           this.beltNumber(-1);
         }
       }
    },

    animateBeltTransition(){
      if(this.isFinalKey){
        this.beltNumber(this.belt.current.index-1);
        this.timers.push(setTimeout(() => {
         this.beltNumber(this.belt.current.index);
        }, 5000));
        this.timers.push(setTimeout(() => {
          this.loopMemomanAnimation()
        }, 6201));
      } 
    },

    keysNumber(v){
        this.inputs[0].value = v;
    },

    keyNumber(v){
        this.inputs[1].value = v;
    },

    beltNumber(v){
      this.inputs[2].value = v;
    },

    loopMemomanAnimation(){
      this.inputs[3].value = true;
    },

    setLifeskillCategory(){
      this.inputs[5].value = this.getCategoryNumberFromName();
    },

    getCategoryNumberFromName(){
            if(this.categoryName === 'brain'){
                return 1;
            } 
            else if(this.categoryName  === 'knowledge'){
                return 2;
            }
            else if(this.categoryName  === 'mind'){
                return 3;
            }
            else if(this.categoryName  === 'body'){
                return 4;
            }
            else if(this.categoryName  === 'soul'){
                return 5;
            }
            else if(this.categoryName  === 'career'){
                return 6;
            }
            else if(this.categoryName  === 'leisure'){
                return 7;
            } else {
              return 1;
            }
    }

  },
  
};
</script>

<style scoped lang="scss">

.riveContainer {
    width: 28em;
  @media (max-width: 620px) {
    width: 85vw;
  }
}

</style>
