/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'admin-icon': {
    width: 16,
    height: 16,
    viewBox: '0 0 1000 1000',
    data: '<path pid="0" d="M421.4 8.5c91.5 0 184.8 77.7 215.4 179.1 27 89.7-5.9 179.5-89.8 245.6-74.4 58.7-155.1 63.7-238.5 14.6-155.7-91.6-151.2-308.9 9.9-406.8 30.4-18.4 63.1-28.4 103-32.5 1.1 2.6-.9-2.5 0 0zm-92.5 892.3c-83.8-9.6-167.7-19.1-251.4-28.7-1-.1-1.9-.3-2.8-.4-71.4-15.3-74.7-20.5-52.8-88 14.7-45.5 28.7-91.3 45-136.3 25.7-71.7 80.6-108.8 153.8-112.5 85.3-4.4 171-1.8 256.4-1 29.4.3 34.5 10.5 22 37.4-3.2 6.7-6.7 13.5-10.7 19.8-49.9 80.3-66.2 165.9-43 258.8 9.5 38.1 2.1 45.2-37 46.1-19.7.4-39.5.1-59.1.1h-19.7c-.3 1.5-.6 3-.7 4.7zM486.2 692c14.6-3.7 28.9-8 43.5-11 22.8-4.8 25.9-16.1 12-33.6-8.8-11-18-21.6-29.2-34.7 11.7-15.8 23.8-32.2 35.9-48.5 11.7-15.8 23.1-31.6 36.2-49.5 15.3 11.4 29.2 22.1 43.5 32.5 22.1 16 37.1 12.1 48.3-13.5 5.9-13.6 9.4-28.3 14.7-45 28.3 0 57.4-1.2 86.2 1 6.5.5 14.7 12.2 17.6 20.4 21.9 60.1 24.3 60.9 75.5 21 6.6-5.1 13.5-9.8 22-15.8 24.2 32.5 47.4 63.8 74.4 99.9-10.3 8.3-20.8 14.9-29.3 23.4-24.1 23.9-21 34.7 11.1 43 13.5 3.6 27.1 6.9 41.4 10.5v97.1c-16 4.5-31.4 8-46.1 13.3-25.9 9.4-28.9 24.9-8.9 43 8.9 8.1 18.6 15.5 27.5 23-32.6 31.4-64.2 61.9-98.6 94.9-6.7-6.1-17.5-16-28.6-25.4-20.5-17.5-38.4-10.7-43.2 16.1-1.1 6.5-1.9 13.1-2.9 19.5-.8 5.5-1.5 11.1-2.6 18.7h-96.8c-3-13.9-5.5-28.6-9.4-42.8-5.5-20.6-17.9-28.2-37.8-16.8-17.9 10.3-35.5 21.2-56 33.4-24.6-31.8-49.5-64-75.4-97.4 10.3-8.9 19.8-16.5 28.6-24.8 20.8-19.5 17.9-32.5-9.6-42.2-14-5-28.6-8.4-44-12.8V692zm377.3 46.9c-.4-70.6-57.2-127.1-127.2-126.4-70.3.5-126 57.5-125.6 128.2.5 70.4 57.1 126.3 127.6 126 70.8-.4 125.6-56.3 125.2-127.8z"/>'
  }
})
