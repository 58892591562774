import * as memolifeGeneral from './memolife-general';
import * as memolifeDebugSettings from './memolife-debug-settings';

const handlerMap = {
    memolifeGeneral,
    memolifeDebugSettings
};

const staticIdHandlerMap = {};
const dynamicIdHandlers = [];

Object.values(handlerMap).forEach(handler => {
    if (handler.id) {
        staticIdHandlerMap[handler.id] = handler;
    } else {
        dynamicIdHandlers.push(handler);
    }
});

export function getHandler(docId) {
    return staticIdHandlerMap[docId] || dynamicIdHandlers.find(handler => handler.pattern.exec(docId));
}

export function buildDoc(store, docName, options) {
    const docHandler = handlerMap[docName];
    return docHandler.build(store, options);
}
