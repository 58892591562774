/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'progress-key': {
    width: 23,
    height: 21,
    viewBox: '0 0 23 21',
    data: '<path pid="0" d="M17.008 1.204c1.206.578 1.934 1.66 2.01 2.912.878.048 1.706.385 2.334.986a3.695 3.695 0 011.105 2.623c0 .77-.251 1.54-.753 2.19-.05.072-.076.12-.126.168-1.13 1.155-2.938 1.636-5.373 1.444a19.98 19.98 0 01-2.637-.41c-.075-.023-.15-.023-.226-.047l-9.44 9.047c-.428.409-1.156.409-1.583 0a1.09 1.09 0 01-.326-.77c0-.289.126-.553.326-.77l.628-.602-1.858-1.78a1.09 1.09 0 01-.326-.77c0-.289.125-.554.326-.77a1.173 1.173 0 011.582 0l1.858 1.78.678-.65-1.858-1.78a1.09 1.09 0 01-.326-.77c0-.289.125-.553.326-.77.2-.192.502-.313.803-.313.302 0 .578.12.804.313l1.858 1.78 4.947-4.74c0-.048-.026-.096-.026-.144a16.101 16.101 0 01-.452-2.599c-.2-2.334.302-4.066 1.507-5.149.05-.048.126-.096.2-.144 1.181-.794 2.688-.89 4.018-.265zm.277 4.764c-.327-.24-.503-.65-.377-1.01.05-.193.1-.385.1-.578 0-.697-.401-1.323-1.03-1.612-.602-.265-1.255-.192-1.832.24-.678.626-1.005 1.565-1.005 2.84 0 .963.201 2.094.578 3.345 1.808.505 4.947 1.082 6.453-.41.276-.336.427-.697.427-1.082 0-.505-.276-.987-.728-1.3a1.99 1.99 0 00-1.733-.264.988.988 0 01-.854-.169z" _fill="#FFCB45"/>'
  }
})
