import store from '@/store';
import gameHelper from '@/modules/games-shared/game-helper';
import ability from '@/ability';

const App = () => import('./App');

const InitialView = () => import('./views/InitialView');
const ChallengesView = () => import('./views/ChallengesView');
const ChallengeCreateView = () => import('./views/ChallengeCreateView');
const ChallengeInvitationView = () => import('./views/ChallengeInvitationView');
const ChallengeResultView = () => import('./views/ChallengeResultView');
const LevelsView = () => import('./views/LevelsView');
const SessionView = () => import('./views/SessionView');
const LevelFailView = () => import('./views/LevelFailView');
const LevelSuccessView = () => import('./views/LevelSuccessView');
const PracticeResultView = () => import('./views/PracticeResultView');

function hasChallenge(gameId) {
  return require('@/modules/game-' + gameId + '/theme').default.hasChallenge;
}

export default [
  {
    path: '/games/:gameId',
    props: true,
    component: App,
    beforeEnter(to, from, next) {
      if (store.getters['moduleMemogenius/getGameIds'].indexOf(to.params.gameId) === -1) {
        next({
          name: 'DashboardHomeView',
        });
      } else {
        next();
      }
    },
    children: [
      {
        path: '',
        name: 'MemogeniusGame-InitialView',
        props: true,
        meta: {
          transitionHint: 'full',
        },
        component: InitialView,
      },
      {
        path: 'levels',
        name: 'MemogeniusGame-Levels',
        props: true,
        meta: {
          transitionHint: 'banner',
        },
        component: LevelsView,
      },
      {
        path: 'level/:levelIndex',
        name: 'MemogeniusGame-LevelSession',
        beforeEnter: async (to, from, next) => {
          const gameId = to.params.gameId;
          const levelIndex = parseInt(to.params.levelIndex, 10);
          if (!ability.can('play', { modelName: 'Game', gameId, levelIndex })) {
            return next({
              name: 'MemogeniusGame-Levels',
              params: { gameId },
            });
          }
          const session = await gameHelper.createLevelSession({
            gameId: to.params.gameId,
            levelIndex: parseInt(to.params.levelIndex, 10),
          });
          next({
            name: 'MemogeniusGame-LevelSession--withSession',
            params: {
              gameId: to.params.gameId,
              levelIndex: parseInt(to.params.levelIndex, 10),
              sessionId: session.sessionId,
            },
          });
        },
      },
      {
        path: 'level/:levelIndex/:sessionId',
        name: 'MemogeniusGame-LevelSession--withSession',
        props(route) {
          return {
            gameId: route.params.gameId,
            levelIndex: parseInt(route.params.levelIndex, 10),
            sessionId: route.params.sessionId,
          };
        },
        component: SessionView,
        beforeEnter(to, from, next) {
          if (!gameHelper.sessions[to.params.sessionId]) {
            next({
              name: 'MemogeniusGame-Levels',
              params: { gameId: to.params.gameId },
            });
          } else {
            next();
          }
        },
      },
      {
        path: 'level/:levelIndex/:sessionId/fail',
        name: 'MemogeniusGame-LevelFail',
        props(route) {
          return {
            gameId: route.params.gameId,
            levelIndex: parseInt(route.params.levelIndex, 10),
            sessionId: route.params.sessionId,
          };
        },
        meta: {
          transitionHint: 'banner',
        },
        component: LevelFailView,
        beforeEnter(to, from, next) {
          if (!gameHelper.sessions[to.params.sessionId]) {
            next({
              name: 'MemogeniusGame-Levels',
              params: { gameId: to.params.gameId },
            });
          } else {
            next();
          }
        },
      },
      {
        path: 'level/:levelIndex/:sessionId/success',
        name: 'MemogeniusGame-LevelSuccess',
        props(route) {
          return {
            gameId: route.params.gameId,
            levelIndex: parseInt(route.params.levelIndex, 10),
            sessionId: route.params.sessionId,
          };
        },
        meta: {
          transitionHint: 'banner',
        },
        component: LevelSuccessView,
        beforeEnter(to, from, next) {
          if (!gameHelper.sessions[to.params.sessionId]) {
            next({
              name: 'MemogeniusGame-Levels',
              params: { gameId: to.params.gameId },
            });
          } else {
            next();
          }
        },
      },
      {
        path: 'practice/:sessionId',
        name: 'MemogeniusGame-PracticeSession',
        props: true,
        component: SessionView,
        beforeEnter(to, from, next) {
          if (!gameHelper.sessions[to.params.sessionId]) {
            next({
              name: 'DashboardHomeView',
            });
          } else {
            next();
          }
        },
      },
      {
        path: 'practice/:sessionId/result',
        name: 'MemogeniusGame-PracticeResult',
        props: true,
        component: PracticeResultView,
        beforeEnter(to, from, next) {
          if (!gameHelper.sessions[to.params.sessionId]) {
            next({
              name: 'DashboardHomeView',
            });
          } else {
            next();
          }
        },
      },
      {
        path: 'challenges',
        name: 'MemogeniusGame-Challenges',
        props: true,
        meta: {
          transitionHint: 'banner',
        },
        component: ChallengesView,
        beforeEnter(to, from, next) {
          if (!hasChallenge(to.params.gameId)) {
            next({
              name: 'DashboardHomeView',
            });
          } else {
            next();
          }
        },
      },
      {
        path: 'challenges/create',
        name: 'MemogeniusGame-ChallengesCreate',
        props: true,
        component: ChallengeCreateView,
        beforeEnter(to, from, next) {
          if (!hasChallenge(to.params.gameId)) {
            next({
              name: 'DashboardHomeView',
            });
          } else {
            next();
          }
        },
      },
      {
        path: 'challenge/:challengeId/session/:sessionId',
        name: 'MemogeniusGame-ChallengeSession',
        props: true,
        component: SessionView,
      },
      {
        path: 'challenge/:challengeId/invitation/:groupInvitationId',
        name: 'MemogeniusGame-ChallengeInvitation',
        props: true,
        component: ChallengeInvitationView,
      },
      {
        path: 'challenge/:challengeId/result',
        name: 'MemogeniusGame-ChallengeResult',
        props: true,
        component: ChallengeResultView,
      },
    ],
  },
];
