<template>
  <div class="TeamAdmin">
    <h1>Your teams</h1>
    <table>
      <tr v-for="team of teams"
          class="--global-clickable"
          :key="team.id"
          @click="teamDetail(team.id)">
        <td>{{ team.name }}</td>
      </tr>
    </table>

    <TeamCreate @created="fetchTeams()" />

  </div>
</template>

<script>
import TeamCreate from "./TeamCreate";
import apolloClient from '@/apollo-client';
import gql from 'graphql-tag';


export default {
  components: {
    TeamCreate,
  },
  data: function() {
    return {
      teams: [],
    }
  },

  props: {},

  mounted() {
    this.fetchTeams();
  },

  methods: {
    teamDetail(id) {
      this.$router.push({
        name: "TeamDetails",
        params: { teamId: `${id}` },
      });
    },

    async fetchTeams() {
      const response = await apolloClient.query({
        fetchPolicy: 'no-cache',
        query: gql`
                    query Teams {
                        teamsList (orderBy: NAME_ASC ){
                            id
                            name
                            isAdmin
                        }
                    }
                `,
      });
      this.teams = response.data.teamsList;
    }
  },

  computed: {},

  filters: {},
}
</script>

<style lang="scss" scoped>
.TeamAdmin {
  overflow: scroll;
  color: white;
}

</style>

