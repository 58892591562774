import axios from 'axios';
import settings from '@/settings';
import apolloClient from '@/apollo-client';
import gql from 'graphql-tag';

export function fetchCourseProgress() {
  return apolloClient
    .query({
      fetchPolicy: 'no-cache',
      query: gql`
      query ScenarioProgress {
        scenarios: courseProgressesList(condition: {context: "scenario"}) {
          activity
          context
          contextId
          courseId
          createdAt
          status
        }
        quiz: courseProgressesList(condition: {context: "stepgroup" activity:"quiz",status:"done"}) {
          activity
          context
          contextId
          courseId
          createdAt
          status
        }
      }`
    })
    .then(result => result.data);
}


export function updateCourseProgress(scenarioId, courseId) {
  return apolloClient.mutate({
    mutation: gql`
    mutation($courseId: String!, $context: String!, $contextId: String!, $activity: String!, $status: String!) {
    updateCourseProgress(
      input: {
        _courseId: $courseId
        _context: $context
        _contextId: $contextId
        _activity: $activity
        _status: $status
      }
    ) {
      results {
        activity
        context
        contextId
        courseId
        createdAt
        status
      }
    }
  }
        `,
    variables: {
      courseId,
      context: 'scenario',
      contextId: scenarioId,
      activity: 'scenario',
      status: 'done',
    }
  })
  .then(result => result.data.updateCourseProgress.results);
}

export function fetchCourseScenarios(courseId) {
  const url = `${settings.endpoints.memomapsData}/courses/${courseId}/scenarios.json`;
  return axios
    .get(url);
}
