/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-down': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M27.414 19.414l-10 10a2 2 0 01-2.828 0l-10-10a2 2 0 112.828-2.828L14 23.172V4a2 2 0 114 0v19.172l6.586-6.586c.39-.39.902-.586 1.414-.586s1.024.195 1.414.586a2 2 0 010 2.828z"/>'
  }
})
