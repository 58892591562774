/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'library2': {
    width: 49,
    height: 41,
    viewBox: '0 0 49 41',
    data: '<g fill-rule="nonzero"><path pid="0" d="M4.083 41h40.834A4.092 4.092 0 0049 36.9V12.3c0-2.264-1.828-4.1-4.083-4.1V4.1c0-2.264-1.829-4.1-4.084-4.1H8.167a4.092 4.092 0 00-4.084 4.1v4.1A4.092 4.092 0 000 12.3v24.6C0 39.164 1.828 41 4.083 41zM8.167 4.1h32.666v4.1H8.167V4.1zm-4.084 8.2h40.834v24.6H4.083V12.3z"/><path pid="1" d="M20.659 16.28H10.415a2.058 2.058 0 00-2.05 2.067v10.336c0 1.141.918 2.067 2.05 2.067h10.244a2.058 2.058 0 002.048-2.067V18.347a2.058 2.058 0 00-2.048-2.068zm-2.05 10.336h-6.146v-6.202h6.147v6.202zM38.585 16.28H28.341c-1.131 0-2.048.944-2.048 2.11 0 1.165.917 2.11 2.048 2.11h10.244c1.132 0 2.05-.945 2.05-2.11 0-1.166-.918-2.11-2.05-2.11zM38.585 24.72H28.341c-1.131 0-2.048.945-2.048 2.11 0 1.166.917 2.111 2.048 2.111h10.244c1.132 0 2.05-.945 2.05-2.11 0-1.166-.918-2.11-2.05-2.11z"/></g>'
  }
})
