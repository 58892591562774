<template>
  <div class="HeartGraph">
    <svg
      ref="svg"
      class="canvas"
      :viewBox="svgViewBox">
      <g id="blocksGroup" />
    </svg>
  </div>
</template>

<script>
import { select } from 'd3-selection';
import 'd3-transition';
import categoryThemes from '@/category-themes';
import { getCategoryIdFromLifeskillId } from '@/utils';

export default {
    // props: [ 'data' ],
    props: {
        data: {
            type: Array,
            required: true
        },
        emptyColor: {
            type: String,
            default: '#ffffff0d'
        }
    },
    data() {
        return {
            svgViewBox: '0 0 0 0'
        };
    },
    computed: {
        dailyRequiredBraindates() {
            return this.$store.state.moduleApp.memolife.dailyRequiredBraindates;
        },
        tiles() {
            const braindates = this.data.length;
            const goal = this.$store.state.moduleApp.memolife.dailyRequiredBraindates * 7;
            return braindates > goal ? braindates : goal;
        }
    },
    watch: {
        data: {
            handler: function (val, oldVal) {
                this.render();
            },
            deep: true
        }
    },
    mounted() {
        this.render();
    },
    methods: {
        render() {
            this.svg = select(this.$refs.svg);

            const INSET = 4;
            const totalTileCount = this.tiles;
            const braindates = this.data.map(bd => bd.lifeskillNumber || '');
            const grid = grids[`${totalTileCount}`] || grids['28'];
            this.svgViewBox = grid.viewBox;
            const emptyColor = this.emptyColor;

            function color(index) {
                const lifeskillId = braindates[index];
                if (lifeskillId === undefined) {
                    return emptyColor;
                }
                let categoryId = getCategoryIdFromLifeskillId(braindates[index]) || 'default';
                return categoryThemes[categoryId].color;
            }

            let rect = select('#blocksGroup')
                .selectAll('rect')
                // .data(grid.slice(0, braindates.length));
                .data(grid.blocks);

            rect.enter()
                .append('rect')
                .attr('x', d => d.x + INSET)
                .attr('y', d => d.y + INSET)
                .attr('width', d => d.width - INSET * 2)
                .attr('height', d => d.height - INSET * 2)
                .attr('fill', (d, i) => {
                    return color(i);
                });

            rect.exit().remove();
            rect.transition().attr('fill', (d, i) => {
                return color(i);
            });
        }
    }
};

// Search and replace pattern:
// <rect .*?"(\d+)".*?"(\d+)".*?"(\d+)".*?"(\d+)".*
// { x: $1, y: $2, width: $3, height: $4 },
//
// Ungroup all in Inkscape
// ^\W+
// (style|id|rx)=.*\n
// rect\nx="(.*?)"\ny="(.*?)"\nwidth="(.*?)"\nheight="(.*?)"
// { x: $1, y: $2, width: $3, height: $4 },
const grids = {
    '7': {
        viewBox: '0 0 574 485',
        blocks: [
            { x: 222, y: 403.98767, width: 131, height: 81.012337 },
            { x: 151, y: 322.97534, width: 271.32431, height: 81.012337 },
            { x: 77, y: 241.963, width: 419.35263, height: 81.012337 },
            { x: 0, y: 161, width: 191, height: 81 },
            { x: 191, y: 161, width: 383, height: 81 },
            { x: 37, y: 80, width: 501, height: 81 },
            { x: 86, y: 0, width: 131, height: 81 },
            { x: 357, y: 0, width: 131, height: 81 }
        ]
    },
    '14': {
        viewBox: '0 0 623 526',
        blocks: [
            { x: 249, y: 460, width: 131, height: 61 },

            { x: 179, y: 399, width: 271.32431, height: 61 },

            { x: 105, y: 338, width: 419.35263, height: 61 },

            { x: 57, y: 277, width: 215, height: 61 },
            { x: 272, y: 277, width: 299.37817, height: 61 },

            { x: 33, y: 216, width: 281.95731, height: 61 },
            { x: 315.04269, y: 216, width: 281.95731, height: 61 },

            { x: 10, y: 155, width: 203.29474, height: 61 },
            { x: 212.85263, y: 155, width: 203.29474, height: 61 },
            { x: 415.70526, y: 155, width: 203.29474, height: 61 },

            { x: 33, y: 94, width: 281.95731, height: 61 },
            { x: 315.04269, y: 94, width: 281.95731, height: 61 },

            { x: 78, y: 33, width: 186, height: 61 },
            { x: 366, y: 33, width: 186, height: 61 }
        ]
    },
    '21': {
        viewBox: '0 0 660 526',
        blocks: [
            { x: 268, y: 460, width: 117, height: 49 },

            { x: 227, y: 411, width: 200, height: 49 },

            { x: 148, y: 362, width: 358, height: 49 },

            { x: 102, y: 313, width: 200, height: 49 },
            { x: 302, y: 313, width: 249, height: 49 },

            { x: 52, y: 264, width: 149, height: 49 },
            { x: 201, y: 264, width: 200, height: 49 },
            { x: 401, y: 264, width: 200, height: 49 },

            { x: 30, y: 215, width: 172, height: 49 },
            { x: 202, y: 215, width: 249, height: 49 },
            { x: 451, y: 215, width: 172, height: 49 },

            { x: 13, y: 166, width: 238, height: 49 },
            { x: 251, y: 166, width: 200, height: 49 },
            { x: 451, y: 166, width: 189, height: 49 },

            { x: 2, y: 117, width: 200, height: 49 },
            { x: 202, y: 117, width: 200, height: 49 },
            { x: 402, y: 117, width: 249, height: 49 },

            { x: 48, y: 68, width: 249, height: 49 },
            { x: 356, y: 68, width: 249, height: 49 },

            { x: 98, y: 19, width: 149, height: 49 },
            { x: 406, y: 19, width: 149, height: 49 }
        ]
    },
    '28': {
        viewBox: '0 0 623 526',
        blocks: [
            { x: 236, y: 453, width: 131, height: 61 },

            { x: 167, y: 392, width: 131, height: 61 },
            { x: 297.54272, y: 392, width: 137.7816, height: 61 },

            { x: 95, y: 331, width: 145, height: 61 },
            { x: 240, y: 331, width: 137.7816, height: 61 },
            { x: 377.35263, y: 331, width: 131, height: 61 },

            { x: 50, y: 270, width: 168, height: 61 },
            { x: 218, y: 270, width: 84.378181, height: 61 },
            { x: 302, y: 270, width: 131, height: 61 },
            { x: 432.32431, y: 270, width: 120.05388, height: 61 },

            { x: 30, y: 209, width: 145, height: 61 },
            { x: 174.95729, y: 209, width: 131, height: 61 },
            { x: 306.04269, y: 209, width: 79.352631, height: 61 },
            { x: 384.5, y: 209, width: 91.351242, height: 61 },
            { x: 475, y: 209, width: 99, height: 61 },

            { x: 0.5, y: 148, width: 119.05388, height: 60 },
            { x: 119.94612, y: 148, width: 120.05388, height: 61 },
            { x: 239.14737, y: 148, width: 168, height: 61 },
            { x: 406.70526, y: 148, width: 91.351242, height: 61 },
            { x: 498, y: 148, width: 105, height: 61 },

            { x: 25, y: 87, width: 137.7816, height: 61 },
            { x: 162.1757, y: 87, width: 137.7816, height: 61 },
            { x: 300.04269, y: 87, width: 158.33818, height: 61 },
            { x: 457.94614, y: 87, width: 120.05388, height: 61 },

            { x: 68, y: 26, width: 120, height: 61 },
            { x: 188, y: 26, width: 59, height: 61 },
            { x: 356, y: 26, width: 59, height: 61 },
            { x: 415, y: 26, width: 120, height: 61 }
        ]
    },
    '42': {
        viewBox: '0 0 660 526',
        blocks: [
            { x: 272, y: 459, width: 117, height: 49 },

            { x: 233, y: 410, width: 98, height: 49 },
            { x: 331, y: 410, width: 98, height: 49 },

            { x: 159, y: 361, width: 126, height: 49 },
            { x: 285, y: 361, width: 101, height: 49 },
            { x: 386, y: 361, width: 117, height: 49 },

            { x: 110, y: 312, width: 95, height: 49 },
            { x: 205, y: 312, width: 126, height: 49 },
            { x: 331, y: 312, width: 90, height: 49 },
            { x: 421, y: 312, width: 130, height: 49 },

            { x: 62, y: 263, width: 75, height: 49 },
            { x: 137, y: 263, width: 71, height: 49 },
            { x: 208, y: 263, width: 95, height: 49 },
            { x: 303, y: 263, width: 101, height: 49 },
            { x: 404, y: 263, width: 95, height: 49 },
            { x: 499, y: 263, width: 101, height: 49 },

            { x: 40, y: 214, width: 75, height: 49 },
            { x: 115, y: 214, width: 95, height: 49 },
            { x: 210, y: 214, width: 117, height: 49 },
            { x: 327, y: 214, width: 126, height: 49 },
            { x: 453, y: 214, width: 90, height: 49 },
            { x: 543, y: 214, width: 78, height: 49 },

            { x: 23, y: 165, width: 117, height: 49 },
            { x: 140, y: 165, width: 117, height: 49 },
            { x: 257, y: 165, width: 95, height: 49 },
            { x: 352, y: 165, width: 101, height: 49 },
            { x: 453, y: 165, width: 59, height: 49 },
            { x: 512, y: 165, width: 126, height: 49 },

            { x: 11, y: 116, width: 95, height: 49 },
            { x: 106, y: 116, width: 101, height: 49 },
            { x: 207, y: 116, width: 95, height: 49 },
            { x: 302, y: 116, width: 101, height: 49 },
            { x: 403, y: 116, width: 162, height: 49 },
            { x: 565, y: 116, width: 85, height: 49 },

            { x: 53, y: 67, width: 111, height: 49 },
            { x: 164, y: 67, width: 134, height: 49 },
            { x: 361, y: 67, width: 125, height: 49 },
            { x: 486, y: 67, width: 122, height: 49 },

            { x: 104, y: 18, width: 51, height: 49 },
            { x: 155, y: 18, width: 95, height: 49 },
            { x: 412, y: 18, width: 95, height: 49 },
            { x: 507, y: 18, width: 51, height: 49 }
        ]
    }
};
</script>

<style lang="scss" scoped>
.HeartGraph {
}
.canvas {
    display: block;
}
</style>
