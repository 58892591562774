import { getCategoryIdFromLifeskillId } from '@/utils'
import categories from '@/categories'

const languageIds = ['norwegian', 'german', 'french', 'italian', 'portuguese', 'spanish', 'english']
const essentialLifeskillIds = ['00', '01', '80', '88', '42', '37', '50', '44', '48', '87', '45', '10', '02', '11', '70', '74', '69'] // Lifeskills related to the 16 steps in the book

export default {
  serversideGitRevision: process.env.GIT_REVISION,
  clientsideGitRevision: process.env.GIT_REVISION,
  time: {
    startOfCurrentMinute: 0,
    startOfCurrentDay: 0,
    endOfCurrentDay: 0,
    startOfCurrentWeek: 0,
    endOfCurrentWeek: 0,
    startOfCurrentMonth: 0,
    endOfCurrentMonth: 0
  },
  loadStatus: {
    didLoadAllVideos: false
  },
  availableFavorateLifeskillIds: ['00', '01', '02', '03', '10', '11', /* '12', */ '14', '18', '22', '25', '37', '40', '41', '42', '44', '50', '80', '86', '87', '88'],
  videos: {
  },
  videos_in_progress: [],
  memolife: {
    systemExitTo: '/',
    gameExitTo: '/',
    initiallyOpenNotificationDropdown: false,
    practicePanelViewMode: null, // 'byHabitJourneys', // Expected values: 'byLearningSteps' and  'byHabitJourneys'

    access: false,
    appLanguage: null, // NOTE: assigned during app startup
    categories: categories.map(c => c.id),
    dailyRequiredBraindates: 3,
    dailyRequiredBraindatesNotSetByUser: true,

    didSelectFavoriteLifeskills: false,
    didShowDashboardOnboarding: false,
    didShowGetStartedPopup: false,
    didShowMemoOnboardingPopup: false,
    didShowMemomapsOnboardingPopup: false,
    didExitGettingStartedFocus: false,
    autoplayVideoParts: false,
    whatsNewViewedArticles: [],

    mission: '',
    selectedHabitMissions: [],
    teamSectionUnlocked: false,
    selectedTeamId: '0',
    teamsList: [],
  },
  videosPage: {
    selectedVideoId: null,
    selectedCategoryId: null,
    selectedLifeskillId: null
  },
  lifeskillHideMap: {
    '32': true,
    '10': true,
    '74': true,
    '45': true,
    '69': true
  },
  packages: {
    'essentials': {
      lifeskillIds: ['32', '40', '02', '10', '44', '50', '42', '88'],
      fullAccess: true,
      showBrainTraining: true
    },
    'selfmanagement': {
      lifeskillIds: ['01', '37', '80', '48', '03', '70', '87', '74', '45', '69']
    },
    'allroundknowledge': {
      lifeskillIds: ['11', '00', '18', '25']
    }
  },
  memolanguage: { // TODO: should only be in moduleMemolanguage
    availableLanguages: languageIds
  },
  languages: (function () {
    let map = {}
    languageIds.forEach(languageId => {
      map[languageId] = {
        id: languageId,
        repetitions: {}
      }
    })
    return map
  }()),
  categories: (function () {
    let map = {}
    categories.forEach(category => {
      map[category.id] = {
        lifeskills: category.lifeskillIds
      }
    })
    return map
  }()),

  lifeskillsList: [],

  lifeskills: (function () {
    let map = {}
    for (let i = 0; i < 100; i++) {
      let lifeskillId = i < 10 ? `0${i}` : `${i}`
      map[lifeskillId] = {
        id: lifeskillId,
        categoryId: getCategoryIdFromLifeskillId(lifeskillId),
        // related videos should be loaded from server
        relatedVideos: [], // ['vid1', 'vid2'],
        numberOfRelatedVideos: 0,
        selectedVideoId: null
      }
    }
    // map['00'].metaSkill = true
    map['09'].metaSkill = true
    map['36'].metaSkill = true

    essentialLifeskillIds.forEach(id => {
      map[id].isEssential = true
    })
    return map
  }()),
  followedLifeskills: [],
  memostreak: 0,
  braindates: [],
  lifekeys: [],
  recommendedVideos: [],
  // braindates: {
  //   '{}': 'dummy' // NOTE: somehow needed to make braindates reactive the first time a key is set
  // },

  // Should not persist in puchdb
  localState: {
    showDebugButton: false
  },

  debugSettings: {
    useQuizCheatMode: false,
    debugButtonPosition: { y: 0.5, x: 0 },
    noFullscreenReadOnMobile: false,
    noActivityTransitions: false,
    doNotStartQuizAutomatically: false
  }
}
