/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'play-button': {
    width: 32,
    height: 33,
    viewBox: '0 0 32 33',
    data: '<path pid="0" d="M.096 32.416V.703L31.81 16.56.096 32.416z" _fill="#fff"/>'
  }
})
