import { render, staticRenderFns } from "./GameBankvaultQuizViewDebug.vue?vue&type=template&id=b28eaf22&scoped=true&"
import script from "./GameBankvaultQuizViewDebug.vue?vue&type=script&lang=js&"
export * from "./GameBankvaultQuizViewDebug.vue?vue&type=script&lang=js&"
import style0 from "./GameBankvaultQuizViewDebug.vue?vue&type=style&index=0&id=b28eaf22&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b28eaf22",
  null
  
)

export default component.exports