<template>
  <div
    class="BlockContainer"
    :class="{ collapsed }">
    <div
      class="header"
      @click="toggleExpand()">
      <svgicon
        class="toggleExpandIcon"
        icon="chevron-down" />
      <slot name="header">
        <span class="blockType">{{ type }}</span>
                &nbsp;
        <span class="blockLabel">{{ label }}</span>
        <span
          v-if="progress !== undefined"
          class="blockProgress">{{ progress.toFixed(2) }}</span>
      </slot>
    </div>
    <div
      v-if="!collapsed"
      class="body">
      <slot />
    </div>
  </div>
</template>

<script>
import storage from '@/store/persistence/storage';

const EXPANDED_BLOCKS_MAP_KEY = 'memolife-debug-expanded-blocks-map';
let expandedBlocksMap = null;

function getExpandedBlocksMap() {
    if (!expandedBlocksMap) {
        try {
            expandedBlocksMap = JSON.parse(storage.getItem(EXPANDED_BLOCKS_MAP_KEY)) || {};
        } catch (err) {
            expandedBlocksMap = {};
        }
    }
    return expandedBlocksMap;
}
function setExpandedBlocksMap(xxx) {
    storage.setItem(EXPANDED_BLOCKS_MAP_KEY, JSON.stringify(xxx));
}

function getCollapsedState(key) {
    return !getExpandedBlocksMap()[key];
}

function setCollapsedState(key, value) {
    const expandedBlocksMap = getExpandedBlocksMap();
    expandedBlocksMap[key] = !value;
    setExpandedBlocksMap(expandedBlocksMap);
}

export default {
    props: {
        type: {
            type: String
        },
        label: {
            type: String
        },
        progress: {
            type: Number
        }
    },
    data() {
        return {
            collapsed: getCollapsedState(this.label)
        };
    },
    methods: {
        toggleExpand() {
            this.collapsed = !this.collapsed;
            setCollapsedState(this.label, this.collapsed);
        }
    }
};
</script>

<style lang="scss" scoped>
.BlockContainer {
    border-radius: 0.5em;
    border: 1px solid rgba(black, 0.2);
    overflow: hidden;

    &.collapsed {
        .toggleExpandIcon {
            transform: rotate(-90deg);
        }
    }
}
.header {
    background-color: rgba(black, 0.2);
    color: black;
    padding: 0.3em 1em;
    display: flex;
}
.blockProgress {
    margin-left: 1em;
    flex: 1;
    text-align: right;
}
.toggleExpandIcon {
    $size: 1em;
    width: $size;
    height: $size;
    fill: rgba(black, 0.2);
    margin-right: 0.5em;
}
.blockType {
    font-weight: bold;
    text-transform: capitalize;
}
.body {
    padding: 1em 1em;
}
</style>
