<template>
  <transition name="modal">
    <div
      v-if="modalSession"
      class="DropdownHost">
      <div
        class="mask"
        @click="modalClose('cancel')" />
      <div
        ref="sizerLeft"
        class="sizer sizer--left" />

      <transition
        name="xxx"
        appear
        mode="out-in"
        @enter="enter">
        <div
          ref="dropdownFrame"
          :key="modalSessionId"
          class="DropdownFrame">
          <div class="DropdownFrame__clip">
            <component
              :is="modalSession.component"
              class="component"
              v-bind="modalSession.props"
              @signal="modalClose"
              @close="modalClose" />
          </div>
        </div>
      </transition>

      <div
        ref="sizerRight"
        class="sizer sizer--right" />

      <transition
        name="xxx"
        appear
        mode="out-in">
        <div
          :key="modalSessionId"
          class="DropdownFrame__funnnelAnchor"
          :style="funnelStyle">
          <div class="DropdownFrame__funnel" />
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import Vue from 'vue';
import { ModalHostMixin } from '@/plugins/modal-helper';

export default {
    components: {},
    mixins: [ModalHostMixin],
    data() {
        return {
            funnelStyle: null
        };
    },
    mounted() {
        window.addEventListener('resize', this.updateLayout);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateLayout);
    },
    methods: {
        enter(el, done) {
            Vue.nextTick(() => {
                this.updateLayout();
                done();
            });
        },
        updateLayout() {
            if (this.modalSession) {
                const selfBB = this.$el.getBoundingClientRect();
                const targetElem = this.modalSession.hostOptions.pointAt;
                const targetElemBB = targetElem.getBoundingClientRect();
                const targetElemCenterX = targetElemBB.left + targetElemBB.width / 2;

                this.$refs.sizerLeft.style.flexBasis = `${Math.max(
                    selfBB.width - (selfBB.right - targetElemCenterX) * 2,
                    0
                )}px`;
                this.$refs.sizerRight.style.flexBasis = `${Math.max(
                    selfBB.width - (targetElemCenterX - selfBB.left) * 2,
                    0
                )}px`;

                this.funnelStyle = {
                    left: `${targetElemCenterX - selfBB.left}px`
                };

                if (this.modalSession.hostOptions.autoPlacement) {
                    this.$refs.dropdownFrame.style.marginTop = `${targetElemBB.bottom}px`;
                    this.funnelStyle.top = `${targetElemBB.bottom}px`;
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
$transitionDuraction: 0.3s;
$backgroundColor: white;
$funnelHeight: 0.8em;

.DropdownHost {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;

    &.modal-enter-active,
    &.modal-leave-active {
        transition: opacity $transitionDuraction ease;
    }
    &.modal-enter,
    &.modal-leave-active {
        .mask {
            opacity: 0;
        }
    }

    &.modal-leave-active {
        .DropdownFrame,
        .DropdownFrame__funnnelAnchor {
            transform: translate3d(0, 100px, 0);
            opacity: 0;
        }
    }
}

.mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: opacity $transitionDuraction ease;
}

.sizer {
    flex: 1;
}

.DropdownFrame {
    position: relative;
    transition: transform $transitionDuraction ease, opacity $transitionDuraction;
    pointer-events: none;
    padding-top: $funnelHeight;

    display: flex;
    flex-direction: column;
    margin-top: $topBarHeight - $funnelHeight; // NOTE: default value
    padding-left: 2em;
    padding-right: 2em;
    margin-bottom: 1em;
    max-height: calc(100vh - #{$topBarHeight + 2em});
    max-height: calc(var(--vh, 1vh) * 100 - #{$topBarHeight + 2em});
}

.DropdownFrame__funnnelAnchor {
    position: absolute;
    top: $topBarHeight - $funnelHeight; // NOTE: default value
    transition: transform $transitionDuraction ease, opacity $transitionDuraction;
}
.DropdownFrame__funnel {
    pointer-events: all;
    $width: 1.4em;
    $height: $funnelHeight;
    width: $width;
    position: relative;
    left: -$width / 2;
    border-left: $width / 2 solid transparent;
    border-right: $width / 2 solid transparent;
    border-bottom: $height solid $backgroundColor;
}
.DropdownFrame__clip {
    background-color: $backgroundColor;
    position: relative;
    border-radius: 0.5em;
    // overflow: hidden;
    box-shadow: 0 0 1em rgba(black, 0.3);
    pointer-events: all;

    // NOTE: Needed for Safari, to prevent height of child from overflowing
    display: flex;
}

.DropdownFrame,
.DropdownFrame__funnnelAnchor {
    &.xxx-enter,
    &.xxx-leave-active {
        transform: translate3d(0, 100px, 0);
        opacity: 0;
    }
}

.component {
    position: relative;
    max-height: 100%; // NOTE: Needed for scrolling inside component
    max-width: 100%;
}

@include media('<mobile') {
    .DropdownFrame {
        padding-left: 1em;
        padding-right: 1em;
        margin-bottom: 1em;
        width: 100%;
    }
}
@include media('>=mobile', 'height>600px') {
    .DropdownFrame {
        max-height: calc(600px - #{$topBarHeight + 2em});
    }
}
</style>
