/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'heart-broken': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M23.6 2c4.637 0 8.4 3.764 8.4 8.401 0 9.132-9.87 11.964-15.999 21.232C9.516 22.307 0 19.834 0 10.401 0 5.764 3.763 2 8.4 2c1.886 0 3.625.86 5.025 2.12L11 8l7 4-4 10 11-12-7-4 1.934-2.901C21.041 2.419 22.284 2 23.599 2z"/>'
  }
})
