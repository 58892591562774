<template>
  <div class="GameBankvaultQuizViewDebug">
    GameBankvaultQuizViewDebug
    <ButtonPlain
      label="fillAllDigits"
      @click="dbgFillAllDigits()" />
  </div>
</template>

<script>
import ButtonPlain from '../components/ButtonPlain';

export default {
    components: { ButtonPlain },
    props: ['instance'],
    methods: {
        dbgFillAllDigits() {
            const instance = this.instance;
            const sourceItems = instance.session.quiz.sourceItems;
            instance.items.forEach((item, itemIndex) => {
                const xx = sourceItems[itemIndex].data;
                const fasit = [];
                if (xx.ring1) {
                    fasit.push(xx.ring1);
                }
                if (xx.ring2) {
                    fasit.push(xx.ring2);
                }
                if (xx.inner1) {
                    fasit.push(xx.inner1[0]);
                    fasit.push(xx.inner1[1]);
                }
                if (xx.inner2) {
                    fasit.push(xx.inner2[0]);
                    fasit.push(xx.inner2[1]);
                }
                item.value.forEach((digit, digitIndex) => {
                    if (digit === '-') {
                        instance.module.dispatch('s/quiz/setDigit', {
                            itemIndex,
                            digitIndex,
                            value: fasit[digitIndex]
                        });
                    }
                });
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.GameBankvaultQuizViewDebug {
    padding: 1em;
}
</style>
