import { render, staticRenderFns } from "./TabsWithSlidingIndicator.vue?vue&type=template&id=38366c6c&scoped=true&"
import script from "./TabsWithSlidingIndicator.vue?vue&type=script&lang=js&"
export * from "./TabsWithSlidingIndicator.vue?vue&type=script&lang=js&"
import style0 from "./TabsWithSlidingIndicator.vue?vue&type=style&index=0&id=38366c6c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38366c6c",
  null
  
)

export default component.exports