/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'getting-started-icons/masterquiz': {
    width: 50,
    height: 82,
    viewBox: '0 0 50 82',
    data: '<g _fill="#5081FF" fill-rule="nonzero"><path pid="0" d="M6.32 62.534l3.818 3.659a1.124 1.124 0 001.542 0L18 60.137l6.32 6.056c.212.204.491.306.77.306.28 0 .56-.102.772-.306l3.818-3.66a1.014 1.014 0 000-1.477l-6.32-6.057 6.32-6.056a1.014 1.014 0 000-1.478l-3.818-3.659a1.124 1.124 0 00-1.542 0L18 49.862l-6.32-6.056a1.124 1.124 0 00-1.542 0L6.32 47.465a1.014 1.014 0 000 1.478l6.319 6.056-6.32 6.056a1.015 1.015 0 000 1.479zm8.633-6.795a1.014 1.014 0 000-1.479l-6.32-6.056 2.276-2.18 6.32 6.056a1.124 1.124 0 001.542 0l6.32-6.056 2.275 2.18-6.32 6.056a1.014 1.014 0 000 1.478l6.32 6.057-2.275 2.18-6.32-6.056a1.112 1.112 0 00-.771-.306c-.28 0-.559.102-.772.306l-6.319 6.056-2.275-2.18 6.32-6.056z"/><path pid="1" d="M40.885 57.812a1.097 1.097 0 00-1.533 0L25.474 71.457l-6.826-6.712a1.097 1.097 0 00-1.533 0l-3.797 3.733a1.053 1.053 0 000 1.509l11.39 11.2c.211.208.489.312.766.312.278 0 .556-.104.768-.312l18.44-18.133a1.053 1.053 0 000-1.508l-3.797-3.734zm-15.41 21.112l-9.856-9.691 2.262-2.225 6.826 6.712c.424.417 1.111.417 1.534 0l13.877-13.645 2.263 2.225-16.907 16.624z"/><g><path pid="2" d="M3.017 10.969l6.08 25.503c.127.578.703 1.028 1.28 1.028H39.621c.64 0 1.152-.45 1.28-1.028l6.08-25.503c.128-.514-.448-.964-.896-.642L34.95 17.586c-.256.192-.576.128-.768-.129l-8.704-9.764a.617.617 0 00-.896 0l-8.703 9.764a.679.679 0 01-.768.129l-11.135-7.26a.617.617 0 00-.96.643z"/><circle pid="3" cx="2.5" cy="6" r="2.5"/><circle pid="4" cx="25.5" cy="3" r="2.5"/><circle pid="5" cx="47.5" cy="6" r="2.5"/></g></g>'
  }
})
