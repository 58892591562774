<template>
  <div class="root">
    <div>MemorizeView</div>
    <br>
    <ButtonPlain
      label="Start quiz"
      @click="startQuiz()" />
  </div>
</template>

<script>
import ButtonPlain from '../components/ButtonPlain';

export default {
    components: { ButtonPlain },
    props: ['instance'],
    methods: {
        startQuiz() {
            const vm = this.instance;
            vm.$router.push(`../quiz/${vm.itemGroup.index}`);
            this.$emit('request-close');
        }
    }
};
</script>

<style lang="scss" scoped>
.root {
    padding: 1em;
}
</style>
