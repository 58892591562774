import moment from 'moment';
import ability from '@/ability';

export default {
    memoIdsInStep: (state ) => stepId => {
        return Object.values(state.memos)
            .filter(m => m.stepId === stepId)
            .map(m => m.id);
    },

    inProgressItems(state, getters) {
        const items = [];
        state.courseIds.forEach(courseId => {
            const courseStats = getters.getCourseStats(courseId);
            if (!courseStats) {
                return; // Probably caused by vuex plugin
            }
            if (courseStats.theoryProgress > 0 && courseStats.theoryProgress < 1) {
                items.push({
                    type: 'course',
                    courseId,
                    firstActiveJourneyId: courseStats.firstActiveJourneyId,
                    firstActiveStepId: courseStats.firstActiveStepId,
                    latestActivity: courseStats.latestTheoryActivity || 0
                });
            }
        });
        return items.sort((a, b) => moment(b.latestActivity).diff(moment(a.latestActivity)));
    },

    courseIds: state => {
        return state.courseIds;
    },

    courseByLifeskillId: state => lifeskillId => {
        const coursesMap = state.courses;
        const courses = state.courseIds.map(courseId => coursesMap[courseId]);

        return courses.find(course => course.lifeskillNumber === lifeskillId);
    },

    getStepById: state => stepId => {
        return state.steps[stepId];
    },

    getJourneyById: state => journeyId => {
      return state.stepGroups[journeyId];
    },

    getCourseById: state => courseId => {
      return state.courses[courseId];
    },

    independentCourseIds(state) {
        const coursesMap = state.courses;
        const courses = state.courseIds.map(courseId => coursesMap[courseId]);
        return (
            courses
                .filter(course => !course.lifeskillNumber)
                .map(course => course.id)
                .filter(courseId => ability.can('use', { modelName: 'Course', courseId }))
        );
    },

    getCourseStats: (state, getters) => courseId => {
        const course = state.courses[courseId];
        if (!course) {
            return; // Probably caused by vuex plugin
        }

        let stats = {
            totalTheoryBraindates: 0,
            totalPracticeBraindates: 0,

            acquiredTheoryBraindates: 0,
            acquiredPracticeBraindates: 0,
            numberOfDonePracticeJourneys: 0,
            numberOfPracticeJourneys: 0,

            scenariosDoneCount: 0,
            scenariosTotalCount: 0,
            numberOfScenarios: 0,
            numberOfDonePracticeSteps: 0,
            numberOfPracticeSteps: 0,

            numberOfSteps: 0,
            numberOfDoneSteps: 0,

            accumulatedStepCount: 0,
            accumulatedStepProgress: 0
        };

        let latestTheoryActivity = 0;
        let firstActiveJourneyId = null;
        let firstActiveStepId = null;

        let totalTheoryLifekeys = 0;
        let acquiredTheoryLifekeys = 0;
        let totalPracticeLifekeys = 0;
        let acquiredPracticeLifekeys = 0;

        course.stepGroupIds.forEach(stepGroupId => {
            const stepGroupStats = getters.getStepGroupStats(stepGroupId);
            if (!firstActiveJourneyId && stepGroupStats.theoryProgress >= 0 && stepGroupStats.theoryProgress < 1){
                firstActiveJourneyId = stepGroupId;
                firstActiveStepId = stepGroupStats.firstActiveStepId;
            } 
            
            Object.keys(stats).forEach(key => {
                stats[key] += stepGroupStats[key] || 0;
            });
            latestTheoryActivity = Math.max(latestTheoryActivity, stepGroupStats.latestTheoryActivity);

            totalTheoryLifekeys++;
            if (stepGroupStats.theoryProgress === 1) {
                acquiredTheoryLifekeys++;
            }
            if (stepGroupStats.numberOfScenarios > 0) {
                totalPracticeLifekeys++;
                if (stepGroupStats.numberOfScenarios === stepGroupStats.scenariosDoneCount) {
                    acquiredPracticeLifekeys++;
                }
            }
        });

        if (course.introStepId) {
            const intoStep = state.steps[course.introStepId];
            intoStep.activityNames
                .map(activityName => intoStep[activityName])
                .forEach(activity => {
                    stats.totalTheoryBraindates++;
                    if (activity.progress === 1) {
                        stats.acquiredTheoryBraindates++;
                    }
                });
        }

        if (firstActiveJourneyId) {
            stats.firstActiveJourneyId = firstActiveJourneyId;
            stats.firstActiveStepId = firstActiveStepId;
        }

        stats.totalTheoryLifekeys = totalTheoryLifekeys;
        stats.acquiredTheoryLifekeys = acquiredTheoryLifekeys;
        stats.totalPracticeLifekeys = totalPracticeLifekeys;
        stats.acquiredPracticeLifekeys = acquiredPracticeLifekeys;

        stats.theoryProgress = stats.accumulatedStepCount
            ? stats.accumulatedStepProgress / stats.accumulatedStepCount
            : 0;
        stats.latestTheoryActivity = latestTheoryActivity;
        return stats;
    },

    getStepGroupQuizState: state => stepGroupId => {
        return state.stepGroupQuizStates[stepGroupId] || null;
    },

    stepGroupIds: state => {
        return Object.keys(state.stepGroups);
    },

    getStepGroupStats: (state, getters, rootState, rootGetters) => stepGroupId => {
        const stepGroup = state.stepGroups[stepGroupId];
        const steps = stepGroup.stepIds.map(stepId => state.steps[stepId]);

        let firstActiveStepId = null;
        let scenariosDoneCount = 0;
        let totalTheoryBraindates = 0;
        let acquiredTheoryBraindates = 0;

        let latestTheoryActivity = 0;
        let accumulatedStepProgress = 0; // Includes quiz
        let accumulatedStepCount = 0; // Includes quiz

        let numberOfSteps = 0;
        let numberOfDoneSteps = 0;
        let numberOfCompletedSteps = 0; // Note: can be fractions
        let numberOfScenarios = 0;
        let numberOfDonePracticeSteps = 0;
        let numberOfPracticeSteps = 0;
        let numberOfDonePracticeJourneys = 0;
        let numberOfPracticeJourneys = 0;

        steps.forEach(step => {
            accumulatedStepProgress += Math.min(step.progress, 1); // NOTE: avoiding possible bug with min
            accumulatedStepCount += 1;

            numberOfCompletedSteps += step.progress;
            if (!step.isIntro) {
                numberOfSteps++;
                if (step.progress === 1) {
                    numberOfDoneSteps += 1;
                }
            }

            if (!firstActiveStepId && step.progress < 1) {
                firstActiveStepId = step.id;
            }

            step.activityNames
                .map(activityName => step[activityName])
                .forEach(activity => {
                    totalTheoryBraindates++;
                    if (activity.progress === 1) {
                        acquiredTheoryBraindates++;
                    }
                    latestTheoryActivity = Math.max(latestTheoryActivity, activity.lastUpdated);
                });

            if (step.scenarioIds) {
              let scenariosDoneCountForStep = step.scenarioIds
                .filter(sid => rootGetters['scenario/isDone'](sid))
                .length;
              scenariosDoneCount += scenariosDoneCountForStep;

              numberOfScenarios += step.scenarioIds.length;
              if (step.scenarioIds.length) {
                numberOfPracticeSteps++;
              }
              if (scenariosDoneCountForStep === step.scenarioIds.length) {
                numberOfDonePracticeSteps++;
              }
            }
        });

        if (numberOfPracticeSteps > 0) {
            numberOfPracticeJourneys++;
            if (numberOfPracticeSteps === numberOfDonePracticeSteps) {
                numberOfDonePracticeJourneys++;
            }
        }

        totalTheoryBraindates++;
        if (stepGroup.quiz) {
            accumulatedStepCount++;
            if (stepGroup.quiz.done) {
                acquiredTheoryBraindates++;
                // acquiredTheoryLifekeys++ // NOTE: in normal circumstances, totalTheoryBraindates === acquiredTheoryBraindates at this point
                accumulatedStepProgress = accumulatedStepCount; // NOTE: will make theoryProgress === 1
                latestTheoryActivity = Math.max(latestTheoryActivity, stepGroup.quiz.done);
            }
        }

        return {
            // Theory:

            totalTheoryBraindates,
            acquiredTheoryBraindates,

            numberOfSteps,
            numberOfDoneSteps,

            quizUnlockProgress: numberOfCompletedSteps / stepGroup.stepIds.length,

            accumulatedStepCount,
            accumulatedStepProgress,
            theoryProgress: accumulatedStepCount ? accumulatedStepProgress / accumulatedStepCount : 0,
            latestTheoryActivity,

            firstActiveStepId,

            // Practice:

            totalPracticeBraindates: numberOfScenarios,
            acquiredPracticeBraindates: scenariosDoneCount,
            scenariosDoneCount,
            scenariosTotalCount: numberOfScenarios,
            numberOfDonePracticeSteps,
            numberOfPracticeSteps,
            numberOfDonePracticeJourneys,
            numberOfPracticeJourneys,
            numberOfScenarios
        };
    }
};
