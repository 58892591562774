/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'trophy2': {
    width: 160,
    height: 160,
    viewBox: '0 0 160 160',
    data: '<path pid="0" d="M102.095 98.691A118.1 118.1 0 0193.2 112.4a3.993 3.993 0 01-3.203 1.6 4 4 0 01-3.197-6.4c12.249-16.331 23.606-38.416 24.178-88.6H49.022c.572 50.185 11.929 72.269 24.178 88.6a4 4 0 01-6.4 4.8 118.1 118.1 0 01-8.895-13.709C37.371 97.598 21 80.55 21 59.749c0-5.169.996-10.192 2.961-14.929a38.846 38.846 0 018.462-12.648 4 4 0 015.657 5.657A30.89 30.89 0 0029 59.749c0 14.903 10.572 27.38 24.61 30.334C44.799 70.372 41 46.884 41 15a4 4 0 014-4h70a4 4 0 014 4c0 31.884-3.799 55.372-12.61 75.083C120.428 87.129 131 74.651 131 59.749a30.8 30.8 0 00-2.351-11.864 30.838 30.838 0 00-6.729-10.055 4 4 0 115.657-5.657 38.817 38.817 0 018.463 12.648 38.775 38.775 0 012.961 14.929c-.001 20.8-16.372 37.848-36.906 38.941zM51 145a4 4 0 004 4h50a4 4 0 000-8H55a4 4 0 00-4 4zm44-16a4 4 0 000-8H65a4 4 0 000 8h30z"/>'
  }
})
