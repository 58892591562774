<template>
  <div class="GameBankvaultMemorizeViewDebug">
    GameBankvaultMemorizeViewDebug
    <div class="dbgDisplay">
      <div>ring1: {{ instance.currentItem.data.ring1 }}</div>
      <div>ring2: {{ instance.currentItem.data.ring2 }}</div>
      <div>inner1: {{ instance.currentItem.data.inner1 }}</div>
      <div>inner2: {{ instance.currentItem.data.inner2 }}</div>
      <br><br>
    </div>
    <ButtonPlain
      label="startQuiz"
      @click="instance.transition('START_QUIZ')" />
  </div>
</template>

<script>
import ButtonPlain from '../components/ButtonPlain';

export default {
    components: { ButtonPlain },
    props: ['instance']
};
</script>

<style lang="scss" scoped>
.GameBankvaultMemorizeViewDebug {
    padding: 1em;
}
</style>
