/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'activities/read': {
    width: 33,
    height: 21,
    viewBox: '0 0 33 21',
    data: '<path pid="0" d="M16.597 0C6.18 0 0 10.5 0 10.5S6.181 21 16.597 21C26.807 21 33 10.5 33 10.5S26.806 0 16.597 0zm.27 3c4.085 0 7.333 3.321 7.333 7.5S20.952 18 16.867 18c-4.086 0-7.334-3.321-7.334-7.5S12.781 3 16.867 3zm0 3.751c-2.42 0-4.4 1.856-4.4 4.125 0 2.268 1.98 4.124 4.4 4.124 2.42 0 4.4-1.856 4.4-4.124 0-.62-.125-1.129-.345-1.644-.33.412-.756.612-1.306.612-.88 0-1.65-.721-1.65-1.546 0-.516.213-.916.653-1.225a4.795 4.795 0 00-1.753-.323v.001z" _fill="#111" fill-rule="nonzero"/>'
  }
})
