import apolloClient from '@/apollo-client';

import allMemoransierGamesGql from './graphql/allMemoransierGames.gql';
import createMemoransierGameGql from './graphql/createMemoransierGame.gql';
import updateMemoransierGameGql from './graphql/updateMemoransierGame.gql';
import deleteMemoransierGameGql from './graphql/deleteMemoransierGame.gql';
import allMemoransierGamePlayersGql from './graphql/allMemoransierGamePlayers.gql';
import createMemoransierGamePlayerGql from './graphql/createMemoransierGamePlayer.gql';
import updateMemoransierGamePlayerGql from './graphql/updateMemoransierGamePlayer.gql';
import allMemoransierLeaderboardsGql from './graphql/allMemoransierLeaderboards.gql';

export default {
    namespaced: true,
    state() {
        return {
            games: [] // {}
        };
    },
    actions: {
        async refresh({ dispatch }) {
            await dispatch('fetchAll');
        },
        async fetchAll({ state, commit }) {
            const response = await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: allMemoransierGamesGql
            });
            commit('setGames', response.data.memoransierGamesList);
        },
        async getLeaderboard({ dispatch }, gameId) {
            const response = await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: allMemoransierLeaderboardsGql,
                variables: {
                    gameId
                }
            });
            return response.data.memoransierLeaderboardsList;
        },
        async getGamePlayers({ dispatch }, gameId) {
            const response = await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: allMemoransierGamePlayersGql,
                variables: {
                    gameId
                }
            });
            return response.data.memoransierGamePlayersList;
        },

        async createGame({ commit, rootGetters }, { name, role, options, teamId }) {
            const result = await apolloClient.mutate({
                mutation: createMemoransierGameGql,
                variables: {
                    name,
                    role,
                    options: options,
                    owner: rootGetters['moduleAuth/profile']._id,
                    startedAt: null,
                    teamId
                }
            });
            commit('addGame', result.data.createMemoransierGame.memoransierGame);
        },

        async deleteGame({ dispatch }, gameId) {
            await apolloClient.mutate({
                mutation: deleteMemoransierGameGql,
                variables: {
                    id: gameId
                }
            });
            await dispatch('refresh');
        },
        async startGame({ dispatch }, gameId) {
            await apolloClient.mutate({
                mutation: updateMemoransierGameGql,
                variables: {
                    id: gameId,
                    running: true,
                    startedAt: new Date().toUTCString()
                }
            });
            await dispatch('refresh');
        },
        async endGame({ dispatch }, gameId) {
            await apolloClient.mutate({
                mutation: updateMemoransierGameGql,
                variables: {
                    id: gameId,
                    running: false
                }
            });
            await dispatch('refresh');
        },
        async publishGame({ dispatch }, gameId) {
            await apolloClient.mutate({
                mutation: updateMemoransierGameGql,
                variables: {
                    id: gameId,
                    published: true
                }
            });
            await dispatch('refresh');
        },
        async unpublishGame({ dispatch }, gameId) {
            await apolloClient.mutate({
                mutation: updateMemoransierGameGql,
                variables: {
                    id: gameId,
                    published: false
                }
            });
            await dispatch('refresh');
        },
        async revealLeaderboard({ dispatch }, gameId) {
            await apolloClient.mutate({
                mutation: updateMemoransierGameGql,
                variables: {
                    id: gameId,
                    leaderboardReveal: true
                }
            });
            await dispatch('refresh');
        },
        async joinGame({ dispatch, rootState }, gameId) {
            await apolloClient.mutate({
                mutation: createMemoransierGamePlayerGql,
                variables: {
                    gameId,
                    userId: rootState.moduleAuth.profile._id
                }
            });

            await dispatch('refresh');
        },
        async updatePlayer({ dispatch, rootState }, { gameId, completed, time, correct }) {
            await apolloClient.mutate({
                mutation: updateMemoransierGamePlayerGql,
                variables: {
                    gameId,
                    userId: rootState.moduleAuth.profile._id,
                    time,
                    completed,
                    correct
                }
            });
        }
    },

    mutations: {
        setGames(state, games) {
          state.games = games.map(g => {
            let options = g.options;
            if (typeof g.options === 'string') {
              options = JSON.parse(g.options)
            }
            return {
              ...g,
              options,
          }})
        },

      addGame(state, game) {
        state.games.push(game);
      }
    },

    getters: {
        publishedGames(state, getters, rootState) {
            return state.games.filter(game => {
                return game.published && rootState.moduleAuth.profile.roles.indexOf(game.role) !== -1;
            });
        },
        games(state) {
            return state.games;
        }
    }
};
