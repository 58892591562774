/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-left': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M12.586 27.414l-10-10a2 2 0 010-2.828l10-10a2 2 0 112.828 2.828L8.828 14H28a2 2 0 110 4H8.828l6.586 6.586c.39.39.586.902.586 1.414s-.195 1.024-.586 1.414a2 2 0 01-2.828 0z"/>'
  }
})
