/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'carousel-arrow-left': {
    width: 6,
    height: 12,
    viewBox: '0 0 6 12',
    data: '<path pid="0" d="M5.772 10.102a.85.85 0 010 1.158.75.75 0 01-1.1 0L.228 6.58a.85.85 0 010-1.16L4.672.74a.75.75 0 011.1 0 .85.85 0 010 1.158L1.876 6.002l3.896 4.1z" _fill="#5983F7"/>'
  }
})
