import Vue from 'vue';

export default {
    setSession(state, payload) {
        state.session = payload;
    },
    setNameUserstate(state, { nameId, data }) {
        Vue.set(state.namesUserstate, nameId, data);
    },
    setNamesUserstate(state, data) {
        state.namesUserstate = data;
    },
    clearNamesUserstate(state) {
        state.namesUserstate = {};
    }
};
