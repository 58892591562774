export default {
    setQuestionServiceState(state, data) {
        state.questionServiceState = data;
    },
    updateQuestionServiceState(state, data) {
        Object.assign(state.questionServiceState, data);
    },

    updateLevelUserstate(state, { levelIndex, data }) {
        Object.assign(state.levels[levelIndex], data);
    },
    setLevelsUserstate(state, data) {
        state.levels.forEach(level => {
            const levelUserstate = Object.assign({ stars: 0, bestTime: 0 }, data[level.index]);
            Object.assign(level, levelUserstate);
        });
    }
};
