import { render, staticRenderFns } from "./LifelongPanel.vue?vue&type=template&id=031e1562&scoped=true&"
import script from "./LifelongPanel.vue?vue&type=script&lang=js&"
export * from "./LifelongPanel.vue?vue&type=script&lang=js&"
import style0 from "./LifelongPanel.vue?vue&type=style&index=0&id=031e1562&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "031e1562",
  null
  
)

/* custom blocks */
import block0 from "./LifelongPanel.vue?vue&type=custom&index=0&blockType=translations"
if (typeof block0 === 'function') block0(component)

export default component.exports