<template>
  <PanelBase class="DailyPanel">
    <template
      v-if="showHeader"
      #header
      class="header">
      <div class="title">
        {{ $t('title') }}
      </div>
      <div class="subtitle">
        {{ $t('subtitle') }}
      </div>
    </template>
    <template #body>
      <!-- <div
        class="text"
        v-html="$t('text')"
      /> -->
      <AnimatedBraindate
        ref="animatedBraindate"
        :count="braindateCountToday"
        :goal="requiredDailyBraindates"
        :color="progressColor"
        :live="goalJustReached"
        @done="onAnimationCompleted()" />
    </template>
    <template
      v-if="!hideFooter"
      #footer
      class="footer">
      <div class="streakSection">
        <i18n path="streak">
          <span class="streakLength">{{ streakLength }}</span>
        </i18n>
      </div>
    </template>
  </PanelBase>
</template>

<translations>
  title: Great job
  title_no: Bra gjort
  subtitle: 'You finished your daily goal!'
  subtitle_no: 'Du har nådd dagsmålet ditt'

  text: 'Enjoy your daily<br> brain dates'
  text_no: 'Nyt dine daglige<br> hjernedates'

  streak: 'You are on a {0} day streak'
  streak_no: 'Du  er på en {0} dagers streak'
</translations>

<script>
import { getCategoryIdFromLifeskillId } from '@/utils';
import categoryThemes, { cssVariables } from '@/category-themes';
import PanelBase from './PanelBase';
import AnimatedBraindate from '../AnimatedBraindate';

export default {
    components: { PanelBase, AnimatedBraindate },
    props: {
        showHeader: {
            type: Boolean,
            default: false
        },
        hideFooter: {
            type: Boolean,
            default: false
        },
        goalJustReached: {
            type: Boolean,
            default: false
        },
        lifeskillId: {
            type: String,
            default: ''
        },
        requiredDailyBraindates: {
            type: Number,
            required: true
        },
        braindateCountToday: {
            type: Number,
            required: true
        },
        streakLength: {
            type: Number,
            default: 0
        }
    },
    created() {
        const categoryId = getCategoryIdFromLifeskillId(this.lifeskillId);
        this.progressColor = categoryId ? categoryThemes[categoryId].color : cssVariables.completionColor;
    },
    methods: {
        onAnimationCompleted() {
            this.$emit('animation-completed');
        }
    }
};
</script>

<style lang="scss" scoped>
.DailyPanel {
}
.text {
    color: rgba(black, 0.8);
    text-align: center;
    font-size: 120%;
    line-height: 1.5em;
    margin-bottom: 1em;
}
.AnimatedBraindate {
    width: 60%;
}
</style>
