<template>
  <div
    class="AnimatedNumbershapeSprite"
    :class="{ reversed, inverted, playing: play, paused }"
    :data-numbershape-number="number">
    <div class="number" />
  </div>
</template>

<script>
export default {
    props: {
        number: {
            type: String,
            required: true
        },
        inverted: {
            type: Boolean,
            default: false
        },
        play: {
            type: Boolean,
            default: false
        },
        paused: {
            type: Boolean,
            default: false
        },
        reversed: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style lang="scss" scoped>
$animations: (
    '0': (
        frames: 36,
        old: true
    ),
    '1': (
        frames: 36,
        old: true
    ),
    '2': (
        frames: 36,
        old: true,
        x: -10%,
        y: 0%
    ),
    '3-bra': (
        frames: 47,
        old: true
    ),
    '4': (
        frames: 36,
        old: true
    ),
    '5': (
        frames: 36,
        old: true
    ),
    '6': (
        frames: 36,
        old: true
    ),
    '7': (
        frames: 36,
        old: true
    ),
    '8': (
        frames: 36,
        old: true
    ),
    '9': (
        frames: 36,
        old: true
    ),
    '00': (
        frames: 36,
        old: true
    ),
    '01': (
        frames: 25
    ),
    '02': (
        frames: 36,
        old: true,
        x: 12%,
        y: 0%
    ),
    '03': (
        frames: 36,
        old: true,
        x: 12%,
        y: 0%
    ),
    '04': (
        frames: 2,
        duration: 2s
    ),
    '05': (
        frames: 2,
        duration: 2s
    ),
    '06': (
        frames: 36,
        old: true,
        x: 2%,
        y: 0%
    ),
    '07': (
        frames: 36,
        old: true,
        x: 5%,
        y: -13%
    ),
    '08': (
        frames: 36,
        old: true,
        x: 1%,
        y: -12%
    ),
    '09': (
        frames: 2,
        duration: 2s
    ),
    '10': (
        frames: 20
    ),
    '11': (
        frames: 36,
        old: true
    ),
    '12': (
        frames: 36,
        old: true,
        x: 0%,
        y: 8%
    ),
    '13': (
        frames: 36,
        old: true,
        x: 0%,
        y: -14%
    ),
    '14': (
        frames: 36,
        old: true
    ),
    '15': (
        frames: 36,
        old: true,
        x: 9%,
        y: 0%
    ),
    '16': (
        frames: 36,
        old: true,
        x: 0%,
        y: -8%
    ),
    '17': (
        frames: 36,
        old: true,
        x: -10%,
        y: 0%
    ),
    '18': (
        frames: 47,
        old: true
    ),
    '19': (
        frames: 47,
        old: true,
        x: -8%,
        y: 0%
    ),
    '20': (
        frames: 36,
        old: true,
        x: 6%,
        y: 0%
    ),
    '21': (
        frames: 36,
        old: true,
        x: 7%,
        y: 0%
    ),
    '22': (
        frames: 2,
        duration: 2s
    ),
    '23': (
        frames: 2,
        duration: 2s
    ),
    '24': (
        frames: 36,
        old: true
    ),
    '25': (
        frames: 36,
        old: true,
        x: 0%,
        y: -2%
    ),
    '26': (
        frames: 2,
        duration: 2s
    ),
    '27': (
        frames: 2,
        duration: 2s
    ),
    '28': (
        frames: 36,
        old: true,
        x: 8%,
        y: 8%
    ),
    '29': (
        frames: 36,
        old: true,
        x: 8%,
        y: 8%
    ),
    '30': (
        frames: 47,
        old: true,
        x: -3%,
        y: 0%
    ),
    '31': (
        frames: 36,
        old: true,
        x: -12%,
        y: 6%
    ),
    '32': (
        frames: 2,
        duration: 2s
    ),
    '33': (
        frames: 2,
        duration: 2s
    ),
    '34': (
        frames: 36,
        old: true
    ),
    '35': (
        frames: 36,
        old: true,
        x: -6%,
        y: -9%
    ),
    '36': (
        frames: 36,
        old: true,
        x: 6%,
        y: -6%
    ),
    '37': (
        frames: 47,
        old: true,
        x: -21%,
        y: -14%
    ),
    '38': (
        frames: 36,
        old: true,
        x: 0%,
        y: 6%
    ),
    '39': (
        frames: 36,
        old: true,
        x: 0%,
        y: -2%
    ),
    '40': (
        frames: 36,
        old: true,
        x: 0%,
        y: -9%
    ),
    '41': (
        frames: 36,
        old: true,
        x: 4%,
        y: 2%
    ),
    '42': (
        frames: 63
    ),
    '43': (
        frames: 36,
        old: true
    ),
    '44': (
        frames: 36,
        old: true,
        x: 0%,
        y: 3%
    ),
    '45': (
        frames: 36,
        old: true,
        x: 12%,
        y: -5%
    ),
    '46': (
        frames: 36,
        old: true,
        x: 5%,
        y: 3%
    ),
    '47': (
        frames: 36,
        old: true,
        x: 2%,
        y: -4%
    ),
    '48': (
        frames: 72
    ),
    '49': (
        frames: 36,
        old: true,
        x: 5%,
        y: -6%
    ),
    '50': (
        frames: 36,
        old: true,
        x: 0%,
        y: 5%
    ),
    '51': (
        frames: 36,
        old: true
    ),
    '52': (
        frames: 36,
        old: true,
        x: -4%,
        y: 0%
    ),
    '53': (
        frames: 47,
        old: true,
        x: 1%,
        y: -12%
    ),
    '54': (
        frames: 36,
        old: true,
        x: 2%,
        y: -5%
    ),
    '55': (
        frames: 36,
        old: true
    ),
    '56': (
        frames: 36,
        old: true,
        x: -10%,
        y: 0%
    ),
    '57': (
        frames: 2,
        duration: 2s
    ),
    '58': (
        frames: 36,
        old: true,
        x: -14%,
        y: 2%
    ),
    '59': (
        frames: 47,
        old: true,
        x: 7%,
        y: 2%
    ),
    '60': (
        frames: 36,
        old: true,
        x: 0%,
        y: -17%
    ),
    '61': (
        frames: 36,
        old: true,
        x: 16%,
        y: 5%
    ),
    '62': (
        frames: 36,
        old: true,
        x: 3%,
        y: -4%
    ),
    '63': (
        frames: 2,
        duration: 2s
    ),
    '64': (
        frames: 2,
        duration: 2s
    ),
    '65': (
        frames: 36,
        old: true,
        x: -9%,
        y: 0%
    ),
    '66': (
        frames: 36,
        old: true,
        x: -9%,
        y: -16%
    ),
    '67': (
        frames: 36,
        old: true,
        x: 13%,
        y: 10%
    ),
    '68': (
        frames: 47,
        old: true,
        x: 0%,
        y: 4%
    ),
    '69': (
        frames: 36,
        old: true,
        x: 2%,
        y: -6%
    ),
    '70': (
        frames: 36,
        old: true,
        x: 0%,
        y: -7%
    ),
    '71': (
        frames: 2,
        duration: 2s
    ),
    '72': (
        frames: 36,
        old: true,
        x: 5%,
        y: -1%
    ),
    '73': (
        frames: 36,
        old: true,
        x: -7%,
        y: -13%
    ),
    '74': (
        frames: 2,
        duration: 2s
    ),
    '75': (
        frames: 2,
        duration: 2s
    ),
    '76': (
        frames: 47,
        old: true,
        x: -11%,
        y: -7%
    ),
    '77': (
        frames: 36,
        old: true,
        x: -3%,
        y: -2%
    ),
    '78': (
        frames: 36,
        old: true,
        x: 2%,
        y: 2%
    ),
    '79': (
        frames: 2,
        duration: 2s
    ),
    '80': (
        frames: 49
    ),
    '81': (
        frames: 36,
        old: true
    ),
    '82': (
        frames: 2,
        duration: 2s
    ),
    '83': (
        frames: 2,
        duration: 2s
    ),
    '84': (
        frames: 36,
        old: true,
        x: 1%,
        y: -10%
    ),
    '85': (
        frames: 2,
        duration: 2s
    ),
    '86': (
        frames: 36,
        old: true,
        x: -1%,
        y: 1%
    ),
    '87': (
        frames: 2,
        duration: 2s
    ),
    '88': (
        frames: 72
    ),
    '89': (
        frames: 2,
        duration: 2s
    ),
    '90': (
        frames: 2,
        duration: 2s
    ),
    '91': (
        frames: 36,
        old: true,
        x: 0%,
        y: -2%
    ),
    '92': (
        frames: 36,
        old: true,
        x: 0%,
        y: -10%
    ),
    '93': (
        frames: 36,
        old: true
    ),
    '94': (
        frames: 2,
        duration: 2s
    ),
    '95': (
        frames: 2,
        duration: 2s
    ),
    '96': (
        frames: 36,
        old: true
    ),
    '97': (
        frames: 36,
        old: true,
        x: 2%,
        y: -4%
    ),
    '98': (
        frames: 2,
        duration: 2s
    ),
    '99': (
        frames: 2,
        duration: 2s
    )
);

.AnimatedNumbershapeSprite {
    overflow: hidden;

    &:not(.playing) {
        .number {
            animation: none !important;
        }
        &.reversed {
            .number {
                background-position: right top;
            }
            @each $number, $adjustment in $animations {
                &[data-numbershape-number='#{$number}'] {
                    .number {
                        position: relative;
                        left: map-get($adjustment, x);
                        top: map-get($adjustment, y);
                    }
                }
            }
        }
    }

    &.paused .number {
        animation-play-state: paused;
    }

    $animParamsSeen: ();
    @each $number, $adjustment in $animations {
        $frames: map-get($adjustment, frames);
        $old: map-get($adjustment, old);
        $duration: map-get($adjustment, duration);
        $prePause: 1s;
        $pause: 1s;
        @if not $duration {
            $duration: $frames * 0.07s;
        }
        $totalDuration: $prePause + $duration + $pause;
        $prePauseKey: round($prePause / $totalDuration * 100);
        // NOTE: calc pause key, and adjust further to allow time for last real sprite frame
        $pauseKey: round(((($prePause + $duration) / $totalDuration) - ($duration / $totalDuration / $frames)) * 100);

        $animKey: #{$prePauseKey}-#{$pauseKey};

        &[data-numbershape-number='#{$number}'] {
            .number {
                background-image: url('./numbershapes-small/#{$number}.png');

                animation-name: numbershape-anim-plain-#{$animKey};
                animation-duration: $totalDuration;
                animation-timing-function: steps(#{$frames - 1}, end);
            }

            &.inverted {
                .number {
                    background-image: url('./numbershapes-small-inverted/#{$number}.png');
                }
            }
        }

        @if not map-has-key($animParamsSeen, $animKey) {
            $animParamsSeen: map-merge(
                $animParamsSeen,
                (
                    $animKey: true
                )
            );

            @keyframes numbershape-anim-plain-#{$animKey} {
                0% {
                    background-position: 0px 0;
                }
                #{ $prePauseKey }% {
                    background-position: 0px 0;
                }
                #{ $pauseKey }% {
                    background-position: #{100}% 0;
                }
                100% {
                    background-position: #{100}% 0;
                }
            }
        }
    }
}
.number {
    background-repeat: no-repeat;
    background-position: left top;
    background-size: auto 100%;

    width: 100%;
    height: 100%;

    animation-iteration-count: infinite;
}
</style>
