/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'memo-story-2': {
    width: 49,
    height: 35,
    viewBox: '0 0 49 35',
    data: '<g fill-rule="evenodd"><path pid="0" d="M0 2.5C0 3.88.84 5 1.878 5h17.244C20.159 5 21 3.88 21 2.5S20.16 0 19.122 0H1.878C.841 0 0 1.12 0 2.5zM28 2.5c0 1.38.84 2.5 1.878 2.5h17.244C48.159 5 49 3.88 49 2.5S48.16 0 47.122 0H29.878C28.841 0 28 1.12 28 2.5zM0 17.5C0 18.88.843 20 1.884 20h8.232C11.156 20 12 18.88 12 17.5s-.843-2.5-1.884-2.5H1.884C.844 15 0 16.12 0 17.5zM18 17.5c0 1.38.848 2.5 1.893 2.5h27.214C48.152 20 49 18.88 49 17.5s-.848-2.5-1.893-2.5H19.893C18.848 15 18 16.12 18 17.5zM0 32.5C0 33.88.85 35 1.896 35h12.208C15.15 35 16 33.88 16 32.5s-.85-2.5-1.896-2.5H1.896C.85 30 0 31.12 0 32.5zM22 32.5c0 1.38.85 2.5 1.896 2.5h12.208C37.15 35 38 33.88 38 32.5s-.85-2.5-1.896-2.5H23.896C22.85 30 22 31.12 22 32.5z"/></g>'
  }
})
