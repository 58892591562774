import lifeskillLabels from './lifeskill-labels';
import lifeskillLabelsSoftbreak from './lifeskill-labels-softbreak';

export default {
    general: {
        categoryLabels: {
            brain: 'Brain',
            brain_no: 'Hjerne',

            knowledge: 'Knowledge',
            knowledge_no: 'Kunnskap',

            mind: 'Mind',
            mind_no: 'Sinn',

            body: 'Body',
            body_no: 'Kropp',

            soul: 'Soul',
            soul_no: 'Sjel',

            career: 'Career',
            career_no: 'Karriere',

            leisure: 'Leisure',
            leisure_no: 'Fritid'
        },
        lifeskillLabels,
        lifeskillLabelsSoftbreak,

        showMore: 'Show more',
        showLess: 'Show less',

        upgrade: 'Upgrade',
        upgrade_no: 'Oppgrader',

        step: 'step | steps',
        step_no: 'steg | steg',

        journey: 'journey | journeys',
        journey_no: 'reise | reiser',

        lifekey: 'life key | life keys',
        lifekey_no: 'livsnøkkel | livsnøkler',

        theoryJourney: 'learning journey | learning journeys',
        theoryJourney_no: 'læringsreise | læringsreiser',

        theoryJourneySingular: 'learning journey',
        theoryJourneySingular_no: 'læringsreise',

        habitJourney: 'habit journey | habit journeys',
        habitJourney_no: 'vanereise | vanereiser',

        video: 'video | videos',
        video_no: 'video | videoer',
        braindate: 'brain date | brain dates',
        scenario: 'scenario | scenarios',
        scenario_no: 'scenario | scenarier',
        // groupQuiz: 'group quiz | group quizzes',
        masterQuiz: 'master quiz | master quizzes',
        masterQuiz_no: 'masterquiz | masterquizer',

        belt: 'belt',
        belt_no: 'belte',

        ordinals: {
            o1: 'first',
            o1_no: 'første',
            o2: 'second',
            o2_no: 'andre',
            o3: 'third',
            o3_no: 'tredje',
            o4: 'fourth',
            o4_no: 'fjerde',
            o5: 'fifth',
            o5_no: 'femte',
            o6: 'sixth',
            o6_no: 'sjette',
            o7: 'seventh',
            o7_no: 'syvende',
            o8: 'eighth',
            o8_no: 'åttende',
            o9: 'ninth',
            o9_no: 'niende',
            o10: 'tenth',
            o10_no: 'tiende',
            o11: 'eleventh',
            o11_no: 'ellevte',
            o12: 'twelfth',
            o12_no: 'tolvte',
            o13: 'thirteenth',
            o13_no: 'trettende',
            o14: 'fourteenth',
            o14_no: 'fjortende',
            o15: 'fifteenth',
            o15_no: 'femtende',
            o16: 'sixteenth',
            o16_no: 'sekstende',
            o17: 'seventeenth',
            o17_no: 'syttende',
            o18: 'eighteenth',
            o18_no: 'attende',
            o19: 'nineteenth',
            o19_no: 'nittende',
            o20: 'twentieth',
            o20_no: 'tjuende',
            o21: 'twenty-first',
            o21_no: 'tjueførste',
            o22: 'twenty-second',
            o22_no: 'tjueandre',
            o23: 'twenty-third',
            o23_no: 'tjuetredje'
        },

        beltGrades: {
            grade0: 'trainee',
            grade0_no: 'nybegynner',
            grade1: 'white',
            grade1_no: 'hvitt',
            grade2: 'yellow',
            grade2_no: 'gult',
            grade3: 'orange',
            grade3_no: 'oransje',
            grade4: 'light green',
            grade4_no: 'lysegrønt',
            grade5: 'dark green',
            grade5_no: 'mørkegrønt',
            grade6: 'light blue',
            grade6_no: 'lyseblått',
            grade7: 'dark blue',
            grade7_no: 'mørkeblått',
            grade8: 'light brown',
            grade8_no: 'lysebrunt',
            grade9: 'dark brown',
            grade9_no: 'mørkebrunt',
            grade10: 'red',
            grade10_no: 'rødt',
            grade11: 'black',
            grade11_no: 'svart'
        },
        beltGradesDefinite: {
            grade0: 'trainee',
            grade1: 'white',
            grade2: 'yellow',
            grade3: 'orange',
            grade4: 'light green',
            grade5: 'dark green',
            grade6: 'light blue',
            grade7: 'dark blue',
            grade8: 'light brown',
            grade9: 'dark brown',
            grade10: 'red',
            grade11: 'black',

            grade0_no: 'nybegynner',
            grade1_no: 'hvite',
            grade2_no: 'gule',
            grade3_no: 'oransje',
            grade4_no: 'lysegrønne',
            grade5_no: 'mørkegrønne',
            grade6_no: 'lyseblå',
            grade7_no: 'mørkeblå',
            grade8_no: 'lysebrune',
            grade9_no: 'mørkebrune',
            grade10_no: 'røde',
            grade11_no: 'svarte'
        }
    },

    notifications: {
        standardBraindate: 'You got a brain date',
        standardBraindate_no: 'Du fikk en brain date'
    },

    DashboardCourseDetails: {
        step: '{count} step | {count} steps',
        step_no: '{count} steg',

        braindate: '{count} brain date | {count} brain dates',
        lifekey: '{count} life key | {count} life keys',

        getStarted: 'Get Started',
        getStarted_no: 'Kom i gang',

        whatsUp: "Recommended",
        whatsUp_no: 'Anbefalt'
    }
};
