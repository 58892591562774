<template>
  <div class="IntroStep">
    IntroStep {{ stepId }}
    <div class="activities">
      <ButtonWithCheckmark
        v-if="step.read"
        :done="step.read.progress === 1"
        label="Read"
        @click="toggleRead()" />
    </div>
  </div>
</template>

<script>
import ButtonWithCheckmark from '../ButtonWithCheckmark';

export default {
    components: { ButtonWithCheckmark },
    props: ['stepId'],
    computed: {
        step() {
            return this.$store.state.moduleCourse.steps[this.stepId];
        }
    },
    methods: {
        toggleRead() {
            this.$store.dispatch('moduleCourse/dbgMarkActivity', {
                stepId: this.stepId,
                activityName: 'read',
                reset: this.step.read.progress === 1
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.IntroStep {
    padding: 1em;
    background-color: rgba(black, 0.05);
}
.activities {
    display: flex;
}
</style>
