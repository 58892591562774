/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'getting-started-icons/article': {
    width: 50,
    height: 51,
    viewBox: '0 0 50 51',
    data: '<g _fill="#5081FF" fill-rule="nonzero"><path pid="0" d="M11.194 24.4H0v17.917C0 47.043 3.265 51 7.37 51h33.208C45.802 51 50 46.054 50 39.899V0H11.194v24.4zm-9 18.798v-16.73h9v16.598c0 3.03-2.06 5.402-4.446 5.402-2.385 0-4.554-2.24-4.554-5.27zM13.645 2.41h33.81v38.14c0 4.038-2.875 7.586-6.345 7.586H12.455c.793-1.385 1.19-3.202 1.19-4.933V2.41z"/><path pid="1" d="M22.659 15h7.682c.873 0 1.659-.649 1.659-1.54 0-.892-.786-1.46-1.659-1.46H22.66c-.873 0-1.659.649-1.659 1.54 0 .892.698 1.46 1.659 1.46zM21.81 23h16.38c.953 0 1.81-.632 1.81-1.5s-.762-1.5-1.81-1.5H21.81c-.953 0-1.81.632-1.81 1.5s.762 1.5 1.81 1.5zM21.81 31h16.38c.953 0 1.81-.632 1.81-1.5s-.762-1.5-1.81-1.5H21.81c-.953 0-1.81.632-1.81 1.5s.762 1.5 1.81 1.5zM21.81 39h16.38c.953 0 1.81-.632 1.81-1.5s-.762-1.5-1.81-1.5H21.81c-.953 0-1.81.632-1.81 1.5s.762 1.5 1.81 1.5z"/></g>'
  }
})
