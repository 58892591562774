import Hammer from 'hammerjs';

function insertHotspot() {
    document.body.insertAdjacentHTML(
        'beforeend',
        '<div id="dbg-secret-blocking-layer" style="position: fixed; top: 0; right: 0; height: 6em; width: 6em; z-index: 2000;"></div>'
    );
    return document.querySelector('#dbg-secret-blocking-layer');
}

export default function setupSecretGesture(callback) {
    let hammer = new Hammer.Manager(document.body, {
        recognizers: [[Hammer.Press, { time: 2000, threshold: 10 }]]
    });
    let secretLayerElem = null;

    hammer.on('pressup', event => {
        if (secretLayerElem) {
            return;
        }
        secretLayerElem = insertHotspot();

        const hotspotTimeoutId = setTimeout(() => {
            _cleanup();
        }, 2000);

        let tempHammer = new Hammer.Manager(secretLayerElem, {
            recognizers: [[Hammer.Tap, { taps: 1 }]]
        });

        function _cleanup() {
            clearTimeout(hotspotTimeoutId);
            tempHammer.destroy();
            if (secretLayerElem) {
                if (secretLayerElem.parentNode) {
                    secretLayerElem.parentNode.removeChild(secretLayerElem);
                }
                secretLayerElem = null;
            }
        }

        tempHammer.on('tap', () => {
            _cleanup();
            callback();
        });
    });
}
