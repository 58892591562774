/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sound-mute': {
    width: 54,
    height: 47,
    viewBox: '0 0 54 47',
    data: '<g _fill="#000" fill-rule="nonzero"><path pid="0" d="M25.417.173c-.799-.368-1.72-.123-2.334.491l-10.81 10.872H2.876c-1.228 0-2.21.982-2.21 2.15v18.732c0 1.228.982 2.15 2.21 2.15h9.397L23.144 45.5c.43.43.983.676 1.536.676.307 0 .491-.062.737-.185a2.228 2.228 0 001.29-2.027V2.2c0-.921-.43-1.72-1.29-2.027zM50.688 12L43.5 19.188 36.312 12 33 15.313l7.188 7.187L33 29.688 36.313 33l7.187-7.188L50.688 33 54 29.687 46.82 22.5 54 15.312z"/></g>'
  }
})
