<template>
  <div
    class="ContainerWithModalSide"
    :class="[{ 'is-showing-sidebar': expanded }, `side-${side}`]">
    <transition
      :name="transitionName"
      mode="out-in"
      @enter="enter"
      @after-leave="afterLeave">
      <component
        :is="modalSession.component"
        v-if="modalSession"
        class="side"
        v-bind="modalSession.props"
        @signal="modalClose" />
    </transition>

    <div class="main">
      <slot name="main" />
      <transition name="fade">
        <div
          v-if="expanded"
          class="blockingLayer"
          @click="modalClose('cancel')" />
      </transition>
    </div>
    <slot />
  </div>
</template>

<script>
import { ModalHostMixin } from '@/plugins/modal-helper';

export default {
    mixins: [ModalHostMixin],
    data() {
        return {
            side: 'right',
            blockMain: false
        };
    },
    computed: {
        expanded() {
            return Boolean(this.modalSession);
        },
        transitionName() {
            return `slide-${this.side}`;
        }
    },
    watch: {
        'modalSession.hostOptions.side': function (val) {
            if (val) {
                this.side = val;
            }
        }
    },
    methods: {
        enter() {
            this.blockMain = true;
        },
        afterLeave() {
            this.blockMain = false;
        }
    }
};
</script>

<style lang="scss" scoped>
$transitionDuration: 0.5s;
$sidebarWidth: 90%;
$sidebarSlideDistance: 20%;

.ContainerWithModalSide {
    position: relative;
    overflow: hidden;

    &.side-right {
        &.is-showing-sidebar .main {
            transform: translate3d(-$sidebarWidth, 0, 0);
        }
        .side {
            right: 0;
        }
    }
    &.side-left {
        &.is-showing-sidebar .main {
            transform: translate3d($sidebarWidth, 0, 0);
        }
        .side {
            left: 0;
        }
    }
}
.main {
    position: relative;
    transition: transform $transitionDuration cubic-bezier(0.55, 0, 0.1, 1);
    height: 100%;
}
.blockingLayer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(black, 0.8);

    opacity: 1;
    transition: opacity $transitionDuration;

    z-index: 1; // NOTE: should be controlled from somewhere else

    &.fade-enter {
        opacity: 0;
    }
    &.fade-leave-active {
        opacity: 0;
    }
}

.side {
    position: absolute;
    width: $sidebarWidth;
    top: 0;
    bottom: 0;

    transition: transform $transitionDuration cubic-bezier(0.55, 0, 0.1, 1);

    &.slide-right-enter {
        transform: translate3d($sidebarSlideDistance, 0, 0);
    }
    &.slide-right-leave-active {
        transform: translate3d($sidebarSlideDistance, 0, 0);
    }

    &.slide-left-enter {
        transform: translate3d(-$sidebarSlideDistance, 0, 0);
    }
    &.slide-left-leave-active {
        transform: translate3d(-$sidebarSlideDistance, 0, 0);
    }
}

// -----------------------------------------
// Adjustments for large screen
// -----------------------------------------

@include media('>=mobile') {
    $sidebarWidth: 28em;
    .side {
        width: $sidebarWidth;
    }
    .ContainerWithModalSide {
        position: relative;
        overflow: hidden;

        &.side-right {
            &.is-showing-sidebar .main {
                transform: translate3d(-$sidebarWidth, 0, 0);
            }
        }
        &.side-left {
            &.is-showing-sidebar .main {
                transform: translate3d($sidebarWidth, 0, 0);
            }
        }
    }
}
</style>
