import last from 'lodash-es/last';
import { chunkArray } from '@/utils';
import gameHelper from '@/modules/games-shared/game-helper';

const module = {
    namespaced: true,
    state() {
        return {
            sessionId: null,
            loading: true,
            pageSize: 1,
            pageIndex: 0,
            items: [],
            timeoutPerItem: false,
            timeUsed: 0,
            pages: []
        };
    },
    actions: {
        async enter({ state, commit }) {
            commit('setLoading', true);

            const data = await gameHelper.sessions[state.sessionId].generateMemorize();
            const allItemIndices = data.items.map((item, i) => i);
            const pages = chunkArray(allItemIndices, data.pageSize || 1).map(itemIndices => {
                return {
                    itemIndices,
                    timeUsed: 0,
                    sessions: []
                };
            });
            data.pages = pages;
            commit('initialize', data);
            commit('setLoading', false);
        },
        leave({ state, commit }) {
            const timeUsed = state.pages
                .map(page => page.timeUsed)
                .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            state.pages.forEach(page => {
                const averageTimeUsedPerItem = page.timeUsed / page.itemIndices.length;
                page.itemIndices.forEach(itemIndex => {
                    commit('setItemTimeUsed', {
                        itemIndex,
                        timeUsed: averageTimeUsedPerItem
                    });
                });
            });
            gameHelper.sessions[state.sessionId].reportMemorize({ items: state.items, timeUsed });
        },
        prev({ commit, state }) {
            commit('setPageIndex', state.pageIndex - 1);
        },
        next({ commit, state }) {
            commit('setPageIndex', state.pageIndex + 1);
        },

        enterPage({ state, commit }) {
            const timestamp = Date.now();
            commit('registerPageEnterTime', {
                pageIndex: state.pageIndex,
                timestamp
            });
        },
        leavePage({ state, commit }) {
            const timestamp = Date.now();

            commit('registerPageLeaveTime', {
                pageIndex: state.pageIndex,
                timestamp
            });

            commit('updatePageTimeUsed', {
                pageIndex: state.pageIndex,
                timeUsed: state.pages[state.pageIndex].sessions
                    .map(session => session.end - session.start)
                    .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
            });
        }
    },
    mutations: {
        setSessionId(state, sessionId) {
            state.sessionId = sessionId;
        },
        setLoading(state, flag) {
            state.loading = flag;
        },
        initialize(state, data) {
            Object.assign(state, data);
        },
        setPageIndex(state, pageIndex) {
            state.pageIndex = pageIndex;
        },
        registerPageEnterTime(state, { pageIndex, timestamp }) {
            const page = state.pages[pageIndex];
            page.sessions.push({
                start: timestamp,
                end: 0
            });
        },
        registerPageLeaveTime(state, { pageIndex, timestamp }) {
            const page = state.pages[pageIndex];
            last(page.sessions).end = timestamp;
        },
        updatePageTimeUsed(state, { pageIndex, timeUsed }) {
            state.pages[pageIndex].timeUsed = timeUsed;
        },
        setItemTimeUsed(state, { itemIndex, timeUsed }) {
            state.items[itemIndex].timeUsed = timeUsed;
        }
    }
};

export default module;
