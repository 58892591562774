/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'categories/mind': {
    width: 43,
    height: 34,
    viewBox: '0 0 43 34',
    data: '<path pid="0" d="M21.761 0c4.713 0 8.534 1.72 8.534 3.84s-3.821 3.84-8.534 3.84c-4.713 0-8.534-1.72-8.534-3.84S17.048 0 21.761 0zM37.74 18.554c-4.59 3.684-19.46.229-24.75-2.435C4.846 17.366 0 19.864 0 24.215s9.552 8.854 21.334 8.854c11.783 0 21.335-3.964 21.335-8.854 0-2.152-1.852-4.126-4.93-5.66zM26.873 8.356c-3.818.906-10.125.617-13.906-1.795-2.722-.018-4.355 1.217-4.657 2.437-1.64 6.6 27.418 13.816 29.057 7.216.609-2.451-3.926-5.745-10.494-7.858z" fill-rule="nonzero"/>'
  }
})
