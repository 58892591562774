/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'head-with-heart': {
    width: 222.522,
    height: 222.523,
    viewBox: '0 0 222.522 222.523',
    data: '<path pid="0" d="M187.771 97.921c-5.427-10.448 2.293-16.698 2.813-22.097 2.394-24.905-6.012-40.152-17.449-52.216C154.245 3.626 129.958-.13 114.058.003c-40.704.295-57.178 13.21-69.641 27.11-25.924 28.936-20.439 54.341-20.439 54.341.618 15.063 21.409 42.495 21.409 42.495 16.571 23.826 8.429 42.209 8.429 42.209 33.467 7.524 60.902 56.365 60.902 56.365 1.211-7.235 17.292-37.771 17.292-37.771 6.721-8.446 18.011-5.479 24.099-4.734 17.472 2.152 21.816-2.99 21.816-2.99 3.818-2.335 2.01-12.059 2.01-12.059-1.4-5.029.881-7.488 2.306-9.457 3.836-5.35 1.613-7.832 1.613-7.832-1.206-1.519-.396-2.316-.396-2.316 4.215-1.407 4.522-3.712 4.522-3.712l-.106-7.737c-.697-5.314 1.708-5.639 1.708-5.639 9.652-.178 9.439-6.426 9.439-6.426.388-3.433-11.25-23.929-11.25-23.929zm-38.266-29.699c-3.913 17.517-39.052 39.596-39.052 39.596S75.281 85.745 71.395 68.222a22.431 22.431 0 01-1.132-6.998c0-8.565 4.738-16.01 11.736-19.881a22.715 22.715 0 0110.956-2.829c7.04 0 13.32 3.195 17.499 8.213 4.164-5.018 10.445-8.213 17.472-8.213a22.6 22.6 0 0110.965 2.829c6.998 3.871 11.732 11.316 11.732 19.875a22.52 22.52 0 01-1.118 7.004z"/>'
  }
})
