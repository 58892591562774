export default {
    title: 'Supermarket',
    title_no: 'Handlelisten',
    subtitle: 'Lists & Productivity',
    subtitle_no: 'Lister & Produktivitet',

    techniques: ['rhymesys', 'alphabetsys', 'clonesys', 'doublesys'],
    articles: ['shoppinglist'],

    // tags: ['shopping lists', 'to-do lists', 'project lists'],
    // tags_no: ['handlelister', 'to-do lister', 'prosjektliste'],

    levelTexts: {
        level0:
            "You’re doing your grocery shopping in the mega-supermarket. You’ve got big list of items you want to buy, but you've forgotten the list at home. Have you got what it takes to remember the right products in the right sequence?",
        level0_no:
            'Du er på handletur i et mega-supermarked. Handlelisten din er stor, men uheldigvis har du glemt den hjemme. Har du allikevel det som skal til for å huske alle produktene i riktig rekkefølge?',

        level1:
            'You’re on the way to the store when someone calls and asks you to bring a long list of shopping items. Have you got it in you to remember all you need?',
        level1_no:
            'Du er på vei til butikken når noen ringer og ber deg ta med en haug av ting. Plutselig er handlelisten blitt stor. Har du det som skal til for å huske alt?',
        level2:
            'It’s time for your weekly shopping trip and there are many products you need to remember to buy. Are you up for the challenge?',
        level2_no:
            'Det er på tide med din ukentlige storhandel og det er mange produkter du må huske på. Klarer du å få med deg alt uten å skrive det ned?',
        level3:
            'All right, now let’s see if you can get the right quantities of your shopping products as well. Have you got what it takes to remember it all?',
        level3_no:
            'Ok, la oss nå se om du kan få med deg de riktige mengdene og antallet av produktene du skal handle. Har du det som skal til å ta med deg nok?',
        level4:
            'Supermarkets sometimes price products differently inside the store than in the cash registers. Let’s see if you remember the prices and  avoid paying too much!',
        level4_no:
            'Det hender supermarkedene priser produktene forskjellig i butikken og i kassen. Klarer du å huske prisene for produktene og unngå å betale for mye?'
    },

    testLabels: {
        matchTest: 'Item',
        matchTest_no: 'Produkt',
        quantityTest: 'Quantity',
        quantityTest_no: 'Antall',
        priceTest: 'Price',
        priceTest_no: 'Pris'
    },

    MemorizeView: {
        instruction: 'Memorize the shopping list',
        instruction_no: 'Memorer handlelisten',
        items: 'items',
        items_no: 'produkter',
        continue: 'Continue',
        continue_no: 'Fortsett'
    },

    QuizView: {
        instruction: 'Drag items in the right order',
        instruction_no: 'Dra produktene i riktig rekkefølge'
    }
};
