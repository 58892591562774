<template>
  <div class="AnimatedGift">
    <Lottie
      :options="options"
      @animCreated="handleAnimation" />
  </div>
</template>

<script>
import Lottie from '@/components/Lottie';
import animationData from './present-for-you.json';

export default {
    components: { Lottie },
    created() {
        this.options = {
            autoplay: false,
            animationData
        };
    },
    methods: {
        handleAnimation(anim) {
            this.anim = anim;
            anim.addEventListener('complete', this.onLottieAnimationCompleted);
            this.anim.play();
        },
        onLottieAnimationCompleted() {
            this.$emit('done');
        }
    }
};
</script>

<style lang="scss" scoped>
.AnimatedGift {
    overflow: hidden;
}
.Lottie {
    position: relative;
    top: -10%;
}
</style>
