<template>
  <PanelBase
    class="LifelongPanel"
    footer-clickable
    @click-footer="gotoStats()">
    <template
      v-if="showHeader"
      #header
      class="header">
      <div class="title">
        {{ $t('title') }}
      </div>
      <div class="subtitle">
                &nbsp;
      </div>
    </template>
    <template #body>
      <WheelOfLife hide-legend />
      <div
        v-if="hideFooter"
        class="link --global-clickable"
        @click="gotoStats()">
        {{ $t('footerText') }}
      </div>
    </template>
    <template
      v-if="!hideFooter"
      #footer>
      {{ $t('footerText') }}
    </template>
  </PanelBase>
</template>

<translations>
  title: 'Your wheel of life'
  title_no: 'Ditt livshjul'

  footerText: 'View your profile'
  footerText_no: 'Se profilen din'
</translations>

<script>
import PanelBase from './PanelBase';
import WheelOfLife from '@/modules/dashboard/components/WheelOfLife';

export default {
    components: { PanelBase, WheelOfLife },
    props: {
        showHeader: {
            type: Boolean,
            default: false
        },
        hideFooter: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        gotoStats() {
            this.$emit('request-close');
            if (this.$route.name === 'DashboardProfileLifeViewWithTab') {
                return;
            }
            this.$router.push({
                name: 'DashboardProfileView'
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.LifelongPanel {
}
.WheelOfLife {
    position: relative;
    width: 60%;
    min-height: 0;
    min-width: 0;
}

.link {
    color: rgba(black, 0.7);
}
</style>
