/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'music': {
    width: 54,
    height: 52,
    viewBox: '0 0 54 52',
    data: '<path pid="0" d="M52.98 39.694h.064V0h-.058L14.512 5.177v31.245a12.545 12.545 0 00-5.024-.53C3.833 36.463-.4 40.484.03 44.903c.43 4.418 5.358 7.553 11.012 7.002 5.655-.552 9.883-4.578 9.453-8.992 0-.074 0-.148-.037-.222h.048V15.59l26.523-3.872v21.701a12.54 12.54 0 00-5.024-.53c-5.654.53-9.887 4.572-9.458 8.991.43 4.419 5.358 7.554 11.013 7.002 5.654-.552 9.888-4.572 9.458-8.991-.01-.048-.032-.106-.037-.197z" _fill="#000" fill-rule="nonzero"/>'
  }
})
