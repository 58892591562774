// import store from '@/store'

import state from './state';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';

// SET_MEMO_GAME_PROGRESS

// store.subscribe((mutation, state) => {
//   console.log('XXXX', mutation, state)
//   // if (ignoreMutation) {
//   //   return
//   // }
//   // if (mutationHooks[mutation.type]) {
//   //   console.log('MUTATION HOOK DISABLED', mutation.type)
//   //   // mutationHooks[mutation.type](mutation.payload, { setupPouch, requestWrite, requestRead, requestLocalWrite, state })
//   // }
// })

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
