/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'getting-started-icons/daily-goal': {
    width: 46,
    height: 49,
    viewBox: '0 0 46 49',
    data: '<path pid="0" d="M34.6 49c-3.757-2.343-6.92-5.27-9.886-8.59-2.175-2.537-1.977-6.442.395-8.785 2.57-2.537 6.723-2.537 9.49 0 2.57-2.537 6.723-2.537 9.49 0 2.373 2.343 2.571 6.248.396 8.785-2.768 3.514-6.129 6.247-9.885 8.59zm4.942-40.215H3.954C1.78 8.785 0 10.542 0 12.689v29.283c0 2.148 1.78 3.904 3.954 3.904h17.794v-3.904H3.954V20.498h35.588v5.857h3.955V12.689c0-2.147-1.78-3.904-3.955-3.904zm-1.977-5.857C37.565 1.367 36.181 0 34.6 0c-1.582 0-2.966 1.367-2.966 2.928V4.88h5.931V2.928zm-25.702 0C11.863 1.367 10.479 0 8.897 0 7.315 0 5.931 1.367 5.931 2.928V4.88h5.932V2.928z" _fill="#5081FF" fill-rule="nonzero"/>'
  }
})
