/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'check': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" clip-rule="evenodd" _fill="none" d="M20 6L9 17l-5-5"/>'
  }
})
