import * as types from './mutation-types';
import settings from '@/settings';

const lifekeyPlugin = (store) => {
  store.subscribe((mutation, state) => {
    // console.log('mutation fired', mutation, state);
    if (mutation.type === 'moduleApp/' + types.MIQ_COMPLETED) {
      store.dispatch('moduleApp/awardLifekey', {
        lifeskill: '02',
        context: 'memoryiq'
      }, { root: true });
    }

    if (mutation.type === 'moduleMentalhygiene/' + types.MH_DAILY_COMPLETED) {
      if (store.getters['moduleMentalhygiene/isTotalPracticeFinished']) {
        store.dispatch('moduleApp/awardLifekey', {
          lifeskill: '70',
          context: 'mentalhygiene-practice',
        }, { root: true });
      }
    }

    if (mutation.type === 'scenario/' + types.SCENARIO_DONE) {
      const { scenarioId, lifeskillNumber: lifeskill, stepGroupId } = mutation.payload;
      // are all scenarios done in journey?
      const scenarios = store.getters['scenario/byJourneyId'](stepGroupId);
      const allDone = scenarios.every(item => store.getters['scenario/isDone'](item.scenario.id));
      if (allDone) {
        let related = [{ lifeskill, context: `habit-journey:${stepGroupId}` }];
        const journeys = store.getters['scenario/journeys'](scenarioId)
          .filter(j => j.id !== stepGroupId);
        const relatedJourneysDone = journeys.every(j =>
          store.getters['scenario/byJourneyId'](j.id)
          .every(s => store.getters['scenario/isDone'](s.scenario.id)));
        if (relatedJourneysDone) {
          related.push(...journeys.map(j => ({
            lifeskill: j.lifeskillNumber,
            context: `habit-journey:${j.id}`,
          })));
        }

        related.forEach(({ lifeskill, context }) => {
          store.dispatch('moduleApp/awardLifekey', {
            lifeskill,
            context,
          }, { root: true });
        });

      }
    }

    if (mutation.type === 'moduleCourse/' + types.SET_STEP_GROUP_QUIZ_PROGRESS) {
      const { lifeskillNumber: lifeskill, stepGroupId, related } = mutation.payload;
      const context = `theory-journey:${stepGroupId}`;
      const lifekeyType = 'theory';
      [{ lifeskill, context, lifekeyType },
        ...related,
      ].forEach(({ lifeskill, context }) =>  {
        store.dispatch('moduleApp/awardLifekey', { lifeskill, context, lifekeyType}, { root: true });
      });
    }

    if (mutation.type === 'moduleApp/' + types.ADD_BRAINDATE) {
      const { lifeskillNumber: lifeskill, videoId, context } = mutation.payload;
      if (context !== 'video') return;

      if (videoId === settings.onboardingVideoDatabaseId) {
        store.dispatch('moduleApp/awardLifekey', {
          lifeskill,
          context: 'firstvideo'
        }, { root: true });
      }

      const braindates = store.getters['moduleApp/getVideoBraindatesMap'][lifeskill] || [];
      if (braindates.length >= settings.video.braindatesForLifekey) {
        store.dispatch('moduleApp/awardLifekey', {
          lifeskill,
          context: `${lifeskill}-video1`,
        }, { root: true });
      }
      if (braindates.length >= settings.video.maxBraindatesForLifekey) {
        store.dispatch('moduleApp/awardLifekey', {
          lifeskill,
          context: `${lifeskill}-video2`,
        }, { root: true });
      }
    }
  });
};

export default lifekeyPlugin;
