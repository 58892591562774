import * as types from '../mutation-types';

const mutationMap = {
    [`moduleApp/${types.SET_LOCALE}`](payload, { requestLocalWrite }) {
        requestLocalWrite('memolifeGeneral');
    },

    [`moduleApp/${types.SET_SHOW_DEBUG_BUTTON}`](payload, { requestLocalWrite }) {
        requestLocalWrite('memolifeGeneral');
    },
    [`moduleApp/${types.UPDATE_DEBUG_SETTINGS}`](payload, { requestLocalWrite }) {
        requestLocalWrite('memolifeDebugSettings');
    }
};

export default mutationMap;
