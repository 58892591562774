/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'carousel-arrow-right': {
    width: 7,
    height: 12,
    viewBox: '0 0 7 12',
    data: '<path pid="0" d="M.728 10.102a.85.85 0 000 1.158.75.75 0 001.1 0l4.444-4.68a.85.85 0 000-1.16L1.828.74a.75.75 0 00-1.1 0 .85.85 0 000 1.158l3.896 4.104-3.896 4.1z" _fill="#5983F7"/>'
  }
})
