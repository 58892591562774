import store from '@/store';
import router from '@/router';
import memorizeModule from './memorize-module';
import quizModule from './quiz-module';

function extractPath(machineState) {
    // NOTE: assumption: no parallel states
    const path = [];
    let state = machineState;
    while (true) {
        if (typeof state === 'string') {
            path.push(state);
            break;
        }
        const [key, val] = Object.entries(state)[0];
        path.push(key);
        state = val;
    }
    return path.join('/');
}

function generateSessionModule({ moduleName, customQuizModule, sessionId }) {
    return {
        namespaced: true,
        modules: {
            memorize: memorizeModule,
            quiz: customQuizModule || quizModule
        },
        state() {
            return {
                moduleName,
                levelInfo: null,
                machineState: 'initial', // 'success', // 'initial',
                appIsLoading: true
            };
        },
        actions: {
            init({ commit, state }, payload) {
                commit('update', payload);
                commit('memorize/setSessionId', sessionId);
                commit('quiz/setSessionId', sessionId);
            }
        },
        mutations: {
            update(state, payload) {
                Object.assign(state, payload);
            },
            setMachineState(state, machineState) {
                state.machineState = machineState;
            },
            setAppIsLoading(state, flag) {
                state.appIsLoading = flag;
            }
        },
        getters: {
            currentPath(state) {
                return extractPath(state.machineState);
            }
        }
    };
}

const gameHelper = {
    session: null,
    sessions: {},

    async createLevelSession({ gameId, levelIndex }) {
        const sessionId = '' + Date.now();
        const gameConfig = (await import('@/modules/game-' + gameId + '/config')).default;
        const session = new gameConfig.Session({ levelIndex });
        session.sessionId = sessionId;
        session.quizMapping = gameConfig.quizMapping;
        this.sessions[sessionId] = session;
        this.session = session;

        if (store.state[gameConfig.moduleName].s) {
            store.unregisterModule([gameConfig.moduleName, 's']);
        }
        store.registerModule(
            [gameConfig.moduleName, 's'],
            generateSessionModule({
                moduleName: gameConfig.moduleName,
                customQuizModule: gameConfig.quizModule,
                sessionId
            })
        );

        const level = store.state[gameConfig.moduleName].levels[levelIndex];
        store.dispatch(`${gameConfig.moduleName}/s/init`, {
            levelInfo: {
                groupIndex: level.groupIndex,
                levelIndex,
                previouslyCompleted: level.stars
            }
        });

        return session;
    },

    async createPracticeSession({ gameId, sessionOptions, theme, testName, exitUrl = '', resultUrl = '', navigate }) {
        const sessionId = '' + Date.now();

        const gameConfig = (await import('@/modules/game-' + gameId + '/config')).default;

        const session = new gameConfig.Session(sessionOptions);

        this.session = session;

        session.sessionId = sessionId;
        session.id = sessionId;
        session.testName = testName;
        session.theme = theme || null;
        session.quizMapping = gameConfig.quizMapping;
        this.sessions[sessionId] = session;

        session.exitUrl = exitUrl;
        session.resultUrl =
            resultUrl ||
            router.resolve({
                name: 'MemogeniusGame-PracticeResult',
                params: {
                    gameId,
                    sessionId
                }
            }).href;

        if (store.state[gameConfig.moduleName].s) {
            store.unregisterModule([gameConfig.moduleName, 's']);
        }
        store.registerModule(
            [gameConfig.moduleName, 's'],
            generateSessionModule({
                moduleName: gameConfig.moduleName,
                customQuizModule: gameConfig.quizModule,
                sessionId
            })
        );
        store.dispatch(`${gameConfig.moduleName}/s/init`, {});

        if (navigate) {
            router.push({
                name: 'MemogeniusGame-PracticeSession',
                params: {
                    gameId,
                    sessionId
                }
            });
        }
        return session;
    },

    async createChallengeSession({ challengeId, navigate, exitUrl = '', resultUrl = '' }) {
        const sessionId = '' + Date.now();
        const challenge = store.getters['moduleSocial/getChallenge'](challengeId);
        const settings = JSON.parse(challenge.settings);
        const gameId = settings.gameId;
        const gameConfig = (await import('@/modules/game-' + gameId + '/config')).default;

        const session = new gameConfig.Session(settings.sessionOptions);

        this.session = session;
        session.sessionId = sessionId;
        session.challengeId = challengeId;
        session.id = sessionId;
        session.quizMapping = gameConfig.quizMapping;
        this.sessions[sessionId] = session;

        const defaultResultUrl = router.resolve({
            name: 'MemogeniusGame-ChallengeResult',
            params: {
                gameId,
                challengeId
            }
        }).href;

        session.exitUrl = exitUrl || defaultResultUrl;
        session.resultUrl = resultUrl || defaultResultUrl;

        if (store.state[gameConfig.moduleName].s) {
            store.unregisterModule([gameConfig.moduleName, 's']);
        }
        store.registerModule(
            [gameConfig.moduleName, 's'],
            generateSessionModule({
                moduleName: gameConfig.moduleName,
                customQuizModule: gameConfig.quizModule,
                sessionId
            })
        );
        store.dispatch(`${gameConfig.moduleName}/s/init`, {});

        if (navigate) {
            router.push({
                name: 'MemogeniusGame-ChallengeSession',
                params: {
                    gameId,
                    sessionId
                }
            });
        }
    }
};
export default gameHelper;
