<template>
  <div class="CreateTeamInvitation">
    <div class="inviteTitle">{{ $t('invite') }}</div>
    <div class="container">
      <div class="link" :class="{ linkCopied: linkCopiedText.length }" @click="copyTeamKeyLink()">{{
        !linkCopiedText.length ? teamKeyLink : linkCopiedText
      }}</div>
      <div class="copy-button" @click="copyTeamKeyLink()">{{ $t('copy') }}</div>
    </div>
  </div>
</template>

<translations>
  invite: 'Invite team members with a link:'
  invite_no: 'Inviter teammedlemmer med link:'
  copy: 'Copy'
  copy_no: 'Kopier'
</translations>

<script>
import gql from 'graphql-tag'
import apollo from '@/apollo-client'
import moment from 'moment'

export default {
  filters: {},
  props: {
    teamId: {
      type: Number,
      required: true,
    },
  },
  data: function () {
    return {
      linkCopiedText: '',
      teamKeyId: '',
    }
  },

  computed: {
    teamKeyLink() {
      return window.location.origin + '/team/key/' + this.teamKeyId
    },
  },

  async mounted() {
    const teamKeys = await this.fetchTeamKeys()
    this.teamKeyId = teamKeys[0]?.id ?? (await this.createTeamKey())
  },

  methods: {
    async fetchTeamKeys() {
      const result = await apollo.query({
        query: gql`
          query TeamKeys($teamId: Int!) {
            teamKeysList(condition: { teamId: $teamId, isExpired: false }) {
              id
              expiresAt
            }
          }
        `,
        variables: { teamId: this.teamId },
      })

      return result.data.teamKeysList
    },

    async createTeamKey() {
      const expiresAt = moment().add(10000, 'days').format('YYYY-MM-DD')
      const result = await apollo.mutate({
        mutation: gql`
          mutation CreateTeamKey($teamId: Int!, $expiresAt: Datetime!) {
            __typename
            teamKeyCreate(input: { teamId: $teamId, expiresAt: $expiresAt }) {
              clientMutationId
              results {
                expiresAt
                id
                teamId
              }
            }
          }
        `,
        variables: { teamId: this.teamId, expiresAt: expiresAt },
      })
      return result.data.teamKeyCreate.results.find(tk => tk.teamId === this.teamId).id
    },

    copyTeamKeyLink() {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(this.teamKeyLink)
        this.linkCopiedText = 'Link copied!'
        setTimeout(() => {
          this.linkCopiedText = ''
        }, 2000)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.CreateTeamInvitation {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 2em;
}

.inviteTitle {
  font-size: 1em;
  display: flex;
  // justify-content: center;
  text-align: center;
  width: 100%;
  // font-family: 'Raleway', sans-serif;
  font-weight: 500;
  color: #d6d4efac;
  margin-left: 3em;
  // margin-top: 3em;

  @media only screen and (max-width: 600px) {
    font-size: 4vw;
    margin-bottom: 0;
    margin-top: 2vw;
  }
}
.container {
  display: flex;
  align-items: center;
  padding: 1.3em;
  padding-top: 0.5em;
  @media only screen and (max-width: 600px) {
    padding: 5vw;
    padding-top: 2vw;
  }
}

.link {
  padding: 0.6em;
  font-size: 1em;
  color: rgba(180, 226, 255, 0.758);
  border-radius: 0.3em;
  border: 1px solid #ccccccb2;
  width: 22em;
  background-color: #ffffff00;
  text-decoration: underline;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media only screen and (max-width: 600px) {
    width: 65vw;
  }

  &.linkCopied {
    text-decoration: none;
    color: white;
  }
}

.copy-button {
  padding: 0.7em 1.3em;
  background-color: #295dbc;
  color: #fff;
  border-radius: 0.3em;
  border: none;
  font-size: 1em;
  cursor: pointer;
  margin-left: 1em;

  @media only screen and (max-width: 600px) {
    padding: 2.7vw 4vw;
  }
}
</style>
