/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'spinner': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M32 16a15.832 15.832 0 00-4.864-11.137 15.717 15.717 0 00-5.156-3.302A15.512 15.512 0 0015.999.5 15.335 15.335 0 005.216 5.216a15.23 15.23 0 00-3.193 4.994A15.027 15.027 0 001 16a14.92 14.92 0 001.254 5.694A14.84 14.84 0 005.57 26.43a14.708 14.708 0 004.832 3.085A14.534 14.534 0 0016 30.5c1.897-.04 3.78-.455 5.502-1.216a14.346 14.346 0 004.574-3.208 14.213 14.213 0 002.977-4.67c.407-1.046.684-2.137.829-3.244a2 2 0 002.111-2.163H32zm-3.178 5.311c-.733 1.663-1.796 3.169-3.099 4.412s-2.844 2.225-4.508 2.868A13.536 13.536 0 0116 29.5a13.328 13.328 0 01-9.369-4.131 13.246 13.246 0 01-2.76-4.346 13.048 13.048 0 01.231-9.952c.683-1.541 1.672-2.937 2.883-4.088s2.642-2.058 4.184-2.652a12.539 12.539 0 019.568.231c1.48.658 2.82 1.609 3.926 2.774s1.975 2.54 2.543 4.021c.57 1.481.837 3.064.794 4.641h.007a2 2 0 001.777 2.155 13.829 13.829 0 01-.962 3.156z"/>'
  }
})
