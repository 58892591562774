<template>
  <div class="MemolanguageAureliaRootViewDebug">
    MemolanguageAureliaRootViewDebug
    <br>

    <template v-for="(entry, i) of entries">
      <div
        v-if="entry.section"
        :key="i"
        class="sectionLabel">
        {{ entry.section }}
      </div>
      <div
        v-if="entry.func"
        :key="i"
        class="entry"
        @click="execute(entry)">
        {{ entry.label || entry.key }}
      </div>
    </template>
  </div>
</template>

<script>
export default {
    props: ['instance'],
    computed: {
        entries() {
            return this.instance.memolanguageDebugService ? this.instance.memolanguageDebugService.entries : [];
        }
    },
    methods: {
        execute(entry) {
            this.$emit('request-close');
            entry.inst ? entry.func.call(entry.inst) : entry.func();
        }
    }
};
</script>

<style lang="scss" scoped>
.MemolanguageAureliaRootViewDebug {
    padding: 1em;
}

.sectionLabel {
    padding: 0.5em 1em;
    background-color: #ddd;
    border-bottom: 1px solid #ddd;
}
.entry {
    padding: 0.5em 1em;
    padding-left: 2em;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
}
</style>
