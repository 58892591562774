/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'play': {
    width: 101.46,
    height: 101,
    viewBox: '0 0 101.46 101',
    data: '<path pid="0" d="M58.24 51.321l-10.75 6.933c-.58.38-1.37.183-1.37-.926V43.676c0-1.116.73-1.27 1.37-.926l10.75 6.933a.962.962 0 010 1.638zM50.73 0A50.5 50.5 0 11.01 50.5 50.605 50.605 0 0150.73 0z" fill-rule="evenodd"/>'
  }
})
