<template>
  <div
    class="ButtonWithCheckmark --global-clickable"
    :class="{ done }"
    @click="$emit('click')">
    <div class="label">
      {{ label }}
    </div>
    <transition name="fade">
      <div
        v-if="done"
        class="checkmark">
        <svgicon
          class="svg-icon"
          icon="checkmark" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
    props: {
        done: {
            type: Boolean
        },
        label: {
            type: String
        }
    }
};
</script>

<style lang="scss" scoped>
$transitionSpeed: 0.5s;

.ButtonWithCheckmark {
    border-radius: 2em;
    color: white;
    // padding: 0.5em 1em;
    padding: 0.3em 0.8em;

    display: inline-flex;
    align-items: center;
    transition: background-color $transitionSpeed;

    &.done {
        background-color: $standardButtonColor;
    }

    &:not(.done) {
        background-color: #eee;
        color: #444;
    }
}
.label {
    font-size: 90%;
}
.checkmark {
    .svg-icon {
        $size: 0.8em;
        display: block;
        width: $size;
        height: $size;
        fill: white;
        margin-left: 0.5em;
    }

    &.fade-enter-active,
    &.fade-leave-active {
        transition: opacity $transitionSpeed, max-width $transitionSpeed;
        max-width: 3em;
    }
    &.fade-enter,
    &.fade-leave-to {
        opacity: 0;
        max-width: 0;
    }
}
</style>
