export default {
    single: {
        name: 'Single system',
        name_no: 'Single-systemet',

        intro:
            '<p><b>Bruk barnas skyggelek i mørket</b> … og hva tallene 0-9 ligner på for å huske handlelisten, korte lister eller å huske pensum i kategorier og kapitler.<p>'
    },

    rhyme: {
        name: 'Rhyme system',
        name_no: 'Rim-systemet',

        intro:
            '<p><b>Elle melle deg fortelle</b> … bruk koder som rimer for å huske korte til mellomlange lister, innhold fra møter, sidehistorier eller ting for korttidshukommelsen.</p>'
    },

    alphabet: {
        name: 'Alphabet system',
        name_no: 'Alfabet-systemet',

        intro:
            '<p><b>Aper, bjørner og cobraer</b> … bruk verdens dyr for å huske mellomlange lister, underlister, innhold fra møter, sidehistorier, passord og koder.</p>'
    },

    double: {
        name: 'Double system',
        name_no: 'Dobbel-systemet',

        intro:
            '<p><b>Bruk de 100 tallformene</b> for å huske lange lister i hvilket som helst tema, pensum, lange tall, passord, datoer, m.m.</p><p><b>Eller bruk tallformene som memotags</b> - steder for Memolifes 100 ferdigheter.</p>'
    },

    olpa: {
        // name: 'Extra large system',
        // name_no: 'Extra large-systemet',
        name: 'OLPA',
        name_no: 'OLPA',
        subtitle: 'Objekt - Livsferdighet - Person - Aksjon',

        intro:
            '<p><b>Bruk de 300 kodene</b> i lynhukommelse for å huske lange tall, passord, datoer, lange lister, ligninger, m.m.</p><p><b>Eller bruk de 100 objektene som memotags</b> - steder for Memolifes 100 ferdigheter.</p>'
    },

    journey: {
        name: 'Journey system',
        name_no: 'Reise-systemet',

        intro:
            '<p><b>Her har jeg jo vært før</b> … la deg forbause av hvor lett det er å huske nesten hva du vil ved å bruke steder du allerede har vært.</p>'
    },

    name: {
        name: 'Name system',
        name_no: 'Navne-systemet',

        intro:
            '<p><b>Det navnet minner meg jo om</b> … lær deg jukselappene for å huske folks navn, rett og slett ved hjelp av hva navnene minner deg om.</p>'
    }
};
