const ScenarioView = () => import('./ScenarioView');

export default [
    {
      path: '/scenario/:id',
        name: 'ScenarioView',
        props: true,
        component: ScenarioView
    },
];
