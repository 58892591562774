/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'categories/knowledge': {
    width: 46,
    height: 34,
    viewBox: '0 0 46 34',
    data: '<g fill-rule="nonzero"><path pid="0" d="M38 17.85s2.377 2.666.803 13.898c-.133.91 2.578 2.412 2.833-.253C42.8 19.456 40.799 16 40.799 16L38 17.85zM8 22c1.062 4.206 2.415 6.041 6.931 7.973C19.448 31.904 21.587 34 23.337 34c1.75 0 3.775-1.842 8.292-3.766C36.145 28.31 34.938 27.72 36 23.52l-12.647 6.004L8 22z"/><path pid="1" d="M44.88 11.454L25.74.635a6.165 6.165 0 00-5.452 0l-19.14 10.82c-1.53.85-1.53 2.233 0 3.09l19.14 10.82c1.72.847 3.731.847 5.451 0l12.365-6.955-13.39-3.223a7.034 7.034 0 01-1.677.2c-2.42 0-4.372-1.174-4.372-2.619 0-1.445 1.96-2.627 4.372-2.627 1.876 0 3.468.71 4.088 1.7l14.202 4.706 3.56-2.01c1.485-.85 1.485-2.233-.008-3.083z"/></g>'
  }
})
