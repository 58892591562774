export default {
    activities: {
        read: {
            label: 'Read',
            label_no: 'Les'
        },
        memo: {
            // remove
            label: 'Memo'
        },
        memorize: {
            label: 'Memo'
        },
        quiz: {
            label: 'Quiz'
        },
        memorize2: {
            label: 'Memo2'
        },
        quiz2: {
            label: 'Quiz2'
        }
    }
};
