/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'minimize': {
    width: 13,
    height: 13,
    viewBox: '0 0 13 13',
    data: '<g fill-rule="evenodd"><path pid="0" d="M1.426 5h3.069A.505.505 0 005 4.494V1.425c0-.558-.32-.69-.715-.296l-.863.864L1.577.148a.505.505 0 00-.714 0L.148.863a.505.505 0 000 .714l1.845 1.845-.863.864C.735 4.68.867 5 1.426 5zM12.852 11.423l-1.845-1.845.864-.864c.395-.394.262-.714-.296-.714h-3.07A.505.505 0 008 8.505v3.07c0 .558.32.69.715.296l.863-.863 1.845 1.844a.505.505 0 00.714 0l.715-.714a.505.505 0 000-.715zM12.852.69L12.137.12a.598.598 0 00-.714 0L9.578 1.594l-.863-.69C8.32.588 8 .694 8 1.14v2.456c0 .223.226.404.505.404h3.07c.558 0 .69-.256.296-.572l-.864-.69 1.845-1.476c.197-.158.197-.414 0-.572zM5 11.575v-3.07A.505.505 0 004.495 8h-3.07c-.558 0-.69.32-.296.714l.864.864-1.845 1.845a.505.505 0 000 .715l.715.714a.505.505 0 00.714 0l1.845-1.845.863.864c.395.394.715.262.715-.296z"/></g>'
  }
})
