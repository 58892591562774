export default {
    title: 'Bank Vault',
    title_no: 'Bankhvelvet',
    subtitle: 'Numbers & Concentration',
    subtitle_no: 'Tall & Konsentrasjon',

    techniques: ['journeysys', 'doublesys'],
    articles: ['bankvault-numbers'],

    // tags: ['numbers', 'pin-codes', 'dates'],
    // tags_no: ['tall', 'pin-koder', 'datoer'],

    levelTexts: {
        level0:
            'You’re a luxury thief who’s about to break into the bank vault. Can you keep your cool and remember all the high speed number codes without triggering the alarm?',
        level0_no:
            'Du er en luksustyv som er iferd med å bryte seg inn i et bankhvelv med diamanter. Er du hurtig nok i hodet og avslappet nok i magen til å memorere alle sifferkodene i høy hastighet, eller kommer du til å utløse alarmen?',

        level1:
            'The safe wheel is spinning and briefly stops … before it continues. Can you remember the pin codes and avoid triggering the alarm?',
        level1_no:
            'Kodehjulet spinner og stopper for et lite øyeblikk ... før det spinner videre. Husker du alle tallkodene uten å utløse alarmen?',
        level2:
            'Here’s a second wheel with numbers you need to remember. Are you up for the challenge, or will you trigger the alarm?',
        level2_no:
            'Her kommer to kodehjul med sifre du må huske. Har du det som skal til, eller kommer du til å utløse alarmen?',
        level3:
            'Great, now let’s see if you can master another two digits in the center of the safe as well! Have you got what it takes or will you trigger the alarm?',
        level3_no:
            'Bra, la oss nå i tillegg se om du klarer å mestre to nye sifre i midten av safen. Klarer du å heve nivået ditt, eller kommer du til å utløse alarmen?',
        level4:
            'You’re almost there! Now, you have to memorize 6 digits at the time. Are you up for the challenge or will you trigger the alarm and get caught?',
        level4_no:
            'Nesten fremme! Nå må du memorere 6 sifre om gangen. Har du det som skal til eller utløser du alarmen og blir tatt?'
    },

    code: 'code {index}',
    code_no: 'kode {index}',
    codeSpan: 'code {start}-{end}',
    codeSpan_no: 'kode {start}-{end}'
};
