<template>
  <div class="DashboardHomeViewDebug">
    <div>Hi from DashboardHomeView</div>
    <div>Show get-started-popup</div>
  </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    computed: {},
    created() {},
    methods: {}
};
</script>

<style lang="scss" scoped>
.DashboardHomeViewDebug {
    border: 1px solid black;
    padding: 1em;
    background-color: red;
}
</style>
