/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'lifekey': {
    width: 42,
    height: 20,
    viewBox: '0 0 42 20',
    data: '<path pid="0" d="M10 0c4.448 0 8.249 2.954 9.531 7H39a3 3 0 013 3v7a3 3 0 01-6 0v-4h-4v4a3 3 0 01-6 0v-4h-6.469c-1.282 4.046-5.083 7-9.531 7-5.487 0-10-4.513-10-10S4.513 0 10 0zm0 6c-2.245 0-4 1.755-4 4s1.755 4 4 4 4-1.755 4-4-1.755-4-4-4z"/>'
  }
})
