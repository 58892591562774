/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'article': {
    width: 55,
    height: 48,
    viewBox: '0 0 55 48',
    data: '<g fill-rule="nonzero"><path pid="0" d="M12.9 22.9H.9v16.3c0 4.3 3.5 7.9 7.9 7.9h35.6c5.6 0 10.1-4.5 10.1-10.1V.7H12.9v22.2zM4.6 39.3V26.6h8.3v12.6c0 2.3-1.9 4.1-4.1 4.1-2.2 0-4.2-1.7-4.2-4zm12-34.9h34.1V37c0 3.5-2.9 6.4-6.4 6.4H15.4c.8-1.2 1.2-2.6 1.2-4.1V4.4z"/><path pid="1" d="M25.2 14.4H34c1 0 1.9-.8 1.9-1.9 0-1.1-.9-1.8-1.9-1.8h-8.8c-1 0-1.9.8-1.9 1.9 0 1.1.8 1.8 1.9 1.8zM25.2 21.6h17.2c1 0 1.9-.8 1.9-1.9 0-1.1-.8-1.9-1.9-1.9H25.2c-1 0-1.9.8-1.9 1.9 0 1.1.8 1.9 1.9 1.9zM25.2 28.8h17.2c1 0 1.9-.8 1.9-1.9 0-1.1-.8-1.9-1.9-1.9H25.2c-1 0-1.9.8-1.9 1.9 0 1.1.8 1.9 1.9 1.9zM25.2 36h17.2c1 0 1.9-.8 1.9-1.9 0-1.1-.8-1.9-1.9-1.9H25.2c-1 0-1.9.8-1.9 1.9 0 1.1.8 1.9 1.9 1.9z"/></g>'
  }
})
