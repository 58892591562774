/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menu/get-started': {
    width: 22,
    height: 20,
    viewBox: '0 0 22 20',
    data: '<g _fill="#658CBD" fill-rule="nonzero"><path pid="0" d="M21.937 16.954L14.644.442a.743.743 0 10-1.36.604l.177.4L11 3.593l1.308 2.92 2.448-2.132-.338-.767 1.576 3.569-2.437 2.121 1.292 2.885 2.421-2.119 3.307 7.487a.745.745 0 00.982.38.748.748 0 00.378-.983zM11.778 7L9 9.297 10.191 12 13 9.766zM13.14 18s1.485-1.2 2.86-2.363L14.862 13 12 15.354 13.14 18zM7 12.725l1.295 3.123c.516.666 2.705-1.071 2.705-1.071L9.812 12C7.655 13.497 7 12.725 7 12.725zM4.214 20L7 17.9 5.661 15 3 17.04zM0 11l1.393 3L4 11.93 2.65 9z"/><path pid="1" d="M6.252 10.35C6.848 10.944 9 9.137 9 9.137L7.773 6.095c-.54.397-.976.641-1.337.79L5.183 4s.132.904-.183 2.538a.439.439 0 00.038.348.67.67 0 01-.042-.048l1.156 3.313L4 12.103 5.17 15l2.15-1.887-1.068-2.763zm.166-3.456c-.038.015-.067.022-.104.035l.104-.035zm-.435.136l-.116.025.116-.025z"/><path pid="2" d="M3.57 4L1 6.02 2.39 9 5 7.058z"/></g>'
  }
})
