<template>
  <div class="SimpleMarkdownViewer">
    <div class="header">
      <span class="title">{{ title }}</span>
    </div>
    <div
      class="content"
      v-html="content" />
  </div>
</template>

<script>
import axios from 'axios';
import marked from 'marked';

function processMarkdown(textMarkdown, options = {}) {
    const renderer = new marked.Renderer();
    if (options.docUrl) {
        renderer.image = function (href, title, text) {
            const url = new URL(href, location.origin + options.docUrl);

            return `<img src="${url}" alt="${title}" title="${title}">`;
        };
    }
    const text = marked(textMarkdown, { renderer: renderer });
    return {
        text: text
    };
}

const emojiMap = {
    ':simple_smile:': '🙂',
    ':heart:': '🧡'
};

export default {
    props: {
        url: {
            type: String
        }
    },
    data() {
        return {
            title: '',
            content: ''
        };
    },
    created() {
        const docUrl = `${process.env.BASE_URL}${this.url}`;
        axios.get(docUrl).then(response => {
            let titleMatch = response.data.match(/class="document-title">(.*?)<\/div>/);
            this.title = titleMatch ? titleMatch[1] : '';
            this.content = processMarkdown(response.data, { docUrl }).text.replace(/:[\w]+:/g, m => {
                const emoji = emojiMap[m];
                return emoji ? `<span class="emoji">${emojiMap[m]}</span>` : '';
            });
        });
    }
};
</script>

<style lang="scss" scoped>
.SimpleMarkdownViewer {
    background-color: white;

    overflow: hidden;
    width: 40em;
    display: flex;
    flex-direction: column;
}
.header {
    background-color: $dashboard-background;
    color: white;
    padding: 1em;
    text-align: center;
}
.title {
    font-size: 120%;
    font-weight: bold;
}
.content {
    padding: 1em;
    flex: 1;
    overflow-y: auto;
    line-height: 1.5em;

    /deep/ .document-title {
        display: none;
    }

    /deep/ h1 {
        font-size: 150%;
        text-transform: uppercase;
    }
    /deep/ h2 {
        font-size: 130%;
    }
}
</style>
