import englishLifeAreas from '@/assets/lifeareas_en.json';
import norwegianLifeAreas from '@/assets/lifeareas_no.json';

const lifeskillLabelsSoftbreak = {};

englishLifeAreas.forEach(({ number, data }) => {
    lifeskillLabelsSoftbreak[number] = data.life_area_softbreak || data.life_area;
});
norwegianLifeAreas.forEach(({ number, data }) => {
    lifeskillLabelsSoftbreak[`${number}_no`] = data.life_area_softbreak || data.life_area;
});

export default lifeskillLabelsSoftbreak;
