import * as types from '@/store/mutation-types';

export const id = 'memolife-general';

export const version = 1;

export function build({ state }) {
    let doc = {
        _id: id,
        version,
        data: Object.assign(
            {
                appLanguage: state.moduleApp.memolife.appLanguage
            },
            state.moduleApp.localState
        )
    };
    return doc;
}

export function apply(doc, { commit, state }) {
    if (doc._deleted) {
        return;
    }
    if (doc.data.appLanguage) {
        commit(`moduleApp/${types.SET_LOCALE}`, doc.data.appLanguage);
    }

    commit(`moduleApp/${types.SET_SHOW_DEBUG_BUTTON}`, { show: doc.data.showDebugButton });
}
