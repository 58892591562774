import Vue from 'vue';
import moment from 'moment';
import {
  fetchRepetitions,
  createRepetition,
  updateRepetition,
  deleteRepetition
} from './api';
import * as types from '@/store/mutation-types';

export default {
  namespaced: true,
  getters: {
    loaded(state) {
      return state.loaded;
    },

    dailyGoal(state, getters) {
      const MAX = 3;
      const doneToday = getters.today.length;
      const due = getters.due.length;
      return Math.min(MAX, due + doneToday);
    },

    all(state, getters, rootState, rootGetters) {
      return state.repetitions
        .map(repDetails(rootGetters))
        .filter(r => r !== null)
        .sort((a, b) => {
          const aa = a.repeats.find(x => !x.done);
          const bb = b.repeats.find(x => !x.done);
          if (aa && bb) {
            return new Date(aa.when) - new Date(bb.when);
          }
          return 0;
      });
    },

    due(state, getters) {
      return getters.all
        .filter(r => r.repeats.some(x => !x.done && moment(x.when).isSameOrBefore(getters.now)));
    },

    done(state, getters) {
      return getters.all
        .filter(repetition => repetition.repeats.every(repeat => repeat.done));
    },

    today(state, getters) {
      return getters.all
        .filter(r => r.repeated.some(x => moment(x.when).isSame(getters.now, 'day')));
    },

    getJourney: (state) => (id) => {
      return state.repetitions
        .filter(r => r.list_id === id);
    },

    energyLevel(state, getters) {
      const l = getters.due.length;
      if (l === 0 || getters.today.length >= getters.dailyGoal) {
        return 'green';
      }
      if ((l > 0 && l < 4) || getters.today.length === getters.dailyGoal - 1) {
        return 'yellow';
      }
      if ((l > 3 && l < 10) || getters.today.length === getters.dailyGoal - 2) {
        return 'orange';
      }
      return 'red';
    },

    energyFull(state, getters) {
      return getters.energyLevel === 'green';
    },

    energyLow(state, getters) {
      return ['yellow', 'orange'].indexOf(getters.energyLevel) > -1
    },

    energyDown(state, getters) {
      return getters.energyLevel === 'red';
    },

    energyBringUp(state, getters) {
      return getters.energyLow || getters.energyDown;
    },

    repsLeft(state, getters) {
      const left = getters.dailyGoal - getters.today.length
      if (left < 0) return 0;
      return left;
    },
  },

  state: {
    now: moment(),
    loaded: false,
    repetitions: [],
  },

  actions: {
    load({ commit, state }) {
      if (state.repetitions.length) return;
      setInterval(() => commit('now'), 60000); // every 60 seconds
      return fetchRepetitions()
        .then(response => commit('repetitions', response.data))
        .catch(error => console.error(error))
    },

    create({ commit }, { app, listId, name, limit, creationDate, link }) {
      let data = {
        app: app,
        list_id: listId,
        name: name
      };
      if (limit) {
        data.limit = limit;
      }
      if (link) {
        data.url = link;
      }
      if (creationDate) {
        data.created = new Date(creationDate).toISOString();
      }
      return createRepetition(data)
        .then(response => response.data)
        .then(repetition => commit('add', repetition))
        .catch(console.error);
    },

    update({ commit }, { id, app, listId, name }) {
      let data = {
        app: app,
        list_id: listId,
        name: name
      };
      return updateRepetition(id, data)
        .then(response => response.data)
        .then(repetition => commit(types.REPETITION_UPDATE, repetition))
        .catch(console.error);
    },

    delete({ commit }, id) {
      return deleteRepetition(id)
        .then(response => response.data)
        .then(repetition => commit('delete', id)) // eslint-disable-line no-unused-vars
        .catch(console.error);
    },
  },

  mutations: {
    repetitions(state, data) {
      Vue.set(state, 'repetitions', data);
      Vue.set(state, 'loaded', true);
    },

    add(state, rep) {
      state.repetitions.push(rep);
    },

    [types.REPETITION_UPDATE](state, rep) {
      const idx = state.repetitions.findIndex(r => r.id === rep.id);
      state.repetitions.splice(idx, 1, rep);
    },

    delete(state, id) {
      const idx = state.repetitions.findIndex(r => r.id === id);
      state.repetitions.splice(idx, 1);
    },

    now(state) {
      Vue.set(state, 'now', moment());
    },
  },
}
const repDetails = rootGetters => (repetition) => {
  if (repetition.app === 'memomaps-step-group') {
    return journeyInfo(rootGetters, repetition);
  }

  if (repetition.app.startsWith('language')) {
    return languageRep(repetition);
  }
  return null;
}

const journeyInfo = (rootGetters, repetition) => {
  const journey = rootGetters['moduleCourse/getJourneyById'](repetition.list_id);
  if (!journey) {
    return null;
  }
  const course = rootGetters['moduleCourse/getCourseById'](journey.courseId);

  const title = journey.name || `Journey ${journey.index + 1}`;

  return {
    ...repetition,
    subtitle: 'translate me',
    title,
    isPractice: false,
    ls: course.lifeskillNumber,
    courseId: course.id,
  }
}

const languageRep = (repetition) => {
  return {
    ...repetition,
    subtitle: repetition.app.split("-")[1],
    title: repetition.name,
    isPractice: false,
    ls: "11",
  }
}
