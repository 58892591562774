/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'getting-started-icons/flag-with-checkmark': {
    width: 12,
    height: 15,
    viewBox: '0 0 12 15',
    data: '<path pid="0" d="M10.4.79H1.6V.394A.397.397 0 001.2 0C.98 0 .8.177.8.395V14.21H.4c-.22 0-.4.176-.4.394 0 .218.18.395.4.395H2c.22 0 .4-.177.4-.395a.397.397 0 00-.4-.394h-.4v-3.948h8.8c.884 0 1.6-.707 1.6-1.579V2.368A1.59 1.59 0 0010.4.79zM5.65 7.991L3.576 5.945l.848-.837 1.126 1.11 2.781-3.43.938.74L5.65 7.992z" _fill="#5081FF" fill-rule="nonzero"/>'
  }
})
