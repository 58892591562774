<template>
  <div class="TabsWithSlidingIndicator">
    <div
      ref="slidingIndicator"
      class="SlidingIndicator" />
    <div
      v-for="(item, i) of items"
      :key="i"
      :class="{ active: i === index, pulsate: pulseIndex === i }"
      class="Tab --global-clickable"
      @click="$emit('update:index', i)">
      {{ item.label }}
    </div>
  </div>
</template>

<script>
export default {
    props: {
        items: {
            type: Array,
            required: true
        },
        index: {
            type: Number,
            default: 0
        },
        pulseIndex: {
            type: Number,
            default: -1
        }
    },
    watch: {
        index() {
            this.update();
        }
    },
    mounted() {
        this.update();
    },
    methods: {
        async update() {
            await this.$nextTick();
            const activeTabElem = this.$el.querySelector('.Tab.active');
            this.$refs.slidingIndicator.style.height = activeTabElem.clientHeight + 'px';
            this.$refs.slidingIndicator.style.width = activeTabElem.clientWidth + 'px';
            this.$refs.slidingIndicator.style.transform = `translateX(${activeTabElem.offsetLeft}px)`;
        }
    }
};
</script>

<style lang="scss" scoped>
$transitionDuration: 0.5s;

.TabsWithSlidingIndicator {
    display: flex;
    position: relative;
}

.SlidingIndicator {
    position: absolute;
    padding: 1em;
    box-shadow: 0 0 0.5em rgba(black, 0.1);
    border-radius: 3em;
    background-color: white;

    transition: width $transitionDuration, transform $transitionDuration;
}

.Tab {
    position: relative;
    padding: 0.5em 1em;
    transition: color $transitionDuration;
    font-weight: 600;
    color: rgba(black, 0.7);
    &.active {
        color: $completionColor;
    }
    &.pulsate {
        &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0.2em;
            left: calc(50% - 1em);
            background-color: transparent;
            width: 2em;
            height: 1px;
            transform: scale(0, 1);

            animation: pulseBackgroundColor 2s;
            animation-iteration-count: 4;
            transform-origin: center;
            @keyframes pulseBackgroundColor {
                50% {
                    background-color: rgba(black, 0.5);
                    transform: scale(1, 1);
                }
            }
        }
    }
}
</style>
