/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'branch-shape': {
    width: 64,
    height: 62,
    viewBox: '0 0 64 62',
    data: '<path pid="0" d="M.347 8.403c.023.35.046.712.04 1.042-.016.81-.01 1.62-.008 2.385l.003.587c.817.079 1.626.148 2.411.216 2.007.173 3.902.336 5.807.63 5.671.88 9.903 3.057 12.94 6.652 2.215 2.622 3.688 5.824 4.775 10.384.26 1.089.507 2.2.747 3.274.634 2.842 1.29 5.78 2.213 8.566 1.817 5.492 5.222 9.37 10.12 11.528 2.728 1.204 5.613 1.719 8.324 2.097.245.033.468.065.665.094l.496.074-1.77 5.156a.844.844 0 00.418.91.833.833 0 00.855-.005l14.88-8.636a.85.85 0 00.428-.743.85.85 0 00-.428-.743l-14.877-8.636a.833.833 0 00-.855-.005.848.848 0 00-.419.912l1.881 5.475-.665-.084a55.112 55.112 0 01-2.25-.322c-5.176-.876-8.749-3.632-10.618-8.193-.676-1.647-1.166-3.116-1.497-4.49-.337-1.397-.63-2.827-.916-4.21-.42-2.05-.857-4.17-1.44-6.201-1.559-5.443-4.223-9.795-7.92-12.936l-.866-.735h26.15l-1.859 5.414a.848.848 0 00.418.91.834.834 0 00.856-.003l14.877-8.638a.85.85 0 00.428-.743.85.85 0 00-.428-.743L48.387.006a.834.834 0 00-.856-.004.848.848 0 00-.418.91l1.86 5.415H2.507v.057l-.447-.032c-.345-.025-1.26-.091-1.537.187-.143.143-.216.472-.216.978 0 .279.02.588.04.886z" fill-rule="evenodd"/>'
  }
})
