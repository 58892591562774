/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'deep-dive': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M4 32c-.16 0-1.53-.03-2.65-1.15C.45 29.95 0 28.66 0 27V12c0-.97.7-2 2-2h2c.55 0 1 .45 1 1s-.45 1-1 1H2v15c0 2.8 1.68 2.99 2.01 3H28c1.83 0 1.99-1.53 2-2V2H8v23c0 .55-.45 1-1 1s-1-.45-1-1V2c0-.97.7-2 2-2h22c.97 0 2 .71 2 2v26c0 1.61-1.07 4-4 4H4z"/><path pid="1" d="M17 20h-4c-.55 0-1-.45-1-1s.45-1 1-1h4c.55 0 1 .45 1 1s-.45 1-1 1zM25 20h-4c-.55 0-1-.45-1-1s.45-1 1-1h4c.55 0 1 .45 1 1s-.45 1-1 1zM17 24h-4c-.55 0-1-.45-1-1s.45-1 1-1h4c.55 0 1 .45 1 1s-.45 1-1 1zM25 24h-4c-.55 0-1-.45-1-1s.45-1 1-1h4c.55 0 1 .45 1 1s-.45 1-1 1zM24 16H14c-1.1 0-2-.9-2-2V8c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2v6c0 1.1-.9 2-2 2zM14 8v6h10V8H14z"/><path pid="2" d="M13.314 6C12.586 6 12 6.586 12 7.314v7.229c0 .728.586 1.314 1.314 1.314h11.172c.728 0 1.314-.586 1.314-1.314V7.314C25.8 6.586 25.214 6 24.486 6H13.314zm4.272 2.629l3.614 2.3-3.614 2.3v-4.6z"/>'
  }
})
