import appMessages from './i18n';
import coverImage from './images/cover.jpg';
import appImage from './images/app-icon.png';
import themeSound from './sounds/theme.mp3';

export default {
    windowBackground: '#0b2033',
    background: 'linear-gradient(#3F96C3, #2C72A8)',

    levelItem: {
        gradient: 'linear-gradient(#3582B9, #337EB7)',
        gradientActive: 'linear-gradient(#327CB1, #396FC4)'
    },
    rankingRow: {
        colorActive: '#3367B7'
    },
    primaryColor: '#03a9f4',
    coverImage,
    appImage,
    themeSound,
    appMessages
    // hasChallenge: true
};
