import * as types from '../../mutation-types';
import uniqBy from 'lodash/uniqBy';
import memomapsDataHelper from './memomaps-data-helper';
import store from '@/store';
import { updateCourseProgress, fetchCourseProgress } from './userprogress-helper';
import moment from 'moment';
import { EventBus } from '@/event-bus';

export default {
    async loadAndApplyCourseData({ commit, state }, { courseId, level }) {
        const course = state.courses[courseId];

        if (course && (course.loadInfo[level] || course.loadInfo[level + 'Loading'])) {
            if (course.loadInfo[level + 'Loading']) {
                return new Promise(resolve => {
                    const unwatch = store.watch(
                        globalState => {
                            return globalState.moduleCourse.courses[courseId].loadInfo[level];
                        },
                        val => {
                            if (unwatch) {
                                unwatch();
                            }
                            resolve();
                        }
                    );
                });
            } else {
                return;
            }
        }
        commit(types.SET_COURSE_DATA, { courseId, level: level + 'Loading' });

        const { courseData = [], stepsData = [], stepGroupsData = [], memosData = [] } = await {
            toc: memomapsDataHelper.loadCourseToc,
            content: memomapsDataHelper.loadCourseFull,
        }[level](courseId);
        stepGroupsData.forEach(stepGroupData => {
            commit(types.SET_STEP_GROUP_DATA, {
                stepGroupId: stepGroupData.id,
                data: stepGroupData
            });
        });

        commit(types.SET_STEP_DATA, { stepsData, lifeskillNumber: courseData.lifeskillNumber });

        commit(types.SET_MEMO_DATA, memosData);

        commit(types.SET_COURSE_DATA, { courseId, data: courseData, level });
        if (courseData && courseData.lifeskillNumber) {
            commit(
                'moduleApp/' + types.UPDATE_LIFESKILL_DATA,
                {
                    lifeskillId: courseData.lifeskillNumber,
                    data: { masterCourseId: courseData.id }
                },
                { root: true }
            );
        }
    },

    async loadCourseProgress({ commit, state }) {
      const activitiesNeedingRecalculation = new Set();
      const stepsNeedingRecalculation = new Set();
      const progress = await fetchCourseProgress(state.progressMapping);
      progress
        .filter(p => p.context === 'memo')
        .forEach(p => {
          const memo = state.memos[p.contextId];
          if (!memo) return;

          const timestamp = moment(p.createdAt).valueOf();
          activitiesNeedingRecalculation.add(memo.stepId);
          stepsNeedingRecalculation.add(memo.stepId);

          commit(types.SET_MEMO_PROGRESS, {
            memoId: p.contextId,
            activity: p.activity,
            timestamp,
          });
        });

      progress
        .filter(p => p.context === 'step')
        .forEach(p => {
          const timestamp = moment(p.createdAt).valueOf();
          const stepId = p.contextId;
          stepsNeedingRecalculation.add(stepId);
          commit(types.SET_STEP_PROGRESS, {
            stepId,
            activity: p.activity,
            timestamp
          });
        });

      progress
        .filter(p => p.context === 'stepgroup')
        .forEach(p => {
          const timestamp = moment(p.createdAt).valueOf();
          if (p.context === 'stepgroup') {
            commit(types.INIT_STEP_GROUP_QUIZ_PROGRESS, {
              stepGroupId: p.contextId,
              timestamp: timestamp,
            });
          }
        });

        activitiesNeedingRecalculation.forEach(stepId => {
            commit(types.RECOMPUTE_STEP_MEMO_PROGRESS, { stepId });
        });
        stepsNeedingRecalculation.forEach(stepId => {
            commit(types.RECOMPUTE_STEP_PROGRESS, { stepId });
        });
    },

    async loadCoursesList({ commit, state }) {
        if (state.courseIds.length) {
            return; // Course list already loaded
        }
        let { courses, mapping } = await memomapsDataHelper.loadCoursesList();
        commit(
            types.SET_COURSE_LIST,
            courses.map(course => course.courseData.id)
        );
        commit('SET_PROGRESS_MAPPING', mapping);

        courses.forEach(({ courseData, stepsData, stepGroupsData, memosData = [] }) => {
            stepGroupsData.forEach(stepGroupData => {
                commit(types.SET_STEP_GROUP_DATA, {
                    stepGroupId: stepGroupData.id,
                    data: stepGroupData
                });
            });

            commit(types.SET_STEP_DATA, { stepsData, lifeskillNumber: courseData.lifeskillNumber });

            commit(types.SET_MEMO_DATA, memosData);

            commit(types.SET_COURSE_DATA, {
                courseId: courseData.id,
                data: courseData,
                level: 'list'
            });
            if (courseData && courseData.lifeskillNumber) {
                commit(
                    'moduleApp/' + types.UPDATE_LIFESKILL_DATA,
                    {
                        lifeskillId: courseData.lifeskillNumber,
                        data: { masterCourseId: courseData.id }
                    },
                    { root: true }
                );
            }
        });
    },

    async loadCourseFull({ dispatch }, { courseId }) {
        await dispatch('loadAndApplyCourseData', { courseId, level: 'content' });
    },

    markMemoSummaryAsDone({ commit, state }, { stepId, memoId }) {
        console.debug('markMemoSummaryAsDone', stepId, memoId);
        commit(types.SET_MEMO_SUMMARY_PROGRESS, {
            stepId,
            memoId,
            timestamp: Date.now()
        });
        commit(types.RECOMPUTE_STEP_MEMO_PROGRESS, { stepId });
        commit(types.RECOMPUTE_STEP_PROGRESS, { stepId });

        const courseId = state.steps[stepId].courseId;
        updateCourseProgress(courseId, 'memo', memoId, 'summary', 'done')
        .catch(console.error);

        window.dataLayer.push({ event: 'summary_done', step: stepId });
    },

    markStepReadAsDone({ commit, state }, { stepId }) {
        console.debug('markStepReadAsDone', stepId);
        const step = state.steps[stepId];
        if (step.read.progress === 1) {
            return;
        }
        const timestamp = Date.now();
        const links = step.read.links || [];

        const relatedLifeskills = links.map(stepId => {
          const s = state.steps[stepId];
          return s.fromLifeskill;
        }).filter(ls => ls);

        [stepId, ...links].forEach((sId, idx) => {
            const s = state.steps[sId];

            if (s.read.progress === 1) {
                return;
            }

            const course = state.courses[s.courseId];

            const lifeskillNumber = s.fromLifeskill || course.lifeskillNumber || '00';
            commit(types.SET_STEP_READ_PROGRESS,
              { stepId: sId,
                timestamp,
                lifeskillNumber,
                courseId: course.id,
                relatedLifeskills: idx ? [] : relatedLifeskills,
                isBonus: idx ? true : false,
              });
            commit(types.RECOMPUTE_STEP_PROGRESS, { stepId: sId });
            updateCourseProgress(course.id, 'step', sId, 'read', 'done').catch(console.error);
        });

        window.dataLayer.push({ event: 'read_done', step: stepId });
    },

    markStepQuizAsDone({ commit, state }, { stepId, activityName }) {
        console.debug('markStepQuizAsDone', stepId, activityName);
        const step = state.steps[stepId];
        if (step[activityName].progress === 1) {
          console.log('quiz already marked as done');
          return;
        }

        const timestamp = Date.now();
        const links = step[activityName].links || [];
        const relatedLifeskills = links.map(stepId => {
          const s = state.steps[stepId];
          return s.fromLifeskill;
        }).filter(ls => ls);

        [stepId, ...links].forEach((sId, idx) => {
            const s = state.steps[sId];
            const course = state.courses[s.courseId];
            const lifeskillNumber = s.fromLifeskill || course.lifeskillNumber || '00';

            commit(types.SET_STEP_QUIZ_PROGRESS, {
                stepId: sId,
                activityName,
                timestamp,
                lifeskillNumber,
                courseId: course.id,
                relatedLifeskills: idx ? [] : relatedLifeskills,
                isBonus: idx ? true : false,
            });
            commit(types.RECOMPUTE_STEP_PROGRESS, { stepId: sId });
            updateCourseProgress(s.courseId, 'step', sId, activityName, 'done').catch(console.error);
        });

        window.dataLayer.push({ event: 'quiz_done', step: stepId });
    },

    markMemoGameAsDone(
      { commit, state },
      { stepId, activityName, memoId = null, gameId }) {
        console.debug('markMemoGameAsDone', stepId, activityName, memoId, gameId);
        const step = state.steps[stepId];
        let memoIds = [];
        if (memoId) {
            memoIds = [memoId];
        } else if (activityName) {
            memoIds = step[activityName].memoIds;
        }
        const courseId = step.courseId;
        const course = state.courses[courseId];
        const lifeskillNumber = step.fromLifeskill || course.lifeskillNumber || '00';

        memoIds.filter(mid => {
          // ignore done memos
          if (state.memos[mid] && state.memos[mid][gameId])
              return !state.memos[mid][gameId].done;
          return false;
        }).forEach(memoId => {
          commit(types.SET_MEMO_GAME_PROGRESS, {
            stepId,
            courseId,
            lifeskillNumber,
            activityName,
            gameId,
            memoId,
            timestamp: Date.now()
          });
          updateCourseProgress(courseId, 'memo', memoId, gameId, 'done').catch(console.error);
        });

        commit(types.RECOMPUTE_STEP_MEMO_PROGRESS, { stepId });
        commit(types.RECOMPUTE_STEP_PROGRESS, { stepId });

        window.dataLayer.push({ event: 'game_done', step: stepId, game: gameId });
    },

    updateStepGroupRepetition({ state, rootGetters, dispatch }, payload) {
        const stepGroup = state.stepGroups[payload.stepGroupId];
        const reps = rootGetters['repetition/getJourney'](payload.stepGroupId);
        if (reps.length) {
          reps.forEach(r => {
            dispatch('repetition/update', {
              id: r.id,
              app: 'memomaps-step-group',
              listId: payload.stepGroupId,
              name: payload.name,
            }, { root: true });
          });
        } else {
          dispatch('repetition/create', {
            app: 'memomaps-step-group',
            listId: payload.stepGroupId,
            name: stepGroup.name || payload.stepGroupId,
            link: `${location.origin}/from-rep-email`
          }, { root: true });
        }
        window.dataLayer.push({
            event: 'repetition_done',
            step_group_id: payload.stepGroupId,
            step_group_name: stepGroup.name || payload.stepGroupId
        });
    },

    resetStepGroupQuizProgress({ commit }, { stepGroupId }) {
        commit(types.UPDATE_STEP_GROUP_QUIZ_STATE, {
            stepGroupId,
            reset: true
        });
    },

    registerStepGroupQuizResult({ commit, getters, dispatch }, { stepGroupId, summary }) {
        const previousQuizState = getters.getStepGroupQuizState(stepGroupId) || {
            totalCount: summary.totalCount,
            correctCount: 0,
            allCompletedItems: []
        };

        const accumulatedCorrectCount = previousQuizState.correctCount + summary.correctCount;
        const allCompletedItems = [...previousQuizState.allCompletedItems, ...summary.completedItems];

        commit(types.UPDATE_STEP_GROUP_QUIZ_STATE, {
            stepGroupId,
            totalCount: previousQuizState.totalCount,
            correctCount: accumulatedCorrectCount,
            allCompletedItems,
            progress: accumulatedCorrectCount / previousQuizState.totalCount,
            failedItems: summary.failedItems
        });

        if (summary.success) {
            dispatch('markStepGroupQuizAsDone', { stepGroupId });
            dispatch('updateStepGroupRepetition', { stepGroupId });
        }
    },

    markStepGroupQuizAsDone({ commit, state, getters }, { stepGroupId }) {
        console.debug('markStepGroupQuizAsDone', stepGroupId);
        const j = getters['getJourneyById'](stepGroupId);
        if (j.quiz.done) {
            return;
        }

        const courseId = j.courseId;
        const course = state.courses[courseId];
        const lifeskillNumber = course.lifeskillNumber;

        const steps = j.stepIds
          .map(sid => getters['getStepById'](sid))
          .filter(s => s.quiz);

        const stepLength = steps.length;

        const relatedSteps = steps
          .map(step => step.quiz.links).flat()
          .map(sid => getters['getStepById'](sid))
          .filter(step => step)
          .filter(s => s.fromLifeskill && s.fromLifeskill !== lifeskillNumber)
          .filter((s, idx, array) =>
            array.filter(e => e.fromLifeskill === s.fromLifeskill).length === stepLength);

        const related = uniqBy(relatedSteps, 'fromLifeskill')
          .map(s => ({
            lifeskill: s.fromLifeskill,
            context: `theory-journey:${s.stepGroupId}`,
            courseId: s.courseId,
            stepGroupId: s.stepGroupId,
          }));

        commit(types.SET_STEP_GROUP_QUIZ_PROGRESS, {
            lifeskillNumber: lifeskillNumber || '00',
            courseId,
            stepGroupId,
            timestamp: Date.now(),
            related,
        });

        related.forEach(s => {
          commit(types.SET_STEP_GROUP_QUIZ_PROGRESS, {
            lifeskillNumber: s.lifeskill,
            courseId: s.courseId,
            stepGroupId: s.stepGroupId,
            timestamp: Date.now(),
            related: [],
            isRelated: true,
          });
        });

        updateCourseProgress(courseId, 'stepgroup', stepGroupId, 'quiz', 'done')
        .then(() => {
          EventBus.$emit('journey-quiz-done', stepGroupId);
          EventBus.$emit('scenarios-unlocked', lifeskillNumber);
          window.dataLayer.push({
              event: 'step_group_quiz_done',
              step_group_id: stepGroupId
          });
        })
        .catch(console.error);


      related.forEach(s => {
        updateCourseProgress(s.courseId, 'stepgroup', s.stepGroupId, 'quiz', 'done')
          .then(() => {
            window.dataLayer.push({
              event: 'step_group_quiz_done',
              step_group_id: s.stepGroupId
            });
          });
      });
    },

    dbgStepMarkAll({ state, dispatch }, { stepId, reset }) {
        const step = state.steps[stepId];
        step.activityNames.forEach(activityName => {
            dispatch('dbgMarkActivity', { stepId, activityName, reset });
        });
    },
    dbgMarkActivity({ state, dispatch }, { stepId, activityName, reset }) {
        if (reset) {
            return;
        }
        if (activityName === 'read') {
            dispatch('markStepReadAsDone', { stepId, reset });
        } else if (activityName === 'memorize' || activityName === 'memorize2') {
            const step = state.steps[stepId];
            step[activityName].memoIds.forEach(memoId => {
                dispatch('markMemoSummaryAsDone', { stepId, memoId, reset });
            });
            dispatch('markMemoGameAsDone', {
                stepId,
                activityName,
                gameId: 'gameA',
                reset
            });
            dispatch('markMemoGameAsDone', {
                stepId,
                activityName,
                gameId: 'gameB',
                reset
            });
        } else if (activityName === 'quiz' || activityName === 'quiz2') {
            dispatch('markStepQuizAsDone', { stepId, activityName, reset });
        }
    }
};
