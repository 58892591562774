/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'female': {
    width: 85,
    height: 85,
    viewBox: '0 0 85 85',
    data: '<path pid="0" d="M76.36 50C87.76 38.6 87.755 20.044 76.35 8.646c-11.41-11.402-29.969-11.402-41.369 0-10.228 10.227-11.197 26.171-3.05 37.589L17.524 60.643 6.924 50.05.094 56.88l10.599 10.593-9.417 9.417 6.838 6.832 9.417-9.417 10.606 10.6 6.833-6.831-10.614-10.6 14.407-14.409C50.18 61.203 66.14 60.227 76.36 50zM41.82 15.477c7.63-7.637 20.06-7.637 27.697 0 7.645 7.636 7.65 20.054.014 27.692-7.636 7.636-20.055 7.636-27.697 0-7.646-7.635-7.651-20.059-.014-27.692z" fill-rule="nonzero"/>'
  }
})
