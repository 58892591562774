/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dashboard-icon': {
    width: 34,
    height: 20,
    viewBox: '0 0 34 20',
    data: '<path pid="0" d="M0 0h4v4H0V0zm10 0h4v4h-4V0zm10 0h4v4h-4V0zm10 0h4v4h-4V0zM0 8h4v4H0V8zm10 0h4v4h-4V8zm10 0h4v4h-4V8zm10 0h4v4h-4V8zM0 16h4v4H0v-4zm10 0h4v4h-4v-4zm10 0h4v4h-4v-4zm10 0h4v4h-4v-4z"/>'
  }
})
