/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'loadwheel': {
    width: 28,
    height: 23,
    viewBox: '0 0 28 23',
    data: '<path pid="0" d="M27.732 9.626h-2.488C24.4 4.176 19.697 0 14.006 0 9.617 0 5.815 2.484 3.913 6.118l1.631.652a9.634 9.634 0 018.462-5.02c4.722 0 8.644 3.396 9.469 7.876h-2.99c-.27.263-.53.425-.141.881l3.264 3.32c.271.263.71.263.979 0l3.287-3.32c.27-.263.128-.618-.142-.881zM14.006 21.002a9.63 9.63 0 01-9.266-7h2.845c.271-.263.412-.619.143-.881L4.44 9.8a.707.707 0 00-.98 0L.198 13.12c-.388.457-.129.619.142.881h2.608c1.187 5.014 5.677 8.75 11.059 8.75 4.095 0 7.674-2.166 9.68-5.407l-1.647-.658a9.624 9.624 0 01-8.033 4.316z" fill-rule="evenodd"/>'
  }
})
