/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'activities/read-old': {
    width: 42,
    height: 37,
    viewBox: '0 0 42 37',
    data: '<g fill-rule="nonzero" _fill="none"><path pid="0" d="M9.36 17.316H0V30.03c0 3.354 2.73 6.162 6.162 6.162H33.93a7.856 7.856 0 007.878-7.878V0H9.36v17.316zM2.886 30.108v-9.906H9.36v9.828c0 1.794-1.482 3.198-3.198 3.198s-3.276-1.326-3.276-3.12zm9.36-27.222h26.598v25.428c0 2.73-2.262 4.992-4.992 4.992H11.31c.624-.936.936-2.028.936-3.198V2.886z"/><path pid="1" d="M18.954 10.686h6.864c.78 0 1.482-.624 1.482-1.482S26.598 7.8 25.818 7.8h-6.864c-.78 0-1.482.624-1.482 1.482s.624 1.404 1.482 1.404zM18.954 16.302H32.37c.78 0 1.482-.624 1.482-1.482s-.624-1.482-1.482-1.482H18.954c-.78 0-1.482.624-1.482 1.482s.624 1.482 1.482 1.482zM18.954 21.918H32.37c.78 0 1.482-.624 1.482-1.482s-.624-1.482-1.482-1.482H18.954c-.78 0-1.482.624-1.482 1.482s.624 1.482 1.482 1.482zM18.954 27.534H32.37c.78 0 1.482-.624 1.482-1.482s-.624-1.482-1.482-1.482H18.954c-.78 0-1.482.624-1.482 1.482s.624 1.482 1.482 1.482z"/></g>'
  }
})
