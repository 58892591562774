function range(length, start) {
    return Array.from(new Array(length), (val, index) => index + start);
}

const categories = [
    { id: 'brain', range: ['00', '10'] },
    { id: 'knowledge', range: ['11', '34'] },
    { id: 'mind', range: ['35', '51'] },
    { id: 'body', range: ['52', '67'] },
    { id: 'soul', range: ['68', '78'] },
    { id: 'career', range: ['79', '88'] },
    { id: 'leisure', range: ['89', '99'] }
];

categories.forEach(category => {
    const [start, end] = category.range.map(r => parseInt(r, 10));
    category.lifeskillIds = range(end - start + 1, start).map(num => `${num < 10 ? '0' : ''}${num}`);
});

export default categories;

const startIndexes = categories.map(c => parseInt(c.range[0], 10));

export function getCategoryIdFromLifeskillId(lifeskillId) {
    const c = categories.find(c => {
      return c.lifeskillIds.indexOf(lifeskillId) > -1
    });
    return c && c.id;
}

export function getLifeskillRangeFromCategoryId(categoryId) {
    return (categories.find(c => c.id === categoryId) || { range: ['??', '??'] }).range;
}
