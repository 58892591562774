/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '02games': {
    width: 53,
    height: 44,
    viewBox: '0 0 53 44',
    data: '<path pid="0" d="M45.852 11.958C38.191 4.583 29.625 13 29.625 13h-2.591c-.175-1-.573-1.821 1.038-3.391.75-.729 1.947-1.33 3.215-1.879 2.512-1.087 5.639-2.481 6.585-6.28a1.02 1.02 0 00-.729-1.232.989.989 0 00-1.211.718c-.707 2.84-3.006 3.829-5.438 4.881-1.441.624-2.804 1.376-3.813 2.362C24.933 9.878 24.71 11 24.951 13h-2.108s-8.567-8.417-16.225-1.042C-1.04 19.333-.651 36.125 1.037 39c1.688 2.875 7.604 9.625 16.82-4.125l15.573-.261c9.215 13.75 16.315 7.271 18.002 4.396 1.687-2.874 2.077-19.677-5.58-27.052zM16 25h-4v4h-2v-4H6v-2h4v-4h2v4h4v2zm24.625-7.271a2.214 2.214 0 110 4.429 2.214 2.214 0 010-4.429zm-5.937 5.938a2.215 2.215 0 114.43.002 2.215 2.215 0 01-4.43-.002zm5.937 5.937a2.214 2.214 0 110-4.428 2.214 2.214 0 010 4.428zm3.725-3.723a2.215 2.215 0 110-4.43 2.215 2.215 0 010 4.43z" _fill="#000" fill-rule="nonzero"/>'
  }
})
