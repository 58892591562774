import Vue from 'vue'
import Vuex from 'vuex'

import moduleApp from './modules/module-app'
import moduleCourse from './modules/module-course'
import moduleMemogenius from './modules/module-memogenius'
import moduleMemolanguage from '@/modules/memolanguage/store'
import moduleSocial from '@/modules/social/store'
import moduleMemoransier from '@/modules/memoransier/store'
import moduleAuth from './modules/module-auth'
import moduleTeam from './modules/module-team'

import { myPlugin } from './persistence'

import moduleMentalhygiene from '@/modules/mentalhygiene/store'

import moduleSystemName from '@/modules/system-name/store'

import moduleGameFaces from '@/modules/game-faces/store'
import moduleGameShopping from '@/modules/game-shopping/store'
import moduleGameBankvault from '@/modules/game-bankvault/store'
import moduleGameTimemachine from '@/modules/game-timemachine/store'
import moduleGameBrainstorm from '@/modules/game-brainstorm/store'
import moduleGameVocabguru from '@/modules/game-vocabguru/store'

import repetition from '@/modules/repetition/store'
import scenario from '@/modules/scenario/store'

import braindatePlugin from './braindate'
import lifekeyPlugin from './lifekey'
import crystalPlugin from './crystal'

Vue.use(Vuex)

// const debug = process.env.NODE_ENV !== 'production'
const debug = false // Disable debug, because it makes dev SOOOO slow

export default new Vuex.Store({
  plugins: [myPlugin, braindatePlugin, lifekeyPlugin, crystalPlugin],
  state: {
    routerIsNavigating: false,
    initialAppStateLoaded: false,
    initialUserStateLoaded: false,
    initialRouterNavigationCompleted: false,
  },
  getters: {
    appIsReady: (state, getters, rootState, rootGetters) => {
      if (!state.initialRouterNavigationCompleted) {
        return false
      }
      if (rootGetters['moduleAuth/isAuthenticated']) {
        return state.initialAppStateLoaded && state.initialUserStateLoaded
      } else {
        return state.initialAppStateLoaded
      }
    },

    initialUserStateLoaded: state => state.initialUserStateLoaded,
  },
  mutations: {
    routerStartNavigation: state => {
      state.routerIsNavigating = true
    },
    routerEndNavigation: state => {
      state.routerIsNavigating = false
      if (!state.initialRouterNavigationCompleted) {
        state.initialRouterNavigationCompleted = true
      }
    },

    setInitialAppStateLoaded: state => {
      console.log('setInitialAppStateLoaded')
      state.initialAppStateLoaded = true
    },
    setInitialUserStateLoaded: state => {
      console.log('setInitialUserStateLoaded')
      state.initialUserStateLoaded = true
    },
  },
  modules: {
    moduleApp,
    moduleCourse,
    moduleMemogenius,
    moduleMemolanguage,
    moduleAuth,
    moduleTeam,
    moduleSocial,
    moduleMemoransier,

    moduleSystemName,

    moduleGameFaces,
    moduleGameShopping,
    moduleGameBankvault,
    moduleGameTimemachine,
    moduleGameBrainstorm,
    moduleGameVocabguru,
    moduleMentalhygiene,

    repetition,
    scenario,
  },
  strict: debug,
})
