import coverImage from './images/cover.jpg';
import appImage from './images/app-icon.png';
// import coverImagePractice from '@/modules/system-name/images/cover.jpg'
import themeSound from './sounds/theme.mp3';
import appMessages from './i18n';

export default {
    windowBackground: '#000',
    background: 'linear-gradient(#452f94, #23226a)',
    levelItem: {
        gradient: '#231363',
        gradientActive: 'linear-gradient(#9c46b3, #b467c9)'
    },
    rankingRow: {
        colorActive: '#b569ca'
    },
    primaryColor: '#D578DE',
    coverImage,
    appImage,
    themeSound,
    appMessages
};
// const themePractice = {
//   windowBackground: '#000',
//   background: '#1D1D1D', // 'linear-gradient(#452f94, #23226a)',
//   rankingRow: {
//     colorActive: '#b569ca'
//   },
//   primaryColor: '#7ED321', // '#D578DE',
//   coverImage: coverImagePractice,
//   // appImage: appImage,
//   themeSound
// }
