/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'getting-started-icons/game': {
    width: 67,
    height: 45,
    viewBox: '0 0 67 45',
    data: '<g _fill="#5081FF" fill-rule="nonzero"><path pid="0" d="M0 27.5C0 37.16 7.949 45 17.74 45h31.52C59.05 45 67 37.16 67 27.5S59.051 10 49.26 10H17.74C7.95 10 0 17.84 0 27.5zm52.324-8.41c4.7 0 8.524 3.774 8.524 8.41s-3.824 8.41-8.524 8.41c-4.7 0-8.525-3.774-8.525-8.41s3.824-8.41 8.525-8.41zm-32.648 1.933v4.25l4.262-.046c1.452 0 2.65 1.182 2.65 2.637 0 1.454-1.152 2.636-2.603 2.636l-4.309.023v3.522c0 1.455-1.175 2.614-2.65 2.614-1.474 0-2.65-1.159-2.65-2.614v-3.522l-3.593-.046c-1.452 0-2.65-1.136-2.65-2.59 0-1.455 1.221-2.592 2.673-2.592h3.57v-4.25c0-1.454 1.176-2.613 2.65-2.613 1.475 0 2.65 1.159 2.65 2.59z"/><path pid="1" d="M51.989 32C54.739 32 57 29.765 57 27a5 5 0 00-5.011-5A4.981 4.981 0 0047 27c0 2.765 2.24 5 4.989 5zM33 20c-2.204 0-4-1.746-4-3.888V3.888C29 1.746 30.796 0 33 0c2.204 0 4 1.746 4 3.888v12.224C36.976 18.254 35.204 20 33 20z"/></g>'
  }
})
