<template>
  <div class="TeamCreate">
    <div class="titleContainer">
      <h2 v-if="!team" class="title">{{ $t('createTeam') }}</h2>
      <h2 v-if="team" class="title">{{ $t('team') }} '{{ team.name }}' {{ $t('created') }}</h2>
      <h2 v-if="team" class="subtitle">{{ $t('inviteMembers') }}</h2>
    </div>
    <div v-if="!team" class="teamTags">
      <div class="teamTag" :class="{ selected: selectedTag === 'Family' }" @click="selectedTag = 'Family'">{{
        $t('family')
      }}</div>
      <div class="teamTag" :class="{ selected: selectedTag === 'Friends' }" @click="selectedTag = 'Friends'">{{
        $t('friends')
      }}</div>
      <div class="teamTag" :class="{ selected: selectedTag === 'Work' }" @click="selectedTag = 'Work'">{{
        $t('work')
      }}</div>
      <div class="teamTag" :class="{ selected: selectedTag === 'Other' }" @click="selectedTag = 'Other'">{{
        $t('other')
      }}</div>
    </div>

    <form v-if="!team" @submit.prevent="create">
      <input id="name" v-model="name" type="text" required :placeholder="$t('enterTeamName')" />
      <button type="submit">{{ $t('create') }}</button>
    </form>

    <CreateTeamInvitation v-if="team" :team-id="team.teamId" />
  </div>
</template>

<translations>
  createTeam: 'Create new team'
  createTeam_no: 'Lag nytt team'
  enterTeamName: 'Enter team name'
  enterTeamName_no: 'Skriv teamnavnet her'
  family: 'Family'
  family_no: 'Familie'
  friends: 'Friends'
  friends_no: 'Venner'
  work: 'Work'
  work_no: 'Jobb'
  other: 'Other'
  other_no: 'Annet'
  create: 'Create'
  create_no: 'Lag'
  team: 'Team'
  team_no: 'Teamet'
  created: 'created!'
  created_no: 'er laget!'
  inviteMembers: 'Invite your team members'
  inviteMembers_no: 'Inviter teammedlemmer'

</translations>

<script>
import gql from 'graphql-tag'
import apollo from '@/apollo-client'
import CreateTeamInvitation from './CreateTeamInvitation.vue'

export default {
  components: { CreateTeamInvitation },

  filters: {},

  props: {},
  data: function () {
    return {
      name: '',
      selectedTag: 'Family',
      team: null,
      teamKeyId: null,
    }
  },

  computed: {},

  mounted() {},

  methods: {
    async create() {
      const name = this.name
      this.name = ''
      const result = await apollo.mutate({
        mutation: gql`
          mutation CreateTeam($teamName: String!) {
            __typename
            createTeam(input: { teamName: $teamName }) {
              results {
                name
                teamId
              }
            }
          }
        `,
        variables: { teamName: name },
      })
      const team = result.data.createTeam.results[0]
      await this.addTag(team.teamId, team.name, this.selectedTag)
      this.team = team
      this.$store.dispatch('moduleApp/selectTeamId', `${team.teamId}`)
      // this.$emit("created");
    },
    async addTag(teamId, teamName, tag) {
      const tags = [tag, 'skillsMission:']
      await apollo.mutate({
        mutation: gql`
          mutation UpdateTeamMutation($id: Int!, $name: String!, $tags: [String!]) {
            __typename
            updateTeam(input: { _id: $id, _tags: $tags, _name: $name }) {
              results {
                teamId
                teamName
                teamTags
              }
            }
          }
        `,
        variables: {
          id: teamId,
          name: teamName,
          tags: tags,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.TeamCreate {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40em;
  height: 40em;
  border-radius: 2em;
  background-color: #0f1340;
  overflow-y: scroll;

  @media only screen and (max-width: 600px) {
    width: 100vw;
    height: 120vw;
  }

  .titleContainer {
    position: absolute;
    top: 1em;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media only screen and (max-width: 600px) {
      top: 3vw;
    }

    .title {
      font-size: 1.6em;
      display: flex;
      justify-content: center;
      text-align: center;
      width: 100%;
      // font-family: 'Raleway', sans-serif;
      font-weight: 600;
      color: #d6d4ef;
      margin: 0;
      margin-top: 1em;

      @media only screen and (max-width: 600px) {
        font-size: 5vw;
        margin-top: 4vw;
        margin-bottom: 2vw;
      }
    }
    .subtitle {
      font-size: 1.2em;
      display: flex;
      justify-content: center;
      text-align: center;
      width: 100%;
      // font-family: 'Raleway', sans-serif;
      font-weight: 500;
      color: #968ffb;
      margin: 0;
      margin-top: 0.5em;

      @media only screen and (max-width: 600px) {
        font-size: 4vw;
        margin-top: 0;
        margin-bottom: 4vw;
      }
    }
  }
  .teamTags {
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
    margin-top: 1em;

    @media only screen and (max-width: 600px) {
      margin-bottom: 2vw;
      margin-top: 2vw;
    }

    .teamTag {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 7em;
      height: 7em;
      margin: 0.5em;
      color: white;
      background-color: #36489f;
      border-radius: 0.7em;
      opacity: 0.4;
      cursor: pointer;

      @media only screen and (max-width: 600px) {
        width: 20vw;
        height: 20vw;
        margin: 1vw;
      }

      &.selected {
        opacity: 1;
      }
    }
  }
}

form {
  display: flex;
  align-items: center;
  padding: 1.3em;
  @media only screen and (max-width: 600px) {
    padding: 5vw;
  }
}

label {
  font-weight: bold;
  margin-right: 1.3em;
}

input[type='text'] {
  padding: 0.6em;
  font-size: 1em;
  border-radius: 0.3em;
  border: 1px solid #ccc;
  width: 24em;
  background-color: #ffffff;
  @media only screen and (max-width: 600px) {
    width: 62vw;
    padding: 2.2vw;
  }
}

input[type='text']:focus {
  border-color: #333;
}

input[type='text']::placeholder {
  color: #ccc;
  font-style: italic;
}

button[type='submit'] {
  padding: 0.7em 1.3em;
  background-color: #295dbc;
  color: #fff;
  border-radius: 0.3em;
  border: none;
  font-size: 1em;
  cursor: pointer;
  margin-left: 1em;
}
</style>
