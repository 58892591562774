/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'braindate': {
    width: 32,
    height: 34,
    viewBox: '0 0 32 34',
    data: '<path pid="0" d="M24 0c5.339 0 8 5.074 8 8.62 0 3.546-.705 20.52-22.769 25.245-2.839.538-4.579-.585-3.077-2.463 1.502-1.877 4.617-4.958 1.231-7.388C3.999 21.584 0 17.258 0 11.699 0 6.139 3.869 4.31 6.769 4.31c5.173 0 7.385 3.695 7.385 3.695S16.103 0 24 0z"/>'
  }
})
