/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'memo-story': {
    width: 14,
    height: 12,
    viewBox: '0 0 14 12',
    data: '<path pid="0" d="M8.28 10H.72c-.398 0-.72.448-.72 1s.322 1 .72 1h7.56c.398 0 .72-.448.72-1s-.322-1-.72-1zM14 6c0-.552-.313-1-.7-1H.7C.313 5 0 5.448 0 6s.313 1 .7 1h12.6c.387 0 .7-.448.7-1m-.7-4H.7C.313 2 0 1.552 0 1s.313-1 .7-1h12.6c.387 0 .7.448.7 1s-.313 1-.7 1" fill-rule="evenodd"/>'
  }
})
