<template>
  <div class="SettingsPanel">
    <div class="header">
      <div class="title">
        {{ $t('title') }}
      </div>
      <div class="subtitle">
        {{ $t('subtitle') }}
      </div>
    </div>
    <div class="body">
      <div class="options">
        <GoalOption
          v-for="option of options"
          :key="option"
          class="Option"
          :selected="option === selectedOption"
          :number-of-braindates="option"
          :saving="saving && option === selectedOption"
          @click="select(option)" />
      </div>
    </div>
    <div class="footer --global-clickable">
      <div
        v-if="firstTime"
        class="saveButton"
        @click="saveSettings()">
        {{ $t('save') }}
      </div>
      <div
        v-else
        @click="cancelSettings()">
        {{ $t('cancel') }}
      </div>
    </div>
  </div>
</template>

<translations>
  title: 'Set your daily goal'
  title_no: 'Sett ditt daglige mål'
  subtitle: 'How many minutes do you want to train your brain?'
  subtitle_no: 'Hvor mange minutter vil du trene hjernen?'
  cancel: 'Cancel'
  cancel_no: 'Avbryt'
  save: 'Save'
  save_no: 'Lagre'
</translations>

<script>
import GoalOption from './GoalOption';

export default {
    components: { GoalOption },
    data() {
        return {
            options: [1, 2, 3, 5, 7, 10],
            selectedOption: this.$store.state.moduleApp.memolife.dailyRequiredBraindates,
            saving: false,
            firstTime: this.$store.state.moduleApp.memolife.dailyRequiredBraindatesNotSetByUser
        };
    },
    methods: {
        cancelSettings() {
            this.$emit('request-exit');
        },
        saveSettings() {
            this.saving = true;
            setTimeout(() => {
                this.$store.dispatch('moduleApp/setDailyRequiredBraindates', { value: this.selectedOption });
                this.$emit('request-exit', this.firstTime);
            }, 500);
        },
        select(option) {
            this.selectedOption = option;
            this.saveSettings();
        }
    }
};
</script>

<style lang="scss" scoped>
.SettingsPanel {
}
.body {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.header {
    padding: 0 1em;
    padding-top: 2em;
}
.title {
    text-align: center;
    font-size: 140%;
}
.subtitle {
    text-align: center;
    color: rgba(black, 0.5);
    margin-top: 0.5em;
}
.footer {
    border-top: 1px solid rgba(black, 0.05);
    text-align: center;
    color: $standardButtonColor;
    padding: 1em;
}
.options {
    display: flex;
    flex-wrap: wrap;
    width: 16em;
}
.Option {
    padding: 1em;
    width: 8em;
}

.saveButton {
    background-color: $standardButtonColor;
    color: white;
    font-weight: 600;
    padding: 0.5em 0;
    border-radius: 0.5em;
}
</style>
