/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'habit-icon': {
    width: 25,
    height: 19,
    viewBox: '0 0 25 19',
    data: '<g _fill="#C7C7C7" fill-rule="evenodd"><path pid="0" d="M12.618 10.224C12.296 2.44 17.764 1.83 25 0c-.643 6.256-2.814 11.769-10.544 11.902-1.447 1.984-1.838 4.883-1.838 5.646.322 1.831-2.412 1.984-2.412.153-.09-3.51 3.216-8.067 9.809-14.039 0 0-4.824 1.374-7.397 6.562z"/><path pid="1" d="M11.735 10.775C11.409 3.671 6.519 3.517 0 2.435c.978 5.406 3.097 10.039 11.083 9.73C8.964 8.305 6.03 6.297 4.726 5.37c0 0 4.564 1.699 7.009 5.405z"/></g>'
  }
})
