/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'reveal-summary': {
    width: 33,
    height: 43,
    viewBox: '0 0 33 43',
    data: '<path pid="0" d="M11.533 1.926l-2.5 19.591h-6.67c-.625 0-1.25.417-1.459.834-.208.625-.208 1.25.208 1.667l14.381 17.507c.209.417.834.625 1.25.625.417 0 .834-.208 1.251-.625l14.59-17.507c.208-.208.416-.625.416-1.042 0-.208 0-.417-.208-.625-.209-.625-.834-.834-1.46-.834h-6.669l-2.5-19.59c0-.835-.834-1.46-1.668-1.46h-7.294c-.834 0-1.46.625-1.668 1.46z"/>'
  }
})
