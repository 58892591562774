export class ModalService {
    containerMap = {};
    channels = {};

    addTarget(name, instance) {
        this.containerMap[name] = instance;
    }
    removeTarget(name) {
        delete this.containerMap[name];
    }
    open(options) {
        const session = new ModalSession(options);
        const channel = this._getChannel(options.channelId || 'default');
        channel.queue.push(session);
        if (channel.activeSession) {
            return;
        }
        return this._open(channel.id);
    }
    _open(channelId) {
        const channel = this._getChannel(channelId);
        const session = channel.queue.shift();
        const container = this.containerMap[session.target];
        container.modalOpen(session);
        channel.activeSession = session;
        session.waitDone().then(() => {
            channel.activeSession = null;
            if (channel.queue.length) {
                this._open(channelId);
            }
        });
        return session;
    }
    _getChannel(channelId) {
        if (!this.channels[channelId]) {
            this.channels[channelId] = {
                id: channelId,
                queue: [],
                activeSession: null
            };
        }
        return this.channels[channelId];
    }
}

export default {
    install(Vue) {
        Vue.prototype.$modal = new ModalService();
    }
};

class ModalSession {
    constructor({ component, props, hostOptions, target }) {
        this.component = component;
        this.target = target;
        this.props = props || {};
        this.hostOptions = hostOptions || {};

        this.donePromise = null;
        this.resolve = null;
    }
    waitDone() {
        if (!this.donePromise) {
            this.donePromise = new Promise(resolve => {
                this.resolve = resolve;
            });
        }
        return this.donePromise;
    }
    close(message) {
        if (this.resolve) {
            this.resolve(message);
            this.resolve = null;
        }
    }
}

const ModalHostMixin = {
    props: {
        name: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            modalSession: null,
            modalSessionId: 0
        };
    },
    methods: {
        modalOpen(modalSession) {
            this.modalSessionId++;
            this.modalSession = modalSession;
        },
        modalClose(message) {
            if (this.modalSession) {
                this.modalSession.close(message);
                this.modalSession = null;
            }
        }
    },
    mounted() {
        this.$modal.addTarget(this.name, this);
    },
    destroyed() {
        this.$modal.removeTarget(this.name);
    }
};

export { ModalHostMixin };
