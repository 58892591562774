/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menu/home': {
    width: 24,
    height: 22,
    viewBox: '0 0 24 22',
    data: '<path pid="0" d="M23.446 8.512l-9.161-7.45a3.673 3.673 0 00-4.606 0L.543 8.512A1.139 1.139 0 00.19 9.77c.151.453.58.755 1.057.755h2.517v7.425a3.147 3.147 0 003.146 3.146H17.08a3.147 3.147 0 003.146-3.146v-7.425h2.517c.478 0 .906-.302 1.057-.755a1.1 1.1 0 00-.353-1.258zM13.38 19.334H10.61v-4.278h2.769v4.278zm5.965-10.57a.892.892 0 00-.881.88v8.306c0 .755-.63 1.384-1.384 1.384H15.14v-5.16a.892.892 0 00-.881-.88H9.73a.892.892 0 00-.882.88v5.16H6.91c-.755 0-1.384-.629-1.384-1.384V9.644a.892.892 0 00-.88-.88H3.008l7.777-6.318c.68-.554 1.687-.554 2.391 0l7.803 6.317h-1.636z" _fill="#658CBD" fill-rule="nonzero"/>'
  }
})
