/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'neuron': {
    width: 22,
    height: 17,
    viewBox: '0 0 22 17',
    data: '<path pid="0" d="M21.19 8.454c-.355.068-.73.07-1.119.035.492-.314.941-.769 1.34-1.379a.5.5 0 10-.837-.546c-.723 1.107-1.54 1.542-2.512 1.384-1.636-.343-2.746-.32-4.512-.063-.729.107-.864.125-1.167.152a5.18 5.18 0 01-1.598-.08c-1.325-.493-1.79-1.445-1.683-3.232.012-.02.03-.037.04-.06a7.79 7.79 0 00.364-1.158c.746-.493 1.261-1.125 1.52-1.894a.5.5 0 00-.948-.318 2.417 2.417 0 01-.426.766A5.688 5.688 0 009.403.348a.5.5 0 10-.953.306c.358 1.122.285 2.313-.22 3.593-.013.013-.028.023-.038.04-.846 1.268-2.456 1.62-4.055.824h-.002c-1.256-.614-2.119-1.46-2.61-2.547a.5.5 0 00-.912.411c.241.532.574 1.003.961 1.438a2.417 2.417 0 01-.828-.285.5.5 0 10-.492.87c.707.4 1.512.524 2.396.392.267.188.563.356.871.515.801 1.69.58 3.217-.723 4.573-.025.026-.039.055-.057.083-.026.024-.055.044-.076.075-.235.35-.432.702-.597 1.058-.832.327-1.467.838-1.879 1.537a.501.501 0 00.862.507c.15-.253.341-.472.575-.66a5.693 5.693 0 00-.113 1.727.499.499 0 10.995-.1c-.116-1.16.2-2.302.946-3.438 1.83-.52 3.596.106 4.507 1.455.012.017.027.026.04.042.005.025.002.051.012.076.144.396.314.762.504 1.104-.224.865-.184 1.681.14 2.424a.5.5 0 00.916-.398 2.41 2.41 0 01-.196-.854c.392.43.825.811 1.33 1.105a.498.498 0 00.684-.178.502.502 0 00-.179-.686c-1.029-.602-1.78-1.549-2.26-2.86-.018-.05-.049-.09-.079-.13-.135-1.744.43-2.753 1.861-3.41a6.258 6.258 0 001.736.076c.331-.029.473-.048 1.226-.158 1.65-.24 2.652-.26 4.141.05 1.602.661 2.27 1.355 2.17 2.048a.498.498 0 00.422.566.497.497 0 00.566-.422c.085-.584-.087-1.113-.476-1.596.292-.004.578-.03.858-.085a.498.498 0 00.396-.585.498.498 0 00-.583-.397zm-14.31.991a1.003 1.003 0 110-2.005 1.003 1.003 0 010 2.005z" _fill="#6454DB"/>'
  }
})
