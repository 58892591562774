/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'energy': {
    width: 199,
    height: 279,
    viewBox: '0 0 199 279',
    data: '<g _stroke="#79BFE6"><circle pid="0" cx="97.5" cy="93.5" r="24.5" stroke-width="8"/><path pid="1" d="M65.278 127.5c-9.093-8.488-14.778-20.58-14.778-34C50.5 67.819 71.319 47 97 47s46.5 20.819 46.5 46.5c0 14.713-6.834 27.831-17.5 36.351"/><path pid="2" d="M66 127.5c-10.667-.167-31 8.1-31 40.5s41.333 61.5 62 72c21.167-12.667 63.5-44.8 63.5-72s-22.667-37-34-38.5"/></g><path pid="3" d="M38.5 123.5S18 132 18 169c0 39.921 54.549 78.063 80.074 91C124.216 244.394 180 204.514 180 171c0-26-11.5-39-24-45" _stroke="#6ED3BB" stroke-opacity=".2"/><path pid="4" d="M13 129s-12 8-12 38.188c0 48.612 66.334 95.058 97.374 110.81 31.126-14 99.626-67.567 99.626-108.38 0-10.623-3-31.623-15-38.123" opacity=".08" _stroke="#79BFE6"/><path pid="5" d="M38.635 123.5c-4.564-9.013-7.135-19.206-7.135-30C31.5 56.773 61.273 27 98 27c36.727 0 66.5 29.773 66.5 66.5 0 11.804-3.076 22.89-8.47 32.5" _stroke="#6ED3BB" stroke-opacity=".2"/><path pid="6" d="M182.86 131.5c5.23-11.593 8.141-24.456 8.141-38 0-51.086-41.414-92.5-92.5-92.5s-92.5 41.414-92.5 92.5c0 12.386 2.434 24.204 6.851 35" opacity=".08" _stroke="#79BFE6"/><path pid="7" d="M113.9 143a24.893 24.893 0 00-15.996 5.817A24.896 24.896 0 0081.908 143c-6.635 0-12.906 2.545-17.632 7.271s-7.271 10.998-7.271 17.632c0 6.635 2.545 12.907 7.271 17.633l31.993 31.993c.454.454 1 .636 1.636.636s1.181-.182 1.636-.636l31.993-31.993c4.726-4.726 7.271-10.998 7.271-17.633 0-6.634-2.545-12.906-7.271-17.632-4.727-4.635-10.998-7.271-17.633-7.271zm14.361 39.355l-30.357 30.356-30.357-30.356c-3.908-3.909-5.999-8.998-5.999-14.452 0-5.453 2.09-10.543 5.999-14.36 3.817-3.817 8.998-5.999 14.36-5.999 5.453 0 10.543 2.091 14.36 5.999.909.909 2.363.909 3.181 0 3.817-3.817 8.998-5.999 14.36-5.999 5.454 0 10.543 2.091 14.361 5.999 4 3.817 6.09 8.998 6.09 14.36 0 5.454-2.09 10.543-5.999 14.452z" _fill="#79BFE6" _stroke="#79BFE6" stroke-width="3"/>'
  }
})
