const HomeView = () => import('./views/HomeView');
const LearnView = () => import('./views/LearnView');
const PracticeView = () => import('./views/PracticeView');
const BrowseView = () => import('./views/BrowseView');
const MemorizeView = () => import('./views/MemorizeView');
const QuizView = () => import('./views/QuizView');

export default function generateRoutes({ path, App, overrideViews = {}, extraRoutes = [] }) {
    const routes = [
        {
            path,
            component: App,
            children: [
                {
                    path: '',
                    redirect: { path: `${path}/home` }
                },
                {
                    path: 'home',
                    component: HomeView
                },
                {
                    path: 'learn',
                    // props: {
                    //   tab: 'learn'
                    // },
                    component: LearnView
                },
                {
                    path: 'practice',
                    // props: {
                    //   tab: 'practice'
                    // },
                    component: PracticeView
                },
                {
                    path: 'browse',
                    component: overrideViews.browse || BrowseView
                },
                {
                    path: 'memorize/:itemId',
                    props: true,
                    component: MemorizeView
                },
                {
                    path: 'quiz/:groupIndex',
                    props(route) {
                        return {
                            groupIndex: parseInt(route.params.groupIndex, 10)
                        };
                    },
                    component: QuizView
                },
                ...extraRoutes
            ]
        }
    ];

    return routes;
}
