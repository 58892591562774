export default {
    singlesys: {
        title: 'Memolife: Single System',
        title_no: 'Memolife: Enkel-systemet',
        intro:
            '<p>One system to bind them all … . Seriously, though, it’s not that far from the truth. The Memolife System is the master system which gives you almost endless possibilities.</p><p>The Memolife System is a number-shape system which uses the objects that the numbers 0 to 9 and 00 to 99 look like and links them with people and actions. As a result you can create cool movie scenes in your mind to remember whatever you like at lightning speed. But above all, each number code is linked to a unique Brain Improvement Area, which lets you train your brain, accelerate your knowledge or improve your skills.</p><p> Let’s look at some tips and examples in the article right away ...</p>',
        intro_no:
            '<p>Og et system som binder dem alle ... nei, men seriøst, det er ikke så langt fra sannheten. Memolife-systemet er mastersystemet som gir deg nesten uendelige muligheter.</p><p>Memolife-systemet er et tallfigursystem som bruker objektene som tallene mellom 0 til 9 og 00 til 99 ligner på og kobler dem opp med mennesker og handlinger. Som resultat kan du lage kule filmscener i hodet ditt og bruke dem til å huske nærsagt hva du vil i lynets hastighet. Men, fremfor alt, så er hver kode linket opp til et Hjernetreningsområde, som lar deg få en skarpere hjerne, hurtigere kunnskap og bedre ferdigheter. </p><p> La oss se på noen tips og eksempler i artikkelen med en gang ...</p>',
        hasPractice: 'yes',
        articles: ['memolifesys-single-and-double']
    },
    doublesys: {
        title: 'Memolife: Double System',
        title_no: 'Memolife: Dobbel-systemet',
        intro:
            '<p>One system to bind them all … . Seriously, though, it’s not that far from the truth. The Memolife System is the master system which gives you almost endless possibilities.</p><p>The Memolife System is a number-shape system which uses the objects that the numbers 0 to 9 and 00 to 99 look like and links them with people and actions. As a result you can create cool movie scenes in your mind to remember whatever you like at lightning speed. But above all, each number code is linked to a unique Brain Improvement Area, which lets you train your brain, accelerate your knowledge or improve your skills.</p><p> Let’s look at some tips and examples in the article right away ...</p>',
        intro_no:
            '<p>Og et system som binder dem alle ... nei, men seriøst, det er ikke så langt fra sannheten. Memolife-systemet er mastersystemet som gir deg nesten uendelige muligheter.</p><p>Memolife-systemet er et tallfigursystem som bruker objektene som tallene mellom 0 til 9 og 00 til 99 ligner på og kobler dem opp med mennesker og handlinger. Som resultat kan du lage kule filmscener i hodet ditt og bruke dem til å huske nærsagt hva du vil i lynets hastighet. Men, fremfor alt, så er hver kode linket opp til et Hjernetreningsområde, som lar deg få en skarpere hjerne, hurtigere kunnskap og bedre ferdigheter. </p><p> La oss se på noen tips og eksempler i artikkelen med en gang ...</p>',
        articles: ['memolifesys-single-and-double'],
        hasPractice: 'yes'
    },
    olpasys: {
        title: 'Memolife: OLPA System',
        title_no: 'Memolife: OLPA-systemet',
        intro:
            '<p>One system to bind them all … . Seriously, though, it’s not that far from the truth. The Memolife System is the master system which gives you almost endless possibilities.</p><p>The Memolife System is a number-shape system which uses the objects that the numbers 0 to 9 and 00 to 99 look like and links them with people and actions. As a result you can create cool movie scenes in your mind to remember whatever you like at lightning speed. But above all, each number code is linked to a unique Brain Improvement Area, which lets you train your brain, accelerate your knowledge or improve your skills.</p><p> Let’s look at some tips and examples in the article right away ...</p>',
        intro_no:
            '<p>Og et system som binder dem alle ... nei, men seriøst, det er ikke så langt fra sannheten. Memolife-systemet er mastersystemet som gir deg nesten uendelige muligheter.</p><p>Memolife-systemet er et tallfigursystem som bruker objektene som tallene mellom 0 til 9 og 00 til 99 ligner på og kobler dem opp med mennesker og handlinger. Som resultat kan du lage kule filmscener i hodet ditt og bruke dem til å huske nærsagt hva du vil i lynets hastighet. Men, fremfor alt, så er hver kode linket opp til et Hjernetreningsområde, som lar deg få en skarpere hjerne, hurtigere kunnskap og bedre ferdigheter. </p><p> La oss se på noen tips og eksempler i artikkelen med en gang ...</p>',
        articles: ['memolifesys-single-and-double'],
        hasPractice: 'yes'
    },
    journeysys: {
        title: 'The Journey System',
        title_no: 'Reise-systemet',
        intro:
            "<p>Let’s take a journey down memory lane. Think back to the best holiday you've had. I bet it’s quite easy for you to remember where you went and what it looked like there, right?</p><p>The Journey system lets you use the memories you already have and add new memories – the things you want to memorize – on top of them, simply by using different locations in the journeys of your life. Pretty cool, don’t you think?</p><p> Let’s look at some tips and examples in the article right away ...</p>",
        intro_no:
            '<p>La oss ta en tur tilbake til gamle minner. Tenk på den beste ferien du noen gang har hatt. Det er sikkert enkelt for deg å huske hvor du gikk og hvordan det så ut der du var, stemmer ikke det?</p><p>Reise-systemet lar deg bruke disse minnene dine og legge til ny hukommelse - alt det nye du vil huske - ved at du rett og slett bruker forskjellige steder fra minnene i livet ditt. Ganske kult, egentlig, synes du ikke?</p><p> La oss ta en titt på noen eksempler og tips i artikkelen med en gang ...</p>',
        articles: ['journeysys'],
        hasPractice: 'no'
    },
    namesys: {
        title: 'Name System',
        title_no: 'Navn-systemet',
        intro:
            '<p>It’s an embarassing feeling to forget someone’s name - in particular if they remember yours. Luckily there’s a good way to solve this, and once you get the hang of it you’ll remember everyone’s names at the next party.</p><p>Enter the Name System. When meeting a new person all you have to do is focus on what the name reminds you of  and visually connect it with the most dominant facial feature of the person you meet. Later, when looking at that person you’ll automatically see your mental image in the person’s face and be reminded of the name.</p><p> Let’s look at some tips and examples in the article and you’ll get the hang of it before you know it....</p>',
        intro_no:
            '<p>Det er en flau følelse å glemme noens navn - særlig hvis de husker ditt. Heldigvis finnes det en god måte å løse dette på, og når du mestrer teknikkene kommer du til å huske alles navn på neste fest.</p><p>Bank, bank ... og inn kommer navne-systemet. Når du møter en ny person er alt du trenger å gjøre å fokusere på hva navnet minner deg om og visuelt knytte dette opp mot den delen av ansiktet til personen som er mest tydelig for deg. Senere, når du møter samme person igjen, kommer du automatisk til å se det mentale bilet du har laget og bli minnet på hva personen heter. </p><p> La oss ta en titt på noen tips og eksempler i denne artikkelen ... du kommer til å få det til før du vet ordet av det ...</p>',
        articles: ['names-and-faces'],
        hasPractice: 'yes'
    },
    clonesys: {
        title: 'Clone System',
        title_no: 'Klone-systemet',
        intro:
            "<p>What if you could clone yourself 3 to 4 times. How much more wouldn't you get done?</p><p>Well, when it comes to memory the Clone System lets you do exactly that. Simply by adding the elements ice, fire, sand or water to your existing memory codes you can easily recycle them. Taking the Memolife Single code for 2=swan as an example - with a normal swan and a swan made out of ice - you could suddenly have both a no. 2 and no. 12. Same same, but different!</p><p>Why don’t we look at some tips and examples in the article right away?</p>",
        intro_no:
            '<p>Hva hvis du kunne klone deg selv 3 til 4 ganger. Hvor mye mer ville du ikke fått gjort?</p><p>For hukommelse fungerer Klonesystemet akkurat slik. Ved å legge til elementer som is, ild, sand og vann til dine eksisterende huskekoder kan du enkelt bruke huskekodene på nytt. Se for eksempel for deg svanen (nr. 2) i Memolife Singelsystemet som en vanlig svane og en som er laget av is. Dermed har du plutselig en kode for nr. 2 og en for nr. 12. Praktisk, ikke sant?</p><p>La oss at en titt på noen flere eksempler og tips i artikkelen med en gang.</p>',
        articles: ['clone'],
        hasPractice: 'no'
    },
    alphabetsys: {
        title: 'The Alphabet System',
        title_no: 'Alfabet-systemet',
        intro:
            '<p>Improve your memory with apes, bears and cobras! Yes, a trip to the zoo can do wonders for your memory, especially if you use the animals there as memory pegs.</p><p>The Alphabet System uses animals as a fixed code or picture for each letter in the alphabet, just like what the number-shape and rhyme systems do for numbers. So, all you have to do is link these animals  up with the things you want to remember. And let’s face it, who doesn’t love animals?</p><p> Let’s look at some tips and examples in the article right away ...</p>',
        intro_no:
            '<p>Få bedre hukommelse med aper, bjørner og cobraer! Jepp, en tur til dyrehagen kan gjøre underverker for hukommelsen, særlig hvis du bruker dyrene som huskeknagger.</p><p>Alfabet-systemet bruker dyr som faste koder for å representere hver bokstav i alfabetet, akkurat som rim- eller Memolife-systemet gjør for tall. Alt du behøver å gjøre er å linke disse dyrene med det du vil huske. Uansett, hvem er vel ikke glad i dyr?</p><p> La oss ta en titt på noen eksempler og teknikker i artikkelen med en gang ... </p>',
        articles: ['alphabetsys'],
        hasPractice: 'yes'
    },
    rhymesys: {
        title: 'Rhyme System',
        title_no: 'Rim-systemet',
        intro:
            '<p>Eeny, miny, moe - memory is fun once you get into the flow. Let yourself be seduced by how easy it is to remember when you put rhyming into a system! </p><p>The rhyme system is a simple peg system for numbers, where we create a keyword for each of the numbers from one to twenty which rhymes with the numbers (1 = sun, 2 = shoe, 3 = tree, 4 = door). Once you know your mental images for the numbers all you have to do is link these images up with the things you want to remember.</p><p> It’s fast to get going, so let’s look at some tips and examples in the article right away ...</p>',
        intro_no:
            '<p>Elle, melle, deg fortelle - hukommelse er morsomt når du kommer inn i flyten. La deg selv bli forført av hvor enkelt det er å huske ved å putte rim i system! </p><p>Rim-systemet er et enkelt knaggsystem for tall hvor vi lager et nøkkelord for hvert av tallene fra 1 til 20 som rimer med tallene (1=ben, 2=do, 3=tre, 4=slire). Når du har disse mentale bildene klart for deg er alt du trenger å gjøre å linke dem opp med tingene du vil huske.</p><p> Det er hurtig å komme i gang, så la oss kikke på noen tips og eksempler i artikkelen med en gang ...</p>',
        articles: ['rhymesys'],
        hasPractice: 'yes'
    },
    timesys: {
        title: 'Time System',
        title_no: 'Tids-systemet',
        intro:
            "<p>'Time waits for noone' as the Stones say, but wouldn't it be great if we at least could remember what we did with our time?</p><p>Enter the Time System - a memory system with codes for all the months and all the weekdays. Used in tandem with the Memolife Double System, there won't be a historic date from the past or an event in the future you'll forget.</p><p>Why don’t we look at some tips and examples in the article right away?</p>",
        intro_no:
            '<p>Hva er det som går og går, men aldri kommer frem? Tid er noe vi aldri får igjen, men ihvertfall er det å huske tid innen rikkevidde.</p><p>Inn på banen med Tidssystemet - et huskesystem med koder for alle månedene og ukedagene. Bruker du det riktig i tandem med Memolife Dobbelsystemet kommer det ikke til å være en historisk hendelse fra fortiden eller en avtale i fremtiden som du kommer til å glemme.</p><p>La oss at en titt på noen flere eksempler og tips i artikkelen med en gang.</p>',
        articles: ['timesystem'],
        hasPractice: 'no'
    },
    knowledgesys: {
        title: 'Knowledge System',
        title_no: 'Kunnskaps-systemet',
        intro:
            "<p>Have you ever watched a movie of someone else watching a movie with someone else watching a movie ... ?</p><p>No no, this is not one of those silly Russian doll situations, although it's a bit similar. The Knowledge System is nothing but a list of permanently memorized knowledge, which you after a while know so well that you can use the knowledge bits as a memory system. For instance the list of American presidents or the periodic table.</p><p>This way, you'll always keep on expanding your memory systems and your knowledge areas. Cool, right?</p>",
        intro_no:
            '<p>Har du noen gang sett på en film om en person som ser på en film om en person som ... ?</p><p>Nei, dette er ikke en av disse russiske dukkescenarioene, ihvertfall ikke helt. Kunnskapssystemet er ikke noe annet enn en liste med informasjon du mestrer såpass bra at det kan brukes som huskekoder i et eget huskesystem. Slik som listen med amerikanske presidenter eller det periodesystemet.</p><p>Dermed kan du både fortsette å utvide kunnskapen din og repertoaret med huskesystemer på en gang. Kult, ikke sant?</p>',
        articles: ['knowledgesystem'],
        hasPractice: 'no'
    },
    similaritysys: {
        title: 'Similarity System',
        title_no: 'Likhets-systemet',
        intro:
            "<p>The word 'un coche' (= car in Spanish) looks like a 'couch' in English, doesn't it?</p><p>Well, at least it is written quite similarly. And, similarities is what we're looking for with the Similarity System, whether we're dealing with vocabularies, names or passwords with letters. All you have to do is notice the spelling or pronunciation of a word and find something similar. In a snap you'll have a memory code you can use in a memory fantasy - such as the COUCH with four wheels driving down a Spanish street.</p>",
        intro_no:
            "<p>Ordet 'un coche' (= bil på spansk) høres faktisk ut som en 'kåt skje' på norsk, gjør det ikke?</p><p>Vel, det uttales ihvertfall ganske likt. Og likheter er nøyaktig det vi leter etter med Likhetssystemet, enten det dreier seg om gloser, navn eller passord med bokstaver. Alt som skal til er å se hvordan ordet staves eller lytte til hvordan det uttales og finne noe konkret som ligner. Vips, så får du en huskekode du kan bruke i en huskefantasi - slik som en KÅT SKJE foran på panseret til en spansk BIL.</p><p>La oss at en titt på noen flere eksempler og tips i artikkelen med en gang.</p>",
        articles: ['language-similarity'],
        hasPractice: 'no'
    },
    highlightsys: {
        title: 'Highlight System',
        title_no: 'Markør-systemet',
        intro:
            "<p>What if we could use a mental marker to highlight text and memorize it in our brains at the same time?</p><p>And in on a shiny horse, the Highlight System cometh (at least almost)- a memory system with one focus area, and that is to choose which words to highlight in a text. It's as easy and as hard as that.</p><p>Easy, because most of the words in texts really don't have to be remembered. Hard, because you'll need some practice to choose the right words in which systems to combine to remember them with. But what a feeling when you master it.</p>",
        intro_no:
            '<p>Hva hvis vi kunne bruke en mental markørtusj til å markere tekst og lagre teksten i hukommelsen på en gang?</p><p>Tuuu-tu-tu-tuu-tuuu (trompetlyd) ... og inn kommer Markørsystemet ridende (ihvertfall nesten) - et system som kun fokuserer på å velge ut hvilke ord du skal velge. Både enkelt og vanskelig på en gang.</p><p>Enkelt, for det er svært få ord av en tekst du egentlig trenger å huske. Vanskelig, fordi du må øve deg på å velge riktige ord og kombinere de riktige huskesystemene for å huske dem med. Men for en følelse når det først sitter.</p>',
        articles: ['highlightsys'],
        hasPractice: 'no'
    },
    symbolizationsys: {
        title: 'Symbolization System',
        title_no: 'Symbol-systemet',
        intro:
            "<p>Green light means go, a heart pounds for love and small feet symbolize ... ?</p><p>No no .... but seriously though, we use symbols all the time in our everyday lives. And that's also what we'll be doing when we remember abstract words, facts, definitions, historic events, etc. You see, our brains remember symbols much better than abstract words, such as green light, roses and well ... small feet.</p><p>Why don’t we look at some tips and examples in the article right away?</p>",
        intro_no:
            '<p>Grønt lys betyr kjør, et hjerte slår for kjærlighet og små føtter betyr ...?</p><p>Nei ... seriøst, vi bruker symboler hele tiden i hverdagen. Og, det er også det vi kommer til å bruke når vi skal huske abstrakte ord, fakta, definisjoner og historiske hendelser. Hjernen vår husker nemlig symboler mye lettere enn abstrakte ord, slik som nettopp grønt lys, et hjerte og ... små føtter.</p><p>La oss at en titt på noen flere eksempler og tips i artikkelen med en gang.</p>',
        articles: ['symbolizationsys'],
        hasPractice: 'no'
    },
    chainsys: {
        title: 'The Chain System',
        title_no: 'Lenke-systemet',
        intro:
            '<p>A chain is never stronger than the weakest link, and with the link system you’ll learn to build a strong chain of as many links as you like. </p><p>The link system let’s you get going right away by linking the new things you want to remember with mnemonic associations to things you already know. As such, it’s more of a methodology rather than a system, since it doesn’t use any memory codes or pegs, but that doesn’t make it any less powerful.</p><p> Why don’t we look at some tips and examples in the article right away?</p>',
        intro_no:
            '<p>Et kjede er aldri sterkere enn det svakeste leddet, og med Lenke-systemet kommer du til å lære å bygge et sterkt hukommelseskjede med så mange minner du vil.</p><p>Link-systemet gir deg en flying start ved at du bruker tingene du vil huske og knytter dem direkte opp mot enda flere ting du vil huske - uten at du må lære deg huskekoder. Man kan nesten mer kalle det en metode fremfor et system, nettop på grunn av at det mangler koder, men det gjør det absolutt ikke noe mindre kraftfullt. </p><p> Hvorfor tar vi ikke en titt på noen tips og eksempler i denne artikkelen med en gang?</p>',
        articles: ['linksys'],
        hasPractice: 'no'
    }
};
