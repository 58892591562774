/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menu/in-progress': {
    width: 20,
    height: 17,
    viewBox: '0 0 20 17',
    data: '<path pid="0" d="M10.013 17h-.025a.48.48 0 01-.28-.09c-.267-.189-6.58-4.691-8.905-8.489l-.01-.015A5.537 5.537 0 010 5.546 5.553 5.553 0 015.547 0C7.317 0 8.962.841 10 2.238A5.545 5.545 0 0114.453 0 5.553 5.553 0 0120 5.546a5.537 5.537 0 01-.803 2.875c-2.325 3.798-8.638 8.3-8.906 8.49a.477.477 0 01-.278.089z" _fill="#658CBD" fill-rule="evenodd"/>'
  }
})
