<template>
  <div class="AnimatedCheckmark">
    <div class="container">
      <svg
        class="svg"
        xheight="200"
        viewBox="0 0 200 200"
        xwidth="200"
        xmlns="http://www.w3.org/2000/svg">
        <g
          class="star"
          transform="translate(12 46)">
          <path
            d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z"
            :fill="color" />
        </g>
        <g
          class="star"
          transform="translate(178 84)">
          <path
            d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z"
            :fill="color" />
        </g>
        <g
          class="star"
          transform="translate(32 162)">
          <path
            d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z"
            :fill="color" />
        </g>

        <g
          class="star"
          transform="translate(82 6)">
          <path
            d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z"
            :fill="color" />
        </g>

        <g
          class="star"
          transform="translate(125 162)">
          <path
            d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z"
            :fill="color" />
        </g>

        <g
          class="star"
          transform="translate(16 16)">
          <path
            d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z"
            :fill="color" />
        </g>

        <g
          class="shape"
          transform="translate(40 43)">
          <path
            d="M107.332 72.938c-1.798 5.557 4.564 15.334 1.21 19.96-3.387 4.674-14.646 1.605-19.298 5.003-4.61 3.368-5.163 15.074-10.695 16.878-5.344 1.743-12.628-7.35-18.545-7.35-5.922 0-13.206 9.088-18.543 7.345-5.538-1.804-6.09-13.515-10.696-16.877-4.657-3.398-15.91-.334-19.297-5.002-3.356-4.627 3.006-14.404 1.208-19.962C10.93 67.576 0 63.442 0 57.5c0-5.943 10.93-10.076 12.668-15.438 1.798-5.557-4.564-15.334-1.21-19.96 3.387-4.674 14.646-1.605 19.298-5.003C35.366 13.73 35.92 2.025 41.45.22c5.344-1.743 12.628 7.35 18.545 7.35 5.922 0 13.206-9.088 18.543-7.345 5.538 1.804 6.09 13.515 10.696 16.877 4.657 3.398 15.91.334 19.297 5.002 3.356 4.627-3.006 14.404-1.208 19.962C109.07 47.424 120 51.562 120 57.5c0 5.943-10.93 10.076-12.668 15.438z"
            :fill="color" />
        </g>

        <g
          class="checkmark"
          transform="translate(78 86)">
          <path
            d="M47.248 3.9L43.906.667a2.428 2.428 0 0 0-3.344 0l-23.63 23.09-9.554-9.338a2.432 2.432 0 0 0-3.345 0L.692 17.654a2.236 2.236 0 0 0 .002 3.233l14.567 14.175c.926.894 2.42.894 3.342.01L47.248 7.128c.922-.89.922-2.34 0-3.23" />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
import { TweenMax, TimelineMax, Linear, Elastic } from 'gsap';

export default {
    props: {
        color: {
            type: String,
            default: 'black'
        }
        // heartColor: {
        //   type: String,
        //   default: '#F04151'
        // }
    },
    mounted() {
        TweenMax.to(this.$el.querySelector('.shape'), 35, {
            transformOrigin: 'center',
            rotation: 360,
            ease: Linear.easeNone,
            repeat: -1
        });

        const stars = [
            { delay: 0.7, size: 12 },
            { delay: 1.4, size: 18 },
            { delay: 2.1, size: 10 },
            { delay: 2.8, size: 20 },
            { delay: 3.5, size: 14 },
            { delay: 4.2, size: 10 }
        ];
        const starElems = this.$el.querySelectorAll('.star');

        const tl1 = (this._tl1 = new TimelineMax({ repeat: -1 }));

        stars.forEach((star, i) => {
            tl1.fromTo(
                starElems[i],
                0.6,
                {
                    transformOrigin: 'center',
                    scale: 0,
                    ease: Linear.easeNone
                },
                { scale: (1 * star.size) / 16 },
                star.delay
            );
            tl1.to(
                starElems[i],
                0.6,
                {
                    transformOrigin: 'center',
                    scale: 0,
                    ease: Linear.easeNone
                },
                star.delay + 0.6
            );
        });

        const tl2 = (this._tl2 = new TimelineMax());

        tl2.fromTo(
            this.$el.querySelector('.svg'),
            1,
            {
                transformOrigin: 'center',
                scale: 0,
                ease: Elastic.easeOut.config(1, 0.3) // Linear.easeNone
            },
            { scale: 1, ease: Elastic.easeOut.config(1, 0.5) },
            0
        );

        tl2.fromTo(
            this.$el.querySelector('.checkmark'),
            0.5,
            {
                transformOrigin: 'center',
                scale: 0,
                ease: Linear.easeNone
            },
            { scale: 1 },
            0.2
        );
    },
    beforeDestroy() {
        if (this._tl1) {
            this._tl1.kill();
        }
        if (this._tl2) {
            this._tl2.kill();
        }
    }
};
</script>

<style lang="scss" scoped>
$duration: 1.8s;
.AnimatedCheckmark {
    position: relative;
}

.container {
    position: absolute;
    width: 100%;
    height: 100%;
}

.checkmark {
    fill: white;
}
</style>
