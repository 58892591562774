/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'activities/memo-details': {
    width: 34,
    height: 26,
    viewBox: '0 0 34 26',
    data: '<path pid="0" d="M32.686 12.984c-.547.104-1.126.107-1.726.053.76-.482 1.452-1.18 2.067-2.117a.766.766 0 00-.223-1.063.774.774 0 00-1.068.224c-1.115 1.7-2.375 2.368-3.875 2.126h-.001c-2.522-.527-4.234-.492-6.958-.097-1.125.164-1.333.192-1.8.233-.91.08-1.673.046-2.465-.123-2.044-.757-2.763-2.219-2.597-4.963.019-.032.047-.057.062-.092.242-.6.426-1.192.562-1.779 1.15-.757 1.945-1.727 2.343-2.908a.769.769 0 00-.486-.972.773.773 0 00-.977.483c-.143.429-.364.82-.655 1.177-.005-.894-.11-1.774-.384-2.631a.772.772 0 00-1.47.47c.552 1.723.44 3.552-.341 5.518-.019.02-.042.035-.057.06C11.332 8.532 8.848 9.07 6.382 7.85c-.002 0-.002-.002-.003-.002-1.938-.941-3.269-2.242-4.026-3.91a.773.773 0 00-1.021-.384.767.767 0 00-.386 1.015c.372.817.886 1.54 1.482 2.209a3.74 3.74 0 01-1.277-.438.774.774 0 00-1.052.29.766.766 0 00.293 1.046c1.09.615 2.333.805 3.696.602.412.289.869.547 1.344.791 1.235 2.596.893 4.94-1.116 7.023-.038.04-.06.085-.087.128-.04.036-.085.067-.118.115a11.822 11.822 0 00-.92 1.625c-1.284.502-2.263 1.286-2.899 2.36a.767.767 0 00.273 1.052.772.772 0 001.057-.273c.23-.389.526-.725.887-1.014-.182.875-.264 1.758-.175 2.652.042.423.42.731.844.688a.768.768 0 00.691-.841c-.179-1.783.309-3.536 1.46-5.28 2.822-.799 5.546.163 6.951 2.234.019.026.042.04.062.065.008.038.003.078.019.116.222.609.484 1.17.777 1.696-.345 1.328-.284 2.582.215 3.723.17.39.624.566 1.014.399.39-.17.57-.622.4-1.01-.18-.415-.28-.851-.302-1.312.604.66 1.272 1.245 2.051 1.697a.77.77 0 10.78-1.327c-1.588-.925-2.748-2.379-3.487-4.394-.028-.077-.075-.138-.122-.198-.208-2.678.662-4.228 2.87-5.238.87.16 1.716.202 2.679.118.51-.045.73-.074 1.89-.243 2.546-.37 4.091-.4 6.388.076 2.471 1.016 3.502 2.082 3.346 3.146-.062.42.23.81.652.87a.766.766 0 00.874-.649c.13-.896-.135-1.709-.735-2.45a7.55 7.55 0 001.324-.131.768.768 0 10-.288-1.508zM10.4 14.4a1.6 1.6 0 110-3.2 1.6 1.6 0 110 3.2z" _fill="#111" fill-rule="nonzero"/>'
  }
})
