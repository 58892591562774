import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import QueueLink from 'apollo-link-queue';
import { HttpLink } from 'apollo-link-http';
import { RetryLink } from 'apollo-link-retry';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { logErrorMessages } from '@vue/apollo-util';
import fetch from 'unfetch';

import settings from '@/settings';
import vueAuth from '@/auth';

const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP || settings.endpoints.graph;

const httpLink = new HttpLink({
    uri: httpEndpoint,
    fetch,
});

const errorLink = onError(error => {
  if (error.networkError && error.networkError.statusCode === 403) {
    // store.dispatch('moduleAuth/logout');
    vueAuth.logout();
  } else {
    logErrorMessages(error);
  }
});

const queueLink = new QueueLink();

const retryLink = new RetryLink({
    attempts: (count, operation, error) => {
        return true;
    },
    delay: (count, operation, error) => {
        return count * 1000;
    }
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    // const token = store.getters['moduleAuth/token'];
    const token = vueAuth.getToken();
    
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : ''
        }
    };
});

window.addEventListener('online', event => {
    console.debug('online, open queue link');
    queueLink.open();
});

window.addEventListener('offline', event => {
    console.debug('offline, close queue link');
    queueLink.close();
});

const link = ApolloLink.from([queueLink, retryLink, authLink, errorLink, httpLink]);

const apolloClient = new ApolloClient({
    // link: authLink.concat(httpLink),
    link: link,
    cache: new InMemoryCache()
    // connectToDevTools: true
});

export default apolloClient;
