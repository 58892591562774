import coverImage from './images/cover.jpg';
import appImage from './images/app-icon.png';
import themeSound from './sounds/theme.mp3';
import appMessages from './i18n';

export default {
    windowBackground: '#222',
    background: 'linear-gradient(120deg, #33548F, #487090)',
    levelItem: {
        gradient: 'rgba(255,255,255,0.1)',
        gradientActive: '#2A4880'
    },
    rankingRow: {
        colorActive: '#2A4880'
    },
    primaryColor: '#fff',
    textColorOnPrimaryColor: 'black',
    coverImage,
    appImage,
    themeSound,
    appMessages
};
