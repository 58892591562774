/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'library': {
    width: 50,
    height: 51,
    viewBox: '0 0 50 51',
    data: '<path pid="0" d="M50.003 46.88c0 1.726-1.399 3.125-3.125 3.125H3.128a3.125 3.125 0 110-6.25h6.25V29.692a3.125 3.125 0 016.25 0v14.063h6.25V29.692a3.125 3.125 0 116.25 0v14.063h6.25V29.692a3.125 3.125 0 116.25 0v14.063h6.25c1.726 0 3.125 1.4 3.125 3.125zM.151 19.708a3.125 3.125 0 011.156-3.5L23.183.581a3.125 3.125 0 013.632 0l21.876 15.625a3.125 3.125 0 01-1.813 5.672H3.128A3.125 3.125 0 01.15 19.708zm20.946-7.204a3.906 3.906 0 107.812 0 3.906 3.906 0 00-7.812 0z" _fill="#6B6790" fill-rule="nonzero"/>'
  }
})
