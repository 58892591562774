<template>
  <div class="GameFacesDebug">
    <div class="header">
      GameFacesDebug
    </div>
    <br>

    <br>
    <div>memorize</div>
    <ButtonPlain
      label="skip memorize"
      @click="instance.transition('START_QUIZ')" />

    <br>
    <div>quiz</div>
    <p>
      <ButtonPlain
        label="fake level fail"
        @click="instance.transition('LEVEL_FAIL')" />
      <ButtonPlain
        label="fake level success"
        @click="instance.transition('LEVEL_SUCCEED')" />
    </p>
    <p>
      <ButtonPlain
        label="Fake wrong"
        @click="fakeWrong()" />
      <ButtonPlain
        label="Fake correct"
        @click="fakeCorrect()" />
    </p>
    <br>
    <p>
      <!-- <ButtonPlain @click="showLevelGroupCompletedPopup()" label="show level group completed popup"/> -->
    </p>
    <div />
    <ButtonPlain
      label="Dump state"
      @click="dumpState()" />
  </div>
</template>

<script>
import ButtonPlain from '../components/ButtonPlain';

export default {
    components: { ButtonPlain },
    props: ['instance'],
    data() {
        return {
            levelIndex: 2
        };
    },
    methods: {
        fakeCorrect() {
            // questionController.submit()
            this.instance.transition('SUBMIT_QUESTION_ANSWER', {
                value: this.instance.question.name
            });
        },
        fakeWrong() {},
        showLevelGroupCompletedPopup() {
            //
        },
        dumpState() {
            console.log('------------------------------'); // eslint-disable-line no-console
            console.log(JSON.stringify(this.instance.module.state.session, null, '  ')); // eslint-disable-line no-console
            console.log('------------------------------'); // eslint-disable-line no-console
        }
    }
};
</script>

<style lang="scss" scoped>
.GameFacesDebug {
    padding: 1em;
}
.header {
    font-weight: bold;
}
</style>
