/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'browse-fantasies': {
    width: 21,
    height: 23,
    viewBox: '0 0 21 23',
    data: '<g fill-rule="evenodd"><path pid="0" d="M18.3 6h-5.064C12.553 6 12 6.543 12 7.212v7.576c0 .322.13.63.362.857.232.227.546.355.874.355h5.063c.716 0 1.403-.279 1.91-.776.506-.496.791-1.17.791-1.872v-4.7a2.622 2.622 0 00-.79-1.875A2.728 2.728 0 0018.3 6zM9 7.212C9 6.542 8.447 6 7.765 6H2.7C1.209 6 0 7.186 0 8.65v4.7C0 14.815 1.209 16 2.7 16h5.06c.328 0 .642-.128.874-.355.231-.227.361-.536.361-.857L9 7.212zM10.503 6c.825-.003 1.494-.61 1.497-1.36V1.362C12 .61 11.328 0 10.5 0S9 .61 9 1.362v3.283C9.006 5.395 9.677 6 10.503 6zM10.497 17c-.825.003-1.494.61-1.497 1.36v3.278C9 22.39 9.672 23 10.5 23s1.5-.61 1.5-1.362V18.36c-.003-.752-.675-1.36-1.503-1.36z"/></g>'
  }
})
