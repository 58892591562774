const req = require.context('./auto/', true, /Debug\.vue$/);

const DebugPlugin = {
    install(Vue, options) {
        req.keys().forEach(key => {
            const name = key.slice(2, key.length - 4);
            Vue.component(name, req(key).default);
        });

        Vue.mixin({
            mounted() {
                const dbgComponentName = this.$options.debugComponent || this.$options.name + 'Debug';
                if (Vue.options.components[dbgComponentName]) {
                    menu.add(this._uid, dbgComponentName, this);
                }
            },
            beforeDestroy() {
                menu.remove(this._uid);
            }
        });
    }
};

class Menu {
    entries = new Map();
    add(uid, component, instance) {
        this.entries.set(uid, {
            component: component,
            instance: instance,
            uid: uid
        });
    }

    remove(uid) {
        this.entries.delete(uid);
    }
}

const menu = new Menu();

export default DebugPlugin;
export { menu };
