<template>
  <div
    ref="lavContainer"
    class="Lottie" />
</template>

<script>
import lottie from 'lottie-web';
export default {
    props: {
        options: {
            type: Object,
            required: true
        }
    },
    mounted() {
        this.anim = lottie.loadAnimation({
            container: this.$refs.lavContainer,
            renderer: 'svg',
            loop: true,
            autoplay: false,
            animationData: this.options.animationData,
            prerender: true
        });

        this.$emit('animCreated', this.anim);
        this.anim.addEventListener('loopComplete', () => {
          this.$emit('loopComplete');
        });
        this.anim.addEventListener('complete', () => {
          this.$emit('complete');
        });
    },
    beforeDestroy() {
        this.anim.destroy();
    }
};
</script>
