export default {
    Norway: 'Norway',
    Norway_no: 'Norge',
    Greece: 'Greece',
    Greece_no: 'Hellas',
    Japan: 'Japan',
    Japan_no: 'Japan',
    China: 'China',
    China_no: 'Kina'
};
